import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { Padding } from '../padding';
import { SmallCaps } from '../small-caps';
import { Text } from '../text';

export type BadgeType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'danger'
  | 'danger-outline'
  | 'danger-inverted'
  | 'warning'
  | 'info'
  | 'info-outline'
  | 'dark'
  | 'glitter';

interface Props {
  type?: BadgeType;
  size?: 'small' | 'regular';
  textType?: 'regular' | 'small-caps';
  children: React.ReactNode;
  width?: number;
  borderRadiusSize?: number;
  style?: React.CSSProperties;
  elementRef?: React.RefObject<HTMLDivElement>;
  testId?: string;
  truncate?: boolean;
}

export function Badge(props: Props): JSX.Element {
  const {
    children,
    size = 'small',
    textType = 'regular',
    type = 'secondary',
    width,
    borderRadiusSize,
    style,
    elementRef,
    testId,
    truncate,
  } = props;

  return (
    <StyledBadge
      className={size}
      data-type={type}
      width={width}
      borderRadiusSize={borderRadiusSize}
      ref={elementRef}
      style={style}
      data-testid={`${testId}-badge`}
    >
      {textType === 'small-caps' ? (
        <Padding x={4} y={2}>
          <SmallCaps color="inherit">{children}</SmallCaps>
        </Padding>
      ) : (
        <Text size={size} weight={400} color="inherit" textAlign="center" nowrap truncate={truncate}>
          {children}
        </Text>
      )}
    </StyledBadge>
  );
}

const StyledBadge = styled.div<{ width?: number; borderRadiusSize?: number }>`
  --padding-x: 8px;
  display: flex;
  align-items: center;
  display: inline-block;
  box-sizing: border-box;
  border-radius: ${({ borderRadiusSize }) => borderRadiusSize || 6}px;
  width: ${({ width }) => (width ? `${width}px` : 'fit-content')};
  min-width: calc(72px - (var(--padding-x) * 2));
  padding: 4px var(--padding-x);

  &.regular {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  &[data-type='primary'] {
    background-color: ${colors.brand[400]};
    color: white;
  }
  &[data-type='secondary'] {
    background-color: ${colors.steel[50]};
    color: ${colors.steel[400]};
  }
  &[data-type='tertiary'] {
    border: 1px solid ${colors.peach[500]};
    background-color: transparent;
    color: ${colors.fire[500]};
  }
  &[data-type='success'] {
    background-color: ${colors.grass[100]};
    color: ${colors.grass[500]};
  }
  &[data-type='danger'] {
    background-color: #fff6f8;
    color: ${colors.fire[500]};
  }
  &[data-type='danger-outline'] {
    border: 1px solid ${colors.fire[500]};
    background-color: #fff6f8;
    color: ${colors.fire[500]};
  }
  &[data-type='danger-inverted'] {
    background-color: ${colors.fire[500]};
    color: white;
  }
  &[data-type='info'] {
    background-color: ${colors.brand[100]};
    color: #10376d;
  }
  &[data-type='info-outline'] {
    border: 1px solid ${colors.brand[400]};
    background-color: ${colors.brand[100]};
    color: ${colors.brand[400]};
  }
  &[data-type='dark'] {
    background-color: #021a3c;
    color: white;
  }
  &[data-type='warning'] {
    background-color: ${colors.glitter[200]};
    color: ${colors.glitter[500]};
  }
  &[data-type='glitter'] {
    background-color: ${colors.glitter[100]};
    color: ${colors.steel[500]};
  }
`;
