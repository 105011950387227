import type { LineOfCoverageEditorState, LineOfCoverageEditorAction } from './types';
import { LineOfCoverageEditorActionType } from './types';

export function reducer(
  state: LineOfCoverageEditorState,
  action: LineOfCoverageEditorAction,
): LineOfCoverageEditorState {
  if (action.type === LineOfCoverageEditorActionType.ADD_SELECTED_LOC) {
    const { selectedLineOfCoverageTypeDetails } = state;
    const { lineOfCoverage } = action.payload;

    return {
      ...state,
      selectedLineOfCoverageTypeDetails: [lineOfCoverage, ...selectedLineOfCoverageTypeDetails],
    };
  }
  if (action.type === LineOfCoverageEditorActionType.REMOVE_SELECTED_LOC) {
    const { selectedLineOfCoverageTypeDetails } = state;
    const { index, isDeleting } = action.payload;

    return {
      ...state,
      selectedLineOfCoverageTypeDetails: removeAtIndex(selectedLineOfCoverageTypeDetails, index),
      // if state isDeleting is already `true`, keep it true
      isDeletingCoverage: state.isDeletingCoverage || isDeleting,
    };
  }

  if (action.type === LineOfCoverageEditorActionType.UPDATE_DISPLAY_NAME) {
    const { selectedLineOfCoverageTypeDetails } = state;
    const { displayName, index } = action.payload;
    return {
      ...state,
      selectedLineOfCoverageTypeDetails: updateAtIndex(selectedLineOfCoverageTypeDetails, index, () => ({
        displayName,
      })),
    };
  }

  return state;
}

type UpdateFn<T> = (item: T) => Partial<T>;

function updateAtIndex<T>(group: T[], index: number, updateFn: UpdateFn<T>): T[] {
  const item = group[index];
  return replaceAtIndex(group, index, {
    ...item,
    ...updateFn(item),
  });
}

function replaceAtIndex<T>(group: T[], index: number, updated: T): T[] {
  const newList = group.slice();
  newList.splice(index, 1, updated);
  return newList;
}

function removeAtIndex<T>(group: T[], index: number): T[] {
  const newList = group.slice();
  newList.splice(index, 1);
  return newList;
}
