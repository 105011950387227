import { useRouter } from 'next/router';

import { useCoverageApiBasePath } from '../../provider';

export function useGetApiCacheKey(): (url: string) => string {
  const baseUrl = useCoverageApiBasePath();
  const router = useRouter();
  const appBasePath = router?.basePath || '';

  return (url) => `${appBasePath}${baseUrl}${url}`;
}
