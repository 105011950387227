import { colors, DetailCard, EmployeesRow, Text } from '@newfront-insurance/core-ui';
import type { DetailCardProps } from '@newfront-insurance/core-ui';
import * as React from 'react';

import { AccountDefinitions } from './account-definitions';
import type { AccountCardAccount, AccountCardPublicAccount } from './lib';
import { isPublicAccount } from './lib';

export * from './lib';

interface Props
  extends Pick<
    DetailCardProps,
    | 'actions'
    | 'badgeProps'
    | 'cardProps'
    | 'defaultExpanded'
    | 'eyebrowProps'
    | 'isExpandable'
    | 'isHighlighted'
    | 'isHovering'
    | 'selectableOptions'
  > {
  account: AccountCardAccount | AccountCardPublicAccount;
  /**
   * Use the useEmployees hook from admin-ui to get this value.
   */
  servicingTeam?: Pick<React.ComponentProps<typeof EmployeesRow>, 'employees'>['employees'];
  onCopyEmployeeEmail?: () => void;
  withProgramType?: boolean;
}

export function AccountCard({
  account,
  actions,
  isHighlighted,
  defaultExpanded,
  isExpandable = false,
  onCopyEmployeeEmail,
  servicingTeam,
  cardProps,
  selectableOptions,
  badgeProps,
  eyebrowProps,
  isHovering,
  withProgramType,
}: Props): JSX.Element {
  const dba = isPublicAccount(account) ? account.dba : account.details.dba;
  let subtitle = dba;
  if (withProgramType && account.readableProgramType) {
    subtitle = `${account.readableProgramType}`;
    if (dba) subtitle += ` - ${dba}`;
  }

  return (
    <DetailCard
      title={account.name}
      subtitle={subtitle}
      isHighlighted={isHighlighted}
      isHovering={isHovering}
      defaultExpanded={defaultExpanded}
      isExpandable={isExpandable}
      cardProps={cardProps}
      selectableOptions={selectableOptions}
      actions={actions}
      badgeProps={badgeProps}
      eyebrowProps={eyebrowProps}
    >
      <AccountDefinitions account={account} />

      {servicingTeam && (
        <div className="flex flex-col gap-2">
          <Text size="small" color={colors.steel[400]}>
            Servicing team
          </Text>
          <div className="flex flex-row gap-1">
            <EmployeesRow employees={servicingTeam} variant="expanded" onCopyEmail={onCopyEmployeeEmail} />
          </div>
        </div>
      )}
    </DetailCard>
  );
}
