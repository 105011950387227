import { Box, colors, IconSearch2 as IconGlobalSearch, Text } from '@newfront-insurance/core-ui';
import { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  currentQuery: string;
  onClick: () => void;
  suggestion: string;
}

export function Suggestion({ currentQuery, suggestion, onClick }: Props): JSX.Element {
  const [highlighted, rest] = useMemo<[string, string]>(() => {
    let boundary = 0;
    for (let i = 0; i < currentQuery.length; i += 1) {
      if (i >= suggestion.length || currentQuery.charAt(i) !== suggestion.charAt(i)) break;
      boundary += 1;
    }
    return [suggestion.slice(0, boundary), suggestion.slice(boundary)];
  }, [currentQuery, suggestion]);

  return (
    <StyledSuggestionItem onClick={onClick}>
      <IconGlobalSearch />
      <Box style={{ marginLeft: 12 }}>
        <Text display="inline" weight={700}>
          {highlighted}
        </Text>

        <Text display="inline">{rest}</Text>
      </Box>
    </StyledSuggestionItem>
  );
}

const StyledSuggestionItem = styled.div`
  cursor: pointer;
  display: block;
  margin: 0;
  margin-bottom: 15px;
  font-style: italic;
  display: flex;
  align-items: center;

  svg {
    color: ${colors.steel[300]};
  }
`;
