import { CircleSpinner, Flexbox, LogoSpinner } from '@newfront-insurance/core-ui';
import { useRouter, type Route } from '@newfront-insurance/next-router-provider';
import type { ReactNode } from 'react';
import { Suspense } from 'react';

import { AppHeader } from '../app-header';
import { InternalAppErrorBoundary } from '../error-boundary';

interface Props {
  children: ReactNode;
  disableHomeLink?: boolean;
  homeRoute?: Route;
  leftToolbar?: ReactNode;
  rightToolbar?: ReactNode;
  isShowingDarkSidebar?: boolean;
  showDarkModeToggle?: boolean;
}

export function AppLayout(props: Props): JSX.Element {
  const {
    children,
    disableHomeLink,
    homeRoute,
    leftToolbar,
    rightToolbar,
    showDarkModeToggle,
    isShowingDarkSidebar = false,
  } = props;

  const router = useRouter();

  const pathsToIgnore = ['/accounts', '/accounts/[accountUuid]/exposures', '/billing', '/coverage-gap-analysis'];

  const showAppHeader =
    !isShowingDarkSidebar || (isShowingDarkSidebar && pathsToIgnore.includes(router.router.pathname));
  return (
    <Suspense fallback={<LogoSpinner />}>
      <InternalAppErrorBoundary>
        <Flexbox flexDirection="column" width="100vw" style={{ overflow: 'hidden', height: '100vh' }}>
          {/** Check if AppHeader should be rendered */}
          {showAppHeader && (
            <AppHeader
              disableHomeLink={disableHomeLink}
              homeRoute={homeRoute}
              leftToolbar={leftToolbar}
              rightToolbar={rightToolbar}
              showDarkModeToggle={showDarkModeToggle}
            />
          )}
          <InternalAppErrorBoundary>
            <Suspense fallback={<CircleSpinner />}>{children}</Suspense>
          </InternalAppErrorBoundary>
        </Flexbox>
      </InternalAppErrorBoundary>
    </Suspense>
  );
}
