import type { LineOfCoverageTypeDetails } from '../../..';

export enum LineOfCoverageEditorActionType {
  ADD_SELECTED_LOC = 'ADD_SELECTED_LOC',
  REMOVE_SELECTED_LOC = 'REMOVE_SELECTED_LOC',
  UPDATE_DISPLAY_NAME = 'UPDATE_DISPLAY_NAME',
}

export type LineOfCoverageEditorAction = AddLineOfCoverage | RemoveLineOfCoverage | UpdateLineOfCoverageDisplayName;

interface AddLineOfCoverage {
  type: LineOfCoverageEditorActionType.ADD_SELECTED_LOC;
  payload: {
    lineOfCoverage: LineOfCoverageTypeDetails;
  };
}

interface RemoveLineOfCoverage {
  type: LineOfCoverageEditorActionType.REMOVE_SELECTED_LOC;
  payload: {
    index: number;
    isDeleting: boolean;
  };
}

interface UpdateLineOfCoverageDisplayName {
  type: LineOfCoverageEditorActionType.UPDATE_DISPLAY_NAME;
  payload: {
    index: number;
    displayName: string;
  };
}

export interface LineOfCoverageEditorState {
  selectedLineOfCoverageTypeDetails: LineOfCoverageTypeDetails[];
  isDeletingCoverage: boolean;
}
