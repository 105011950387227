import { useLoggedInUser } from '@/client/hooks/use-logged-in-user';

interface CurrentUserHook {
  currentUserScopes: string[];
}

export function useCurrentUserScopes(): CurrentUserHook {
  const user = useLoggedInUser();
  if (!user) throw new Error('Not logged in');

  const currentUserScopes = user.scopes;

  return { currentUserScopes };
}
