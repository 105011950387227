import postalCodes from 'postal-codes-js';
import * as yup from 'yup';

export const addressYupSchema = yup.object().shape({
  addressLine1: yup.string().required('A street address is required'),
  city: yup.string().required('A city is required'),
  stateOrArea: yup.string().when('countryCode', {
    is: 'USA',
    then: yup.string().required('A state or area is required'),
    otherwise: yup.string(),
  }),
  countryCode: yup.string().required('A country code is required'),
  postalCode: yup
    .string()
    .required('A zip/postal code is required')
    .test('postalCode', 'Zip code is not valid', (value, context) => {
      const validPostcode = postalCodes.validate(context.parent.countryCode, value ?? '');
      return typeof validPostcode === 'string' ? false : !!validPostcode;
    }),
});
