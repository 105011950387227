import { AuthRole } from '@newfront-insurance/auth-api';
import { EmployeeAvatarType } from '@newfront-insurance/core-ui';
import { hasScope } from '@newfront-insurance/next-auth';
import { useProvider } from '@newfront-insurance/react-provision';

import { LayoutConfigProvider } from '../../../../config';

/**
 * Returns the avatar type based on the auth role. Because a user can have multiple roles we don't
 * want to use a map for this so that we can prioritise the return value.
 */
export function useAuthAvatarType(): EmployeeAvatarType | undefined {
  const { authProvider } = useProvider(LayoutConfigProvider);
  const { userDetails: user } = useProvider(authProvider);
  const isProducer = hasScope(user?.scopes ?? [], AuthRole.PRODUCER);
  const isEngineer = hasScope(user?.scopes ?? [], AuthRole.ENGINEER);
  const isAccountManager = hasScope(user?.scopes ?? [], AuthRole.ACCOUNT_MANAGER);
  const isOperations = hasScope(user?.scopes ?? [], AuthRole.OPERATIONS);

  if (isEngineer) {
    return undefined;
  }

  if (isProducer) {
    return EmployeeAvatarType.Producer;
  }

  if (isAccountManager) {
    return EmployeeAvatarType.AccountManager;
  }

  if (isOperations) {
    return EmployeeAvatarType.CustomerService;
  }

  return undefined;
}

/**
 * Get the image url for the current logged in user
 */
export function useAuthProfilePic(): string | undefined {
  const { authProvider } = useProvider(LayoutConfigProvider);
  const { userDetails: user } = useProvider(authProvider);

  return user?.profilePic;
}
