import { AccountStatus } from '@newfront-insurance/account-api';
import { Alert, Badge, Box, CircleSpinnerInline, DefinitionList, FeaturedCard } from '@newfront-insurance/core-ui';
import { useContext } from 'react';

import type { SearchResultRouting } from '../../../../../../bff/types';
import { useAccountByUuid } from '../../../../../hooks/use-account-by-uuid';
import { useServicingTeamForAccount } from '../../../../../hooks/use-servicing-team-for-account';
import { bugsMessage } from '../../../../../utils';
import { SearchContext } from '../../../context';
import { useItemResultRouting } from '../../../hooks/use-item-result-routing';
import { LoadingWrapper } from '../loading-wrapper';
import { ServicingTeam } from '../servicing-team';

interface Props {
  accountUuid: string;
  hasViewAccess: boolean;
  routing: SearchResultRouting;
  containerId?: string;
}

export function AccountCardPreview({ accountUuid, hasViewAccess, routing, containerId }: Props): JSX.Element {
  const { data: account, error } = useAccountByUuid({ accountUuid, isPublic: !hasViewAccess });
  const { getServicingTeamForAccount } = useServicingTeamForAccount();
  const { goToItemResultPage } = useItemResultRouting();
  const { setIsSearchActive } = useContext(SearchContext);

  if (error) {
    return (
      <Box width={512}>
        <Alert>{bugsMessage('Something went wrong trying to loading the account')}</Alert>
      </Box>
    );
  }

  if (!account) {
    return (
      <LoadingWrapper>
        <CircleSpinnerInline />
      </LoadingWrapper>
    );
  }

  const servicingTeam = getServicingTeamForAccount(account);
  const isArchived = account.status.status === AccountStatus.ARCHIVED;

  return (
    <Box backgroundColor="#fff" borderRadius={6} width={512}>
      <FeaturedCard
        eyebrow="Account"
        title={account.name}
        href={routing.absolute}
        onClick={async (e) => {
          e.preventDefault();
          setIsSearchActive(false);
          await goToItemResultPage(routing);
        }}
        subtitle={'details' in account ? account.details.dba : account.dba}
        badge={isArchived ? <Badge>Archived</Badge> : null}
        content={
          <>
            {account.definitionListItems.length > 0 && <DefinitionList items={account.definitionListItems} />}
            {servicingTeam.length > 0 && <ServicingTeam servicingTeam={servicingTeam} containerId={containerId} />}
          </>
        }
      />
    </Box>
  );
}
