import styled from 'styled-components';

import { colors } from '../../../theme/colors';

export function MenuItemDivider(): JSX.Element {
  return <StyledDivider />;
}

/**
 * We use margins instead of padding so that they collapse. This makes it easier for the
 * spacing to work when there are headers and dividers next to each other AND when they're
 * rendered individually.
 */

const StyledDivider = styled.hr`
  border: 0;
  margin: 8px 0;
  height: 1px;
  background-color: ${colors.steel[200]};
`;
