import type { ConfigInterface, NextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext } from 'react';

import { routes } from '../bff/routes';
import type { GetAccountByUuidParams, GetAccountByUuidResponse } from '../bff/types';
import { useGetApiCacheKey } from '../lib/hooks/use-get-api-cache-key';
import { AccountUIApiContext } from '../provider';

export const useGetAccountCacheKey = (): ((accountUuid: string) => string) => {
  const getCacheKey = useGetApiCacheKey();

  const getAccountCacheKey = (accountUuid: string): string => {
    return getCacheKey(routes.getAccount.getUrl({ params: { accountUuid } }));
  };

  return getAccountCacheKey;
};

export function useAccount(
  params: GetAccountByUuidParams,
  config?: ConfigInterface,
): NextApiSubscription<GetAccountByUuidResponse | null> {
  const { basePath } = useContext(AccountUIApiContext);

  return useNextApiSubscription<GetAccountByUuidResponse | null>(
    routes.getAccount.getUrl({ params, basePath }),
    config,
  );
}
