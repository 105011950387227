import type {
  UseComboboxProps,
  UseComboboxReturnValue,
  UseComboboxState,
  UseComboboxStateChangeTypes,
  UseMultipleSelectionReturnValue,
} from 'downshift';
import { useCombobox, useMultipleSelection } from 'downshift';
import type { RefObject } from 'react';

import type { BaseSelectorProps, DisabledProps, MultiSelectorProps } from '../common/types';

interface CommonSelectorOptions<T>
  extends Pick<UseComboboxProps<T>, 'items' | 'itemToString' | 'onStateChange' | 'stateReducer'>,
    Pick<BaseSelectorProps<T>, 'getDisabledProps' | 'onSearch' | 'getItemKey'> {
  inputRef: RefObject<HTMLElement>;
}

interface UseBaseSelectorResult<T>
  extends Pick<
    UseComboboxReturnValue<T>,
    | 'getComboboxProps'
    | 'getMenuProps'
    | 'getItemProps'
    | 'getInputProps'
    | 'getToggleButtonProps'
    | 'isOpen'
    | 'highlightedIndex'
    | 'openMenu'
    | 'closeMenu'
  > {
  clear: () => void;
  inputValue: string;
}

interface UseSelectorOptions<T> extends CommonSelectorOptions<T>, Pick<UseComboboxProps<T>, 'onSelectedItemChange'> {
  selectedItem: T | null;
}

interface UseSelectorResult<T> extends UseBaseSelectorResult<T> {
  selectedItem: T | null;
  selectedItemKey: string | null;
}

export function useSelector<T>({
  items,
  itemToString,
  selectedItem,
  onSearch,
  getDisabledProps,
  getItemKey,
  inputRef,
  ...rest
}: UseSelectorOptions<T>): UseSelectorResult<T> {
  const selectedItemKey = selectedItem ? getItemKey(selectedItem) : null;
  const {
    getComboboxProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    openMenu,
    closeMenu,
    inputValue,
    reset,
  } = useCombobox<T>({
    items,
    itemToString,
    selectedItem,
    onInputValueChange: (changes) => onSearch?.(changes.inputValue ?? ''),
    stateReducer: (state, { type, changes }) => {
      let disabled: DisabledProps | undefined;

      if (defaultReducer[type as DefaultReducerActions]) {
        return defaultReducer[type as DefaultReducerActions]?.(state, { type, changes }) as Partial<
          UseComboboxState<T>
        >;
      }

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          disabled = changes.selectedItem
            ? getDisabledProps?.(changes.selectedItem, { index: state.highlightedIndex })
            : undefined;

          if (disabled?.isDisabled) {
            return state;
          }

          return {
            ...changes,
            inputValue: '',
          };
        default:
          return changes;
      }
    },
    onIsOpenChange: (changes) => {
      if (changes.isOpen) {
        inputRef.current?.focus();
      }
    },
    ...rest,
  });

  return {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
    closeMenu,
    openMenu,
    inputValue,
    clear: reset,
    selectedItem,
    selectedItemKey,
  };
}

interface UseMultipleSelectorOptions<T>
  extends CommonSelectorOptions<T>,
    Pick<BaseSelectorProps<T>, 'getItemKey' | 'onSearch'>,
    Pick<MultiSelectorProps<T>, 'onSelect' | 'selectedItems'> {}

interface UseMultiSelectorResult<T>
  extends UseBaseSelectorResult<T>,
    Pick<UseMultipleSelectionReturnValue<T>, 'getDropdownProps' | 'removeSelectedItem'> {
  selectedItemKeys: string[];
  selectAll: () => void;
  isAllSelected: boolean;
}

export function useMultiSelector<T>({
  inputRef,
  items,
  itemToString,
  selectedItems: selectedItemsProp,
  getDisabledProps,
  onSearch,
  getItemKey,
  onSelect,
}: UseMultipleSelectorOptions<T>): UseMultiSelectorResult<T> {
  const { getDropdownProps, removeSelectedItem, addSelectedItem, selectedItems } = useMultipleSelection<T>({
    selectedItems: selectedItemsProp,
    onSelectedItemsChange: (changes) => {
      onSelect(changes.selectedItems ?? []);
    },
    itemToString,
  });
  const selectedItemKeys = selectedItems.map(getItemKey);
  const availableItemKeys = items
    .filter((item, index) => !getDisabledProps?.(item, { index })?.isDisabled)
    .map(getItemKey);

  const {
    getComboboxProps,
    getMenuProps,
    getItemProps,
    getInputProps,
    getToggleButtonProps,
    inputValue,
    isOpen,
    highlightedIndex,
    openMenu,
    closeMenu,
  } = useSelector<T>({
    items,
    itemToString,
    selectedItem: null,
    inputRef,
    onSearch,
    getItemKey,
    onStateChange: ({ selectedItem, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (!selectedItem) {
            return;
          }

          if (selectedItemKeys.includes(getItemKey(selectedItem))) {
            // we should find the item to remove since the state that holds the selected item
            // might be different than the current available items (in case of react-query refetch, etc)
            // getting the item by key makes sure we get the correct reference
            const itemToRemove = selectedItemsProp.find((item) => getItemKey(item) === getItemKey(selectedItem));

            removeSelectedItem(itemToRemove as T);
          } else {
            addSelectedItem(selectedItem);
          }

          break;
        default:
          break;
      }
    },
    stateReducer: (state, { type, changes }) => {
      let disabled: DisabledProps | undefined;

      if (defaultReducer[type as DefaultReducerActions]) {
        return defaultReducer[type as DefaultReducerActions]?.(state, { type, changes }) as Partial<
          UseComboboxState<T>
        >;
      }

      switch (type) {
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            inputValue: state.inputValue,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          disabled = changes.selectedItem
            ? getDisabledProps?.(changes.selectedItem, { index: state.highlightedIndex })
            : undefined;

          if (disabled?.isDisabled) {
            return state;
          }

          return {
            ...changes,
            isOpen: true,
            highlightedIndex: state.highlightedIndex,
            inputValue: state.inputValue,
          };
        default:
          return changes;
      }
    },
  });

  function handleSelectAll(): void {
    const availableItems = items.filter((item, index) => !getDisabledProps?.(item, { index })?.isDisabled);

    onSelect(availableItems);
  }

  return {
    getComboboxProps,
    getDropdownProps,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    inputValue,
    isOpen,
    closeMenu,
    openMenu,
    isAllSelected: selectedItemKeys.length === availableItemKeys.length,
    clear: () => {
      const unremovableItems = items.filter(
        (item, index) => getDisabledProps?.(item, { index })?.isRemovable === false,
      );

      onSelect(unremovableItems);
    },
    selectAll: handleSelectAll,
    selectedItemKeys,
    removeSelectedItem,
  };
}

type SelectorStateReducer<T> = UseComboboxProps<T>['stateReducer'];

type DefaultReducerActions =
  | UseComboboxStateChangeTypes.ToggleButtonClick
  | UseComboboxStateChangeTypes.FunctionOpenMenu
  | UseComboboxStateChangeTypes.FunctionReset;

const defaultReducer: Record<DefaultReducerActions, SelectorStateReducer<unknown>> = {
  [useCombobox.stateChangeTypes.ToggleButtonClick]: (_state, { changes }) => {
    return {
      ...changes,
      inputValue: '',
    };
  },
  [useCombobox.stateChangeTypes.FunctionOpenMenu]: (_state, { changes }) => {
    return {
      ...changes,
      inputValue: '',
    };
  },
  [useCombobox.stateChangeTypes.FunctionReset]: (_state, { changes }) => {
    return {
      ...changes,
      isOpen: false,
    };
  },
};
