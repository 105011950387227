import { Alert, Badge, Box, CircleSpinnerInline, EmployeeAvatarType, FeaturedCard } from '@newfront-insurance/core-ui';
import { PolicyStatus } from '@newfront-insurance/coverage-api';
import { useContext } from 'react';

import { PolicyDefinitionList } from './policy-definition-list';
import { renderPolicyName, usePolicy } from '../../../../../../../coverage';
import type { SearchResultRouting } from '../../../../../../bff/types';
import { bugsMessage } from '../../../../../utils';
import { SearchContext } from '../../../context';
import { useItemResultRouting } from '../../../hooks/use-item-result-routing';
import { LoadingWrapper } from '../loading-wrapper';
import { ServicingTeam } from '../servicing-team';

interface Props {
  policyUuid: string;
  routing: SearchResultRouting;
}

export function PolicyCardPreview({ policyUuid, routing }: Props): JSX.Element {
  const { data: policy, error } = usePolicy({ policyUuid });
  const { goToItemResultPage } = useItemResultRouting();
  const { setIsSearchActive } = useContext(SearchContext);

  if (error) {
    return (
      <Box width={512}>
        <Alert>{bugsMessage('Something went wrong trying to load the policy')}</Alert>
      </Box>
    );
  }

  if (!policy) {
    return (
      <LoadingWrapper>
        <CircleSpinnerInline />
      </LoadingWrapper>
    );
  }

  const { accountUuid, details, definition, type, status, accountManagers, producers } = policy;
  const accountRouting: SearchResultRouting = {
    absolute: `/accounts/${accountUuid}`,
    relative: {
      pathname: `/accounts/[accountUuid]`,
      query: {
        accountUuid,
      },
    },
  };

  return (
    <Box backgroundColor="#fff" borderRadius={6} width={512}>
      <FeaturedCard
        eyebrow="Policy"
        title={renderPolicyName(definition?.readableName ?? type, details.displayName)}
        href={routing.absolute}
        onClick={async (e) => {
          e.preventDefault();
          setIsSearchActive(false);
          await goToItemResultPage(routing);
        }}
        content={
          <>
            <PolicyDefinitionList
              policy={policy}
              onAccountClick={async (e) => {
                e.preventDefault();
                await goToItemResultPage(accountRouting);
              }}
            />
            {(accountManagers.length > 0 || producers.length > 0) && (
              <ServicingTeam
                servicingTeam={[
                  ...accountManagers.map((am) => ({
                    ...am,
                    name: am.fullName,
                    legacyId: am.deprecatedId,
                    uuid: am.deprecatedUuid,
                    type: EmployeeAvatarType.AccountManager,
                  })),
                  ...producers.map((pd) => ({
                    ...pd,
                    name: pd.fullName,
                    legacyId: pd.deprecatedId,
                    uuid: pd.deprecatedUuid,
                    type: EmployeeAvatarType.Producer,
                  })),
                ]}
              />
            )}
          </>
        }
        badge={status === PolicyStatus.ACTIVE ? <Badge type="success">Active</Badge> : null}
      />
    </Box>
  );
}
