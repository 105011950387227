import { useNextApiFetch } from '@newfront-insurance/next-api-swr';

import { routes } from '../bff/routes';
import type {
  CreateHistoricalPolicyBody,
  CreateHistoricalPolicyResponse,
  RenewPolicyAsIsBody,
  RenewPolicyAsIsResponse,
  UpdatePolicyBillingBody,
  UpdatePolicyBillingParams,
  UpdatePolicyBillingResponse,
  UpdatePolicyDateRangeBody,
  UpdatePolicyDateRangeParams,
  UpdatePolicyDateRangeResponse,
  UpdatePolicyDetailsBody,
  UpdatePolicyDetailsParams,
  UpdatePolicyDetailsResponse,
  UpdatePolicyLinesOfCoverageBody,
  UpdatePolicyLinesOfCoverageParams,
  UpdatePolicyLinesOfCoverageResponse,
  UpdatePolicyNotesBody,
  UpdatePolicyNotesParams,
  UpdatePolicyNotesResponse,
} from '../bff/types';
import { useCoverageApiBasePath } from '../provider';

interface PolicyApi {
  createHistoricalPolicy(body: CreateHistoricalPolicyBody): Promise<CreateHistoricalPolicyResponse | null>;
  updateNotes(params: UpdatePolicyNotesParams, body: UpdatePolicyNotesBody): Promise<UpdatePolicyNotesResponse | null>;
  updateBilling(
    params: UpdatePolicyBillingParams,
    body: UpdatePolicyBillingBody,
  ): Promise<UpdatePolicyBillingResponse | null>;
  updateDetails(
    params: UpdatePolicyDetailsParams,
    body: UpdatePolicyDetailsBody,
  ): Promise<UpdatePolicyDetailsResponse | null>;
  updateDateRange(
    params: UpdatePolicyDateRangeParams,
    body: UpdatePolicyDateRangeBody,
  ): Promise<UpdatePolicyDateRangeResponse | null>;
  updateLinesOfCoverage(
    params: UpdatePolicyLinesOfCoverageParams,
    body: UpdatePolicyLinesOfCoverageBody,
  ): Promise<UpdatePolicyLinesOfCoverageResponse | null>;
  renewAsIs(body: RenewPolicyAsIsBody): Promise<RenewPolicyAsIsResponse | null>;
}

export function usePolicyApi(): PolicyApi {
  const fetch = useNextApiFetch();
  const basePath = useCoverageApiBasePath();

  return {
    createHistoricalPolicy(body: CreateHistoricalPolicyBody) {
      return fetch<CreateHistoricalPolicyBody, CreateHistoricalPolicyResponse>({
        url: withBasePath(basePath, routes.createHistoricalPolicy.getUrl({})),
        method: 'POST',
        body,
      });
    },
    updateNotes(params: UpdatePolicyNotesParams, body: UpdatePolicyNotesBody) {
      return fetch<UpdatePolicyNotesBody, UpdatePolicyNotesResponse>({
        url: withBasePath(basePath, routes.updatePolicyNotes.getUrl({ policyUuid: params.policyUuid })),
        method: 'PUT',
        body,
      });
    },
    updateLinesOfCoverage(params: UpdatePolicyLinesOfCoverageParams, body: UpdatePolicyLinesOfCoverageBody) {
      return fetch<UpdatePolicyLinesOfCoverageBody, UpdatePolicyLinesOfCoverageResponse>({
        url: withBasePath(basePath, routes.updatePolicyLinesOfCoverage.getUrl({ policyUuid: params.policyUuid })),
        method: 'PUT',
        body,
      });
    },
    updateBilling(params: UpdatePolicyNotesParams, body: UpdatePolicyBillingBody) {
      return fetch<UpdatePolicyBillingBody, UpdatePolicyBillingResponse>({
        method: 'PUT',
        url: withBasePath(basePath, routes.updatePolicyBilling.getUrl({ policyUuid: params.policyUuid })),
        body,
      });
    },
    updateDetails(params: UpdatePolicyNotesParams, body: UpdatePolicyDetailsBody) {
      return fetch<UpdatePolicyDetailsBody, UpdatePolicyDetailsResponse>({
        method: 'PUT',
        url: withBasePath(basePath, routes.updatePolicyDetails.getUrl({ policyUuid: params.policyUuid })),
        body,
      });
    },
    updateDateRange(params: UpdatePolicyDateRangeParams, body: UpdatePolicyDateRangeBody) {
      return fetch<UpdatePolicyDateRangeBody, UpdatePolicyDateRangeResponse>({
        method: 'PUT',
        url: withBasePath(basePath, routes.updatePolicyDateRange.getUrl({ policyUuid: params.policyUuid })),
        body,
      });
    },
    renewAsIs(body: RenewPolicyAsIsBody) {
      return fetch<RenewPolicyAsIsBody, RenewPolicyAsIsResponse>({
        method: 'POST',
        url: withBasePath(basePath, routes.renewPolicyAsIs.getUrl({})),
        body,
      });
    },
  };
}

function withBasePath(basePath: string, url: string): string {
  return `${basePath}${url}`;
}
