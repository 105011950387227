import { useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';

import { employeeErrorMessages } from './lib/messages';
import { LayoutConfigProvider } from '../../config';
import { ErrorBoundaryWithMessages } from '../error-boundary/error-boundary';

/**
 * This error boundary should only be used in internal apps. It will define the copy for the
 * error messages and assume the user is an employee.
 */
export function InternalAppErrorBoundary({ children }: PropsWithChildren<unknown>): JSX.Element {
  const { onError } = useProvider(LayoutConfigProvider);

  return (
    <ErrorBoundaryWithMessages onError={onError} messages={employeeErrorMessages}>
      {children}
    </ErrorBoundaryWithMessages>
  );
}
