/**
 * This hook makes a usAutocomplete request when the search query changes and returns results.
 */

import { useNotifications } from '@newfront-insurance/next-notifications';
import { useCallback, useState } from 'react';
import { internationalStreet } from 'smartystreets-javascript-sdk';
import type { core } from 'smartystreets-javascript-sdk';

import { useSmartyStreetsInternationalClient } from '../../providers/smartystreets-international';

export type Lookup = internationalStreet.Lookup | undefined;
export type LookupInternationalAddress = (countryCode: string, formattedAddress: string) => Promise<Lookup>;
export type SmartyStreetsInternationalHook = [LookupInternationalAddress, boolean, core.Errors.SmartyError | undefined];

export function useSmartyStreetsInternationalAddress(): SmartyStreetsInternationalHook {
  const { internationalStreetClient } = useSmartyStreetsInternationalClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<core.Errors.SmartyError>();
  const notifications = useNotifications();

  async function verifyInternationalAddress(countryCode: string, formattedAddress: string): Promise<Lookup> {
    const lookup = new internationalStreet.Lookup(countryCode, formattedAddress);
    setLoading(true);
    setError(undefined);

    try {
      const res = await internationalStreetClient.send(lookup);
      return res;
    } catch (e) {
      const err = e as { error?: core.Errors.SmartyError };
      setError(err.error);
      notifications.addById('use-smartystreets-international-address', {
        title: `There was a problem verifying address.
        ${err.error?.message}`,
        type: 'error',
      });

      throw e;
    } finally {
      setLoading(false);
    }
  }

  return [useCallback(verifyInternationalAddress, [internationalStreetClient, notifications]), loading, error];
}
