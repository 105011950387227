import { AccountType } from '@newfront-insurance/account-api';
import type { DefinitionListItem } from '@newfront-insurance/core-ui';
import { DefinitionList } from '@newfront-insurance/core-ui';
import compact from 'lodash/compact';

import type { AccountCardAccount, AccountCardPublicAccount } from './lib';
import { buildIdentitiesDefinitions } from './lib/utils';

interface Props {
  account: AccountCardAccount | AccountCardPublicAccount;
  hideTitle?: boolean;
  showTitleAsBlock?: boolean;
}

const ACCOUNT_TYPE_LABELS: Record<AccountType, string> = {
  [AccountType.COMMERCIAL]: 'Commercial',
  [AccountType.PERSONAL]: 'Personal',
};

export function AccountDefinitions({ account, hideTitle = false, showTitleAsBlock = false }: Props): JSX.Element {
  const definitionListItems: DefinitionListItem[] = [
    {
      type: 'business',
      title: 'Type',
      value: ACCOUNT_TYPE_LABELS[account.type] ?? '-',
    },
  ];

  definitionListItems.push(...buildIdentitiesDefinitions(account, showTitleAsBlock));

  if ('postalAddress' in account) {
    const { postalAddress } = account;
    definitionListItems.push({
      title: 'Mailing address',
      type: 'location',
      value:
        compact([
          postalAddress?.addressLine1,
          postalAddress?.addressLine2,
          postalAddress?.addressLine3,
          postalAddress?.city,
        ]).join(', ') || '-',
      subValue: compact([postalAddress?.stateOrArea, postalAddress?.postalCode]).join(', ') || '-',
    });
  }
  return <DefinitionList items={definitionListItems} hideTitle={hideTitle} />;
}
