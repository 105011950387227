import {
  Box,
  Card,
  colors,
  formatters,
  Flexbox,
  MiniIconEmail,
  MiniIconLocation,
  MiniIconPhone,
  Padding,
  Spacing,
  Stack,
  Text,
  TextLink,
  Tooltip,
  Menu2 as Menu,
  PopoverMenu,
} from '@newfront-insurance/core-ui';
import type { MenuItem2 as MenuItem } from '@newfront-insurance/core-ui';
import { useNotify } from '@newfront-insurance/next-notifications';

import type { Contact } from '../../bff/types';

interface Props {
  contact: Contact;
  isKeyContact?: boolean;
  type?: 'fluid' | 'fixed';
  maxWidth?: string;
  menuItems?: MenuItem[];
}

export function ContactCard({
  contact,
  isKeyContact = false,
  type = 'fixed',
  maxWidth = '290px',
  menuItems = [],
}: Props): JSX.Element {
  const cardColor = isKeyContact ? '#042553' : '#ffffff';
  const borderColor = isKeyContact ? '#042553' : colors.steel[200];
  const textColor = isKeyContact ? '#ffffff' : colors.steel[500];
  const subtextColor = colors.steel[isKeyContact ? 200 : 400];
  const contactName = [contact.firstName, contact.middleName, contact.lastName].join(' ');

  const notify = useNotify();

  async function copyEmail(email: string | undefined): Promise<void> {
    if (email) {
      await navigator.clipboard.writeText(email);
      notify.success('Email copied successfully');
    }
  }

  return (
    <Box style={{ maxWidth: type === 'fluid' ? '100%' : maxWidth }}>
      <Card style={{ position: 'relative' }} backgroundColor={cardColor} borderColor={borderColor} hasShadow={false}>
        {menuItems.length > 0 && (
          <Box style={{ position: 'absolute', top: '16px', right: '14px' }}>
            <PopoverMenu options={{ targetAnchor: 'bottomRight', anchor: 'topRight' }} type="more">
              {(toggleMenu) => <Menu onItemClick={toggleMenu} items={menuItems} />}
            </PopoverMenu>
          </Box>
        )}
        <Padding size={16}>
          {isKeyContact && (
            <Padding bottom={8}>
              <Text
                size="small"
                weight={600}
                color={textColor}
                style={{ letterSpacing: `0.1em`, textTransform: `uppercase` }}
              >
                Key Contact
              </Text>
            </Padding>
          )}

          {contactName && (
            <Text weight={600} color={textColor}>
              {`${contactName}`}
            </Text>
          )}

          {contact.title && (
            <Text size="small" weight={600} color={subtextColor}>
              {contact.title}
            </Text>
          )}

          {contact.email || contact.phone || contact.mailingAddress ? <Spacing height={8} /> : null}

          <Stack direction="vertical" gap={4}>
            {contact.email && (
              <Stack direction="horizontal" gap={8} alignItems="center">
                <TextLink onClick={() => copyEmail(contact.email)}>
                  <Tooltip text="Copy email" positionTip="right">
                    <MiniIconEmail color={isKeyContact ? '#ffffff' : colors.steel[400]} />
                  </Tooltip>
                </TextLink>
                <TextLink
                  href={`mailto:${contact.email}`}
                  target="_blank"
                  underline={false}
                  underlineOnHover
                  style={{ color: textColor }}
                >
                  {contact.email}
                </TextLink>
              </Stack>
            )}

            {contact.phone && (
              <Stack direction="horizontal" gap={8} alignItems="center">
                <MiniIconPhone color={isKeyContact ? '#ffffff' : colors.steel[400]} />
                <TextLink
                  href={`tel:${contact.phone}`}
                  target="_blank"
                  underline={false}
                  underlineOnHover
                  style={{ color: isKeyContact ? '#ffffff' : textColor }}
                >
                  {formatters.phoneNumber(contact.phone)}
                </TextLink>
              </Stack>
            )}
            {contact.mailingAddress && (
              <Flexbox alignItems="flex-start" flexDirection="row" justifyContent="flex-start">
                <Padding top={4}>
                  <MiniIconLocation color={isKeyContact ? '#ffffff' : colors.steel[400]} />
                </Padding>
                <Flexbox flexDirection="column">
                  <Padding left={8}>
                    <Text color={textColor}>
                      {`${contact.mailingAddress.addressLine1}, ${contact.mailingAddress.city}`}
                    </Text>
                    <Text color={textColor}>
                      {`${contact.mailingAddress.stateOrArea}, ${contact.mailingAddress.postalCode}`}
                    </Text>
                  </Padding>
                </Flexbox>
              </Flexbox>
            )}
          </Stack>
        </Padding>
      </Card>
    </Box>
  );
}
