import type { Money } from '@newfront-insurance/core-money';
import { toMoney, toNumberFromMoney } from '@newfront-insurance/core-money';
import { CheckboxRow, CurrencyInput, Flexbox, Text } from '@newfront-insurance/core-ui';
import type { CurrencyOrCheckboxInputMetadata } from '@newfront-insurance/coverage-api';
import { getStringifiedFieldValue } from '@newfront-insurance/dsl-schema-ui';
import type { SchemaInputControlCustomInputProps, CustomValue } from '@newfront-insurance/dsl-schema-ui';

export type CurrencyOrCheckboxInputValue = Record<string, Money | boolean | undefined> | undefined;

export function CurrencyOrCheckboxDslInput({
  onChange,
  value,
  metadata,
}: SchemaInputControlCustomInputProps<
  CurrencyOrCheckboxInputValue,
  CurrencyOrCheckboxInputMetadata
>): JSX.Element | null {
  // we can assume the metadata is always defined
  if (!metadata) {
    return null;
  }

  const checkboxValue = value?.[metadata.checkboxFieldName] || false;
  const moneyValue = value?.[metadata.currencyFieldName] as Money | undefined;

  function handleCurrencyChange(currencyValue?: number): void {
    // metadata should always be defined
    if (!metadata) {
      return;
    }

    if (checkboxValue) {
      return;
    }

    if (!currencyValue && !checkboxValue) {
      onChange(undefined);
      return;
    }

    onChange({
      ...value,
      [metadata.currencyFieldName]: currencyValue ? toMoney(currencyValue) : undefined,
      [metadata.checkboxFieldName]: checkboxValue || false,
    });
  }

  function handleCheckboxChange(isActive: boolean): void {
    // metadata should always be defined
    if (!metadata) {
      return;
    }

    if (!isActive) {
      onChange(undefined);

      return;
    }

    onChange({
      [metadata.checkboxFieldName]: true,
    });
  }

  return (
    <Flexbox flexDirection="column" gap={8} marginTop="auto">
      <CurrencyInput
        onChange={handleCurrencyChange}
        value={moneyValue ? toNumberFromMoney(moneyValue) : ''}
        disabled={!!checkboxValue}
        name={metadata.currencyFieldName}
      />
      <CheckboxRow
        label={metadata?.checkboxFieldLabel ?? ''}
        isActive={!!checkboxValue}
        onClick={handleCheckboxChange}
      />
    </Flexbox>
  );
}

// eslint-disable-next-line react/function-component-definition
export const CurrencyOrCheckboxDisplay: CustomValue<CurrencyOrCheckboxInputValue, CurrencyOrCheckboxInputMetadata> = ({
  field,
  value,
}) => {
  return <Text>{getStringifiedFieldValue(value, field)}</Text>;
};
