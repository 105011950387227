import type { ReactNode } from 'react';
import { Children } from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { IconWarning } from '../icons';
import { Input } from '../input';
import { CircleSpinner } from '../spinner';
import { Text } from '../text';

interface Props {
  loading?: boolean;
  error?: string | null;
  placeholder: string;
  searchPlaceholder?: string;
  searchInput?: string;
  onSearchInputChange: (value: string) => void;
  children?: ReactNode;
  containerHeight?: number;
}

/**
 * This component is deprecated. Use `Selectable` instead.
 * @deprecated Use Selectable component.
 */
export default function ObjectSelector({
  loading = false,
  error,
  placeholder,
  children,
  searchPlaceholder = 'Filter by keyword',
  searchInput,
  onSearchInputChange,
  containerHeight = 380,
}: Props): JSX.Element {
  const hasChildren = !!children && Children.count(children) > 0;

  return (
    <div>
      <Input
        type="search"
        placeholder={searchPlaceholder}
        style={{
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          marginTop: -1,
          boxShadow: 'none',
          paddingLeft: 40,
        }}
        value={searchInput}
        onChange={onSearchInputChange}
      />
      <div
        style={{
          height: containerHeight,
          overflowY: 'auto',
          background: colors.steel[100],
          paddingBottom: 16,
          position: 'relative',
        }}
      >
        {loading && <CircleSpinner />}
        {!loading && error && (
          <Centered>
            <IconWarning />
            <Text color="default">{error}</Text>
          </Centered>
        )}
        {children}
        {!hasChildren && !error && !loading && (
          <Centered>
            <Text color="default">{placeholder}</Text>
          </Centered>
        )}
      </div>
    </div>
  );
}

const Centered = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: 520;
`;
