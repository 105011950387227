/* eslint-disable jsx-a11y/click-events-have-key-events */
import kebabCase from 'lodash/kebabCase';
import type { ReactNode } from 'react';
import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { generateTestId } from '../../lib/test-helpers';
import { colors } from '../../theme/colors';
import { CheckboxInput } from '../checkbox-input';
import { FadeIn } from '../fade-in';
import { Flexbox, FlexCell } from '../flexbox';
import { IconButton } from '../icon-button';
import { IconRadioButton } from '../icons';
import type { MenuItem } from '../menu-2';
import { Menu } from '../menu-2';
import { Padding } from '../padding';
import { Popper } from '../popper';
import { Spacing } from '../spacing';
import { Stack } from '../stack';
import { Text } from '../text';
import { TextEyebrow } from '../text-eyebrow';
import { TextLink } from '../text-link';
import ToggleDetailsButton from '../toggle-details-button';

export interface FeaturedCardProps {
  eyebrow: string;
  title: string;
  subtitle?: string;
  content?: ReactNode;
  isExpandable?: boolean;
  defaultExpanded?: boolean;
  isSelected?: boolean;
  target?: string;
  icon?: ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  indeterminate?: boolean;
  actions?: ReactNode;
  badge?: ReactNode;
  selectType?: 'radio' | 'checkbox';
  onSelect?: () => void;
  onRemove?: () => void;
  hasShadow?: boolean;
  isRounded?: boolean;
  menuItems?: MenuItem[];
  testId?: string;
}
/**
 * @deprecated use `DetailCard` instead to follow current design system
 */
export default function FeaturedCard({
  icon,
  eyebrow,
  title,
  target,
  href,
  onClick,
  subtitle,
  content,
  isExpandable,
  indeterminate,
  defaultExpanded = false,
  actions,
  badge,
  onSelect,
  isSelected,
  selectType = 'checkbox',
  onRemove,
  hasShadow = false,
  isRounded = true,
  menuItems,
  testId: propTestId,
}: FeaturedCardProps): JSX.Element {
  const isSelectable = !!onSelect;
  const [expanded, setExpanded] = useState(defaultExpanded);
  const testId = propTestId ?? generateTestId(kebabCase(title), 'feature-card');

  function toggleExpanded(): void {
    setExpanded((currentExpanded) => !currentExpanded);
  }

  return (
    <StyledFeaturedCard isSelectable={isSelectable} isRounded={isRounded} hasShadow={hasShadow} data-testid={testId}>
      <Flexbox>
        <FlexCell flex={1}>
          <Flexbox alignItems="center">
            {isSelectable && (
              <div
                onClick={selectType === 'radio' ? onSelect : undefined}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={0}
                style={{
                  marginRight: 12,
                  outline: 0,
                  cursor: 'pointer',
                }}
              >
                {selectType === 'checkbox' ? (
                  <CheckboxInput checked={isSelected} indeterminate={indeterminate} onChange={onSelect} />
                ) : (
                  <IconRadioButton isActive={isSelected} />
                )}
              </div>
            )}

            {icon && (
              <div
                style={{
                  marginRight: 12,
                  display: 'flex',
                }}
              >
                {icon}
              </div>
            )}
            <FlexCell flex={1}>
              <Flexbox justifyContent="space-between">
                <Flexbox flexDirection="column">
                  <TextEyebrow color={colors.peach[500]}>{eyebrow}</TextEyebrow>
                  <Spacing height={4} />
                  <TextLink
                    weight={600}
                    variant="secondary"
                    href={isSelectable ? undefined : href}
                    onClick={isSelectable ? onSelect : onClick}
                    target={isSelectable ? undefined : target}
                    colorOnHover={colors.brand[400]}
                    underline={false}
                    underlineOnHover={false}
                    style={{
                      paddingRight: 16,
                    }}
                  >
                    {title}
                  </TextLink>

                  {subtitle && (
                    <Text color="default" size="regular">
                      {subtitle}
                    </Text>
                  )}
                </Flexbox>

                {(actions || badge || onRemove) && (
                  <Stack direction="horizontal" gap={12} alignItems="center">
                    {actions && <div>{actions}</div>}
                    {badge ? <StyledBadge>{badge}</StyledBadge> : null}
                    {onRemove && <IconButton type="close" onClick={() => onRemove()} ariaLabel={`Remove ${title}`} />}
                    {menuItems && (
                      <Popper
                        trigger={({ toggle, triggerRef }) => (
                          <IconButton type="more" innerRef={triggerRef} onClick={toggle} />
                        )}
                      >
                        {() => <Menu items={menuItems} />}
                      </Popper>
                    )}
                  </Stack>
                )}
              </Flexbox>
            </FlexCell>
          </Flexbox>

          <Padding left={isSelectable ? 32 : 0}>
            {content && <Spacing height={16} />}
            {(expanded || !isExpandable) && <FadeIn duration={isExpandable ? '0.2s' : '0'}>{content}</FadeIn>}
            {isExpandable && (
              <>
                {expanded && <Spacing height={12} />}
                <ToggleDetailsButton showing={expanded} onClick={toggleExpanded} />
              </>
            )}
          </Padding>
        </FlexCell>
      </Flexbox>
    </StyledFeaturedCard>
  );
}

const StyledFeaturedCard = styled.div(
  ({ isSelectable, isRounded, hasShadow }: { isSelectable: boolean; isRounded: boolean; hasShadow: boolean }) => `
      ${isSelectable ? `border-bottom: 1px solid #dbdbdb;` : `border: 1px solid ${colors.steel[200]};`}
      ${isSelectable || !isRounded ? 'border-radius: 0px;' : 'border-radius: 4px;'}

      box-shadow: ${hasShadow ? '0px 5px 10px rgba(0, 0, 0, 0.03)' : 'none'};

      background: #fff;
      position: relative;
      padding: 20px;
      text-align: left;
  `,
);

const StyledBadge = styled.div``;
