import { createProvider, useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';
import { useLocalStorage, useMedia } from 'react-use';
import { createGlobalStyle } from 'styled-components';

import { toCSSVariables } from '../theme/css-variables';
import { darkTheme } from '../theme/theme-dark';
import { lightTheme } from '../theme/theme-light';
import type { Theme } from '../theme/types';

export enum ThemeType {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

/**
 * This exposes state about the current theme. We only support two themes, light and dark.
 * This will default to the light theme.
 */
export const ThemeProvider = createProvider(() => {
  const prefersDarkMode = useMedia('(prefers-color-scheme: dark)');
  const [currentTheme, setTheme] = useLocalStorage<ThemeType>('dash.theme', ThemeType.LIGHT);
  const theme = getTheme(currentTheme ?? ThemeType.LIGHT, prefersDarkMode);

  return {
    name: currentTheme,
    setTheme: (type: ThemeType) => setTheme(type),
    theme,
  };
});

/**
 * Get the current theme. This will short-curcuit if the experiemental flag isn't enabled. If the
 * user has the system theme selected, it will be based on that. Otherwise it will use the theme
 * based on their selection.
 */
function getTheme(currentTheme: ThemeType, prefersDarkMode: boolean): Theme {
  if (currentTheme === ThemeType.SYSTEM) {
    return prefersDarkMode ? darkTheme : lightTheme;
  }
  if (currentTheme === ThemeType.DARK) {
    return darkTheme;
  }
  return lightTheme;
}

/**
 * This should be placed within the ThemeProvider and as high up in the app tree as possible.
 * It will expose the theme as CSS variables prefixed with --nfui.
 */
export function ThemeCSSVariableProvider(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { theme } = useProvider(ThemeProvider);

  return (
    <>
      <BodyVariables customTheme={theme} />
      {children}
    </>
  );
}

/**
 * Add component to automatically add global styles.
 */
export function ThemeGlobalStyles(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { theme } = useProvider(ThemeProvider);
  return (
    <>
      <BodyStyle customTheme={theme} />
      {children}
    </>
  );
}

/**
 * Apply all global styles for theme here
 */
const BodyStyle = createGlobalStyle<{ customTheme: Theme }>(({ customTheme }) => {
  return {
    body: {
      backgroundColor: customTheme.base.background,
    },
  };
});

/**
 * Add all variables for the theme
 */
const BodyVariables = createGlobalStyle<{ customTheme: Theme }>(({ customTheme }) => {
  const variables = toCSSVariables(customTheme);
  return {
    body: {
      ...variables,
    },
  };
});
