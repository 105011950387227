export const ACCOUNT_UUID = '7a664659-6b48-4cbe-ae53-39ae74bea5cb';
export const BUSINESS_IDENTIFIER_UUID = '17871d30-92d1-478a-a94b-f7829a20dddd';
export const CLIENT_SERVICE_ASSOCIATE_EMPLOYEE_UUID = '43d4fdf2-e24d-424f-85d7-fe2ecddce995';
export const CLIENT_SERVICE_ASSOCIATE_UUID = 'a88c88c8-17d3-11ec-9621-0242ac130002';
export const CONTACT_UUID = '1862675c-8cee-4ce7-b4e3-8df5713b722c';
export const PRIMARY_ACCOUNT_MANAGER_EMPLOYEE_UUID = '5dc011d2-9967-4c0a-8efd-01106eabe809';
export const PRIMARY_ACCOUNT_MANAGER_UUID = '228c9caf-9049-4a81-9df2-5e1b9a7d4c7c';
export const PRIMARY_CONTACT_UUID = 'GG8GGcaf-GGGG-GG81-9dGG-GG1b9a7d4cGG';
export const PRIMARY_PRODUCER_EMPLOYEE_UUID = '6d867ad3-adb6-422d-b0ca-a3996989b0c6';
export const PRIMARY_PRODUCER_UUID = '2cf334b5-3617-4cfd-8a7f-e97b80adf0b9';
export const SECONDARY_PRODUCER_EMPLOYEE_UUID = '54d5fdf3-e35d-535f-96d8-fe3ecddce006';
export const SECONDARY_PRODUCER_UUID = '973c12e9-7215-492f-ba8e-05cf0329f189';
