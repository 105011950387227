import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../../../theme/colors';
import { TextEyebrow } from '../../text-eyebrow';

interface Props {
  children: React.ReactNode;
}

export function MenuItemTitle(props: Props): JSX.Element {
  const { children } = props;
  return (
    <StyledTitle>
      <TextEyebrow color={colors.steel[300]}>{children}</TextEyebrow>
    </StyledTitle>
  );
}

/**
 * We use margins instead of padding so that they collapse. This makes it easier for the
 * spacing to work when there are headers and dividers next to each other AND when they're
 * rendered individually.
 */

const StyledTitle = styled.div`
  margin-top: 16px;
  padding: 0 24px;
  margin-bottom: 8px;
`;
