import type React from 'react';
import { useState, useCallback, useRef, useEffect } from 'react';

import { useKeyEvent } from '../key-event';

interface Popover<TargetRef extends HTMLElement, PopoverRef extends HTMLElement> {
  isPopoverOpen: boolean;
  togglePopover: () => void;
  openPopover: () => void;
  closePopover: () => void;
  targetRef: React.RefObject<TargetRef>;
  popoverRef: React.RefObject<PopoverRef>;
}

interface Options<T extends HTMLElement = HTMLElement> {
  excludeTarget?: boolean;
  excludePopover?: boolean;
  targetRef?: React.RefObject<T>;
}

/**
 * Add popover-like functionality to any element. This will add the event handlers to automatically
 * trigger a close callback when the user hits escape or clicks outside of the element.
 * @param ref The element ref
 * @param options
 */
export function usePopover<TargetRef extends HTMLElement = HTMLElement, PopoverRef extends HTMLElement = HTMLElement>(
  options: Options<TargetRef> = {},
): Popover<TargetRef, PopoverRef> {
  const { excludeTarget = false, excludePopover = true, targetRef: initialTargetRef } = options;
  const [isOpen, setOpen] = useState(false);
  const togglePopover = (): void => setOpen((value) => !value);
  const targetRef = useRef<TargetRef>(initialTargetRef?.current ?? null);
  const popoverRef = useRef<PopoverRef>(null);

  useEffect(() => {
    if (initialTargetRef && initialTargetRef.current) {
      // @ts-expect-error Assigning ref
      targetRef.current = initialTargetRef.current;
    }
  }, [initialTargetRef]);

  useKeyEvent(
    'Escape',
    () => {
      if (isOpen) {
        setOpen(false);
      }
    },
    [],
  );

  const clickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const target = event.target as HTMLElement;

      // It's not open, so bail early
      if (!isOpen) {
        return;
      }

      // No element yet
      if (!popoverRef.current) {
        return;
      }

      // Optionally exclude clicks on the popover
      if (excludePopover && (popoverRef.current.contains(target) || popoverRef.current === target)) {
        return;
      }

      // Optionally exclude clicks on the target
      if (excludeTarget && (targetRef.current?.contains(target) || targetRef.current === target)) {
        return;
      }

      // Close the popover
      setOpen(false);
    },
    [isOpen, popoverRef, targetRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside, { capture: true });
    document.addEventListener('touchstart', clickOutside, { capture: true });
    return () => {
      document.removeEventListener('mousedown', clickOutside, { capture: true });
      document.removeEventListener('touchstart', clickOutside, { capture: true });
    };
  }, [clickOutside]);

  return {
    isPopoverOpen: isOpen,
    togglePopover,
    openPopover: () => setOpen(true),
    closePopover: () => setOpen(false),
    targetRef,
    popoverRef,
  };
}
