import { toRecord } from '@newfront-insurance/core';
import type { InsurableItemType, InsurableItemTypeDefinition } from '@newfront-insurance/coverage-api';
import type { NextApiError } from '@newfront-insurance/next-api-error';
import type { NextApiSubscription, ConfigInterface } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext, useMemo, useCallback } from 'react';

import { routes } from '../bff/routes';
import type { GetInsurableItemTypeDefinitionsResponse } from '../bff/types';
import { CoverageApiContext } from '../provider';

function useInsurableItemTypeDefinitions(
  config?: ConfigInterface,
): NextApiSubscription<GetInsurableItemTypeDefinitionsResponse | null> {
  const { basePath } = useContext(CoverageApiContext);
  return useNextApiSubscription(routes.insurableItemTypeDefinitions.getUrl({}, basePath), config);
}

export interface InsurableItemTypeDefinitionsHelpers {
  loading: boolean;
  error?: NextApiError;
  insurableItemTypes: InsurableItemType[];
  insurableItemTypeDefinitions: Record<InsurableItemType, InsurableItemTypeDefinition>;
  renderInsurableItemType: (insurableItemType: InsurableItemType) => string;
}

export function useInsurableItemTypeDefinitionsHelpers(config?: ConfigInterface): InsurableItemTypeDefinitionsHelpers {
  const { data, error } = useInsurableItemTypeDefinitions(config);

  const insurableItemTypeDefinitionsArray: InsurableItemTypeDefinition[] = useMemo(
    () => data?.insurableItemTypes ?? [],
    [data],
  );

  const insurableItemTypeDefinitions: Record<InsurableItemType, InsurableItemTypeDefinition> = useMemo(
    () => toRecord(insurableItemTypeDefinitionsArray || [], ({ id }) => id),
    [insurableItemTypeDefinitionsArray],
  );

  const insurableItemTypes: InsurableItemType[] = useMemo(
    () => insurableItemTypeDefinitionsArray.map(({ id }) => id),
    [insurableItemTypeDefinitionsArray],
  );

  const renderInsurableItemType = useCallback(
    (type: InsurableItemType): string => insurableItemTypeDefinitions[type]?.readableName || type,
    [insurableItemTypeDefinitions],
  );

  return {
    loading: !data && !error,
    error,
    insurableItemTypes,
    insurableItemTypeDefinitions,
    renderInsurableItemType,
  };
}
