import type { AccountRoleMapping as ApiAccountRoleMapping } from '@newfront-insurance/account-api';
import { DepartmentRole, ProducerAssignmentRole } from '@newfront-insurance/account-api';
import { AuthRole } from '@newfront-insurance/auth-api';
import { EmployeeAvatarType } from '@newfront-insurance/core-ui';
import type { Employee as ApiEmployee } from '@newfront-insurance/next-newfront-api-client';
import type { EmployeeResult as ApiEmployeeResult } from '@newfront-insurance/users-api';
import pick from 'lodash/pick';

import { NOW, NOW_DATE } from './dates';
import {
  ACCOUNT_UUID,
  CLIENT_SERVICE_ASSOCIATE_EMPLOYEE_UUID,
  CLIENT_SERVICE_ASSOCIATE_UUID,
  PRIMARY_ACCOUNT_MANAGER_EMPLOYEE_UUID,
  PRIMARY_ACCOUNT_MANAGER_UUID,
  PRIMARY_PRODUCER_EMPLOYEE_UUID,
  PRIMARY_PRODUCER_UUID,
  SECONDARY_PRODUCER_EMPLOYEE_UUID,
  SECONDARY_PRODUCER_UUID,
} from './uuids';
import type { AccountRoleMapping, Employee, NormalizedAccountEmployeeRoleMappings } from '../bff/types';
import { EmployeeStatus } from '../bff/types';

export const API_ACCOUNT_MANAGER: ApiEmployee = {
  accountManagerSalesforceId: '12345',
  email: 'john.doe@example.com',
  extension: 123,
  firstName: 'John',
  id: 123,
  imageUrl: 'https://picsum.photos/seed/picsum/200',
  lastName: 'Doe',
  name: 'John Doe',
  phone: '1212121',
  title: 'Senior Managing Account Executive',
  userUuid: PRIMARY_ACCOUNT_MANAGER_UUID,
  uuid: PRIMARY_ACCOUNT_MANAGER_EMPLOYEE_UUID,
  status: EmployeeStatus.ACTIVE,
};

export const ACCOUNT_MANAGER: Employee = {
  ...pick(API_ACCOUNT_MANAGER, [
    'email',
    'extension',
    'firstName',
    'imageUrl',
    'lastName',
    'name',
    'phone',
    'title',
    'userUuid',
    'uuid',
    'status',
  ]),
  type: EmployeeAvatarType.AccountManager,
  legacyId: API_ACCOUNT_MANAGER.id,
};

export const PRODUCER: Employee = {
  email: 'email@example.org',
  firstName: 'Bill',
  imageUrl: 'https://picsum.photos/200',
  lastName: 'Gates',
  name: 'Bill Gates',
  phone: '1212121',
  title: 'Producer',
  userUuid: PRIMARY_PRODUCER_UUID,
  uuid: PRIMARY_PRODUCER_EMPLOYEE_UUID,
  type: EmployeeAvatarType.Producer,
  legacyId: 34,
  status: EmployeeStatus.ACTIVE,
};

export const SECONDARY_PRODUCER: Employee = {
  email: 'email@example.org',
  firstName: 'Steve',
  imageUrl: 'https://picsum.photos/200',
  lastName: 'Jobs',
  name: 'Steve Jobs',
  phone: '1212121',
  title: 'Producer',
  userUuid: SECONDARY_PRODUCER_UUID,
  uuid: SECONDARY_PRODUCER_EMPLOYEE_UUID,
  type: EmployeeAvatarType.Producer,
  legacyId: 43,
  status: EmployeeStatus.ACTIVE,
};

export const CLIENT_SERVICE_ASSOCIATE: Employee = {
  email: 'email@example.org',
  firstName: 'Paul',
  imageUrl: 'https://picsum.photos/200',
  lastName: 'Gasol',
  name: 'Paul Gasol',
  phone: '1212121',
  title: 'Client Service Associate',
  userUuid: CLIENT_SERVICE_ASSOCIATE_UUID,
  uuid: CLIENT_SERVICE_ASSOCIATE_EMPLOYEE_UUID,
  type: EmployeeAvatarType.CustomerService,
  legacyId: 12,
  status: EmployeeStatus.ACTIVE,
};

export const ACCOUNT_MANAGERS: Employee[] = [ACCOUNT_MANAGER];

export const PRODUCERS: Employee[] = [PRODUCER];

export const CLIENT_SERVICE_ASSOCIATES: Employee[] = [CLIENT_SERVICE_ASSOCIATE];

export const EMPLOYEES: Employee[] = [...ACCOUNT_MANAGERS, ...PRODUCERS];

export const API_ACCOUNT_ROLE_MAPPINGS: ApiAccountRoleMapping[] = [
  {
    accountUuid: ACCOUNT_UUID,
    roleId: ProducerAssignmentRole.PRIMARY_PRODUCER,
    userUuid: PRIMARY_PRODUCER_UUID,
    details: { workingAs: [DepartmentRole.PERSONAL_LINES_PRODUCER] },
    hideFromClientView: false,
    startsAt: NOW_DATE,
    endsAt: NOW_DATE,
  },
  {
    accountUuid: ACCOUNT_UUID,
    roleId: ProducerAssignmentRole.SECONDARY_PRODUCER,
    userUuid: SECONDARY_PRODUCER_UUID,
    details: { workingAs: [DepartmentRole.COMMERCIAL_LINES_PRODUCER] },
    hideFromClientView: false,
    startsAt: NOW_DATE,
    endsAt: NOW_DATE,
  },
];

export const ACCOUNT_ROLE_MAPPINGS: AccountRoleMapping[] = [
  {
    accountUuid: ACCOUNT_UUID,
    roleId: ProducerAssignmentRole.PRIMARY_PRODUCER,
    userUuid: PRIMARY_PRODUCER_UUID,
    details: { workingAs: [DepartmentRole.PERSONAL_LINES_PRODUCER] },
    startsAt: NOW,
    endsAt: NOW,
  },
  {
    accountUuid: ACCOUNT_UUID,
    roleId: ProducerAssignmentRole.SECONDARY_PRODUCER,
    userUuid: SECONDARY_PRODUCER_UUID,
    details: { workingAs: [DepartmentRole.COMMERCIAL_LINES_PRODUCER] },
    startsAt: NOW,
    endsAt: NOW,
  },
];

export const ACCOUNT_EMPLOYEE_ROLE_MAPPINGS: NormalizedAccountEmployeeRoleMappings = {
  PRIMARY_PRODUCER: [
    {
      accountUuid: ACCOUNT_UUID,
      roleId: ProducerAssignmentRole.PRIMARY_PRODUCER,
      userUuid: PRIMARY_PRODUCER_UUID,
      details: { workingAs: [DepartmentRole.PERSONAL_LINES_PRODUCER] },
      startsAt: NOW,
      endsAt: NOW,
    },
  ],
  SECONDARY_PRODUCER: [
    {
      accountUuid: ACCOUNT_UUID,
      roleId: ProducerAssignmentRole.SECONDARY_PRODUCER,
      userUuid: SECONDARY_PRODUCER_UUID,
      details: { workingAs: [DepartmentRole.COMMERCIAL_LINES_PRODUCER] },
      startsAt: NOW,
      endsAt: NOW,
    },
  ],
};

export const API_USER_EMPLOYEE_RESULT_DATA: ApiEmployeeResult = {
  user: {
    uuid: PRIMARY_ACCOUNT_MANAGER_UUID,
    email: 'john.doe@example.com',
    firstName: undefined,
    lastName: undefined,
    createdAt: NOW_DATE,
    updatedAt: NOW_DATE,
    accountUuid: ACCOUNT_UUID,
    updatedById: undefined,
    isAdmin: true,
    passwordResetAt: NOW_DATE,
    passwordResetHash: '202765f3-0933-447e-9d22-63953aa9fa10',
    deletedAt: null,
  },
  employee: {
    email: 'john.doe@example.com',
    name: {
      givenName: 'John',
      familyName: 'Doe',
      fullName: 'John Doe',
    },
    phones: [
      {
        value: '123-456-789 x000',
        type: 'work',
      },
    ],
    title: 'Senior Managing Account Executive',
    roles: [AuthRole.ACCOUNT_MANAGER, AuthRole.AM_MANAGER, AuthRole.CL_ACCOUNT_MANAGER],
  },
};
