import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useProvider } from '@newfront-insurance/react-provision';
import type { ReactNode } from 'react';

import { AccountMenu } from './components/account-menu';
import { AppBadge } from './components/app-badge';
import { AppSwitcherButton } from './components/app-switcher';
import { ReportBugButton } from './components/bug-button';
import { SearchButton } from './components/search-button';
import { ThemeModeToggle } from './theme-toggle';
import { LayoutConfigProvider } from '../../config';

interface Props {
  disableHomeLink?: boolean;
  homeRoute?: Route;
  leftToolbar?: ReactNode;
  rightToolbar?: ReactNode;
  showDarkModeToggle?: boolean;
}

export function HomeLink({
  homeRoute,
  disableHomeLink,
  isShowingDarkSidebar,
}: {
  homeRoute?: Route;
  disableHomeLink?: boolean;
  isShowingDarkSidebar?: boolean;
}) {
  const { createLink } = useRouter();
  const { currentApp } = useProvider(LayoutConfigProvider);

  const homeLink = createLink(
    homeRoute ?? {
      pathname: '/',
    },
  );

  return (
    <div>
      {disableHomeLink ? (
        <AppBadge type={currentApp} textSize="large" iconSize={40} isShowingDarkSidebar={isShowingDarkSidebar} />
      ) : (
        <a
          className={`-m-2 block rounded-sm p-2 no-underline ${isShowingDarkSidebar ? 'hover:bg-steel-500' : 'hover:bg-steel-100'}`}
          href={homeLink.href}
          onClick={homeLink.onClick}
          title="Go back home"
        >
          <AppBadge type={currentApp} textSize="large" iconSize={40} isShowingDarkSidebar={isShowingDarkSidebar} />
        </a>
      )}
    </div>
  );
}

export function AppRightMenu({
  showDarkModeToggle,
  disableReportBug,
  disableAppSwitcher,
  disableSearch,
  disableAccountMenu,
}: {
  showDarkModeToggle?: boolean;
  disableReportBug?: boolean;
  disableAppSwitcher?: boolean;
  disableSearch?: boolean;
  disableAccountMenu?: boolean;
}) {
  return (
    <div className="flex flex-row items-center gap-1.5">
      {showDarkModeToggle && <ThemeModeToggle />}
      {!disableReportBug && <ReportBugButton />}
      {!disableSearch && <SearchButton />}
      {!disableAppSwitcher && <AppSwitcherButton />}
      {!disableAccountMenu && <AccountMenu />}
    </div>
  );
}

export function AppHeader({
  disableHomeLink,
  homeRoute,
  leftToolbar,
  rightToolbar,
  showDarkModeToggle,
}: Props): JSX.Element {
  return (
    <div className=" relative z-10 box-border flex h-[72px] flex-row items-center justify-between border-b-2 border-[#BEC7D050] bg-white px-6 py-0">
      <div className="flex items-center gap-4">
        <HomeLink homeRoute={homeRoute} disableHomeLink={disableHomeLink} />
        {leftToolbar}
      </div>
      <div className="flex flex-row items-center gap-1.5">
        {rightToolbar}
        <AppRightMenu showDarkModeToggle={showDarkModeToggle} />
      </div>
    </div>
  );
}
