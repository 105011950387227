import * as React from 'react';

import type { SpacingValue } from '../../theme';
import { Box } from '../box';
import type { JustifyContent } from '../flexbox';
import { Flexbox } from '../flexbox';
import { Padding } from '../padding';

interface Props {
  children: React.ReactNode;
  showBorder?: boolean;
  padding?: SpacingValue;
  justifyContent?: JustifyContent;
  style?: React.CSSProperties;
}

export function ModalFooter(props: Props): JSX.Element {
  const { children, showBorder = true, padding = 24, justifyContent = 'flex-end', style } = props;

  return (
    <Box borderTop={showBorder ? 1 : 0} style={style}>
      <Padding size={padding}>
        <Flexbox justifyContent={justifyContent}>{children}</Flexbox>
      </Padding>
    </Box>
  );
}
