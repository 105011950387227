import times from 'lodash/times';

import { Select, YearInput } from './select';
import { Flexbox, FlexCell } from '../../flexbox';
import { IconButton } from '../../icon-button';
import { Padding } from '../../padding';
import type { CalendarHook } from '../hooks/calendar';

interface Props {
  minYear?: number;
  maxYear?: number;
  calendarHook: CalendarHook;
}

const monthsInOrder = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function CalendarHeader(props: Props): JSX.Element {
  const { maxYear, minYear, calendarHook } = props;
  const { increaseMonth, decreaseMonth, setYear, setMonth, year, month } = calendarHook;

  return (
    <Flexbox justifyContent="space-between" alignItems="center" width="100%">
      <FlexCell>
        <IconButton type="chevronLeft" onClick={decreaseMonth} />
      </FlexCell>
      <FlexCell flex={1}>
        <Padding x={4}>
          <Flexbox width="100%">
            <Padding right={4} style={{ flex: '1.5' }}>
              <Select minWidth="100px" value={String(month)} onChange={(newMonth) => setMonth(parseInt(newMonth, 10))}>
                {times(12, (num) => {
                  return <option value={num + 1}>{monthsInOrder[num]}</option>;
                })}
              </Select>
            </Padding>
            <div style={{ flex: '1' }}>
              <YearInput value={year} onChange={setYear} minYear={minYear} maxYear={maxYear} />
            </div>
          </Flexbox>
        </Padding>
      </FlexCell>
      <FlexCell>
        <IconButton type="chevronRight" onClick={increaseMonth} />
      </FlexCell>
    </Flexbox>
  );
}
