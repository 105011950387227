import { mutate } from 'swr';
import useMutation from 'use-mutation';

import { usePolicyApi } from '../api/policy-api';
import { routes } from '../bff/routes';
import type {
  GetPolicyByUuidResponse,
  UpdatePolicyDetailsBody,
  UpdatePolicyDetailsParams,
  UpdatePolicyDetailsResponse,
} from '../bff/types';
import { useGetApiCacheKey } from '../utils/hooks/useGetApiCacheKey';

// TODO: should be removed once use-mutation lib types are fixed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useUpdatePolicyDetails() {
  const policyApi = usePolicyApi();
  const getCacheKey = useGetApiCacheKey();

  return useMutation<
    { params: UpdatePolicyDetailsParams; body: UpdatePolicyDetailsBody },
    UpdatePolicyDetailsResponse | null
  >(({ params, body }) => policyApi.updateDetails(params, body), {
    onSuccess({ input, data }) {
      return mutate(
        getCacheKey(routes.policyByUuid.getUrl(input.params)),
        (current: GetPolicyByUuidResponse): GetPolicyByUuidResponse => data || current,
      );
    },
    throwOnFailure: true,
  });
}
