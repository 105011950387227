import type { Option } from '@newfront-insurance/core-ui';
import { SelectInput } from '@newfront-insurance/core-ui';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface Props {
  value?: string;
  onChange: (stateName: string) => unknown;
  disabled?: boolean;
  name?: string;
  innerRef?: React.MutableRefObject<HTMLSelectElement | null>;
  placeholder?: string;
  testId?: string;
  countryCode?: string;
}

async function getCountryStateOptions(countryCode: string): Promise<Option[]> {
  /**
   * pass iso3 country code to 'country-code-lookup' to get it's iso2
   * then pass the iso2, to 'country-state-city', to get the states
   */
  const lookup = (await import('country-code-lookup')).default;
  const countries = await import('country-state-city');

  const iso2 = lookup.byIso(countryCode)?.iso2;
  if (!iso2) return [];

  const states = countries.State.getStatesOfCountry(iso2);

  const options: Option[] = states.map(({ name, isoCode }) => ({
    label: name,
    value: isoCode,
  }));

  return options;
}

export function CountryStateSelect({
  countryCode = 'USA',
  name: elementName,
  onChange,
  value,
  innerRef,
  disabled,
  placeholder = 'Select state',
  testId,
}: Props): JSX.Element {
  const [options, setOptions] = useState<Option[]>();
  // For us, we use the id option because all validations are done around short codes which happen to be each state id
  // For international addresses, Ids don't match to any form of shortcode, so we need to use the label instead
  const selectedId = value && countryCode === 'USA' ? value : options?.find((option) => option.label === value)?.value;

  useEffect(() => {
    getCountryStateOptions(countryCode).then((countries: Option[]) => setOptions(countries));
  }, [countryCode]);

  return (
    <SelectInput
      name={elementName}
      options={options || []}
      onChange={(val) => {
        // same as above, we need to use the label for international addresses
        if (countryCode === 'USA') {
          onChange(val);
        } else {
          const selectedOption = (options || []).find((option) => option.value === val);
          onChange(selectedOption?.label || '');
        }
      }}
      showSelectAnOption={!value}
      placeholder={placeholder}
      value={selectedId}
      innerRef={innerRef}
      disabled={disabled}
      testId={testId}
    />
  );
}
