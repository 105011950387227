import { SelectInput, sortByLabel } from '@newfront-insurance/core-ui';
import { uniq } from 'lodash';
import * as React from 'react';

import type { Carrier } from '../../../types';

export interface Props {
  data?: Carrier[];
  value?: string | null;
  onChange?: (value?: string | null) => unknown;
  disabled?: boolean;
  name?: string;
  innerRef?: React.MutableRefObject<HTMLSelectElement | null>;
  showSelectAnOption?: boolean;
}

export function CarrierSelect(props: Props): JSX.Element {
  const { onChange, value, disabled, name, innerRef, showSelectAnOption, data = [] } = props;

  const carrierOptions = data
    ? uniq(data)
        .map((carrier: Carrier) => ({
          value: carrier.id,
          label: carrier.name,
        }))
        .sort(sortByLabel)
    : [];

  return (
    <SelectInput
      name={name}
      options={carrierOptions}
      onChange={(carrier) => {
        if (onChange) onChange(carrier);
      }}
      value={value}
      innerRef={innerRef}
      disabled={disabled}
      showSelectAnOption={showSelectAnOption}
    />
  );
}
