import { isValidElement, cloneElement } from 'react';

import { colors } from '../../../../theme/colors';
import { cn } from '../../../../v2';
import { Badge } from '../../../badge';
import { ExpandableBody, ExpandableRow } from '../../../expandable-row';
import { TextEyebrow } from '../../../text-eyebrow';
import type { BaseCardProps } from '../../types';
import { IconTitleSubtitleBlock } from '../icon-title-subtitle-block';
import { SelectableControl } from '../selectable-control';

export function DefaultSizeDetailCard(props: BaseCardProps): JSX.Element {
  const {
    actions,
    assistiveText,
    badgeProps,
    eyebrowProps,
    icon,
    subtitle,
    subtitleComponent,
    title,
    children,
    hasActions,
    expanded,
    setExpanded,
    selectableOptions,
    isSelectable,
    isExpandable,
    isHighlighted,
    hasEyebrow,
    showSubtitleComponentWhenExpanded,
    titleColor = 'black',
    testId,
    disabledProps,
    highlightColor,
  } = props;
  const hasBadge = !!badgeProps;
  const { isDisabled } = disabledProps ?? {};

  const innerPaddingClassName = `p-1`;

  return (
    <div className="flex">
      {isSelectable && (
        <div className={innerPaddingClassName}>
          <SelectableControl selectableOptions={selectableOptions} testId={testId} disabled={isDisabled} />
        </div>
      )}
      <div className="flex flex-1 flex-col justify-center">
        <ExpandableRow removeStyling expanded={expanded}>
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <div className="flex flex-col justify-center">
                {hasEyebrow ? (
                  <div className={innerPaddingClassName}>
                    <TextEyebrow
                      color={eyebrowProps?.color ? eyebrowProps?.color : colors.brand[400]}
                      data-testid={`${testId}-eyebrow`}
                    >
                      {eyebrowProps?.text}
                    </TextEyebrow>
                  </div>
                ) : (
                  <div className={innerPaddingClassName}>
                    <IconTitleSubtitleBlock
                      expanded={expanded}
                      showSubtitleComponentWhenExpanded={showSubtitleComponentWhenExpanded}
                      icon={icon}
                      setExpanded={setExpanded}
                      showChevron={isExpandable}
                      subtitle={subtitle}
                      subtitleComponent={subtitleComponent}
                      title={title}
                      titleColor={titleColor}
                      isHighlighted={isHighlighted}
                      testId={testId}
                      highlightColor={highlightColor}
                    />
                  </div>
                )}
              </div>
              <div className={cn('flex items-center', `gap-[6px]`, innerPaddingClassName)}>
                {hasBadge && (
                  <Badge type={badgeProps?.type ? badgeProps.type : 'success'} size="small" testId={testId}>
                    {badgeProps.content}
                  </Badge>
                )}
                {hasActions &&
                  actions?.map((action, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`action-for-${title}-${i}`}>
                      {isDisabled && isValidElement(action)
                        ? cloneElement<{ disabled?: boolean }>(action as JSX.Element, { disabled: isDisabled })
                        : action}
                    </div>
                  ))}
              </div>
            </div>
            {hasEyebrow && (
              <IconTitleSubtitleBlock
                expanded={expanded}
                showSubtitleComponentWhenExpanded={showSubtitleComponentWhenExpanded}
                icon={icon}
                setExpanded={setExpanded}
                showChevron={isExpandable}
                subtitle={subtitle}
                subtitleComponent={subtitleComponent}
                title={title}
                titleColor={titleColor}
                testId={testId}
              />
            )}
          </div>
          <ExpandableBody paddingX={0} paddingBottom={0}>
            <div className={innerPaddingClassName}>
              {children}
              {assistiveText && (
                <div className="pt-1">
                  <div className="text-sm font-light" data-testid={`${testId}-assistive-text`}>
                    {assistiveText}
                  </div>
                </div>
              )}
            </div>
          </ExpandableBody>
        </ExpandableRow>
      </div>
    </div>
  );
}
