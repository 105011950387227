import { useNotifications } from '@newfront-insurance/next-notifications';
import useMutation from 'use-mutation';

import { usePolicyApi } from '../api/policy-api';
import type { RenewPolicyAsIsBody, RenewPolicyAsIsResponse } from '../bff/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useRenewPolicyAsIs() {
  const policyApi = usePolicyApi();
  const notifications = useNotifications();

  return useMutation<{ body: RenewPolicyAsIsBody }, RenewPolicyAsIsResponse | null>(
    ({ body }) => policyApi.renewAsIs(body),
    {
      onSuccess({ data }) {
        if (!data) return;
        notifications.add({ title: 'Policy renewed successfully...', type: 'success' });
      },
      throwOnFailure: true,
    },
  );
}
