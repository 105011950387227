import type { LineOfCoverageType } from '@newfront-insurance/coverage-api';
import flatten from 'lodash/flatten';
import times from 'lodash/times';

import type { LineOfCoverageTypeOption } from '../../types';
import { sortOptions, toOption } from '../../utils/line-of-coverage-type-option';

export function remove(
  options: LineOfCoverageTypeOption[],
  toRemove: LineOfCoverageTypeOption,
): LineOfCoverageTypeOption[] {
  return sortOptions(options.filter(({ id }) => id !== toRemove.id));
}

export function add(options: LineOfCoverageTypeOption[], toAdd: LineOfCoverageTypeOption): LineOfCoverageTypeOption[] {
  return sortOptions([...options, toAdd]);
}

/**
 * Calculates the new available options covering the selected options and default options for the policy type
 * @param defaultOptions
 * @param selectedOptions
 */
export function calculateOptions(
  defaultOptions: LineOfCoverageTypeOption[],
  selectedOptions: LineOfCoverageTypeOption[],
): LineOfCoverageTypeOption[] {
  const lastIndexPerType: Record<LineOfCoverageType, number> = {};

  flatten([selectedOptions, defaultOptions]).forEach(({ index, type }: LineOfCoverageTypeOption): void => {
    const currentLastIndex = lastIndexPerType[type] || 0;
    lastIndexPerType[type] = currentLastIndex < index ? index : currentLastIndex;
  });

  const options: LineOfCoverageTypeOption[] = flatten(
    Object.entries(lastIndexPerType).map(([type, lastIndex]: [LineOfCoverageType, number]) =>
      times(lastIndex + 1, (index) => toOption(type, index)),
    ),
  );

  return sortOptions(options);
}
