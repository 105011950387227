import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../../../theme/colors';
import { Flexbox } from '../../flexbox';
import { IconArrowDropDown } from '../../icons';
import { Text } from '../../text';
import type { DetailCardProps } from '../types';
import { INNER_PADDING } from '../utils';

export function IconTitleSubtitleBlock(
  props: Pick<
    DetailCardProps,
    | 'icon'
    | 'showSubtitleComponentWhenExpanded'
    | 'subtitle'
    | 'subtitleComponent'
    | 'title'
    | 'titleColor'
    | 'isHighlighted'
    | 'testId'
    | 'highlightColor'
  > & {
    expanded: boolean;
    setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    showChevron?: boolean;
  },
): JSX.Element {
  const {
    icon,
    title,
    subtitle,
    subtitleComponent,
    expanded,
    showChevron = true,
    setExpanded,
    titleColor,
    isHighlighted,
    showSubtitleComponentWhenExpanded,
    testId,
    highlightColor,
  } = props;
  return (
    <Flexbox gap={INNER_PADDING} paddingLeft={4}>
      {icon && <>{icon}</>}
      <Flexbox flexDirection="column" justifyContent="space-between">
        <StyledTitleChevron isClickable={showChevron} onClick={showChevron ? () => setExpanded(!expanded) : undefined}>
          <Text
            color={isHighlighted ? highlightColor || colors.brand[400] : titleColor}
            testId={`${testId}-title`}
            breakword
          >
            <strong
              style={{
                color: 'currentColor',
              }}
            >
              {title}
            </strong>
            {showChevron && (
              <StyledArrowDiv className={expanded ? 'expanded' : undefined}>
                <IconArrowDropDown color={titleColor} size={20} testId={`${testId}-expand-btn`} />
              </StyledArrowDiv>
            )}
          </Text>
        </StyledTitleChevron>
        {subtitle ? (
          <Text color={isHighlighted ? colors.brand[400] : 'light'} testId={`${testId}-subtitle`} breakword>
            {subtitle}
          </Text>
        ) : null}
        {subtitleComponent && (!expanded || (expanded && showSubtitleComponentWhenExpanded)) ? subtitleComponent : null}
      </Flexbox>
    </Flexbox>
  );
}

const StyledArrowDiv = styled.div`
  transition: transform 0.3s ease;
  border-radius: 50%;
  background: none;
  display: inline-block;
  position: absolute;
  margin-left: ${INNER_PADDING}px;

  &.expanded {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }
`;

const StyledTitleChevron = styled.div(
  ({ isClickable }: { isClickable: boolean }) => `
  cursor: ${isClickable ? 'pointer' : 'default'};
  position: relative;
  &:hover {
    ${StyledArrowDiv} {
      background: ${colors.steel[100]};
    }
  }
  `,
);
