import classnames from 'classnames';
import type { SyntheticEvent } from 'react';
import { forwardRef } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { fontFamilies } from '../../../theme';
import { colors } from '../../../theme/colors';
import { IconExternal2 as IconExternal } from '../../icons';
import { Padding } from '../../padding';
import { Stack } from '../../stack';

interface Props {
  href?: string;
  isDisabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  target?: string;
  testId?: string;
  children: React.ReactNode;
}

export const MenuItemLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const { href, isDisabled = false, onClick, target = '_self', testId, children } = props;
  const isExternal = (href && href.startsWith('http')) || target === '_blank';

  return (
    <StyledMenuItem
      ref={ref}
      aria-disabled={isDisabled}
      tabIndex={0}
      className={classnames({ isDisabled, isExternal })}
      onClick={onClick}
      role="menuitem"
      target={isExternal ? '_blank' : target}
      href={!isDisabled ? href : undefined}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick?.(event);
        }
      }}
      data-testid={testId}
    >
      <Padding left={24} right={40}>
        <Stack direction="horizontal" gap={8} alignItems="center">
          {children}
          {isExternal && <IconExternal />}
        </Stack>
      </Padding>
    </StyledMenuItem>
  );
});

const StyledMenuItem = styled.a`
  color: ${colors.steel[400]};
  cursor: pointer;
  position: relative;
  display: block;
  font-family: ${fontFamilies.body};
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px inset ${colors.brand[400]};
    color: ${colors.brand[400]};
    background: ${colors.brand[100]};
    text-decoration: none;
  }

  &.isSelected {
    color: ${colors.brand[400]};
  }

  &.isDisabled {
    color: ${colors.steel[200]};
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(.isDisabled):hover {
    color: ${colors.brand[400]};
    background: ${colors.brand[100]};
    text-decoration: none;
  }
`;
