import type { AuthProviderContext, AuthProviderProps, UserProfile } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';

export function useLoggedInUser(authProvider: Provider<AuthProviderContext, AuthProviderProps>): UserProfile {
  const { userDetails } = useProvider(authProvider);
  if (!userDetails) throw new Error('Not logged in');

  return userDetails;
}
