import type { Identity as ApiIdentity } from '@newfront-insurance/account-api';
import { IdentityStatus, IdentityType } from '@newfront-insurance/account-api';
import { formatIdentityValue, IDENTITY_TYPES_SETS_BY_ACCOUNT_TYPE } from '@newfront-insurance/account-common';

import { formatDate } from './dates';
import type { Identity } from '../types';

export function partitionIdentities(identities: ApiIdentity[]): {
  businessIdentifier?: ApiIdentity;
  personalIdentities: ApiIdentity[];
} {
  return {
    businessIdentifier: identities.find(
      ({ type, status }) => type === IdentityType.BUSINESS_IDENTIFIER && status.status === IdentityStatus.ACTIVE,
    ),
    personalIdentities: identities.filter(
      ({ type, status }) =>
        IDENTITY_TYPES_SETS_BY_ACCOUNT_TYPE.personal.has(type) && status.status === IdentityStatus.ACTIVE,
    ),
  };
}

export function formatIdentity(identity: ApiIdentity): Identity {
  return {
    uuid: identity.uuid as string,
    accountUuid: identity.accountUuid,
    type: identity.type,
    countryCode: identity.countryCode,
    value: formatIdentityValue(identity),
    issuingState: identity.issuingState,
    issueDate: formatDate(identity.issueDate),
    expirationDate: formatDate(identity.expirationDate),
  };
}
