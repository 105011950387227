import type { DefinitionListType } from './types';
import {
  MiniIconBusiness,
  MiniIconClock,
  MiniIconContactCard,
  MiniIconCurrency,
  MiniIconDate,
  MiniIconDocument,
  MiniIconEmail,
  MiniIconFollowUpDate,
  MiniIconHash,
  MiniIconLocation,
  MiniIconName,
  MiniIconNote,
  MiniIconPhone,
  MiniIconBusinessAlternative,
  MiniIconBriefcase,
  MiniIconPieChart,
} from '../mini-icons';

interface DefinitionListProps {
  type: DefinitionListType;
  color?: string;
}

export function DefinitionListIcon(props: DefinitionListProps): JSX.Element {
  const { type, color } = props;

  switch (type) {
    case 'business':
      return <MiniIconBusiness />;
    case 'contact':
      return <MiniIconContactCard />;
    case 'document':
      return <MiniIconDocument />;
    case 'date':
      return <MiniIconDate />;
    case 'dateRange':
      return <MiniIconDate />;
    case 'datetime':
      return <MiniIconClock />;
    case 'currency':
    case 'currency-percentage':
    case 'money-string':
      return <MiniIconCurrency />;
    case 'followUpDate':
      return <MiniIconFollowUpDate color={color} />;
    case 'location':
      return <MiniIconLocation />;
    case 'name':
      return <MiniIconName />;
    case 'note':
      return <MiniIconNote />;
    case 'number':
      return <MiniIconHash />;
    case 'email':
      return <MiniIconEmail />;
    case 'phone':
      return <MiniIconPhone />;
    case 'business-alternative':
      return <MiniIconBusinessAlternative />;
    case 'briefcase':
      return <MiniIconBriefcase />;
    case 'pie-chart':
      return <MiniIconPieChart />;
    default:
      return <svg />;
  }
}
