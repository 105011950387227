export enum AuthorizationRoles {
  ACCOUNT_MANAGER = 'AccountManager',
  CLIENT = 'Client',
  CSA = 'ClientServiceAssociate',
  ENGINEER = 'Engineer',
  GROWTH_CALLER = 'GrowthCaller',
  OPERATIONS = 'Operations',
  PRODUCER = 'Producer',
  SALES_MANAGER = 'SalesManager',
}
