import * as React from 'react';
import styled from 'styled-components';

import type { EmployeeDetailsPopoverVariant } from './types';
import { Portal } from '../../hooks/portal';
import { useWindowSize } from '../../hooks/window-size';
import { colors } from '../../theme/colors';
import { Box } from '../box';
import type { Employee } from '../employee-badge/types';
import { EmployeeProfile } from '../employee-profile';
import { Flexbox } from '../flexbox';
import { IconButton } from '../icon-button';
import { PopoverAnimation } from '../popover-animation';
import type { TetherOptions } from '../tether';
import { Tether } from '../tether';

interface Props {
  targetRef: React.RefObject<HTMLElement>;
  isWide?: boolean;
  isPopoverOpen: boolean;
  popoverRef: React.RefObject<HTMLElement>;
  closePopover: () => unknown;
  employees: Employee[];
  variant?: EmployeeDetailsPopoverVariant;
  onCopyEmail?: () => unknown;
  options?: TetherOptions;
  containerId?: string;
  hideEmailAddress?: boolean;
}

export function EmployeesDetailsPopover({
  targetRef,
  isWide,
  isPopoverOpen,
  popoverRef,
  closePopover,
  employees,
  variant,
  onCopyEmail,
  options,
  containerId,
  hideEmailAddress,
}: Props): JSX.Element {
  const windowSize = useWindowSize();
  const targetBounding = targetRef.current?.getBoundingClientRect();
  const boxWidth = isWide ? 352 : 328;

  const xOffset = (targetBounding?.right ?? 0) + boxWidth >= (windowSize?.innerWidth || 0) ? -boxWidth + 14 : -8;

  const tetherOptions = {
    targetAnchor: options?.targetAnchor || (isWide ? 'bottomRight' : 'bottomLeft'),
    anchor: options?.anchor || (isWide ? 'topRight' : 'topLeft'),
    yOffset: options?.yOffset ?? (isWide ? 8 : 16),
    xOffset: options?.xOffset ?? (isWide ? 0 : xOffset),
  };

  return (
    <Portal name={containerId || 'popover'}>
      <Tether
        targetRef={targetRef}
        targetAnchor={tetherOptions.targetAnchor}
        anchor={tetherOptions.anchor}
        yOffset={tetherOptions.yOffset}
        xOffset={tetherOptions.xOffset}
      >
        {isPopoverOpen && (
          <PopoverAnimation isOpen={isPopoverOpen} direction="up">
            <Box
              border={1}
              borderRadius={6}
              boxShadow="overlay"
              backgroundColor="white"
              width={boxWidth}
              style={{ position: 'relative' }}
              elementRef={popoverRef}
            >
              <StyledClose>
                <IconButton type="close" onClick={closePopover} />
              </StyledClose>

              <Flexbox flexDirection="column">
                {employees.map((employee) => (
                  <StyledDetailsItem key={employee.uuid}>
                    <EmployeeProfile
                      variant={variant}
                      name={employee.name}
                      title={employee?.title}
                      imageUrl={employee.imageUrl}
                      email={employee.email}
                      phone={employee?.phone}
                      extension={employee?.extension}
                      departments={employee.departments}
                      onCopyEmail={onCopyEmail}
                      team={employee.team}
                      hideEmailAddress={hideEmailAddress}
                    />
                  </StyledDetailsItem>
                ))}
              </Flexbox>
            </Box>
          </PopoverAnimation>
        )}
      </Tether>
    </Portal>
  );
}

const StyledClose = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const StyledDetailsItem = styled.div`
  padding: 14px 16px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.steel[200]};
  }
`;
