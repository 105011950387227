import { Text } from '@newfront-insurance/core-ui';
import type { CurrencyOrCheckboxInputMetadata } from '@newfront-insurance/coverage-api';
import type { SchemaInputControlCustomInputProps, CustomValue } from '@newfront-insurance/dsl-schema-ui';

import { AddressDisplayField, AddressFormattedText, AddressSelector } from '../../../../../address';

export type AddressValue = Record<string, string | undefined> | undefined;

export function AddressDslInput({
  onChange,
  value,
  accountUuid,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
SchemaInputControlCustomInputProps<any, Omit<CurrencyOrCheckboxInputMetadata, 'label'>>): JSX.Element | null {
  // if accountUuid was not provided, we can't render the address selector
  if (!accountUuid) {
    return <AddressDisplayField address={value} width="100%" />;
  }

  return <AddressSelector accountUuid={accountUuid} onChange={onChange} selectedAddress={value} width={400} />;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/function-component-definition
export const AddressDslDisplay: CustomValue<any, Omit<CurrencyOrCheckboxInputMetadata, 'label'>> = ({
  value,
  size,
  color,
  weight,
}) => {
  return value ? (
    <AddressFormattedText address={value} />
  ) : (
    <Text prewrap size={size} color={color} weight={weight}>
      -
    </Text>
  );
};
