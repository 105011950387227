import type { Address } from '@newfront-insurance/address-api';
import { colors, Flexbox, IconTick, Padding, Spacing, Stack, Text, IconWarning } from '@newfront-insurance/core-ui';
import * as React from 'react';
import type { internationalStreet } from 'smartystreets-javascript-sdk';
import styled from 'styled-components';

import { formatCandidateToAddress } from '../../../utils/format-street-address';

interface Props {
  isActive?: boolean;
  onChange?: (value?: string) => unknown;
  candidate?: internationalStreet.Candidate;
  enteredAddress?: Address;
}

export function SelectableAddress(props: Props): JSX.Element {
  const { isActive = false, onChange, candidate, enteredAddress } = props;

  let value = '';

  if (candidate) {
    const { formattedAddress } = formatCandidateToAddress(candidate);
    value = formattedAddress;
  }

  if (enteredAddress) {
    value = enteredAddress.formattedAddress;
  }

  const handleChange = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (onChange) onChange(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === ' ') {
      if (onChange) onChange(value);
    }
  };

  /**
   * getVerificationStatus
   * render a status message for Partial and Ambigious statuses only
   * (Unverified results won't make it to this component)
   * @param verificationStatus
   * @returns
   */
  function getVerificationStatus(verificationStatus: string): JSX.Element | undefined {
    if (verificationStatus === 'Verified') return;
    return (
      <Flexbox alignItems="center">
        <IconWarning color={verificationStatus !== 'None' ? colors.peach[500] : undefined} size={12} />
        <Spacing width={4} />
        <Text size="small" color={colors.steel[400]}>
          Only some parts of this address were verified
        </Text>
      </Flexbox>
    );
  }

  return (
    <StyledRow
      className={isActive ? 'active' : ''}
      onClick={handleChange}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="checkbox"
    >
      <Padding y={12}>
        <Flexbox alignItems="center" justifyContent="space-between">
          <Flexbox alignItems="flex-start">
            <Padding right={16}>
              <StyledIconWrapper>{isActive ? <IconTick /> : false}</StyledIconWrapper>
            </Padding>
            <Stack gap={4} direction="vertical">
              {value && <Text weight={600}>{value}</Text>}
              {candidate && getVerificationStatus(candidate.analysis.verificationStatus)}
            </Stack>
          </Flexbox>
        </Flexbox>
      </Padding>
    </StyledRow>
  );
}

const StyledRow = styled.div`
  cursor: pointer;
`;

const StyledIconWrapper = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid ${colors.steel[200]};
  border-radius: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  .active & {
    background-color: ${colors.brand[400]};
    border-color: ${colors.brand[400]};
  }
`;
