import { BeamerProvider as AdminUiBeamerProvider } from '@newfront-insurance/admin-ui';

import { AuthProvider } from './auth';

import { getConfig } from '@/config';

export function BeamerProvider(): JSX.Element {
  const { BEAMER } = getConfig();

  return <AdminUiBeamerProvider config={BEAMER} authProvider={AuthProvider} />;
}
