import { Flexbox, RadioButtonGroup, Spacing, TextLink } from '@newfront-insurance/core-ui';
import { SchemaInputControl, type Schema } from '@newfront-insurance/dsl-schema-ui';

// eslint-disable-next-line import/no-cycle

interface Props {
  value?: {
    state?: string;
    address?: object;
  };
  name: string;
  fieldPath: string;
  schema: Schema;
  onChange: (value: unknown) => void;
  accountUuid?: string;
}

export function AddressOrState({ value, onChange, schema, name, fieldPath, accountUuid }: Props): JSX.Element {
  return (
    <div
      style={{
        width: 400,
      }}
    >
      <Flexbox>
        <RadioButtonGroup
          direction="horizontal"
          name="address-or-state"
          value={getRadioValue(value)}
          options={[
            {
              value: 'address',
              label: 'Address',
            },
            {
              value: 'state',
              label: 'State only',
            },
          ]}
          onChange={(radioValue) => {
            if (radioValue === 'address') {
              onChange({
                address: null,
              });
            }

            if (radioValue === 'state') {
              onChange({
                state: '',
              });
            }
          }}
        />
        {value && (
          <TextLink
            underlineOnHover
            onClick={() => {
              onChange(undefined);
            }}
          >
            Clear
          </TextLink>
        )}
      </Flexbox>

      {value && <Spacing height={12} />}

      {value?.state !== undefined && schema.properties?.state && (
        <SchemaInputControl
          schema={schema.properties.state}
          fieldPath={`${fieldPath}.state`}
          name={name}
          type={schema.properties.state.inputType}
          metadata={schema.properties.state.inputMetadata}
          accountUuid={accountUuid}
        />
      )}

      {value?.address !== undefined && schema.properties?.address && (
        <SchemaInputControl
          schema={schema.properties.address}
          fieldPath={`${fieldPath}.address`}
          name={name}
          type={schema.properties.address.inputType}
          metadata={schema.properties.address.inputMetadata}
          accountUuid={accountUuid}
        />
      )}
    </div>
  );
}

function getRadioValue(value: Props['value']): string | undefined {
  if (value?.address !== undefined) {
    return 'address';
  }

  if (value?.state !== undefined) {
    return 'state';
  }

  return undefined;
}
