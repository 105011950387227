/* eslint-disable @typescript-eslint/no-explicit-any */
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import styled from 'styled-components';

import { fontFamilies } from '../../theme';
import { colors } from '../../theme/colors';
import type { TogglePopover } from '../popover-menu';

interface GroupItem {
  group: {
    label: string;
    items: MenuItem[];
  };
}

export interface DividerItem {
  divider: boolean;
}

export interface MenuItem {
  label: string;
  href?: string | null;
  target?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: (isSelected?: boolean) => void;
}

interface Props {
  onToggle?: TogglePopover;
  items: (MenuItem | GroupItem | DividerItem)[];
}

export function Menu(props: Props): JSX.Element {
  const { items, onToggle } = props;

  function renderMenuItem(item: MenuItem): JSX.Element {
    const { label, isSelected, href, target, isDisabled, onClick } = item;
    const debouncedOnClick = onClick ? debounce(onClick, 300) : undefined;

    return (
      <StyledMenuItem
        className={classnames({ isDisabled, isSelected })}
        key={label}
        onClick={(event) => {
          if (!isDisabled && debouncedOnClick) {
            debouncedOnClick(!isSelected);
          }
          if (onToggle) {
            onToggle(false);
          }
          event.stopPropagation();
        }}
        role="menuitem"
        href={!isDisabled ? href ?? undefined : undefined}
        target={target}
      >
        <span className="px-6">{label}</span>
      </StyledMenuItem>
    );
  }

  function renderDividerItem(): JSX.Element {
    return <StyledDivider key="divider" />;
  }

  function renderGroupItem(item: GroupItem): JSX.Element {
    const {
      group: { label, items: childItems },
    } = item;
    return (
      <div key={`${label} items`}>
        <div className="px-4 pb-2">
          <span className="text-base text-steel-300">{label}</span>
        </div>
        {childItems.map(renderMenuItem)}
      </div>
    );
  }

  function isGroupItem(object: any): object is GroupItem {
    return 'group' in object;
  }

  function isDividerItem(object: any): object is DividerItem {
    return 'divider' in object && object.divider === true;
  }

  const menuItems = items.map((item) => {
    if (isGroupItem(item)) {
      return renderGroupItem(item);
    }
    if (isDividerItem(item)) {
      return renderDividerItem();
    }
    return renderMenuItem(item);
  });

  return <div className="flex flex-col gap-0">{menuItems}</div>;
}

const StyledMenuItem = styled.a`
  color: ${colors.steel[400]};
  cursor: pointer;
  &.isSelected {
    color: ${colors.brand[400]};
  }
  &.isDisabled {
    color: ${colors.steel[200]};
    cursor: not-allowed;
  }
  display: block;
  font-family: ${fontFamilies.body};
  font-size: 14px;
  line-height: 40px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  &:not(.isDisabled):hover {
    color: ${colors.brand[400]};
    background: ${colors.brand[100]};
    text-decoration: none;
  }
`;

const StyledDivider = styled.div`
  background-color: ${colors.steel[200]};
  height: 1px;
  width: 100%;
`;
