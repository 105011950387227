import * as React from 'react';

import type { Employee } from './types';
import { usePopover } from '../../hooks/popover';
import { EmployeeAvatar } from '../avatar-employee';
import { EmployeesDetailsPopover } from '../employees-details-popover';
import type { EmployeeDetailsPopoverVariant } from '../employees-details-popover/types';

export * from './types';

interface Props {
  employee: Employee;
  isWide?: boolean;
  onCopyEmail?: () => unknown;
  variant?: EmployeeDetailsPopoverVariant;
  containerId?: string;
  hideEmailAddress?: boolean;
}

const REGULAR_SIZE = 32;
const WIDE_SIZE = 48;

export function EmployeeBadge({
  employee,
  isWide,
  variant,
  onCopyEmail,
  containerId,
  hideEmailAddress,
}: Props): JSX.Element {
  const { popoverRef, targetRef, isPopoverOpen, togglePopover, closePopover } = usePopover();

  return (
    <>
      <EmployeesDetailsPopover
        isWide={isWide}
        popoverRef={popoverRef}
        targetRef={targetRef}
        isPopoverOpen={isPopoverOpen}
        closePopover={closePopover}
        employees={[employee]}
        variant={variant}
        onCopyEmail={onCopyEmail}
        containerId={containerId}
        hideEmailAddress={hideEmailAddress}
      />

      <div ref={targetRef as React.RefObject<HTMLDivElement>}>
        <EmployeeAvatar
          employeeType={employee.type}
          initials={`${employee?.firstName?.[0]}${employee?.lastName?.[0]}`}
          sizeInPixels={isWide ? WIDE_SIZE : REGULAR_SIZE}
          imageUrl={employee?.imageUrl}
          onClick={togglePopover}
          isActive={isPopoverOpen}
        />
      </div>
    </>
  );
}
