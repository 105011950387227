import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { Button } from '../button';
import type { DefinitionListItem } from '../definition-list';
import { DefinitionList } from '../definition-list';
import type { Employee } from '../employee-badge/types';
import { EmployeeThumbnail, squircleClipPath } from '../employee-thumbnail';
import type { EmployeeDetailsPopoverVariant } from '../employees-details-popover/types';
import { IconEmailOutline } from '../icons';
import { MiniIconPerson } from '../mini-icons';
import { Padding } from '../padding';
import { SmallCaps } from '../small-caps';
import { Text } from '../text';
import { TextLink } from '../text-link';

interface Props
  extends Partial<
    Pick<Employee, 'title' | 'name' | 'phone' | 'imageUrl' | 'email' | 'extension' | 'departments' | 'team'>
  > {
  variant?: EmployeeDetailsPopoverVariant;
  onCopyEmail?: () => unknown;
  hideEmailAddress?: boolean;
}

function copyEmail(email: string, onCopyEmail?: () => unknown): void {
  if (email) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigator.clipboard.writeText(email);
    if (onCopyEmail) {
      onCopyEmail();
    }
  }
}

export function EmployeeProfile({
  imageUrl,
  name,
  title,
  email,
  phone,
  variant,
  onCopyEmail,
  team,
  hideEmailAddress = false,
}: Props): JSX.Element {
  const employeeDetailItems: DefinitionListItem[] = [];

  // optionally render a title, if it exists
  if (title) {
    employeeDetailItems.push({
      type: undefined,
      title: 'Title',
      value: title,
      testId: 'employee-title',
    });
  }

  // optionally render a team, if it exists
  if (team) {
    employeeDetailItems.push({
      type: undefined,
      title: 'Team',
      value: team,
      testId: 'employee-team',
    });
  }

  // optionally render a phone number, if it exists
  if (phone) {
    employeeDetailItems.push({
      type: undefined,
      title: 'Phone',
      value: phone,
      testId: 'employee-phone',
    });
  }

  // smaller inline version
  if (variant === 'inline') {
    return (
      <div className="flex items-center">
        {imageUrl ? (
          <EmployeeThumbnail sizeInPixels={40} imageUrl={imageUrl} variant="round" />
        ) : (
          <StyledFallback variant="round" size={40}>
            <MiniIconPerson color={colors.steel[200]} />
          </StyledFallback>
        )}
        <Padding left={8}>
          <Text color={colors.steel[500]} size="large" testId="name">
            {name}
          </Text>

          <DefinitionList labelWidth="80px" labelSpacing={12} columnSpacing={0} items={employeeDetailItems} />
        </Padding>
      </div>
    );
  }

  if (variant === 'small') {
    return (
      <div className="flex items-center">
        {imageUrl ? (
          <EmployeeThumbnail sizeInPixels={40} imageUrl={imageUrl} variant="round" />
        ) : (
          <StyledFallback variant="round">
            <MiniIconPerson color={colors.steel[200]} />
          </StyledFallback>
        )}
        <Padding left={12}>
          <div className="flex flex-col gap-0">
            {title && <SmallCaps testId="employee-title">{title}</SmallCaps>}
            <Text color="dark" testId="employee-name">
              {name}
            </Text>
          </div>
        </Padding>
      </div>
    );
  }

  if (variant === 'details' || variant === 'expanded') {
    return (
      <div className="flex">
        <Padding right={12} top={8}>
          {imageUrl ? (
            <EmployeeThumbnail sizeInPixels={40} imageUrl={imageUrl} variant="round" />
          ) : (
            <StyledFallback variant="round">
              <MiniIconPerson color={colors.steel[200]} />
            </StyledFallback>
          )}
        </Padding>
        <div className="flex flex-col justify-center gap-2">
          <div>
            <Text color={colors.steel[500]} size="large">
              {name}
            </Text>

            <DefinitionList labelWidth="80px" labelSpacing={12} columnSpacing={0} items={employeeDetailItems} />
          </div>

          {email && (
            <div className="flex flex-row gap-4">
              <Button size="secondary" onClick={() => copyEmail(email, onCopyEmail)}>
                <div className="flex flex-row items-center">
                  <IconEmailOutline size={18} />
                  <Padding left={8}>
                    <Text>Copy email address</Text>
                  </Padding>
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
      <Padding right={20}>
        {imageUrl ? (
          <EmployeeThumbnail imageUrl={imageUrl} sizeInPixels={60} />
        ) : (
          <StyledFallback variant="round" size={60}>
            <MiniIconPerson color={colors.steel[200]} />
          </StyledFallback>
        )}
      </Padding>
      <div className="flex flex-col justify-center">
        <Text size="regular" color="dark">
          <Text color={colors.steel[500]} testId="employee-name">
            {name}
          </Text>
          <DefinitionList labelWidth="80px" labelSpacing={12} columnSpacing={0} items={employeeDetailItems} />

          {email && !hideEmailAddress && (
            <TextLink style={{ color: colors.steel[500] }} href={`mailto:${email}`} testId="employee-email">
              {email}
            </TextLink>
          )}
        </Text>
      </div>
    </div>
  );
}

const StyledFallback = styled.div<{ variant?: string; size?: number }>`
  height: ${({ size }) => (size ? `${size}px` : '40px')};
  width: ${({ size }) => (size ? `${size}px` : '40px')};
  background-color: ${colors.steel[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ variant }) => (variant === 'squircle' ? `clip-path: polygon(${squircleClipPath(6)});` : 'border-radius: 50%;')};
`;
