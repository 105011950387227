import type { LineOfCoverage } from '../../../../../../bff/types';

export function stringifyLinesOfCoverage(
  linesOfCoverage: Partial<Pick<LineOfCoverage, 'definition' | 'displayName'>>[],
): string {
  return (
    linesOfCoverage
      .map((loc) => `${loc.definition?.readableName}${loc.displayName ? ` - ${loc.displayName}` : ''}`)
      .join(', ') || '-'
  );
}
