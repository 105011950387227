import { Text } from '@newfront-insurance/core-ui';

import type { Address } from '../../types';

interface Props {
  address?: Address;
}

export function AddressFormattedText({ address }: Props): JSX.Element {
  if (!address) return <div />;
  const { addressLine1, addressLine2, city, displayName, stateOrArea, postalCode } = address;

  return (
    <Text size="small" color="default">
      {displayName && <div>{displayName}</div>}
      <b>{addressLine1}</b>
      {addressLine2 && <div>{addressLine2}</div>}
      <div>
        {city}, {stateOrArea}, {postalCode}
      </div>
    </Text>
  );
}
