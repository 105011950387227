import { useMemo } from 'react';

import type { WithEmployeeRoleMappings } from '@/api/types';
import { useLoggedInUser } from '@/client/hooks/use-logged-in-user';

export function useIsOwnedByCurrentUser(account: WithEmployeeRoleMappings | undefined): boolean {
  const user = useLoggedInUser();
  const currentUserUuid = user?.uuid;

  const isOwnedByCurrentUser = useMemo(() => {
    if (!account || !currentUserUuid) {
      return false;
    }

    return Object.values(account.employeeRoleMappings)
      .flat()
      .map((accountRoleMapping) => accountRoleMapping.userUuid)
      .includes(currentUserUuid);
  }, [currentUserUuid, account]);

  return isOwnedByCurrentUser;
}
