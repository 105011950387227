import type { RouterUseQueryOptions, RouterUseSuspenseQueryOptions } from '@newfront-insurance/data-layer-client';
import type { RouterQueryInput } from '@newfront-insurance/data-layer-server';

import { useAdminTRPC } from '../../../shared/providers/trpc';
import type { SharedRouter } from '../../../shared/trpc/router';

export function useLinesOfCoverage(
  params: RouterQueryInput<SharedRouter, 'loc.getLocs'>,
  options?: RouterUseQueryOptions<SharedRouter, 'loc.getLocs'>,
) {
  const { useQuery } = useAdminTRPC();

  return useQuery(['loc.getLocs', params], options);
}

export function useSuspenseLinesOfCoverage(
  params: RouterQueryInput<SharedRouter, 'loc.getLocs'>,
  options?: RouterUseSuspenseQueryOptions<SharedRouter, 'loc.getLocs'>,
) {
  const { useSuspenseQuery } = useAdminTRPC();

  return useSuspenseQuery(['loc.getLocs', params], options);
}
