/**
 * This hook makes a usAutocomplete request when the search query changes and returns results.
 */

import { useNotifications } from '@newfront-insurance/next-notifications';
import { useCallback, useState } from 'react';
import type { core } from 'smartystreets-javascript-sdk';
import { usAutocompletePro } from 'smartystreets-javascript-sdk';

import { useSmartyStreetsClient } from '../../providers/smartystreets';

export type Lookup = usAutocompletePro.Lookup | undefined;
export type LookupAddress = (query: string, maxResults?: number) => Promise<Lookup>;
export type SmartyStreetsHook = [LookupAddress, boolean, core.Errors.SmartyError | undefined];

export function useSmartyStreets(): SmartyStreetsHook {
  const { usAutocompleteProClient } = useSmartyStreetsClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<core.Errors.SmartyError>();
  const notifications = useNotifications();

  async function lookupAddress(query: string, maxResults?: number): Promise<Lookup> {
    const lookup = new usAutocompletePro.Lookup(query);
    lookup.maxResults = maxResults || 5;
    setLoading(true);
    setError(undefined);

    try {
      const res = await usAutocompleteProClient.send(lookup);
      return res;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const error = e as core.Errors.SmartyError;
      setError(error);

      if (e.statusCode !== 401) {
        notifications.addById('use-smartystreets-hook', {
          title: `There was a problem looking up address. ${error.message}`,
          type: 'error',
        });

        throw e;
      }
    } finally {
      setLoading(false);
    }
  }

  return [useCallback(lookupAddress, [usAutocompleteProClient, notifications]), loading, error];
}
