import {
  Tether,
  PopoverAnimation,
  Padding,
  Box,
  usePopover,
  Portal,
  EmployeeAvatar,
  Menu2 as Menu,
} from '@newfront-insurance/core-ui';

import { useAuthAvatarType, useAuthProfilePic } from './avatar';
import { useAccountMenuItems } from './menu-items';

export function AccountMenu(): JSX.Element {
  const employeeType = useAuthAvatarType();
  const imageUrl = useAuthProfilePic();

  const { popoverRef, isPopoverOpen, togglePopover, targetRef } = usePopover<HTMLDivElement, HTMLDivElement>({
    excludeTarget: true,
  });

  const menuItems = useAccountMenuItems();

  return (
    <>
      <EmployeeAvatar
        employeeType={employeeType}
        imageUrl={imageUrl}
        sizeInPixels={32}
        onClick={togglePopover}
        ref={targetRef}
      />
      <Portal name="popover">
        <Tether targetRef={targetRef} targetAnchor="bottomRight" anchor="topRight" yOffset={10} xOffset={0}>
          <PopoverAnimation isOpen={isPopoverOpen} direction="up">
            <Box
              backgroundColor="white"
              borderRadius={3}
              elementRef={popoverRef}
              boxShadow="overlay"
              style={{ minWidth: 200 }}
            >
              <Padding y={8}>
                <Menu items={menuItems} />
              </Padding>
            </Box>
          </PopoverAnimation>
        </Tether>
      </Portal>
    </>
  );
}
