import {
  CircleSpinnerInline,
  colors,
  fontFamilies,
  fontSizes,
  IconClose as IconNavigationClose,
  IconSearch2 as IconGlobalSearch,
} from '@newfront-insurance/core-ui';
import { useContext } from 'react';
import * as React from 'react';
import styled, { css } from 'styled-components';

import { ACTIVE_GLOBAL_SEARCH_KEY } from '../../app-header/lib/consts';
import { SearchContext } from '../context';

interface Props {
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onReset: () => void;
  placeholder: string;
  innerRef: React.RefObject<HTMLInputElement>;
}

export function InputSearch({ innerRef, onChange, onReset, onKeyDown, placeholder }: Props): JSX.Element {
  const { currentQuery, isSearching, results, error } = useContext(SearchContext);

  const handleOnReset = (): void => {
    innerRef?.current?.focus();
    onReset();
  };

  return (
    <StyledInputSearch isResultActive={!!currentQuery && !isSearching && (!!results || !!error)}>
      <StyledIconSearch>
        <IconGlobalSearch />
      </StyledIconSearch>
      <input
        name="global-search"
        ref={innerRef}
        placeholder={placeholder}
        onChange={onChange}
        value={currentQuery}
        autoComplete="off"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onKeyDown={onKeyDown}
        // avoid type forward slice in the input when search is triggered
        onKeyPress={(e) => {
          if (!currentQuery && e.key === ACTIVE_GLOBAL_SEARCH_KEY) {
            e.preventDefault();
          }
        }}
      />
      {currentQuery && isSearching && (
        <StyledCircleSpinner>
          <CircleSpinnerInline size={16} />
        </StyledCircleSpinner>
      )}
      {currentQuery && (
        <StyledButtonIconReset onClick={handleOnReset}>
          <IconNavigationClose color={colors.steel[400]} />
        </StyledButtonIconReset>
      )}
    </StyledInputSearch>
  );
}

const StyledInputSearch = styled.div<{ isResultActive: boolean }>`
  --icon-size: 14px;
  --default-spacing: 12px;

  position: relative;
  z-index: 4;

  input {
    border-radius: 8px;
    ${({ isResultActive }) =>
      isResultActive &&
      css`
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `}
    border: none;

    box-sizing: border-box;
    display: block;
    font-family: ${fontFamilies.body};
    font-size: ${fontSizes.large.fontSize};
    height: 100%;
    min-height: 72px;
    padding: 1rem 2rem;
    padding-left: calc(1.5rem + var(--icon-size) + var(--default-spacing));
    text-decoration: none;
    text-overflow: ellipsis;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgba(84, 106, 131, 0.5);
    }
  }
`;

const StyledIconSearch = styled.div`
  left: 1.5rem;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.steel[400]};
`;

const StyledButtonIconReset = styled.div`
  cursor: pointer;
  line-height: 0;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledCircleSpinner = styled.div`
  line-height: 0;
  position: absolute;
  right: calc(25px + var(--icon-size) + var(--default-spacing));
  top: 50%;
  transform: translateY(-50%);
`;
