import { AuthRole } from '@newfront-insurance/auth-api';
import { LogoSpinner } from '@newfront-insurance/core-ui';
import { hasAllScopes, hasAnyScope } from '@newfront-insurance/next-auth';
import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { useEffect } from 'react';
import * as React from 'react';
import invariant from 'tiny-invariant';

import { useLoggedInUser } from '@/client/hooks/use-logged-in-user';

enum MATCH {
  EXACT = 'EXACT', // The user ONLY has these exact roles (and no more)
  ANY = 'ANY', // The user has ANY of the roles
  ALL = 'ALL', // The user has ALL of these roles (but maybe more)
}

interface Redirect {
  roles: AuthRole[];
  pathname: string;
  match: MATCH;
}

const OVERRIDES: Redirect[] = [
  {
    roles: [AuthRole.GROWTH_CALLER],
    pathname: '/accounts/create',
    match: MATCH.ALL,
  },
  // The redirect with ensure that any user with this role AND any other role will be redirected to the accounts app.
  // There are cases where producers are also account managers, the AM role beats the producer role and so they should
  // be sent to accounts. When a user is an admin and has many roles, they are also forced to visit accounts.
  {
    roles: [
      AuthRole.PRODUCER,
      AuthRole.PRODUCER_MANAGER,
      AuthRole.SALES_MANAGER,
      AuthRole.ACCOUNT_MANAGER,
      AuthRole.AM_MANAGER,
      AuthRole.ENGINEER,
    ],
    pathname: '/accounts',
    match: MATCH.ANY,
  },
];

/**
 * This provider should be placed within a LoginBoundary. When the user exists and the current
 * path is `/` it will automatically redirect the user to the app based on their role.
 * @returns
 */
export function PostLoginRedirectProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const user = useLoggedInUser();
  const { pushRoute, router } = useRouter();
  const isRoot = router.pathname === '/';

  invariant(user, 'PostLoginRedirect should be within a LoginBoundary so the user is guaranteed to exist');

  useEffect(() => {
    if (isRoot) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      pushRoute(getAppRoute(user.roles));
    }
  }, [isRoot, pushRoute, user]);

  if (isRoot) {
    return <LogoSpinner />;
  }

  return children as JSX.Element;
}

/**
 * Take the user roles and return a route to redirect the user to the correct path.
 * @param roles User roles from the JWT token
 * @returns Route that can be used by pushRoute
 */
function getAppRoute(roles: string[]): Route {
  // Default redirect
  let pathname = '/accounts';

  OVERRIDES.forEach((redirect) => {
    if (redirect.match === MATCH.ALL && hasAllScopes(roles, redirect.roles)) {
      // Check if user has all roles
      pathname = redirect.pathname;
    } else if (redirect.match === MATCH.ANY && hasAnyScope(roles, redirect.roles)) {
      // User has any of the roles
      pathname = redirect.pathname;
    }
  });

  return {
    pathname,
  };
}
