import type { Money } from '@newfront-insurance/core-money';
import { CheckboxRow } from '@newfront-insurance/core-ui';
import { InputType } from '@newfront-insurance/dsl-schema-api';

import { Label } from './label';
import { Value } from './value';
import type { SchemaFieldType } from '../../types';

interface Props {
  name: string;
  field: SchemaFieldType;
  // TODO: all the types "any" for values can be improved using generics depending on the InputType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  accountUuid?: string;
}

export function DisplayItem(props: Props): JSX.Element {
  const { name, field, value, accountUuid } = props;
  const { inputType: type, inputMetadata: metadata } = field;

  const testId = `DisplayItem-${name}`;
  const label = metadata?.label || metadata?.subText || '';

  switch (type) {
    // @deprecated this should be removed after the DSL backend remove its usage
    case 'currencywithnumber': {
      const typedValue = value as { moneyLimit: Money; durationInMonthsLimit: number } | undefined;
      return (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col">
            <Label name={`${name}-currency`} label={label} />
            <Value
              name={`${name}-currency`}
              field={{
                ...field.properties?.moneyLimit,
                inputMetadata: metadata,
                inputType: InputType.CURRENCY,
                errors: {},
              }}
              value={typedValue?.moneyLimit}
            />
          </div>
          <div className="flex flex-col">
            <Label name={`${name}-number`} label={metadata?.numberFieldLabel || ''} />
            <Value
              name={`${name}-number`}
              field={{
                inputMetadata: metadata,
                inputType: InputType.NUMBER,
                errors: {},
              }}
              value={typedValue?.durationInMonthsLimit}
            />
          </div>
        </div>
      );
    }
    case 'checkbox':
      return <CheckboxRow testId={testId} label={metadata?.checkboxLabel || ''} isActive={!!value} disabled />;
    default:
      break;
  }

  return (
    <div className="flex w-full flex-col">
      <Label name={name} label={label} />
      <Value name={name} field={field} value={value} accountUuid={accountUuid} />
    </div>
  );
}
