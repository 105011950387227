import type { Option } from '@newfront-insurance/core-ui';
import { SelectInput } from '@newfront-insurance/core-ui';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface Props {
  value?: string;
  onChange: (countryCode: string) => unknown;
  disabled?: boolean;
  name?: string;
  innerRef?: React.MutableRefObject<HTMLSelectElement | null>;
  placeholder?: string;
  testId?: string;
}

export interface Country {
  country_name_en: string;
  alpha3: string;
}

async function getCountriesOptions(): Promise<Option[]> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const countriesQuery = await import('countries-code');
  const options: Option[] = countriesQuery.allCountriesList().map((country: Country) => ({
    label: country.country_name_en,
    value: country.alpha3,
  }));
  return options;
}

export function CountryCodeSelect({
  name,
  onChange,
  value,
  innerRef,
  disabled,
  placeholder = 'Select country',
  testId,
}: Props): JSX.Element {
  const [options, setOptions] = useState<Option[]>();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getCountriesOptions().then((countries: Option[]) => setOptions(countries));
  }, []);

  return (
    <div>
      <SelectInput
        name={name}
        options={options || []}
        onChange={onChange}
        showSelectAnOption={!value}
        placeholder={placeholder}
        value={value}
        innerRef={innerRef}
        disabled={disabled}
        testId={testId}
      />
    </div>
  );
}
