import type { Calendar } from '@newfront-insurance/string-formatters';
import { createCalendar } from '@newfront-insurance/string-formatters';
import { useState } from 'react';

export interface CalendarHook {
  increaseMonth: () => void;
  decreaseMonth: () => void;
  calendar: Calendar;
  setYear: (year: number) => void;
  setMonth: (month: number) => void;
  year: number;
  month: number;
}

interface Options {
  initialVisibleMonth: number;
  initialVisibleYear: number;
}

export function useCalendar(options: Options): CalendarHook {
  const { initialVisibleMonth, initialVisibleYear } = options;
  const [visibleYear, setVisibleYear] = useState<number>(initialVisibleYear);
  const [visibleMonth, setVisibleMonth] = useState<number>(initialVisibleMonth);

  const calendar = createCalendar({
    month: visibleMonth,
    year: visibleYear,
  });

  function increaseMonth(): void {
    const nextMonth = visibleMonth + 1;
    if (nextMonth > 12) {
      setVisibleMonth(1);
      setVisibleYear(visibleYear + 1);
    } else {
      setVisibleMonth(nextMonth);
    }
  }

  function decreaseMonth(): void {
    const prevMonth = visibleMonth - 1;
    if (prevMonth < 1) {
      setVisibleMonth(12);
      setVisibleYear(visibleYear - 1);
    } else {
      setVisibleMonth(prevMonth);
    }
  }

  return {
    increaseMonth,
    decreaseMonth,
    calendar,
    year: visibleYear,
    month: visibleMonth,
    setYear: (year: number) => {
      setVisibleYear(year);
    },
    setMonth: (month: number) => {
      setVisibleMonth(month);
    },
  };
}
