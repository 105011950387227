import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Flexbox,
  Text,
  colors,
  Padding,
  Box,
  Stack,
  SmallCaps,
  Spacing,
  Badge,
  Menu,
  Input,
  FlexCell,
  Alert,
} from '@newfront-insurance/core-ui';
import type { LineOfCoverageType, ProgramType } from '@newfront-insurance/coverage-api';
import { useState } from 'react';
import styled from 'styled-components';

import { LineOfCoverageTypeCard } from './components/line-of-coverage-type-card';
import { useLineOfCoverageEditorOptions } from './lib/options-hook';
import { useSelectedLinesOfCoverage } from './lib/selected-hook';
import type { LineOfCoverageTypeDetails } from '../..';
import { PolicyTypeSelectInput } from '../policy-type-select-input';

export interface Props {
  /** Initial array of Lines of coverage that will be shown as selected */
  initialLineOfCoverageTypeDetails: LineOfCoverageTypeDetails[];
  /** Modal title */
  title?: string;
  onSubmit: (linesOfCoverage: LineOfCoverageTypeDetails[]) => Promise<void> | void;
  /** Function to build a label for the submit button. It receives the array of selected Lines of coverage as a param */
  renderSubmitButtonLabel?: (linesOfCoverage: LineOfCoverageTypeDetails[]) => string;
  onSubmitSuccess?: (linesOfCoverage: LineOfCoverageTypeDetails[]) => void;
  onClose: () => unknown;
  /** Optional object contains a prop to filter by program or by excluding Lines of Coverage */
  options?: {
    filterByProgram?: ProgramType;
    excludedLineOfCoverageTypes?: LineOfCoverageType[];
  };
}

export function AddLinesOfCoverageModal({
  initialLineOfCoverageTypeDetails,
  title = 'Add lines of coverage',
  renderSubmitButtonLabel = (linesOfCoverage) => `Add ${linesOfCoverage?.length} lines of coverage`,
  onClose,
  onSubmit,
  onSubmitSuccess,
  options,
}: Props): JSX.Element {
  const { addLoc, removeLoc, updateLocDisplayName, selectedLineOfCoverageTypeDetails, isDeleting } =
    useSelectedLinesOfCoverage(initialLineOfCoverageTypeDetails);

  const { selectedPolicyType, setSelectedPolicyType, lineOfCoverageOptions, keyword, search } =
    useLineOfCoverageEditorOptions({
      onSelect: addLoc,
      program: options?.filterByProgram,
      excludedLineOfCoverageTypes: options?.excludedLineOfCoverageTypes,
    });

  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Modal isOpen onToggle={onClose} width="710px">
      <ModalBody>
        <ModalHeader titleText={title} showBorder onClose={onClose} />
        <Padding top={8} bottom={8}>
          <Flexbox width="100%">
            <FlexCell flex={1}>
              <Padding left={4}>
                <Input
                  type="search"
                  placeholder="Search lines of coverage..."
                  style={{
                    border: 0,
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                  value={keyword}
                  onChange={search}
                />
              </Padding>
            </FlexCell>
            <FlexCell width="380px">
              <Padding right={16} left={16}>
                <PolicyTypeSelectInput
                  placeholder="Filter by policy type"
                  value={selectedPolicyType}
                  onChange={(policyType) => setSelectedPolicyType(policyType)}
                  isClearable
                />
              </Padding>
            </FlexCell>
          </Flexbox>
        </Padding>
        <StyledBody>
          <StyledLocSelect>
            <Box borderTop={1}>
              <Padding x={16} top={12} bottom={16}>
                <Flexbox flex="flex-start" alignItems="center">
                  <SmallCaps color={colors.steel[400]}>Lines of coverage</SmallCaps>
                </Flexbox>
              </Padding>
              <Stack direction="vertical" gap={4}>
                <Menu items={lineOfCoverageOptions} />
              </Stack>
            </Box>
          </StyledLocSelect>
          <StyledCart>
            <Box borderTop={1} borderLeft={1}>
              {selectedLineOfCoverageTypeDetails?.length ? (
                <Padding x={16} y={12}>
                  <Flexbox gap={4} flex="flex-start" alignItems="center">
                    <SmallCaps color={colors.steel[400]}>Current selection</SmallCaps>
                    <Badge type="primary" style={{ maxWidth: 27, minWidth: 27 }} width={27} borderRadiusSize={30}>
                      {selectedLineOfCoverageTypeDetails.length}
                    </Badge>
                  </Flexbox>
                  <Spacing height={16} />
                  <Stack direction="vertical" gap={4}>
                    {selectedLineOfCoverageTypeDetails.map((loc, index) => (
                      <LineOfCoverageTypeCard
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${loc.type}-${index}`}
                        index={index}
                        lineOfCoverage={loc}
                        onRemove={removeLoc}
                        onDisplayNameChange={updateLocDisplayName}
                      />
                    ))}
                  </Stack>
                </Padding>
              ) : (
                <Padding x={120}>
                  <Text marginTop={140} textAlign="center" color={colors.steel[400]}>
                    You don&apos;t have any lines of coverage selected. Add some by selecting a line of coverage on the
                    left.
                  </Text>
                </Padding>
              )}
            </Box>
          </StyledCart>
        </StyledBody>

        <ModalFooter>
          <Flexbox flexDirection="column" width="100%">
            {isDeleting && (
              <>
                <Alert>
                  <div>
                    <Text>
                      Deleting lines of coverage from this policy may result in losing data previously entered. You
                      should only delete a line of coverage if it does not exist on the policy.
                    </Text>
                  </div>
                </Alert>
                <Spacing height={12} />
              </>
            )}

            <Flexbox gap={8} justifyContent="flex-end" width="100%">
              <Button onClick={onClose} size="secondary">
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setSubmitting(true);

                  await onSubmit(selectedLineOfCoverageTypeDetails);

                  setSubmitting(false);

                  if (onSubmitSuccess) {
                    onSubmitSuccess(selectedLineOfCoverageTypeDetails);
                  }
                }}
                disabled={
                  !selectedLineOfCoverageTypeDetails || selectedLineOfCoverageTypeDetails?.length < 1 || isSubmitting
                }
                loading={isSubmitting}
                testId="submit-locs"
              >
                {renderSubmitButtonLabel(selectedLineOfCoverageTypeDetails)}
              </Button>
            </Flexbox>
          </Flexbox>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}

const StyledLocSelect = styled.div`
  grid-area: loc-select;
  overflow-y: scroll;
`;

const StyledCart = styled.div`
  grid-area: cart;
  background-color: ${colors.steel[100]};
  display: grid;
  position: relative;
  overflow-y: scroll;
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 380px;
  grid-template-areas: 'loc-select cart cart';
`;
