import { forwardRef } from 'react';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import ReactTextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';

import { fontFamilies, fontSizes } from '../../theme';
import { colors } from '../../theme/colors';

export const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaAutosizeProps>((props, ref) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledTextarea ref={ref} {...props} />;
});

const StyledTextarea = styled(ReactTextareaAutosize)`
  width: 100%;
  border: 1px solid ${colors.steel[200]};
  resize: none;
  box-shadow: 0px 2px 2px rgba(153, 177, 190, 0.2);
  padding: 16px;
  font-family: ${fontFamilies.body};
  font-size: ${fontSizes.regular.fontSize};
  line-height: ${fontSizes.regular.lineHeight};
  box-sizing: border-box;
  outline: 0;
  border-radius: 4px;
  display: block;

  &::placeholder {
    color: ${colors.steel[300]};
  }

  &:focus {
    border-color: ${colors.brand[200]};
  }
`;
