import { useNotify } from '@newfront-insurance/next-notifications';
import noop from 'lodash/noop';

import type { Policy } from '../../bff/types';
import { useUpdatePolicyLocs } from '../../mutations/update-policy-locs';
import { AddLinesOfCoverageModal } from '../add-lines-of-coverage-modal';

interface Props {
  isOpen: boolean;
  onSubmitSuccess?: () => void;
  onToggle: () => void;
  policy: Policy;
}

export function EditPolicyLinesOfCoverageModal({
  isOpen,
  policy,
  onSubmitSuccess = noop,
  onToggle,
}: Props): JSX.Element | null {
  const [updateLocs] = useUpdatePolicyLocs();
  const notify = useNotify();

  if (!isOpen) {
    return null;
  }

  return (
    <AddLinesOfCoverageModal
      onSubmit={async (locs) => {
        try {
          await updateLocs({
            params: {
              policyUuid: policy.uuid ?? '',
            },
            body: {
              linesOfCoverage: locs.map((loc) => ({
                uuid: loc.uuid,
                data: {},
                type: loc.type,
                version: loc.version,
                displayName: loc.displayName ?? undefined,
              })),
            },
          });
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const errData = (e as any).data;
          const errors = errData?.errors ?? [];
          if (errors.length) {
            notify.error(errors[0]);
          } else {
            notify.error('There was a problem updating the lines of coverage for this policy.');
          }
        }
      }}
      title="Manage lines of coverage"
      options={{
        filterByProgram: policy.definition?.program,
      }}
      renderSubmitButtonLabel={() => 'Update lines of coverage'}
      onSubmitSuccess={onSubmitSuccess}
      onClose={onToggle}
      initialLineOfCoverageTypeDetails={policy.linesOfCoverage.map((loc) => ({
        type: loc.type,
        version: loc.version,
        displayName: loc.displayName,
        uuid: loc.uuid,
      }))}
    />
  );
}
