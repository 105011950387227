import type { Identity as ApiIdentity } from '@newfront-insurance/account-api';
import { IdentityStatus, IdentityType } from '@newfront-insurance/account-api';
import { formatIdentityValue } from '@newfront-insurance/account-common';

import { NOW } from './dates';
import { ACCOUNT_UUID, BUSINESS_IDENTIFIER_UUID } from './uuids';
import type { Identity } from '../bff/types';

export const API_BUSINESS_IDENTIFIER: ApiIdentity = {
  uuid: BUSINESS_IDENTIFIER_UUID,
  accountUuid: ACCOUNT_UUID,
  countryCode: 'US',
  status: {
    status: IdentityStatus.ACTIVE,
    statusDate: NOW as unknown as Date,
  },
  type: IdentityType.BUSINESS_IDENTIFIER,
  value: '293487960',
};

export const BUSINESS_IDENTIFIER: Identity = {
  uuid: API_BUSINESS_IDENTIFIER.uuid as string,
  accountUuid: API_BUSINESS_IDENTIFIER.accountUuid,
  issuingState: undefined,
  issueDate: undefined,
  expirationDate: undefined,
  type: IdentityType.BUSINESS_IDENTIFIER,
  countryCode: 'US',
  value: formatIdentityValue({
    countryCode: API_BUSINESS_IDENTIFIER.countryCode,
    type: API_BUSINESS_IDENTIFIER.type,
    value: API_BUSINESS_IDENTIFIER.value,
  }),
};
