import { isEmpty } from '@newfront-insurance/core';
import { Input } from '@newfront-insurance/core-ui';

interface Props {
  onChange: (value?: string) => void;
  disabled?: boolean;
  value?: number;
}

export function PercentageInput({ value, onChange, disabled }: Props): JSX.Element {
  return (
    <Input
      type="number"
      value={value}
      onChange={(inputValue) => onChange(isEmpty(inputValue) ? undefined : inputValue)}
      appendText="%"
      disabled={disabled}
    />
  );
}
