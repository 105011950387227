import { isObject } from '@newfront-insurance/core';

import type {
  BadRequestResult,
  DefaultErrorResult,
  Error,
  NotAuthorizedResult,
  NotFoundResult,
  ServiceErrorResult,
  SuccessfulResult,
} from './types/results';
import { ResultType } from './types/results';

export function notFoundResult(message: string, originalError?: Error): NotFoundResult {
  return {
    type: ResultType.NOT_FOUND,
    message,
    originalError,
  };
}

export function isNotFoundResult(value: unknown): value is NotFoundResult {
  return isObject(value) && value.type === ResultType.NOT_FOUND;
}

export function notAuthorizedResult(message: string, originalError?: Error): NotAuthorizedResult {
  return {
    type: ResultType.NOT_AUTHORIZED,
    message,
    originalError,
  };
}

export function isNotAuthorizedResult(value: unknown): value is NotAuthorizedResult {
  return isObject(value) && value.type === ResultType.NOT_AUTHORIZED;
}

export function serviceErrorResult(message: string, originalError?: Error): ServiceErrorResult {
  return {
    type: ResultType.SERVICE_ERROR,
    message,
    originalError,
  };
}

export function isServiceErrorResult(value: unknown): value is ServiceErrorResult {
  return isObject(value) && value.type === ResultType.SERVICE_ERROR;
}

export function defaultErrorResult(message: string, originalError?: Error): DefaultErrorResult {
  return {
    type: ResultType.DEFAULT_ERROR,
    message,
    originalError,
  };
}

export function isDefaultErrorResult(value: unknown): value is DefaultErrorResult {
  return isObject(value) && value.type === ResultType.DEFAULT_ERROR;
}

export function badRequestResult(message: string, originalError?: Error): BadRequestResult {
  return {
    type: ResultType.BAD_REQUEST,
    message,
    originalError,
  };
}

export function isBadErrorResult(value: unknown): value is BadRequestResult {
  return isObject(value) && value.type === ResultType.DEFAULT_ERROR;
}

export function isAnyErrorResult(
  value: unknown,
): value is ServiceErrorResult | NotFoundResult | NotAuthorizedResult | BadRequestResult | DefaultErrorResult {
  return (
    isObject(value) &&
    (isServiceErrorResult(value) ||
      isNotAuthorizedResult(value) ||
      isNotFoundResult(value) ||
      isBadErrorResult(value) ||
      isDefaultErrorResult(value))
  );
}

export function isSuccesfulResult<T>(value: T): value is SuccessfulResult<T> {
  return !isAnyErrorResult(value);
}
