import { isValidElement, cloneElement, Fragment } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../theme/colors';
import { ExpandableBody, ExpandableRow } from '../../../expandable-row';
import { Flexbox } from '../../../flexbox';
import { IconArrowDropDown } from '../../../icons';
import { LineClamp } from '../../../line-clamp';
import { Padding } from '../../../padding';
import { Text } from '../../../text';
import type { BaseCardProps } from '../../types';
import { INNER_PADDING } from '../../utils';
import { SelectableControl } from '../selectable-control';

export function CompactSizeDetailCard(props: BaseCardProps): JSX.Element {
  const {
    actions,
    assistiveText,
    children,
    disabledProps,
    expanded,
    hasActions,
    isExpandable,
    isHighlighted,
    isSelectable,
    selectableOptions,
    setExpanded,
    showSubtitleComponentWhenExpanded,
    subtitle,
    testId,
    title,
    titleColor = 'black',
  } = props;
  const { isDisabled } = disabledProps ?? {};
  return (
    <ExpandableRow removeStyling expanded={expanded} onToggleRow={(value) => setExpanded(!value)}>
      <Flexbox alignItems="flex-start">
        {isExpandable && (
          <StyledTitleChevron isClickable={isExpandable} onClick={isExpandable && (() => setExpanded(!expanded))}>
            <StyledArrowDiv className={expanded ? 'expanded' : undefined}>
              <IconArrowDropDown size={24} testId={`${testId}-expand-btn`} />
            </StyledArrowDiv>
          </StyledTitleChevron>
        )}
        <Flexbox flex={1} flexDirection="column">
          <Flexbox justifyContent="space-between" gap={INNER_PADDING}>
            <StyledTitleChevron
              isClickable={isExpandable}
              onClick={isExpandable ? () => setExpanded(!expanded) : undefined}
            >
              <LineClamp numOfLines={expanded ? 99 : 2}>
                <Text
                  weight={700}
                  color={isHighlighted ? colors.brand[400] : titleColor}
                  testId={`${testId}-title`}
                  breakword
                >
                  {title}
                </Text>
              </LineClamp>
            </StyledTitleChevron>
            {isSelectable ? (
              <Flexbox alignItems="flex-start">
                <SelectableControl selectableOptions={selectableOptions} disabled={isDisabled} />
              </Flexbox>
            ) : (
              <Flexbox gap={INNER_PADDING}>
                {hasActions && (
                  <>
                    {actions?.map((action, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Fragment key={`action-for-${title}-${i}`}>
                        {isDisabled && isValidElement(action)
                          ? cloneElement<{ disabled?: boolean }>(action as JSX.Element, { disabled: isDisabled })
                          : action}
                      </Fragment>
                    ))}
                  </>
                )}
              </Flexbox>
            )}
          </Flexbox>
          {subtitle && (!expanded || (expanded && showSubtitleComponentWhenExpanded)) && (
            <LineClamp numOfLines={expanded ? 99 : 1}>
              <Text color="light" testId={`${testId}-subtitle`} breakword>
                {subtitle}
              </Text>
            </LineClamp>
          )}
        </Flexbox>
      </Flexbox>
      <ExpandableBody paddingX={0} paddingBottom={0}>
        <Padding size={INNER_PADDING}>
          {children}
          {assistiveText && (
            <Padding top={INNER_PADDING}>
              <Text color="light" size="small" testId={`${testId}-assistive-text`}>
                {assistiveText}
              </Text>
            </Padding>
          )}
        </Padding>
      </ExpandableBody>
    </ExpandableRow>
  );
}

const StyledArrowDiv = styled.div`
  transition: transform 0.3s ease;
  border-radius: 50%;
  background: none;
  display: inline-block;

  &.expanded {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
  }
`;

const StyledTitleChevron = styled.div(
  ({ isClickable }: { isClickable: boolean }) => `
  cursor: ${isClickable ? 'pointer' : 'default'};
  }
  `,
);
