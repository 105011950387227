import { DatadogRumWrapper } from '@newfront-insurance/dd-rum';
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import * as React from 'react';

import type { DdRumConfig } from '../general-app-providers';

interface Props {
  children: React.ReactNode;
  config: DdRumConfig;
  authProvider: Provider<AuthProviderContext>;
}

export function DatadogWrapper({ children, config, authProvider }: Props): JSX.Element {
  return (
    <DatadogRumWrapper
      appName={config.appName}
      applicationId={config.applicationId}
      clientToken={config.clientToken}
      authProvider={authProvider}
    >
      {children as JSX.Element}
    </DatadogRumWrapper>
  );
}
