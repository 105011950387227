import { useDebouncedCallback } from '@newfront-insurance/core-ui';
import { forwardRef, useState, useEffect, useRef, useCallback } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { InputSearch } from './input';
import { Popout } from './popout';
import type { ErrorResponse, SearchResult, SearchSuggestion } from '../../../../bff/types';
import { SearchContext } from '../context';

interface Props {
  debounce?: number;
  error?: ErrorResponse;
  initialValue?: string;
  isSearching?: boolean;
  maxWidth?: string;
  onChange: (value: string) => void;
  onClickResultItem: (result: SearchResult, rank: number) => void;
  onRenderPreview: (result: SearchResult, containerId?: string) => JSX.Element;
  onReset: () => unknown;
  onSelectSuggestion: (suggestion: string) => Promise<void>;
  onSetIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  recentlySearched?: string[];
  recentlyVisited?: SearchResult[];
  results?: SearchResult[];
  suggestions?: SearchSuggestion[];
}

const DEFAULT_DEBOUNCE_MS = 500;

export const SearchContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    debounce = DEFAULT_DEBOUNCE_MS,
    error,
    initialValue,
    isSearching = false,
    maxWidth = '500px',
    onChange,
    onClickResultItem,
    onRenderPreview,
    onReset,
    onSelectSuggestion,
    onSetIsSearchActive,
    placeholder = 'Search...',
    // TODO(ACC-639): Implement recentlySearched & recentlyVisited
    recentlySearched = [],
    recentlyVisited = [],
    results,
    suggestions,
  } = props;
  const [currentQuery, setCurrentQuery] = useState<string>(initialValue || '');
  const [activeResultIndex, setActiveResultIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>();

  const debouncedOnChange = useDebouncedCallback(onChange, debounce);

  useEffect(() => {
    // reset results state if currentQuery is empty
    if (!currentQuery) {
      handleOnReset();
    }

    if (currentQuery && debouncedOnChange) {
      debouncedOnChange(currentQuery);
      setActiveResultIndex(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuery]);

  const handleOnReset = (): void => {
    setCurrentQuery('');
    setActiveResultIndex(0);
    onReset();
  };

  const handleSelectSuggestion = async (suggestion: string): Promise<void> => {
    setCurrentQuery(suggestion);
    inputRef?.current?.focus();
    await onSelectSuggestion(suggestion);
  };

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const resultItems = results && results.length > 0 ? results : [];
      switch (e.key) {
        case 'ArrowDown': {
          e.preventDefault();
          setActiveResultIndex((activeResult) => (activeResult + 1) % resultItems.length);
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          setActiveResultIndex((activeResult) => (activeResult + resultItems.length - 1) % resultItems.length);
          break;
        }
        case 'Enter': {
          onClickResultItem(resultItems[activeResultIndex], activeResultIndex);
          break;
        }
        default: {
          break;
        }
      }
    },
    [activeResultIndex, onClickResultItem, results],
  );

  const isKeyDownEnabled = !!(results && results.length > 0);

  return (
    <SearchContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        currentQuery,
        isSearching,
        recentlySearched,
        recentlyVisited,
        results,
        activeResultIndex,
        setActiveResultIndex,
        setIsSearchActive: onSetIsSearchActive,
        suggestions,
        error,
      }}
    >
      <StyledSearch ref={ref} maxWidth={maxWidth}>
        <InputSearch
          innerRef={inputRef as React.RefObject<HTMLInputElement>}
          onChange={({ target }) => setCurrentQuery(target.value)}
          onReset={handleOnReset}
          onKeyDown={isKeyDownEnabled ? handleKeyDown : undefined}
          placeholder={placeholder}
        />
        <Popout
          onRenderPreview={onRenderPreview}
          onSearchResultClick={onClickResultItem}
          onSearchSuggestionClick={handleSelectSuggestion}
          onRecentlySearchedClick={(value) => setCurrentQuery(value)}
        />
      </StyledSearch>
    </SearchContext.Provider>
  );
});

SearchContainer.displayName = 'SearchContainer';

const StyledSearch = styled.div<{ maxWidth: string }>`
  position: relative;
  width: ${({ maxWidth }) => maxWidth};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-left: auto;
`;
