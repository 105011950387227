import * as React from 'react';

interface Props {
  children?: React.ReactNode;
  onItemClick?: () => unknown;
  testId?: string;
}

export function MenuContainer(props: Props): JSX.Element {
  const { onItemClick, children, testId } = props;
  return (
    <div
      onClick={onItemClick}
      role="menu"
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onItemClick?.();
        }
      }}
      data-testid={testId}
    >
      {children}
    </div>
  );
}
