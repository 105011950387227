import { DisplayItem } from './item';
import { Value } from './value';
import type { CustomValue } from './value/registry';
import type { Schema, SchemaFieldType, Values } from '../..';

interface Props {
  name: string;
  schema: Schema;
  values: Values;
}

/**
 * Renders a singular display item given the schema, values, and property key name
 * @param props
 */
export function SchemaDisplayItem(props: Props): JSX.Element | null {
  const { name, schema, values } = props;
  const field = (schema.properties || {})[name] as SchemaFieldType;

  // When there is no type it means it is a hidden field.
  if (!field?.inputType) return null;

  return <DisplayItem name={name} field={field} value={values[name]} />;
}

/**
 * Allows to extend Value by adding new inputType handlers
 * @param inputType
 * @param customValue
 */
SchemaDisplayItem.registerComponent = (
  inputType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customValue: CustomValue<any, any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStringifiedFieldValue?: (value: any, field?: any) => string | null,
) => {
  Value.registerComponent(inputType, customValue, getStringifiedFieldValue);
};
