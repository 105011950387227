import { useProducers } from '@newfront-insurance/admin-ui/accounts';
import { DetailCard, EmployeeAvatar, Flexbox, SingleObjectSelector, Text, useFuzzy } from '@newfront-insurance/core-ui';
import { isSuccesfulResult } from '@newfront-insurance/data-layer-http';

import { useSelectedProducer } from '@/client/providers/selected-producer-provider';

export function ProducerSelector(): JSX.Element {
  const { currentProducer, setCurrentProducer, removeCurrentProducer } = useSelectedProducer();
  const { data: producers, isLoading } = useProducers();
  const employees = isSuccesfulResult(producers) && producers ? producers : [];
  const { results, search } = useFuzzy(employees, { keys: ['name', 'title', 'email'], debounce: 300 });

  return (
    <SingleObjectSelector
      items={results.slice(0, 20)}
      selectedItem={currentProducer ?? null}
      renderItem={(employee, { isHovering, isSelected }) => (
        <DetailCard
          isHovering={isHovering}
          isHighlighted={isSelected}
          title={employee.name}
          subtitle={employee.title}
          cardProps={{
            hasRadius: false,
            hideBorder: true,
          }}
        />
      )}
      isLoading={isLoading}
      getItemKey={(employee) => employee.uuid}
      itemToString={(employee) => employee?.name ?? ''}
      onSelect={(employee) => {
        if (employee) {
          setCurrentProducer(employee.userUuid);
        } else {
          removeCurrentProducer();
        }
      }}
      renderSelectedItem={(employee) => (
        <Flexbox gap={8}>
          <EmployeeAvatar sizeInPixels={24} imageUrl={employee.imageUrl} initials={employee.name} />
          <Text weight={600}>{employee.name}</Text>
        </Flexbox>
      )}
      emptyPlaceholder="View accounts as producer..."
      onSearch={search}
    />
  );
}
