import styled from 'styled-components';

const sharedGridStyles = `
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 16px;
  `;

export const Styled2Column = styled.div<{ minWidth?: string }>`
  ${sharedGridStyles};
  @media screen and (min-width: ${({ minWidth }) => minWidth || '800px'}) {
    grid-template-columns: 50% 50%;
    grid-column-gap: 0;
  }
`;

export const Styled3Column = styled.div<{ minWidth?: string }>`
  ${sharedGridStyles};
  @media screen and (min-width: ${({ minWidth }) => minWidth || '800px'}) {
    grid-template-columns: 34% 32% 34%;
    grid-column-gap: 0;
  }
`;

export const StyledIconWrapper = styled.span`
    position: absolute;
    right: 16px;
    top 50%;
    transform: translateY(-50%);
  `;
