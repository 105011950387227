import type { RouterUseQueryOptions } from '@newfront-insurance/data-layer-client';

import { useAdminTRPC } from '../../../shared/providers/trpc';
import type { SharedRouter } from '../../../shared/trpc/router';

export function useProducers(options?: RouterUseQueryOptions<SharedRouter, 'employees.getProducers'>) {
  const { useQuery } = useAdminTRPC();

  return useQuery(['employees.getProducers'], options);
}
