import type { SearchResult } from '../../../../bff/types';
import { AccountCardPreview, PolicyCardPreview } from '../components/cards';

/**
 * Helper to build the card preview in base of the result type: account or policy...
 * @param result
 * @returns JSX.Element
 */

export function RenderCardPreview(result: SearchResult, containerId?: string): JSX.Element {
  return <Card result={result} containerId={containerId} />;
}

function Card({ result, containerId }: { result: SearchResult; containerId?: string }): JSX.Element | null {
  const { type, id, hasViewAccess, routing } = result;
  switch (type) {
    case 'account': {
      return (
        <AccountCardPreview
          accountUuid={id}
          hasViewAccess={!!hasViewAccess}
          routing={routing}
          containerId={containerId}
        />
      );
    }
    case 'policy': {
      return <PolicyCardPreview policyUuid={id} routing={routing} />;
    }
    default:
      return null;
  }
}
