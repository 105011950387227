import type { IconProps } from './types';

export function IconCheckmark({ className }: IconProps) {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" className={className}>
      <path
        // eslint-disable-next-line max-len
        d="M6.05837 10.5518L2.23587 6.72935L0.934204 8.02185L6.05837 13.146L17.0584 2.14602L15.7659 0.853516L6.05837 10.5518Z"
        fill="currentColor"
      />
    </svg>
  );
}
