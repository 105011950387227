import type { MenuItem } from '@newfront-insurance/core-ui';
import type {
  LineOfCoverageType,
  LineOfCoverageTypeDefinition,
  PolicyType,
  PolicyTypeDefinition,
  ProgramType,
} from '@newfront-insurance/coverage-api';
import { useMemo, useState } from 'react';

import type { LineOfCoverageTypeDetails } from '../../..';
import { useDefinitionsHelpers } from '../../../hooks/definitions';
import { useFuzzy } from '../../../utils/hooks/use-fuzzy';

export interface LineOfCoverageTypeDetailsHook {
  selectedPolicyType?: PolicyType;
  setSelectedPolicyType: (policyType: PolicyType) => void;
  lineOfCoverageOptions: MenuItem[];
  keyword: string;
  search: (keyword: string) => void;
}

export interface Options {
  onSelect: (lineOfCoverage: LineOfCoverageTypeDetails) => void;
  program?: ProgramType;
  excludedLineOfCoverageTypes?: LineOfCoverageType[];
}

function filterLinesOfCoverage(
  selectedPolicyType: PolicyType | undefined,
  policyTypeDefinitions: Record<string, PolicyTypeDefinition>,
  excludedLineOfCoverageTypes: LineOfCoverageType[],
  allLineOfCoverageTypes: LineOfCoverageType[],
): LineOfCoverageType[] {
  /**
   * Start with all lines of coverage types
   */
  let filteredLineOfCoverageTypes = allLineOfCoverageTypes;

  /**
   * If a policy type is selected, set to the line of coverage types (locs) from the policy type definition.
   * If there are no locs on the policy type definition, set to an empty array []
   */
  if (selectedPolicyType) {
    filteredLineOfCoverageTypes = policyTypeDefinitions[selectedPolicyType]?.locs ?? [];
  }

  if (excludedLineOfCoverageTypes && excludedLineOfCoverageTypes.length > 0) {
    const excludedLinesOfCoverage = new Set(excludedLineOfCoverageTypes);
    filteredLineOfCoverageTypes = filteredLineOfCoverageTypes.filter(
      (type: LineOfCoverageType) => !excludedLinesOfCoverage.has(type),
    );
  }

  return filteredLineOfCoverageTypes;
}

export function useLineOfCoverageEditorOptions({
  onSelect,
  program,
  excludedLineOfCoverageTypes = [],
}: Options): LineOfCoverageTypeDetailsHook {
  const { lineOfCoverageTypes, lineOfCoverageTypeDefinitions, renderLineOfCoverageType, policyTypeDefinitions } =
    useDefinitionsHelpers({
      program,
    });

  // Selected policy type to filter by
  const [selectedPolicyType, setSelectedPolicyType] = useState<PolicyType | undefined>();

  const lineOfCoverageTypeDetails: LineOfCoverageTypeDetails[] = useMemo(() => {
    const filteredLineOfCoverageTypes = filterLinesOfCoverage(
      selectedPolicyType,
      policyTypeDefinitions,
      excludedLineOfCoverageTypes,
      lineOfCoverageTypes,
    );

    // Enrich each line of coverage type with version number and null displayName
    return enrichLineOfCoverageType(filteredLineOfCoverageTypes, lineOfCoverageTypeDefinitions);
  }, [
    lineOfCoverageTypes,
    selectedPolicyType,
    excludedLineOfCoverageTypes,
    lineOfCoverageTypeDefinitions,
    policyTypeDefinitions,
  ]);

  // Line of coverage MenuItems
  const menuItems: MenuItem[] = useMemo(
    () =>
      lineOfCoverageTypeDetails.map((loc) => ({
        label: renderLineOfCoverageType(loc.type),
        onClick: () => {
          onSelect(loc);
        },
      })),
    [lineOfCoverageTypeDetails, renderLineOfCoverageType, onSelect],
  );

  // Search MenuItems by label
  const {
    result: lineOfCoverageOptions,
    keyword,
    search,
  } = useFuzzy(menuItems, {
    keys: ['label'],
  });

  return {
    selectedPolicyType,
    setSelectedPolicyType,
    lineOfCoverageOptions,
    keyword,
    search,
  };
}

/**
 * For each LineOfCoverageType in given array, enrich LineOfCoverageType with version number and null displayName
 * Returns array of LineOfCoverageTypeDetails
 * @param types array of LineOfCoverageTypes to enrich
 * @param definitions map of LineOfCoverageType to it's definition
 */
function enrichLineOfCoverageType(
  types: LineOfCoverageType[],
  definitions: Record<string, LineOfCoverageTypeDefinition>,
): LineOfCoverageTypeDetails[] {
  const locDefinitions = types.map((type) => definitions[type] || []);

  return locDefinitions.map((loc) => ({
    type: loc.id,
    version: loc.currentVersion,
    displayName: null,
  }));
}
