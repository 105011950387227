/* eslint-disable max-len */
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.07);

  & svg {
    display: block;
  }
`;

export interface AppIconProps {
  size: number;
}

export function GrowthIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_627:20825)" />
        <path
          d="M24 23H9C8.73478 23 8.48043 22.8946 8.29289 22.7071C8.10536 22.5196 8 22.2652 8 22V9H10V21H24V23Z"
          fill="white"
        />
        <path d="M24 20H11V18C13.912 18 14.889 16.241 16.126 14.015C17.432 11.664 18.912 9 23 9H24V20Z" fill="white" />
        <defs>
          <linearGradient id="paint0_linear_627:20825" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function AccountsIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_627:20837)" />
        <g filter="url(#filter0_d_627:20837)">
          <path
            d="M16 6.3999C10.7064 6.3999 6.40002 10.7063 6.40002 15.9999C6.40002 21.2935 10.7064 25.5999 16 25.5999C21.2936 25.5999 25.6 21.2935 25.6 15.9999C25.6 10.7063 21.2936 6.3999 16 6.3999ZM12.8 14.3999C12.8 12.6327 14.2752 11.1999 16 11.1999C17.7248 11.1999 19.2 12.6327 19.2 14.3999V15.1999C19.2 16.9671 17.7248 18.3999 16 18.3999C14.2752 18.3999 12.8 16.9671 12.8 15.1999V14.3999ZM16 23.9999C14.0536 23.9999 12.268 23.2991 10.8792 22.1391C11.5504 20.8727 12.8664 19.9999 14.4 19.9999H17.6C19.1336 19.9999 20.4496 20.8727 21.1208 22.1391C19.732 23.2991 17.9464 23.9999 16 23.9999Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_627:20837"
            x="6.40002"
            y="6.3999"
            width="19.2"
            height="21.2"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627:20837" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627:20837" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_627:20837" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function ExposuresIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_627:20849)" />
        <g filter="url(#filter0_d_627:20849)">
          <path
            d="M16 14.5C13.15 14.5 10.225 13.975 8.5 12.7V16C8.5 18.025 12.4 18.9999 16 18.9999C19.6 18.9999 23.5 18.025 23.5 16V12.7C21.775 13.975 18.85 14.5 16 14.5Z"
            fill="white"
          />
          <path
            d="M16 20.5C13.15 20.5 10.225 19.975 8.5 18.7V21.9999C8.5 24.0249 12.4 24.9999 16 24.9999C19.6 24.9999 23.5 24.0249 23.5 21.9999V18.7C21.775 19.975 18.85 20.5 16 20.5Z"
            fill="white"
          />
          <path
            d="M16 7C12.4 7 8.5 7.975 8.5 10C8.5 12.025 12.4 13 16 13C19.6 13 23.5 12.025 23.5 10C23.5 7.975 19.6 7 16 7Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_627:20849"
            x="8.5"
            y="7"
            width="15"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627:20849" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627:20849" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_627:20849" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function ClaimsIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_627:20861)" />
        <g filter="url(#filter0_d_627:20861)">
          <path
            d="M15.57 11V11.9167C14.8864 11.9497 14.2279 12.1852 13.6784 12.5933C13.4627 12.7621 13.2892 12.9787 13.1715 13.226C13.0538 13.4733 12.9951 13.7445 13 14.0183C12.9839 14.5158 13.1593 15.0005 13.49 15.3725C13.9669 15.8304 14.5396 16.1766 15.1667 16.3858L15.5675 16.5408V18.3942C15.1253 18.3599 14.6876 18.2818 14.2609 18.1608C13.8306 18.0498 13.412 17.8977 13.0109 17.7067V19.2842C13.8211 19.6235 14.6908 19.7982 15.5692 19.7983V21H16.3875V19.775C17.0847 19.7467 17.7565 19.5056 18.3125 19.0842C18.5366 18.8993 18.7151 18.6654 18.8343 18.4005C18.9535 18.1357 19.0102 17.8469 19 17.5567C19.0061 17.2357 18.9255 16.919 18.7667 16.64C18.584 16.3471 18.3369 16.0997 18.0442 15.9167C17.5185 15.603 16.9626 15.3429 16.385 15.14V13.3733C17.044 13.4298 17.6904 13.5869 18.3017 13.8392L18.8634 12.4408C18.0804 12.1033 17.2406 11.9167 16.3884 11.8908V11H15.57ZM15.57 14.8017C15.3561 14.7174 15.1572 14.5992 14.9809 14.4517C14.9211 14.3968 14.8739 14.3297 14.8424 14.2549C14.8109 14.1801 14.7959 14.0994 14.7984 14.0183C14.7984 13.68 15.0556 13.4728 15.57 13.3967V14.8017ZM16.3884 16.8633C16.6069 16.9552 16.8121 17.076 16.9984 17.2225C17.0622 17.2732 17.1136 17.3377 17.1488 17.4112C17.184 17.4847 17.2021 17.5652 17.2017 17.6467C17.2017 18.025 16.9306 18.26 16.3884 18.3517V16.8633Z"
            fill="white"
          />
          <path
            d="M16 6C13.8352 5.998 11.7287 6.70191 10 8.005L8.5 6L6.5 11.6667L12.5 11.3333L11 9.3375C12.5012 8.2122 14.3383 7.62668 16.2138 7.67577C18.0893 7.72487 19.8933 8.40572 21.3335 9.60802C22.7738 10.8103 23.7659 12.4637 24.1493 14.3002C24.5326 16.1368 24.2847 18.0489 23.4457 19.727C22.6066 21.4051 21.2256 22.7507 19.5264 23.546C17.8272 24.3412 15.9092 24.5395 14.0832 24.1087C12.2572 23.6779 10.6301 22.6432 9.46557 21.1723C8.301 19.7013 7.66714 17.8803 7.66667 16.0042C7.66667 15.7832 7.57887 15.5712 7.42259 15.4149C7.26631 15.2586 7.05435 15.1708 6.83333 15.1708C6.61232 15.1708 6.40036 15.2586 6.24408 15.4149C6.0878 15.5712 6 15.7832 6 16.0042C6.00082 17.9818 6.58801 19.9148 7.68733 21.5587C8.78664 23.2027 10.3487 24.4838 12.1761 25.24C14.0034 25.9962 16.0139 26.1937 17.9535 25.8073C19.893 25.421 21.6744 24.4683 23.0725 23.0696C24.4706 21.6709 25.4226 19.8891 25.8082 17.9494C26.1937 16.0097 25.9954 13.9992 25.2384 12.1722C24.4814 10.3452 23.1997 8.78365 21.5553 7.68502C19.9109 6.58639 17.9776 6 16 6V6Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_627:20861"
            x="6"
            y="6"
            width="20"
            height="22"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627:20861" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627:20861" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_627:20861" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function ConversionIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="4" fill="url(#paint0_linear_505_18529)" />
        <path
          d="M17 24.9999H15C13.6739 24.9999 12.4021 24.4731 11.4645 23.5354C10.5268 22.5977 10 21.3259 10 19.9999C10 18.6738 10.5268 17.402 11.4645 16.4643C12.4021 15.5266 13.6739 14.9999 15 14.9999V17.4999C14.9999 17.5935 15.0262 17.6853 15.0757 17.7647C15.1252 17.8442 15.1961 17.9081 15.2802 17.9493C15.3643 17.9905 15.4583 18.0072 15.5514 17.9976C15.6445 17.9879 15.7331 17.9524 15.807 17.8949L20.307 14.3949C20.3672 14.3481 20.416 14.2882 20.4495 14.2198C20.483 14.1513 20.5004 14.0761 20.5004 13.9999C20.5004 13.9236 20.483 13.8484 20.4495 13.7799C20.416 13.7115 20.3672 13.6516 20.307 13.6049L15.807 10.1049C15.7331 10.0474 15.6445 10.0118 15.5514 10.0022C15.4583 9.99254 15.3643 10.0093 15.2802 10.0504C15.1961 10.0916 15.1252 10.1555 15.0757 10.235C15.0262 10.3145 14.9999 10.4062 15 10.4999V12.9999C13.1435 12.9999 11.363 13.7374 10.0503 15.0501C8.7375 16.3629 8 18.1433 8 19.9999C8 21.8564 8.7375 23.6369 10.0503 24.9496C11.363 26.2624 13.1435 26.9999 15 26.9999H17C17.2652 26.9999 17.5196 26.8945 17.7071 26.707C17.8946 26.5194 18 26.2651 18 25.9999C18 25.7346 17.8946 25.4803 17.7071 25.2928C17.5196 25.1052 17.2652 24.9999 17 24.9999Z"
          fill="white"
        />
        <path
          d="M25.0004 13H23.0004C22.7352 13 22.4809 13.1054 22.2933 13.2929C22.1058 13.4804 22.0004 13.7348 22.0004 14C22.0004 14.2652 22.1058 14.5196 22.2933 14.7071C22.4809 14.8946 22.7352 15 23.0004 15H25.0004C26.3265 15 27.5983 15.5268 28.536 16.4645C29.4737 17.4021 30.0004 18.6739 30.0004 20C30.0004 21.3261 29.4737 22.5979 28.536 23.5355C27.5983 24.4732 26.3265 25 25.0004 25V22.5C25.0005 22.4064 24.9743 22.3146 24.9247 22.2351C24.8752 22.1557 24.8044 22.0917 24.7203 22.0506C24.6362 22.0094 24.5422 21.9927 24.4491 22.0023C24.3559 22.0119 24.2674 22.0475 24.1934 22.105L19.6934 25.605C19.6332 25.6517 19.5845 25.7116 19.551 25.7801C19.5174 25.8485 19.5 25.9238 19.5 26C19.5 26.0762 19.5174 26.1515 19.551 26.2199C19.5845 26.2884 19.6332 26.3483 19.6934 26.395L24.1934 29.895C24.2674 29.9525 24.3559 29.9881 24.4491 29.9977C24.5422 30.0073 24.6362 29.9906 24.7203 29.9494C24.8044 29.9083 24.8752 29.8443 24.9247 29.7649C24.9743 29.6854 25.0005 29.5936 25.0004 29.5V27C26.857 27 28.6374 26.2625 29.9502 24.9497C31.2629 23.637 32.0004 21.8565 32.0004 20C32.0004 18.1435 31.2629 16.363 29.9502 15.0503C28.6374 13.7375 26.857 13 25.0004 13Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_505_18529" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function FilesIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_628:21672)" />
        <g filter="url(#filter0_d_628:21672)">
          <path
            d="M25.1667 12.6666H10.1667C9.94565 12.6666 9.73369 12.7544 9.57741 12.9107C9.42113 13.0669 9.33333 13.2789 9.33333 13.4999V22.6666C9.33333 22.8876 9.24554 23.0996 9.08926 23.2558C8.93298 23.4121 8.72101 23.4999 8.5 23.4999C8.27899 23.4999 8.06702 23.4121 7.91074 23.2558C7.75446 23.0996 7.66667 22.8876 7.66667 22.6666V8.49992H11.3875L12.8042 10.6291C12.8805 10.7435 12.984 10.8373 13.1054 10.902C13.2269 10.9667 13.3624 11.0004 13.5 10.9999H21.8333V11.8333H23.5V10.1666C23.5 9.94558 23.4122 9.73361 23.2559 9.57733C23.0996 9.42105 22.8877 9.33326 22.6667 9.33326H13.9458L12.5292 7.20409C12.4528 7.08964 12.3493 6.99588 12.2279 6.93117C12.1065 6.86646 11.9709 6.83282 11.8333 6.83326H6.83333C6.61232 6.83326 6.40036 6.92105 6.24408 7.07733C6.0878 7.23361 6 7.44558 6 7.66659V22.6666C6.00242 23.3289 6.26658 23.9634 6.7349 24.4317C7.20322 24.9 7.8377 25.1642 8.5 25.1666H9.33333H23.5C24.1622 25.164 24.7966 24.8997 25.2649 24.4314C25.7331 23.9632 25.9974 23.3288 26 22.6666V13.4999C26 13.2789 25.9122 13.0669 25.7559 12.9107C25.5996 12.7544 25.3877 12.6666 25.1667 12.6666Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_628:21672"
            x="6"
            y="6.83325"
            width="20"
            height="20.3333"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_628:21672" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_628:21672" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_628:21672" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function QuotingIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_719:22646)" />
        <g filter="url(#filter0_d_719:22646)">
          <path
            d="M16.375 20.4249C16.225 20.4999 16.15 20.4999 16 20.4999C15.85 20.4999 15.775 20.4999 15.625 20.4249L7 15.7749V24.2499C7 24.6999 7.3 24.9999 7.75 24.9999H24.25C24.7 24.9999 25 24.6999 25 24.2499V15.7749L16.375 20.4249Z"
            fill="white"
          />
          <path
            d="M11.5 16.45V8.5H20.5V13V16.45L24.325 14.35L22 12.625V7.75C22 7.3 21.7 7 21.25 7H10.75C10.3 7 10 7.3 10 7.75V12.7L7.67505 14.425L11.5 16.45Z"
            fill="white"
          />
          <path d="M19 10.75H13V12.25H19V10.75Z" fill="white" />
          <path d="M19 13.75H13V15.25H19V13.75Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_719:22646"
            x="7"
            y="7"
            width="18"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_719:22646" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_719:22646" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_719:22646" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function CertificatesIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="32" height="32" rx="4" fill="url(#paint0_linear_719:22627)" />
        <g filter="url(#filter0_d_719:22627)">
          <path
            d="M22.625 25H9.125C8.45 25 8 24.55 8 23.875V8.125C8 7.45 8.45 7 9.125 7H22.625V9.25H10.25V22.75H21.5V21.625H23.75V23.875C23.75 24.55 23.3 25 22.625 25Z"
            fill="white"
          />
          <path
            d="M26 13.75C26 11.8375 24.5375 10.375 22.625 10.375C20.7125 10.375 19.25 11.8375 19.25 13.75C19.25 14.7625 19.7 15.6625 20.375 16.225V20.5L22.625 18.25L24.875 20.5V16.225C25.55 15.6625 26 14.7625 26 13.75Z"
            fill="white"
          />
          <path d="M17 12.625H12.5V14.875H17V12.625Z" fill="white" />
          <path d="M18.125 17.125H12.5V19.375H18.125V17.125Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_719:22627"
            x="8"
            y="7"
            width="18"
            height="20"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_719:22627" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_719:22627" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_719:22627" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function TradingPartnersIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="4" fill="url(#paint0_linear_2_535)" />
        <path
          d="M24.4255 14.0994C24.4255 15.2731 23.9593 16.3987 23.1293 17.2287C22.2994 18.0586 21.1737 18.5249 20 18.5249C18.8263 18.5249 17.7006 18.0586 16.8707 17.2287C16.0407 16.3987 15.5745 15.2731 15.5745 14.0994C15.5745 12.9256 16.0407 11.8 16.8707 10.97C17.7006 10.1401 18.8263 9.67383 20 9.67383C21.1737 9.67383 22.2994 10.1401 23.1293 10.97C23.9593 11.8 24.4255 12.9256 24.4255 14.0994ZM31.8014 17.0497C31.8014 17.8322 31.4906 18.5826 30.9373 19.1359C30.384 19.6892 29.6335 20.0001 28.851 20.0001C28.0686 20.0001 27.3181 19.6892 26.7648 19.1359C26.2115 18.5826 25.9007 17.8322 25.9007 17.0497C25.9007 16.2672 26.2115 15.5168 26.7648 14.9635C27.3181 14.4102 28.0686 14.0994 28.851 14.0994C29.6335 14.0994 30.384 14.4102 30.9373 14.9635C31.4906 15.5168 31.8014 16.2672 31.8014 17.0497ZM25.9007 27.376C25.9007 25.811 25.279 24.3101 24.1724 23.2035C23.0658 22.0969 21.5649 21.4752 20 21.4752C18.435 21.4752 16.9341 22.0969 15.8276 23.2035C14.721 24.3101 14.0993 25.811 14.0993 27.376V31.8015H25.9007V27.376ZM14.0993 17.0497C14.0993 17.8322 13.7884 18.5826 13.2351 19.1359C12.6818 19.6892 11.9314 20.0001 11.1489 20.0001C10.3664 20.0001 9.616 19.6892 9.0627 19.1359C8.50941 18.5826 8.19857 17.8322 8.19857 17.0497C8.19857 16.2672 8.50941 15.5168 9.0627 14.9635C9.616 14.4102 10.3664 14.0994 11.1489 14.0994C11.9314 14.0994 12.6818 14.4102 13.2351 14.9635C13.7884 15.5168 14.0993 16.2672 14.0993 17.0497ZM28.851 31.8015V27.376C28.8532 25.8761 28.4724 24.4006 27.7447 23.0891C28.3987 22.9217 29.0823 22.9059 29.7433 23.0429C30.4044 23.18 31.0254 23.4661 31.559 23.8797C32.0926 24.2932 32.5247 24.8231 32.8224 25.429C33.1201 26.035 33.2754 26.7009 33.2766 27.376V31.8015H28.851ZM12.2553 23.0891C11.5277 24.4006 11.1469 25.8761 11.1489 27.376V31.8015H6.72339V27.376C6.7231 26.7004 6.87749 26.0337 7.17471 25.427C7.47194 24.8204 7.90411 24.2898 8.43811 23.876C8.97211 23.4622 9.59376 23.1761 10.2554 23.0397C10.9171 22.9033 11.6012 22.9202 12.2553 23.0891Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_2_535" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function BillingIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="4" fill="url(#paint0_linear_324_14012)" />
        <path
          d="M28.0335 23.7765V14.6276C28.0335 13.3696 27.0006 12.3403 25.7382 12.3403H9.67115C8.40874 12.3403 7.37585 13.3696 7.37585 14.6276V23.7765C7.37585 25.0345 8.40874 26.0637 9.67115 26.0637H25.7382C27.0006 26.0637 28.0335 25.0345 28.0335 23.7765ZM17.7047 22.6329C15.7996 22.6329 14.2617 21.1004 14.2617 19.202C14.2617 17.3036 15.7996 15.7712 17.7047 15.7712C19.6098 15.7712 21.1476 17.3036 21.1476 19.202C21.1476 21.1004 19.6098 22.6329 17.7047 22.6329ZM32.6241 15.7712V28.351C32.6241 29.6089 31.5912 30.6382 30.3288 30.6382H10.8188V28.351H30.3288V15.7712H32.6241Z"
          fill="white"
        />
        <defs>
          <linearGradient id="paint0_linear_324_14012" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export function CoverageGapAnalysisIcon({ size = 32 }: AppIconProps): JSX.Element {
  return (
    <Container>
      <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="4" fill="url(#paint0_linear_324_14012)" />
        <g clipPath="url(#clip1_322_2634)">
          <g clipPath="url(#clip2_322_2634)">
            <path
              d="M12.5489 27.107L8.1709 22L9.9999 20L13.8339 23.006L24.9999 11L26.9999 12L15.6639 27.011C15.3443 27.434 14.8699 27.7127 14.3448 27.786C13.8198 27.8594 13.2871 27.7212 12.8639 27.402C12.7488 27.3151 12.6432 27.2162 12.5489 27.107Z"
              fill="white"
            />
            <path
              d="M31 17H28C27.7348 17 27.4804 16.8946 27.2929 16.7071C27.1054 16.5196 27 16.2652 27 16C27 15.7348 27.1054 15.4804 27.2929 15.2929C27.4804 15.1054 27.7348 15 28 15H31C31.2652 15 31.5196 15.1054 31.7071 15.2929C31.8946 15.4804 32 15.7348 32 16C32 16.2652 31.8946 16.5196 31.7071 16.7071C31.5196 16.8946 31.2652 17 31 17Z"
              fill="white"
            />
            <path
              d="M31 22H24C23.7348 22 23.4804 21.8946 23.2929 21.7071C23.1054 21.5196 23 21.2652 23 21C23 20.7348 23.1054 20.4804 23.2929 20.2929C23.4804 20.1054 23.7348 20 24 20H31C31.2652 20 31.5196 20.1054 31.7071 20.2929C31.8946 20.4804 32 20.7348 32 21C32 21.2652 31.8946 21.5196 31.7071 21.7071C31.5196 21.8946 31.2652 22 31 22Z"
              fill="white"
            />
            <path
              d="M31 27H21C20.7348 27 20.4804 26.8946 20.2929 26.7071C20.1054 26.5196 20 26.2652 20 26C20 25.7348 20.1054 25.4804 20.2929 25.2929C20.4804 25.1054 20.7348 25 21 25H31C31.2652 25 31.5196 25.1054 31.7071 25.2929C31.8946 25.4804 32 25.7348 32 26C32 26.2652 31.8946 26.5196 31.7071 26.7071C31.5196 26.8946 31.2652 27 31 27Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <linearGradient id="paint0_linear_324_14012" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF1073" />
            <stop offset="1" stopColor="#DB0E63" />
          </linearGradient>
          <clipPath id="clip1_322_2634">
            <rect width="24" height="24" fill="white" transform="translate(8 8)" />
          </clipPath>
          <clipPath id="clip2_322_2634">
            <rect width="24" height="24" fill="white" transform="translate(8 8)" />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
}
