import { ErrorMessage } from '@newfront-insurance/admin-ui/layout';
import { Button, Flexbox, IconNotAllowed, Text } from '@newfront-insurance/core-ui';
import { useProvider } from '@newfront-insurance/react-provision';
import type { PropsWithChildren } from 'react';

import { AuthProvider } from '@/client/providers/auth';

const SERVICE_DESK_HREF = 'https://newfront.atlassian.net/servicedesk/customer/portal/1';

export function IncompleteUserErrorProvider(props: PropsWithChildren<unknown>): JSX.Element {
  const { children } = props;
  const { userDetails } = useProvider(AuthProvider);

  if (userDetails?.scopes.length === 0 || !userDetails?.uuid) {
    return (
      <ErrorMessage
        title={`Looks like your dash user isn't completed`}
        subtitle="Please create a ticket or contact your manager."
        icon={<IconNotAllowed />}
        action={
          <Flexbox flexDirection="column" justifyContent="center" alignItems="center" gap={8}>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Try refreshing the page
            </Button>
            <Text size="large">or</Text>
            <Button size="secondary" href={SERVICE_DESK_HREF} target="_blank" style={{ textAlign: 'center' }}>
              Create a ticket
            </Button>
          </Flexbox>
        }
      />
    );
  }

  return <>{children}</>;
}
