/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
import type { Address, SavedAddress } from '@newfront-insurance/address-api';
import {
  Button,
  Field,
  Flexbox,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Padding,
  SegmentedControl,
  Spacing,
  Stack,
} from '@newfront-insurance/core-ui';
import { useFormik, FormikProvider } from 'formik';
import type { Alpha3Code } from 'i18n-iso-countries';
import { useEffect, useState, useCallback } from 'react';
import type { internationalStreet } from 'smartystreets-javascript-sdk';

import { addressYupSchema } from '../../../schema';
import { getFormattedAddress, transformFormValuesToAddress } from '../../../utils/format-street-address';
import { useCreateAddress } from '../../hooks/use-create-address';
import { useSmartyStreetsInternationalAddress } from '../../hooks/use-smartystreets-international-address';
import { CountryCodeSelect } from '../country-code-select';
import { CountryStateSelect } from '../country-state-select';
import { Styled2Column } from '../shared-styled-components';
import { VerifyInternationalAddressModal } from '../verify-international-address-modal';

type Locale = 'US' | 'international';

interface Props {
  accountUuid: string;
  isOpen: boolean;
  toggle: (isOpen: boolean) => unknown;
  onSubmit?: (address?: SavedAddress) => void;
  width?: string;
  disableCloseOnEscape?: boolean;
}

interface FormValues {
  displayName?: string;
  addressLine1: string;
  addressLine2?: string;
  formattedAddress: string;
  city: string;
  stateOrArea: string;
  postalCode: string;
  countryCode: string;
}

const initialFormValues = {
  addressLine1: '',
  city: '',
  stateOrArea: '',
  postalCode: '',
  countryCode: 'USA',
  formattedAddress: '',
};

export function CreateAddressModal({
  accountUuid,
  isOpen,
  toggle,
  onSubmit,
  width = '600px',
  disableCloseOnEscape,
}: Props): JSX.Element {
  const { mutateAsync: createAddress } = useCreateAddress();
  const [verifyInternationalAddress, , verifyInternationalAddressError] = useSmartyStreetsInternationalAddress();

  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [internationalAddressResults, setInternationalAddressResults] = useState<
    internationalStreet.Candidate[] | undefined
  >();
  const [selectedInternationalCandidate, setSelectedInternationalCandidate] = useState<Address | undefined>();
  const [locale, setLocale] = useState<Locale>('US');

  const form = useFormik<FormValues>({
    validateOnBlur: true,
    validationSchema: addressYupSchema,
    initialValues: initialFormValues,
    onSubmit: async () => {
      setIsSubmitting(true);
      handleVerification();
    },
  });

  /**
   * handleSelectedInternationalVerifiedAddress
   * If an address is selected from the verified international address modal,
   * and populate form fields with verified address
   * Then call handleSubmit
   */
  function handleSelectedInternationalVerifiedAddress(selectedAddress?: Address): void {
    if (!selectedAddress) return;
    const { addressLine1, addressLine2, city, stateOrArea, postalCode } = selectedAddress;
    setSelectedInternationalCandidate(selectedAddress);
    form.setFieldValue('addressLine1', addressLine1);
    form.setFieldValue('addressLine2', addressLine2);
    form.setFieldValue('city', city);
    form.setFieldValue('stateOrArea', stateOrArea);
    form.setFieldValue('postalCode', postalCode);

    setIsSubmitting(true);
    handleSubmit();
  }

  /**
   * handleSubmit
   * Convert form values to an Address.
   * if so, return existingAddress, otherwise create new address and return it.
   */
  async function handleSubmit(): Promise<void> {
    const address = transformFormValuesToAddress(form.values);

    const createdAddress = await createAddress({
      accountUuid,
      address: {
        ...address,
        formattedAddress: getFormattedAddress(address),
      },
    });

    setIsSubmitting(false);
    toggle(false);

    if (onSubmit && createdAddress) {
      onSubmit(createdAddress?.createdAddress as SavedAddress);
    }
  }

  /**
   * If 'international' has been selected, we format form data into an address and
   * verify it with smartystreets and saving it's result.
   * Otherwise call handleSubmit()
   */
  const handleVerification = useCallback(() => {
    async function verifyCustomAddress(): Promise<void> {
      setIsValidating(true);
      const { countryCode, ...address } = form.values;
      const formattedAddress = getFormattedAddress({ ...address, countryCode: countryCode as Alpha3Code });
      const response = await verifyInternationalAddress(countryCode, formattedAddress);

      setInternationalAddressResults(response?.result);
      setIsValidating(false);
    }

    if (locale === 'international') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      verifyCustomAddress();
    } else {
      handleSubmit();
    }
  }, [form.isValid, locale]);

  /**
   * When the form is toggled,
   * ensure form is reset and state is cleared
   */
  useEffect(() => {
    if (!isOpen) {
      form.resetForm();
      setIsSubmitting(false);
      setIsValidating(false);
      setSelectedInternationalCandidate(undefined);
      setInternationalAddressResults(undefined);
      setLocale('US');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={!!isOpen} onToggle={() => toggle(!isOpen)} width={width} disableCloseOnEscape={disableCloseOnEscape}>
      <ModalBody>
        <ModalHeader titleText="Add custom address" onClose={() => toggle(false)} />

        <Padding size={24}>
          <FormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>
              <Stack direction="vertical" gap={16}>
                <SegmentedControl
                  value={locale}
                  options={[
                    {
                      label: 'US',
                      value: 'US',
                    },
                    {
                      label: 'International',
                      value: 'international',
                    },
                  ]}
                  onChange={(val: string) => setLocale(val as Locale)}
                />

                {locale === 'international' && (
                  <Field fieldId="country-code" label="Country">
                    <CountryCodeSelect
                      value={form.values.countryCode}
                      onChange={(value: string) => {
                        // When the countryCode is selected, make sure
                        // state is reset (and automatically repopulated in relevance to countryCode)
                        // as well as stateOrArea/postalCode to ensure user data
                        // is entered 'in order' and somewhat correctly.
                        form.setFieldValue('countryCode', value);
                        form.setFieldValue('city', '');
                        form.setFieldValue('stateOrArea', '');
                        form.setFieldValue('postalCode', '');
                        setSelectedInternationalCandidate(undefined);
                      }}
                    />
                  </Field>
                )}

                <Field fieldId="street-address-line-1" label="Address line 1*" error={form.errors.addressLine1}>
                  <Input
                    type="text"
                    name="street-address-line-1"
                    value={form.values.addressLine1}
                    onChange={(value) => {
                      form.setFieldValue('addressLine1', value);
                    }}
                  />
                </Field>

                <Field fieldId="street-address-line-2" label="Address line 2">
                  <Input
                    type="text"
                    name="street-address-line-2"
                    value={form.values.addressLine2}
                    onChange={(value) => {
                      form.setFieldValue('addressLine2', value);
                    }}
                  />
                </Field>

                <Styled2Column minWidth="600px">
                  <Padding right={8}>
                    <Field fieldId="city" label="City*" error={form.errors.city}>
                      <Input
                        type="text"
                        name="city"
                        value={form.values.city}
                        onChange={(value) => {
                          form.setFieldValue('city', value);
                        }}
                      />
                    </Field>
                  </Padding>

                  <Padding left={8}>
                    <Field
                      fieldId="state"
                      label={form.values.countryCode === 'USA' ? 'State*' : 'State'}
                      error={form.errors.stateOrArea}
                    >
                      <CountryStateSelect
                        value={form.values.stateOrArea}
                        countryCode={form.values.countryCode}
                        onChange={(value) => {
                          form.setFieldValue('stateOrArea', value);
                        }}
                      />
                    </Field>
                  </Padding>
                </Styled2Column>

                <Field fieldId="zip" label="Zip*" error={form.errors.postalCode}>
                  <Input
                    type="text"
                    name="zip"
                    value={form.values.postalCode}
                    onChange={(value) => {
                      form.setFieldValue('postalCode', value);
                    }}
                  />
                </Field>

                {!selectedInternationalCandidate &&
                  internationalAddressResults &&
                  internationalAddressResults.length > 0 && (
                    <VerifyInternationalAddressModal
                      candidates={internationalAddressResults}
                      enteredAddress={transformFormValuesToAddress(form.values)}
                      isOpen={!!internationalAddressResults}
                      onSelectAddress={handleSelectedInternationalVerifiedAddress}
                      handleClose={() => {
                        // We're not validating address info as its inputted in the form.
                        // So, if the user cancels the verify modal (to perhaps tweak a field value), ...
                        // we can clear some input values so the yup schema can place the form in an invalid state.
                        // This forces the user to make required updates, and select city/state/zip again.
                        // Otherwise the form just thinks it has complete string values (no validation of values) and wont
                        // re-verify on an updated valid address.
                        // Also it seems to makes sense to wipe these 3 related fields, rather than just 1 to ensure
                        // data is entered 'in order' and 'correctly'
                        form.setFieldValue('city', '');
                        form.setFieldValue('stateOrArea', '');
                        form.setFieldValue('postalCode', '');
                        setInternationalAddressResults(undefined);
                      }}
                      width="500px"
                    />
                  )}

                <Flexbox alignItems="center" justifyContent="flex-end">
                  <Button type="button" size="secondary" onClick={() => toggle(false)}>
                    Cancel
                  </Button>
                  <Spacing width={12} />
                  <Button
                    type="submit"
                    loading={!verifyInternationalAddressError && (isSubmitting || isValidating)}
                    disabled={!!verifyInternationalAddressError || isSubmitting || isValidating || !form.isValid}
                  >
                    Submit
                  </Button>
                </Flexbox>
              </Stack>
            </form>
          </FormikProvider>
        </Padding>
      </ModalBody>
    </Modal>
  );
}
