import type { BadgeType } from '@newfront-insurance/core-ui';
import { PolicyStatus } from '@newfront-insurance/coverage-api';

export const POLICY_STATUS_BADGE_MAP: Record<PolicyStatus, BadgeType> = {
  [PolicyStatus.ACTIVE]: 'success',
  [PolicyStatus.EXPIRED]: 'secondary',
  [PolicyStatus.CANCELLED]: 'danger',
  [PolicyStatus.UPCOMING]: 'info',
};

export const POLICY_STATUS_LABEL_MAP: Record<PolicyStatus, string> = {
  [PolicyStatus.ACTIVE]: 'Active',
  [PolicyStatus.EXPIRED]: 'Expired',
  [PolicyStatus.CANCELLED]: 'Cancelled',
  [PolicyStatus.UPCOMING]: 'Upcoming',
};
