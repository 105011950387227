import type { DefinitionListItem } from '@newfront-insurance/core-ui';
import { DefinitionList } from '@newfront-insurance/core-ui';
import { DateFormatStyle } from '@newfront-insurance/string-formatters';
import type { MouseEvent } from 'react';

import { stringifyLinesOfCoverage } from './utils';
import type { Policy } from '../../../../../../bff/types';

interface Props {
  policy: Policy;
  onAccountClick: (e: MouseEvent) => void;
}

export function PolicyDefinitionList(props: Props): JSX.Element {
  const { policy, onAccountClick } = props;

  return (
    <DefinitionList
      items={createPolicyDefinitionList({
        policy,
        onAccountClick,
      })}
      labelWidth="150px"
    />
  );
}

export function createPolicyDefinitionList(props: Props): DefinitionListItem[] {
  const { policy, onAccountClick } = props;
  const { account, expirationDate, linesOfCoverage, effectiveDate, policyNumber, carrier } = policy;

  let items: DefinitionListItem[] = [];

  if (account) {
    items.push({
      type: 'name',
      title: 'Client',
      value: account?.name || '-',
      href: `/accounts/${policy.accountUuid}`,
      onClick: onAccountClick,
    });
  }

  items = [
    ...items,
    {
      type: 'document',
      title: 'Line of coverage',
      value: stringifyLinesOfCoverage(linesOfCoverage),
    },
    {
      type: 'dateRange',
      title: 'Effective dates',
      value: `${effectiveDate.format({ style: DateFormatStyle.STANDARD })} - ${expirationDate.format({
        style: DateFormatStyle.STANDARD,
      })}`,
    },
    {
      type: 'business',
      title: 'Carrier',
      value: carrier?.name || '-',
    },
  ];

  if (policyNumber) {
    items.push({
      type: 'number',
      title: 'Policy number',
      value: policyNumber,
      target: '_blank',
    });
  }

  return items;
}
