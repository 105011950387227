import type { ConfigInterface, NextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext } from 'react';

import { routes } from '../bff/routes';
import type { GetPolicyByUuidParams, GetPolicyByUuidResponse } from '../bff/types';
import { CoverageApiContext } from '../provider';

export function usePolicyByUuid(
  params: GetPolicyByUuidParams,
  config?: ConfigInterface,
): NextApiSubscription<GetPolicyByUuidResponse | null> {
  const { basePath } = useContext(CoverageApiContext);

  return useNextApiSubscription<GetPolicyByUuidResponse | null>(routes.policyByUuid.getUrl(params, basePath), config);
}
