import type { SelectableOption } from '../radio-button';
import { RadioButton } from '../radio-button';
import type { TextColors } from '../text';

interface Props {
  direction?: 'vertical' | 'horizontal';
  disabled?: boolean;
  label?: string;
  name: string;
  onChange?: (selection: string) => unknown;
  options: SelectableOption[];
  textColor?: TextColors;
  value?: string;
  justifyEnd?: boolean;
  wrapLabel?: boolean;
  gap?: number;
}

export * from './util';

export function RadioButtonGroup({
  direction = 'vertical',
  disabled,
  label,
  name,
  onChange,
  options,
  textColor,
  value,
  justifyEnd,
  wrapLabel,
  gap,
}: Props): JSX.Element {
  function handleChange(selection: string): void {
    if (onChange) {
      onChange(selection);
    }
  }

  const className = `
  flex
  ${direction === 'horizontal' ? 'flex-row' : 'flex-col'}
  ${gap ? `gap-${gap}` : 'gap-2'}
  ${justifyEnd ? 'justify-end' : ''}
  ${direction === 'horizontal' ? 'items-center' : ''}
  `;

  return (
    <div className={className}>
      {options.map((option: SelectableOption) => {
        return (
          <div key={`${name}-${option.value}`}>
            <RadioButton
              disabled={disabled}
              isChecked={value === option.value}
              label={label || option.label}
              name={name}
              onChange={() => handleChange(option.value.toString())}
              textColor={textColor}
              value={option.value}
              testId={option.testId}
              wrapLabel={wrapLabel}
              inputSize={option.inputSize}
            />
          </div>
        );
      })}
    </div>
  );
}
