import { SchemaDisplayItem } from './components/schema-display/schema-item';
import type { CustomValue } from './components/schema-display/value/registry';
import { SchemaInputControl } from './components/schema-input/control';
import type { SchemaInputControlCustomInput } from './components/schema-input/control/registry';
import type { SchemaFieldType } from './types';

/**
 * Registers a custom value display component and custom input component for a new input type.
 * @param inputType
 * @param customValue
 * @param customInput
 */
export function addCustomSchemaHandler<T, K>(
  inputType: string,
  customValue: CustomValue<T, K>,
  customInput: SchemaInputControlCustomInput<T, K>,
  getStringifiedFieldValue: (value: T, field?: SchemaFieldType<K>) => string | null,
): void {
  SchemaInputControl.registerComponent(inputType, customInput);
  SchemaDisplayItem.registerComponent(inputType, customValue, getStringifiedFieldValue);
}
