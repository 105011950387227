import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { createProvider } from '@newfront-insurance/react-provision';

import type { ErrorCallback } from './components/error-boundary';
import type { AppType } from './metadata/apps';

export interface LayoutConfigValue {
  authProvider: Provider<AuthProviderContext>;
  apiBasePath: string;
  currentApp: AppType;
  logoutRedirect: string;
  legacyAppBaseUrl: string;
  onError?: ErrorCallback;
}

/**
 * This is used internally to access the layout config.
 * @access private
 */
export const LayoutConfigProvider = createProvider((props: LayoutConfigValue) => props);
