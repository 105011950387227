import { colors } from '@newfront-insurance/core-ui';
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';
import Script from 'next/script';
import { useEffect } from 'react';

import { useAdminTRPC } from './trpc';

interface Props {
  config: {
    PRODUCT_ID: string;
  };
  authProvider: Provider<AuthProviderContext>;
}

let isBeamerInitialized = false;

export function BeamerProvider({ config, authProvider }: Props): JSX.Element | null {
  const { userDetails } = useProvider(authProvider);
  const { useQuery } = useAdminTRPC();
  const { data } = useQuery(['user.getLoggedInUserDetails']);

  useEffect(() => {
    if (data && !isBeamerInitialized) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).beamer_config = {
        product_id: config.PRODUCT_ID,
        top: 0,
        right: 0,
        ...(userDetails
          ? {
              user_id: userDetails.uuid,
              user_firstname: userDetails.firstName,
              user_lastname: userDetails.lastName,
              user_email: userDetails?.email,
              user_team: data.user?.team,
            }
          : {}),
      };

      isBeamerInitialized = true;
    }
  }, [config, userDetails, data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <div
        className="beamerTrigger"
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: 32,
            height: 32,
            borderRadius: 9999,
            backgroundColor: colors.steel[400],
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg version="1.1" id="Layer_1" width="20px" height="20px" viewBox="0 0 24 24" stroke="#fff" strokeWidth={1}>
            <path
              fill="#fff"
              // eslint-disable-next-line max-len
              d="M20.721,15.907c-0.926-0.925-2.072-2.074-2.072-6.566c0-3.299-2.404-6.037-5.555-6.559 c0.154-0.222,0.236-0.485,0.236-0.755c0-0.733-0.596-1.329-1.33-1.329s-1.33,0.596-1.33,1.329c0,0.28,0.087,0.54,0.235,0.755 c-3.151,0.521-5.554,3.26-5.554,6.559c0,4.492-1.148,5.642-2.072,6.566c-1.254,1.254-0.365,3.405,1.41,3.405h4.65 c0,1.469,1.191,2.659,2.66,2.659s2.658-1.19,2.658-2.659h4.65C21.082,19.312,21.977,17.163,20.721,15.907z"
            />
          </svg>
        </div>
      </div>
      <Script src="https://app.getbeamer.com/js/beamer-embed.js" strategy="afterInteractive" />
    </>
  );
}
