import { BrokerOfRecord, EMPLOYEE_ROLE_IDS } from '@newfront-insurance/account-api';
import type {
  Account as ApiAccount,
  AccountRoleMapping as ApiAccountRoleMapping,
  PublicAccount as ApiPublicAccount,
} from '@newfront-insurance/account-api';
import { toGroupedRecord } from '@newfront-insurance/core';
import omit from 'lodash/omit';

import { formatIdentity, partitionIdentities } from './account-identities';
import { formatAccountRoleMapping } from './account-role-mappings';
import { formatDate } from './dates';
import { formatStatus } from './status';
import type { Account, AccountRoleMapping, NormalizedAccountEmployeeRoleMappings, PublicAccount } from '../types';

export function groupAccountEmployeeRoleMappingsByRoleId(
  accountRoleMappings: AccountRoleMapping[],
): NormalizedAccountEmployeeRoleMappings {
  const accountEmployeeRoleMappings = accountRoleMappings.filter(({ roleId }) => EMPLOYEE_ROLE_IDS.includes(roleId));
  return toGroupedRecord(accountEmployeeRoleMappings, (accountRoleMapping) => accountRoleMapping.roleId);
}

export function formatAccount(account: ApiAccount, accountRoleMappings: ApiAccountRoleMapping[]): Account {
  const identities = partitionIdentities(account.identities);
  const formattedAccountRoleMappings = accountRoleMappings.map(formatAccountRoleMapping);

  return {
    ...omit(account, 'accountUuid', 'identities', 'leadStatus'),
    uuid: account.uuid as string,
    isNewfrontBrokerage: account.brokerOfRecord === BrokerOfRecord.NEWFRONT,
    businessIdentifier: identities.businessIdentifier && formatIdentity(identities.businessIdentifier),
    createdAt: formatDate(account.createdAt) as string,
    dba: account.details.dba,
    details: { ...account.details, followupDate: formatDate(account.details.followupDate), dba: account.details.dba },
    employeeRoleMappings: groupAccountEmployeeRoleMappingsByRoleId(formattedAccountRoleMappings),
    personalIdentities: identities.personalIdentities.map(formatIdentity),
    status: formatStatus(account.status),
    updatedAt: formatDate(account.updatedAt) as string,
  };
}

export function formatPublicAccount(
  publicAccount: ApiPublicAccount,
  accountRoleMappings: ApiAccountRoleMapping[],
): PublicAccount {
  const identities = partitionIdentities(publicAccount.identities);
  const formattedAccountRoleMappings = accountRoleMappings.map(formatAccountRoleMapping);

  return {
    ...omit(publicAccount, 'identities'),
    uuid: publicAccount.uuid as string,
    businessIdentifier: identities.businessIdentifier && formatIdentity(identities.businessIdentifier),
    employeeRoleMappings: groupAccountEmployeeRoleMappingsByRoleId(formattedAccountRoleMappings),
    personalIdentities: identities.personalIdentities.map(formatIdentity),
    status: formatStatus(publicAccount.status),
  };
}
