import { isEmpty, isNotNil } from '@newfront-insurance/core';
import { moneyToString } from '@newfront-insurance/core-money';
import { InputType } from '@newfront-insurance/dsl-schema-api';
import { standardUTCDateFormat } from '@newfront-insurance/string-formatters';
import find from 'lodash/find';
import get from 'lodash/get';

import type { SchemaFieldType } from '../../../types';
import { customValueComponentsRegistry } from '../value/registry';

export function getStringifiedFieldValue(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
  value: any,
  field?: SchemaFieldType,
): string | null {
  let returnValue: string | null = null;

  if (!field) {
    return null;
  }

  const { inputType: type, inputMetadata: metadata, layout } = field;
  const customGetStringifiedFieldValue = customValueComponentsRegistry[type]?.getStringifiedFieldValue;

  if (customGetStringifiedFieldValue) {
    return customGetStringifiedFieldValue(value, field);
  }

  switch (type) {
    case InputType.EMBEDDED_COVERAGE:
    case InputType.NESTED_SCHEMA:
      returnValue = (layout || [])
        .flatMap((group) => {
          return group.inputs.flatMap((input) => {
            const nestedField = field.properties?.[input] as SchemaFieldType;
            const nestedValue = get(value, input);

            if (!nestedValue) {
              return null;
            }
            return getStringifiedFieldValue(nestedValue, nestedField);
          });
        })
        .filter(Boolean)
        .join(' ');
      break;
    case InputType.CHECKBOX:
      returnValue = value ? 'Yes' : 'No';
      break;
    case InputType.CURRENCY:
      returnValue = !isEmpty(value) ? moneyToString(value, { fractionDigits: 2 }) : '-';
      break;
    case InputType.CURRENCY_APPLIES:
      returnValue = !isEmpty(value) ? moneyToString(value?.money, { fractionDigits: 2 }) : '-';
      break;
    case InputType.CURRENCY_PERCENTAGE:
      if (value?.type === 'MONETARY') returnValue = moneyToString(value?.money, { fractionDigits: 2 });
      if (value?.type === 'PERCENTAGE') returnValue = isNotNil(value?.percentage) ? `${value?.percentage}%` : '-';
      if (value?.type === 'NOT_APPLICABLE') returnValue = 'Not applicable';
      break;
    case InputType.CURRENCY_WITH_NUMBER:
      returnValue = `${moneyToString(value?.moneyLimit, {
        fractionDigits: 2,
      })} ${metadata?.numberFieldLabel?.toLowerCase()} ${value?.durationInMonthsLimit}`;
      if (metadata?.appendText) {
        returnValue += ` ${metadata?.appendText}`;
      }
      break;
    case InputType.CURRENCY_WITH_TYPE: {
      const option = find(metadata?.options, { value: value?.type });
      returnValue = value?.money
        ? `${moneyToString(value?.money, { fractionDigits: 2 })} ${option?.label.toLowerCase()}`
        : option?.label ?? '';
      break;
    }
    case InputType.DATE:
      returnValue = isNotNil(value) ? standardUTCDateFormat(value) : null;
      break;
    case InputType.PERCENTAGE:
      returnValue = isNotNil(value) ? `${String(value)}%` : '-';
      break;
    case InputType.RADIO: {
      const option = find(metadata?.options, { value });
      returnValue = option?.label ?? '';
      break;
    }
    case InputType.MULTI_SELECT: {
      returnValue =
        (value as string[] | undefined)?.map((v) => metadata?.options?.find((o) => o.value === v)?.label).join(', ') ??
        null;
      break;
    }
    case InputType.SELECT: {
      const option = find(metadata?.options, { value });
      returnValue = option?.label ?? value;
      break;
    }
    case InputType.NUMBER:
      if (value !== null && value !== undefined) {
        returnValue = metadata?.skipFormat ? value : new Intl.NumberFormat('en-US').format(value);
        if (metadata?.appendText) {
          returnValue += ` ${metadata?.appendText}`;
        }
      }
      break;
    case InputType.ADDRESS:
      returnValue = value?.formattedAddress;
      break;
    case InputType.ADDRESS_OR_STATE:
      if (value?.address?.formattedAddress) {
        returnValue = value.address.formattedAddress;
      }

      if (value?.state) {
        const option = find(field.properties?.state.inputMetadata?.options, { value: value.state });
        returnValue = option?.label ?? value.state;
      }

      break;
    case InputType.TEXT:
    default:
      if (value !== null && value !== undefined) {
        returnValue = String(value);
      }
      break;
  }

  return returnValue;
}
