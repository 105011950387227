import { colors, fontFamilies, fontSizes } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

import type { AppType } from '../../../../../metadata/apps';
import { getAppTypeMetadata } from '../../../../../metadata/apps';
import { AppBadge } from '../../app-badge';

interface Props {
  types: AppType[];
}

export function AppSwitcherMenu(props: Props): JSX.Element {
  const { types } = props;
  return (
    <>
      {types.map((type) => {
        const { href, label } = getAppTypeMetadata(type);
        return (
          <StyledMenuItem tabIndex={0} title={label} role="menuitem" href={href} key={href}>
            <AppBadge type={type} iconSize={32} textSize="small" showDescription />
          </StyledMenuItem>
        );
      })}
    </>
  );
}

const StyledMenuItem = styled.a`
  align-item: center;
  box-sizing: border-box;
  color: ${colors.steel[400]};
  cursor: pointer;
  display: flex;
  font-family: ${fontFamilies.body};
  font-size: ${fontSizes.regular.fontSize};
  margin: 4px 8px;
  padding: 8px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px inset ${colors.brand[400]};
    color: ${colors.brand[400]};
    background: ${colors.brand[100]};
    text-decoration: none;
  }

  &.isSelected {
    color: ${colors.brand[400]};
  }

  &.isDisabled {
    color: ${colors.steel[200]};
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(.isDisabled):hover {
    color: ${colors.brand[400]};
    background: ${colors.brand[100]};
    text-decoration: none;
  }
`;
