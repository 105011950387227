import { AuthRole } from '@newfront-insurance/auth-api';
import type { MenuItem2 as MenuItem } from '@newfront-insurance/core-ui';
import { MenuItemType } from '@newfront-insurance/core-ui';
import { useProvider } from '@newfront-insurance/react-provision';

import { LayoutConfigProvider } from '../../../../config';

/**
 * Render the account menu popover items that is used in the global navigation based on the type
 * of user that is currently logged in. Returns an array of MenuItems that can be used with the Menu component.
 */
export function useAccountMenuItems(): MenuItem[] {
  const { authProvider, logoutRedirect } = useProvider(LayoutConfigProvider);
  const { logout, userDetails: user } = useProvider(authProvider);
  const isProducer = user?.scopes.includes(AuthRole.PRODUCER);

  function onLogout(): Promise<void> {
    return logout(logoutRedirect);
  }

  const menuItems: MenuItem[] = [];

  if (isProducer) {
    menuItems.push(
      {
        type: MenuItemType.TITLE,
        title: 'External links',
      },
      {
        type: MenuItemType.LINK,
        label: 'View oracle',
        href: 'https://newfront.zendesk.com',
        target: '_blank',
      },
      {
        type: MenuItemType.LINK,
        label: 'View market access',
        href:
          // eslint-disable-next-line max-len
          'https://10ay.online.tableau.com/#/site/newfrontinsurance/views/CarrierRiskAppetiteCatalog/CarrierRiskAppetite?:iid=1',
        target: '_blank',
      },
      {
        type: MenuItemType.LINK,
        label: 'View your commission reports',
        href: 'https://app.captivateiq.com/login?next=/',
        target: '_blank',
      },
      {
        type: MenuItemType.LINK,
        label: 'View your book’s key metrics',
        href: 'https://10ay.online.tableau.com/#/site/newfrontinsurance/views/ProducerKeyMetrics/KeyMetrics?:iid=1',
        target: '_blank',
      },
      {
        type: MenuItemType.DIVIDER,
      },
    );
  }

  menuItems.push({
    type: MenuItemType.LINK,
    label: 'Log out',
    onClick: onLogout,
  });

  return menuItems;
}
