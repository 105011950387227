import { EmployeesRow, Spacing, Text } from '@newfront-insurance/core-ui';
import type { Employee } from '@newfront-insurance/next-newfront-api';

interface Props {
  servicingTeam: Employee[];
  containerId?: string;
}

export function ServicingTeam({ servicingTeam, containerId }: Props): JSX.Element {
  return (
    <>
      <Spacing height={24} />
      <Text size="small">Servicing team</Text>
      <Spacing height={8} />
      <EmployeesRow employees={servicingTeam} containerId={containerId} />
    </>
  );
}
