import * as React from 'react';
import type { Keyframes } from 'styled-components';
import styled, { keyframes } from 'styled-components';

interface Props {
  delay?: string;
  duration?: string;
  yOffset?: string;
  xOffset?: string;
  children: React.ReactNode;
}

export function FadeIn(props: Props): JSX.Element {
  const { delay = '0ms', duration = '200ms', yOffset = '0', xOffset = '0', children } = props;

  return (
    <StyledFadeInAnimationWrapper delay={delay} duration={duration} yOffset={yOffset} xOffset={xOffset}>
      {children}
    </StyledFadeInAnimationWrapper>
  );
}

interface AnimationProps {
  delay: string;
  duration: string;
  yOffset: string;
  xOffset: string;
}

const fade = (xOffset: string, yOffset: string): Keyframes => keyframes`
  from {
    opacity: 0;
    transform: translate(${xOffset}, ${yOffset});
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const StyledFadeInAnimationWrapper = styled.div<AnimationProps>`
  animation-name: ${({ xOffset, yOffset }) => fade(xOffset, yOffset)};
  animation-duration: ${({ duration }) => duration};
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
  animation-delay: ${({ delay }) => delay};
`;
