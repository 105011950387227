/* eslint-disable no-process-env */
import { AdminTRPCProvider } from '@newfront-insurance/admin-ui';
import { AccountSwitcher } from '@newfront-insurance/admin-ui/accounts';
import { AdminLayoutProvider, AppLayout, AppType } from '@newfront-insurance/admin-ui/layout';
import { useFeatureFlag } from '@newfront-insurance/app-providers';
import type { Route } from '@newfront-insurance/next-router-provider';
import { useRouter } from '@newfront-insurance/next-router-provider';
import type { PropsWithChildren, ReactNode } from 'react';

import { AuthProvider } from './auth';
import { BeamerProvider } from './beamer';
import { SelectedProducerProvider } from './selected-producer-provider';
import { ProducerSelector } from '../components/select-current-producer-modal';
import { useCurrentUserScopes } from '../hooks/use-current-user-scopes';
import { useCanSelectProducer } from '../hooks/use-permission';
import { pageRoutes } from '../routes';

import { useCurrentUserUuid } from '@/client/hooks/use-current-user-uuid';
import { getConfig } from '@/config';

const { LINKS } = getConfig();

export function AdminUIProvider({
  children,
  isShowingDarkSidebar,
}: PropsWithChildren<unknown> & { isShowingDarkSidebar?: boolean }): JSX.Element {
  const appConfig = useAppConfig();
  const accountSwitcherComponent = useAccountSwitcherComponent();
  const producerSelectorComponent = useProducerSelectorComponent();
  const isDarkModeToggleEnabled = useFeatureFlag('dark-mode-toggle', false);

  return (
    <AdminTRPCProvider basePath="/api/admin-ui/trpc" authProvider={AuthProvider}>
      <SelectedProducerProvider localStorageKey="current-producer-impersonation">
        <AdminLayoutProvider
          apiBasePath="/api/admin-ui/layout"
          authProvider={AuthProvider}
          logoutRedirect={LINKS.MARKETING_SITE}
          legacyAppBaseUrl={LINKS.LEGACY_DASH_APP}
          currentApp={appConfig?.type ?? AppType.ACCOUNTS}
        >
          {appConfig ? (
            <AppLayout
              showDarkModeToggle={isDarkModeToggleEnabled}
              isShowingDarkSidebar={isShowingDarkSidebar}
              leftToolbar={accountSwitcherComponent}
              rightToolbar={
                <div
                  style={{
                    display: 'flex',
                    gap: 12,
                    alignItems: 'center',
                  }}
                >
                  {producerSelectorComponent}
                  <BeamerProvider />
                </div>
              }
              homeRoute={appConfig.homeRoute}
            >
              {children}
            </AppLayout>
          ) : (
            children
          )}
        </AdminLayoutProvider>
      </SelectedProducerProvider>
    </AdminTRPCProvider>
  );
}

interface AppConfig {
  type: AppType;
  homeRoute: Route;
}

function useAppConfig(): AppConfig | undefined {
  const { router } = useRouter();

  if (router.pathname === '/billing') {
    return {
      type: AppType.BILLING,
      homeRoute: {
        pathname: '/billing',
      },
    };
  }

  // Show app layout on coverage gap analysis page
  if (router.pathname === '/coverage-gap-analysis') {
    return {
      type: AppType.COVERAGE_GAP_ANALYSIS,
      homeRoute: {
        pathname: '/coverage-gap-analysis',
      },
    };
  }

  // Hide app layout on coverage gap analysis workflow page(s)
  if (
    router.pathname.includes('/coverage-gap-analysis/workflow') ||
    router.pathname.includes('/coverage-gap-analysis/result')
  ) {
    return undefined;
  }

  const isExposuresRoute = pageRoutes.viewAccountInsurableItemsDetails().pathname === router.pathname;

  if (isExposuresRoute) {
    return {
      type: AppType.EXPOSURES,
      homeRoute: {
        pathname: `/accounts/${router.query.accountUuid}/exposures`,
      },
    };
  }

  if (router.pathname.endsWith('/policy-change-request')) {
    return undefined;
  }

  return {
    type: AppType.ACCOUNTS,
    homeRoute: {
      pathname: '/accounts',
    },
  };
}

export function useAccountSwitcherComponent(): ReactNode {
  const { getQuery, pushRoute, router } = useRouter();
  const accountUuid = getQuery<string>('accountUuid');
  const { currentUserScopes } = useCurrentUserScopes();
  const { currentUserUuid } = useCurrentUserUuid();

  if (!accountUuid) {
    return null;
  }

  return (
    <AccountSwitcher
      loggedInUserUuid={currentUserUuid}
      currentAccountUuid={accountUuid}
      userScopes={currentUserScopes}
      onSelectAccount={(account) => {
        return pushRoute({
          pathname: router.pathname,
          query: {
            ...router.query,
            accountUuid: account,
          },
        });
      }}
    />
  );
}

const PRODUCER_SELECTOR_VISIBLE_PATHNAMES = ['/accounts', '/billing'];

function useProducerSelectorComponent(): ReactNode {
  const canSelectProducer = useCanSelectProducer();
  const { router } = useRouter();

  if (!canSelectProducer || !PRODUCER_SELECTOR_VISIBLE_PATHNAMES.includes(router.pathname)) {
    return null;
  }

  if (canSelectProducer) {
    return (
      <div
        style={{
          minWidth: 300,
        }}
      >
        <ProducerSelector />
      </div>
    );
  }
}
