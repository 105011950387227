import { SelectInput, sortByLabel } from '@newfront-insurance/core-ui';
import { uniq } from 'lodash';
import * as React from 'react';

import type { Wholesaler } from '../../../types';

export interface Props {
  data?: Wholesaler[];
  value?: string | null;
  onChange?: (value?: string | null) => unknown;
  disabled?: boolean;
  name?: string;
  innerRef?: React.MutableRefObject<HTMLSelectElement | null>;
  showSelectAnOption?: boolean;
}

export function WholesalerSelect(props: Props): JSX.Element {
  const { onChange, value, disabled, name, innerRef, showSelectAnOption, data = [] } = props;

  const wholesalerOptions = data
    ? uniq(data)
        .map((wholesaler: Wholesaler) => ({
          value: wholesaler.id,
          label: wholesaler.name,
        }))
        .sort(sortByLabel)
    : [];

  return (
    <SelectInput
      name={name}
      options={wholesalerOptions}
      onChange={(wholesaler) => {
        if (onChange) onChange(wholesaler);
      }}
      value={value}
      innerRef={innerRef}
      disabled={disabled}
      showSelectAnOption={showSelectAnOption}
      showSelectAnOptionDisabled={false}
    />
  );
}
