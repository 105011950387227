import type Fuse from 'fuse.js';
import { useState } from 'react';
import * as React from 'react';
import { useDebounce } from 'react-use';

import { fuzzySearch } from './fuzzy-search';

interface UseFuzzyReturnValue<T> {
  query: string;
  debouncedQuery: string;
  results: T[];
  search: (query: string) => void;
}

export type FuzzySearchOptions<T> = Partial<Fuse.IFuseOptions<T>> & { debounce?: number };

export function useFuzzy<T>(data: T[], options: FuzzySearchOptions<T>): UseFuzzyReturnValue<T> {
  const { debounce = 0, ...fuzzyOptions } = options;
  const [query, setQuery] = React.useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    debounce,
    [query, setDebouncedQuery],
  );

  const results = fuzzySearch(data, debouncedQuery, fuzzyOptions);

  return {
    query,
    debouncedQuery,
    results,
    search: setQuery,
  };
}
