import type {
  Account,
  AccountEmployeeRoleMapping,
  AccountType,
  Identity,
  PublicAccount,
} from '@newfront-insurance/account-api';
import { ProgramType } from '@newfront-insurance/account-api';
import { formatIdentityValue } from '@newfront-insurance/account-common';
import { AuthRole } from '@newfront-insurance/auth-api';
import { toGroupedRecord } from '@newfront-insurance/core';
import type { BadgeType, DefinitionListItem } from '@newfront-insurance/core-ui';
import compact from 'lodash/compact';

import { getBusinessIdentifierLabel, IDENTITY_TYPE_LABELS } from './utils';
import type {
  AccountDefinition,
  AccountRoleMapping,
  NormalizedAccountEmployeeRoleMappings,
  PublicAccountDefinition,
  SearchBadge,
} from '../types';

export const hasAnyAccountViewAccessScopes = [
  AuthRole.AM_MANAGER,
  AuthRole.ENGINEER,
  AuthRole.OPERATIONS,
  AuthRole.PRODUCER_MANAGER,
  AuthRole.SALES_MANAGER,
  // TODO(ACC-743): Remove AMs and CSA from this list once they can be added to an account's servicing teams
  AuthRole.ACCOUNT_MANAGER,
  AuthRole.CSA,
];

const PROGRAM_TYPE_TO_READABLE: Record<ProgramType, string> = {
  [ProgramType.PROPERTY_AND_CASUALTY]: 'BI',
  [ProgramType.EMPLOYEE_BENEFITS]: 'TR',
};

const PROGRAM_TYPE_TO_BADGE_TYPE: Record<ProgramType, BadgeType> = {
  [ProgramType.PROPERTY_AND_CASUALTY]: 'dark',
  [ProgramType.EMPLOYEE_BENEFITS]: 'glitter',
};

export function formatAccountSearchResultBadge(
  programType: string | undefined,
  isArchived?: boolean | undefined,
): SearchBadge[] | undefined {
  const badges: { content: string; type: BadgeType }[] = [];

  if (programType) {
    badges.push({
      content: PROGRAM_TYPE_TO_READABLE[programType as ProgramType],
      type: PROGRAM_TYPE_TO_BADGE_TYPE[programType as ProgramType],
    });
  }

  if (isArchived) {
    badges.push({
      content: 'Archived',
      type: 'secondary',
    });
  }

  return badges;
}

export function formatAccount(
  account: Account,
  accountEmployeeRoleMappings: AccountEmployeeRoleMapping[],
): AccountDefinition {
  const { identities, type, postalAddress } = account;
  const definitionListItems: DefinitionListItem[] = [];

  if (identities.length > 0) {
    definitionListItems.push(...formatIdentitiesDefinitions(identities, type));
  }

  definitionListItems.push({
    title: 'Mailing address',
    type: 'location',
    value:
      compact([
        postalAddress?.addressLine1,
        postalAddress?.addressLine2,
        postalAddress?.addressLine3,
        postalAddress?.city,
      ]).join(', ') || '-',
    subValue: compact([postalAddress?.stateOrArea, postalAddress?.postalCode]).join(', ') || '-',
  });

  return {
    ...account,
    definitionListItems,
    employeeRoleMappings: groupAccountEmployeeRoleMappingsByRoleId(accountEmployeeRoleMappings),
  };
}

export function formatPublicAccount(
  account: PublicAccount,
  accountEmployeeRoleMappings: AccountEmployeeRoleMapping[],
): PublicAccountDefinition {
  const { identities, type } = account;
  const definitionListItems: DefinitionListItem[] = [];

  if (identities.length > 0) {
    definitionListItems.push(...formatIdentitiesDefinitions(identities, type));
  }

  return {
    ...account,
    definitionListItems,
    employeeRoleMappings: groupAccountEmployeeRoleMappingsByRoleId(accountEmployeeRoleMappings),
  };
}

function formatIdentitiesDefinitions(identities: Identity[], accountType: AccountType): DefinitionListItem[] {
  const identitiesDefinitions = identities.map((identity): DefinitionListItem => {
    return {
      title:
        accountType === 'commercial'
          ? getBusinessIdentifierLabel(identity.countryCode)
          : IDENTITY_TYPE_LABELS[identity.type],
      type: 'number',
      value: identity.value ? formatIdentityValue(identity) : '-',
    };
  });

  return identitiesDefinitions;
}

function groupAccountEmployeeRoleMappingsByRoleId(
  apiAccountEmployeeRoleMappings: AccountEmployeeRoleMapping[],
): NormalizedAccountEmployeeRoleMappings {
  const accountEmployeeRoleMappings: AccountRoleMapping[] = apiAccountEmployeeRoleMappings.map(
    ({ accountUuid, roleId, userUuid }) => ({ accountUuid, roleId, userUuid }),
  );
  return toGroupedRecord(accountEmployeeRoleMappings, (accountRoleMapping) => accountRoleMapping.roleId);
}
