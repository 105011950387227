import noop from 'lodash/noop';
import type { KeyboardEvent, RefObject } from 'react';

interface KeyboardControlsOptions {
  inputRef: RefObject<HTMLDivElement>;
  popoverRef: RefObject<HTMLDivElement>;
  onEsc?: (event: KeyboardEvent<Element>) => void;
}

interface KeyboardControlsResult {
  onKeyDown: (event: KeyboardEvent<Element>) => void;
}

export function useKeyboardControls({
  inputRef,
  popoverRef,
  onEsc = noop,
}: KeyboardControlsOptions): KeyboardControlsResult {
  return {
    onKeyDown: (event: KeyboardEvent<Element>) => {
      const searchInput = inputRef.current;
      const container = popoverRef.current;
      const current = event.currentTarget as HTMLDivElement;

      if (event.key === 'ArrowDown') {
        /**
         * if we're currently in the lookup input,
         * Check if we have rendered any previously saved addresses, if so, focus the FIRST child
         * otherwise, check if we have rendered lookup results, if so, focus the FIRST child
         */
        if (current.className.includes('address-lookup')) {
          const previouslySavedAddresses = container?.querySelector('.previously-saved-addresses');
          if (previouslySavedAddresses && previouslySavedAddresses.firstChild instanceof HTMLElement) {
            previouslySavedAddresses.firstChild.focus();
            return;
          }

          const lookupResults = container?.querySelector('.lookup-results');
          if (lookupResults && lookupResults.firstChild instanceof HTMLElement) {
            lookupResults.firstChild.focus();
            return;
          }
        }

        /**
         * if we're currently on a previously saved address,
         * Check if we have any next siblings to focus,
         * otherwise, check if we have rendered lookup results, if so, focus the FIRST child
         */
        if (current.hasAttribute('data-previously-saved-address')) {
          const nextResult = current?.nextSibling;

          if (nextResult instanceof HTMLElement) {
            nextResult.focus();
            return;
          }

          if (!nextResult) {
            const lookupResults = container?.querySelector('.lookup-results');
            if (lookupResults && lookupResults.firstChild instanceof HTMLElement) {
              lookupResults.firstChild.focus();
              return;
            }
            return;
          }
        }

        /**
         * if we're currently on a lookup result,
         * Check if we have any next siblings to focus,
         */
        if (current.hasAttribute('data-lookup-result')) {
          const nextResult = current?.nextSibling;
          if (nextResult instanceof HTMLElement) {
            nextResult.focus();
            return;
          }
        }
      }

      if (event.key === 'ArrowUp') {
        /**
         * if we're currently on a lookup result,
         * Check if we have any previous siblings to focus,
         * otherwise, check if we have rendered previously saved addresses, if so, focus the LAST child
         * otherwise, go back to the lookup input
         */
        if (current.hasAttribute('data-lookup-result')) {
          const previousResult = current?.previousSibling;
          if (previousResult instanceof HTMLElement) {
            previousResult.focus();
            return;
          }

          if (!previousResult) {
            const previouslySavedAddresses = container?.querySelector('.previously-saved-addresses');
            if (previouslySavedAddresses && previouslySavedAddresses.lastChild instanceof HTMLElement) {
              previouslySavedAddresses.lastChild.focus();
              return;
            }

            if (!previouslySavedAddresses) {
              if (searchInput instanceof HTMLElement) {
                searchInput.focus();
                return;
              }
            }
          }
        }

        /**
         * if we're currently on a previously saved address,
         * Check if we have any previous siblings to focus,
         * otherwise, go back to the lookup input
         */
        if (current.hasAttribute('data-previously-saved-address')) {
          const previousResult = current?.previousSibling;

          if (previousResult instanceof HTMLElement) {
            previousResult.focus();
            return;
          }

          if (!previousResult) {
            if (searchInput instanceof HTMLElement) {
              searchInput.focus();
              return;
            }
          }
        }
      }

      /**
       * Close and reset the component
       */
      if (event.key === 'Escape') {
        onEsc(event);
      }
    },
  };
}
