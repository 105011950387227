/* eslint-disable @typescript-eslint/no-explicit-any, react/jsx-no-bind */
import { isNotNil } from '@newfront-insurance/core';
import { toMoney, toNumberOrUndefinedFromMoney } from '@newfront-insurance/core-money';
import {
  AdvancedSelectInput,
  CheckboxRow,
  CurrencyInput,
  Flexbox,
  Input,
  RadioButtonGroup,
  SelectInput,
  TextareaAutosize,
} from '@newfront-insurance/core-ui';
import { InputType, TextInputType } from '@newfront-insurance/dsl-schema-api';
import Ajv from 'ajv';
import { useField, useFormikContext } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import trimStart from 'lodash/trimStart';
import { useEffect } from 'react';

// eslint-disable-next-line import/no-cycle
import type { SchemaInputControlCustomInput, SchemaInputControlProps } from './registry';
import { customInputComponentsRegistry } from './registry';
import { CurrencyWithNumber } from '../components/currencywithnumber';
import { DateField } from '../components/date-field';
import { PercentageInput } from '../components/percentage-input';
import { useRootSchema } from '../context';

const ajv = new Ajv({
  strict: false,
  allErrors: true,
  $data: true,
  strictTypes: false,
  logger: false,
  messages: true,
});

export function SchemaInputControl(props: SchemaInputControlProps): JSX.Element | null {
  const { name, type, metadata, schema, fieldPath, accountUuid } = props;
  const context = useFormikContext();
  const { values } = context || {};
  const rootSchema = useRootSchema();
  const fieldName = `${name}.${fieldPath}`;

  const [input, , { setValue }] = useField({
    name: fieldName,
    // field validation is the most complex function of this package due to how json-schemas are validated
    // if it's a nested schema, the validator doesn't tell which nested fields are invalid, it only tells that the field is missing
    // TODO: move this validation to the form-level as it is not per-field but per-form
    // also, run the validator over each nested schema so that we can catch nested field errors upfront to improve the UX
    validate: () => {
      const validator = ajv.compile(rootSchema);
      validator(get(values, name));

      const jsonSchemaError = (validator.errors || []).find((error) => {
        const flatErrorKey = trimStart(error.instancePath, '/');

        if (flatErrorKey === fieldPath) {
          return error;
        }

        if (error.params.missingProperty) {
          // json schema errors are separated by "/" (/name/firstName), so we just replace it by "." and make them compatible with our field paths
          const errorKey = trimStart(`${error.instancePath.replaceAll('/', '.')}.${error.params.missingProperty}`, '.');
          const splittedErrorKey = errorKey.split('.');
          const splittedFieldPath = fieldPath.split('.');

          // nesting is separated by dots, so the error should be shown if it's field nesting is only one level deeper than the error nesting
          if (splittedFieldPath.length > splittedErrorKey.length + 1) {
            return false;
          }

          if (errorKey === fieldPath || splittedFieldPath[0] === errorKey || fieldPath.startsWith(errorKey)) {
            return error;
          }
        } else {
          if (error.keyword === 'if') {
            return false;
          }

          const errorKey = trimStart(`${error.instancePath.replaceAll('/', '.')}`, '.');
          // if the error key is a false value, such as an empty string "", we need to return false. Otherwise the fieldPath
          // startsWith(errorKey) conditional in the next if will always evaluate to true with an empty string
          if (!errorKey) {
            return false;
          }

          if (errorKey === fieldPath || fieldPath.startsWith(errorKey) || errorKey.startsWith(fieldPath)) {
            return error;
          }
        }

        return false;
      });

      if (jsonSchemaError) {
        return (
          schema.errors?.[jsonSchemaError.keyword] ||
          (jsonSchemaError.keyword === 'required' ? 'This field is required' : 'This field is invalid')
        );
      }

      return undefined;
    },
  });

  const { value } = input;

  function onChange(newValue: any): void {
    if (typeof newValue === 'string' && isEmpty(newValue)) {
      setValue(undefined);
    } else {
      setValue(newValue, false);
    }

    // skipping validation due to race conditions on how formik validation works
    // we wait the next tick and call the validation manually to avoid weird UI validation errors
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      context.validateForm();
    }, 0);
  }

  // Sets default value if value is not defined and default value is defined
  useEffect(() => {
    if (!value && metadata?.defaultValue) {
      onChange(metadata.defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (type) {
    case InputType.CHECKBOX:
      return (
        <CheckboxRow
          label={metadata?.checkboxLabel || ''}
          isActive={value}
          onClick={onChange}
          style={{ minHeight: 40, alignItems: 'center' }}
        />
      );
    case InputType.RADIO:
      return <RadioButtonGroup name={fieldName} value={value} options={metadata?.options || []} onChange={onChange} />;
    case InputType.NUMBER:
      return (
        <Input
          type="number"
          value={value || parseInt(value, 10) === 0 ? value : ''}
          onChange={(inputValue) => onChange(inputValue ? Number(inputValue) : undefined)}
          appendText={metadata?.appendText}
        />
      );
    case InputType.CURRENCY: {
      const { allowCents, allowNegative } = metadata || {};
      const decimalScale = allowCents ? undefined : 0;
      return (
        <CurrencyInput
          name={fieldName}
          value={toNumberOrUndefinedFromMoney(value)}
          allowNegative={allowNegative}
          decimalScale={decimalScale}
          onChange={(inputValue) => onChange(inputValue !== undefined ? toMoney(inputValue) : undefined)}
        />
      );
    }
    case InputType.CURRENCY_APPLIES: {
      const decimalScale = metadata?.allowCents ? undefined : 0;
      return (
        <CurrencyInput
          name={fieldName}
          value={toNumberOrUndefinedFromMoney(value?.money)}
          decimalScale={decimalScale}
          onChange={(inputValue) =>
            onChange(inputValue !== undefined ? { money: toMoney(inputValue), applies: !!inputValue } : undefined)
          }
        />
      );
    }
    case InputType.CURRENCY_PERCENTAGE: {
      const { options, allowCents, currencyEnabledOptions, percentageEnabledOptions } = metadata || {};
      if (!metadata || !options) return null;
      const decimalScale = allowCents ? undefined : 0;
      const currencyEnabled = currencyEnabledOptions?.includes(value?.type);
      const percentageEnabled = percentageEnabledOptions?.includes(value?.type);
      return (
        <Flexbox gap={16} flexDirection="row">
          <SelectInput
            name={fieldName}
            options={options || []}
            value={value?.type}
            onChange={(newType: string) => {
              const newValue = { type: newType, money: undefined, percentage: undefined };
              if (currencyEnabledOptions?.includes(newType)) newValue.money = value?.money;
              else if (percentageEnabledOptions?.includes(newType)) newValue.percentage = value?.percentage;
              onChange(newValue);
            }}
            showSelectAnOption
          />
          {currencyEnabled && (
            <CurrencyInput
              name={fieldName}
              value={toNumberOrUndefinedFromMoney(value?.money)}
              decimalScale={decimalScale}
              onChange={(inputValue) =>
                onChange(
                  inputValue !== undefined ? { type: value?.type, money: toMoney(inputValue) } : { type: value?.type },
                )
              }
            />
          )}
          {percentageEnabled && (
            <Input
              type="number"
              value={value?.percentage}
              onChange={(inputValue) =>
                onChange(
                  inputValue
                    ? { type: value?.type, percentage: Number(inputValue) }
                    : { type: value?.type, percentage: undefined },
                )
              }
              appendText="%"
            />
          )}
        </Flexbox>
      );
    }
    case InputType.CURRENCY_WITH_NUMBER: {
      const { numberFieldName, currencyFieldName, numberFieldLabel, currencyFieldLabel, allowCents } = metadata || {};
      if (!metadata || !numberFieldName || !currencyFieldName) return null;
      const decimalScale = allowCents ? undefined : 0;
      return (
        <CurrencyWithNumber
          name={fieldName}
          value={value}
          onChange={onChange}
          currencyFieldLabel={currencyFieldLabel}
          currencyFieldName={currencyFieldName}
          numberFieldLabel={numberFieldLabel}
          numberFieldName={numberFieldName}
          decimalScale={decimalScale}
          appendText={metadata?.appendText}
        />
      );
    }
    case InputType.CURRENCY_WITH_TYPE: {
      const { options, currencyEnabledOptions, allowCents } = metadata || {};
      if (!metadata || !options || !currencyEnabledOptions) return null;
      const decimalScale = allowCents ? undefined : 0;
      return (
        <Flexbox gap={16} flexDirection="row">
          <SelectInput
            name={fieldName}
            options={options}
            value={value?.type}
            onChange={(newType: string) => {
              const newValue = { type: newType, money: undefined };
              if (currencyEnabledOptions.includes(newType)) newValue.money = value?.money;
              onChange(newValue);
            }}
            showSelectAnOption
          />
          <CurrencyInput
            name={fieldName}
            value={toNumberOrUndefinedFromMoney(value?.money)}
            decimalScale={decimalScale}
            onChange={(inputValue) =>
              onChange(inputValue !== undefined ? { ...value, money: toMoney(inputValue) } : undefined)
            }
            disabled={!currencyEnabledOptions.includes(value?.type)}
          />
        </Flexbox>
      );
    }
    case InputType.DATE:
      return <DateField name={fieldName} value={value} setValue={setValue} />;
    case InputType.SELECT:
      return (
        <SelectInput
          name={fieldName}
          options={metadata?.options || []}
          value={value}
          onChange={onChange}
          showSelectAnOption
          showSelectAnOptionDisabled={false}
        />
      );
    case InputType.MULTI_SELECT:
      return (
        <AdvancedSelectInput
          name={fieldName}
          onChange={onChange}
          isMulti
          options={metadata?.options || []}
          value={value}
        />
      );
    case InputType.TEXT: {
      if (metadata?.textInputType === TextInputType.TEXT_AREA) {
        return <TextareaAutosize value={value} onChange={(event) => onChange(event.target.value)} />;
      }
      return <Input type="text" value={value} onChange={onChange} />;
    }
    case InputType.PERCENTAGE: {
      return <PercentageInput value={value} onChange={onChange} />;
    }
    default: {
      // Check if there is a custom component for the type and use it if that is the case
      const CustomComponent = customInputComponentsRegistry[type];
      if (CustomComponent) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <CustomComponent {...omit(props, 'type')} accountUuid={accountUuid} onChange={onChange} value={value} />;
      }

      // fallback
      return <Input type="text" value={value || ''} onChange={onChange} />;
    }
  }
}

/**
 * Allows to extend SchemaInputControl by adding new inputType handlers
 * @param inputType
 * @param customInput
 */
SchemaInputControl.registerComponent = (inputType: string, customInput: SchemaInputControlCustomInput<any, any>) => {
  const previouslyRegisteredComponent = customInputComponentsRegistry[inputType];

  if (isNotNil(previouslyRegisteredComponent)) {
    // eslint-disable-next-line no-console
    console.warn(`SchemaInputControl.registerComponent for type "${inputType}" override. It was already set.`);
  }

  customInputComponentsRegistry[inputType] = customInput;
};
