/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TextSizes } from '@newfront-insurance/core-ui';
import type { ComponentType } from 'react';

import type { SchemaFieldType, SchemaFieldMetadataType } from '../../../types';

export interface ValueProps<T = any, K = any> {
  name: string;
  field: SchemaFieldType<K>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T;
  accountUuid?: string;
  size?: TextSizes;
  color?: string;
  weight?: 300 | 400 | 500 | 600 | 700;
}

/**
 * Props for a custom value display for a new inputType handler added to the Value
 */
export type CustomValueProps<T = any, K = SchemaFieldMetadataType> = ValueProps<T, K>;

/**
 * Custom value display for a new inputType handler added to the Value
 */
export type CustomValue<T, K = SchemaFieldMetadataType> = ComponentType<CustomValueProps<T, K>>;

interface CustomValueRegistryValue<T, K = SchemaFieldMetadataType> {
  component: CustomValue<T, K>;
  getStringifiedFieldValue?: (value: T, field?: K) => string | null;
}
/**
 * Components registered as new input types with the handler for the new case. This makes the value extendable.
 */
export const customValueComponentsRegistry: Record<string, CustomValueRegistryValue<any, any>> = {};
