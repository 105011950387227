import type { LineOfCoverageMetadata } from '@newfront-insurance/coverage-api';
import type { NextApiSubscription, ConfigInterface } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext } from 'react';

import { routes } from '../bff/routes';
import type { GetLineOfCoverageMetadataParams, GetLineOfCoverageMetadataResponse } from '../bff/types';
import { CoverageApiContext } from '../provider';

interface Params extends Omit<GetLineOfCoverageMetadataParams, 'lineOfCoverageVersion'> {
  lineOfCoverageVersion?: number;
}

export function useLineOfCoverageMetadata(
  params: Params,
  config?: ConfigInterface,
): NextApiSubscription<LineOfCoverageMetadata | null> {
  const { basePath } = useContext(CoverageApiContext);

  return useNextApiSubscription<GetLineOfCoverageMetadataResponse | null>(
    routes.linesOfCoverageMetadata.getUrl(
      {
        ...params,
        lineOfCoverageVersion: params.lineOfCoverageVersion?.toString() ?? undefined,
      },
      basePath,
    ),
    config,
  );
}
