import type { LineOfCoverageType, PolicyType } from '@newfront-insurance/coverage-api';
import type { NextApiError } from '@newfront-insurance/next-api-error';
import type { ConfigInterface } from '@newfront-insurance/next-api-swr';
import differenceBy from 'lodash/differenceBy';
import { useState, useEffect, useMemo } from 'react';

import { add, remove, calculateOptions } from './helpers';
import type { LineOfCoverageTypeOption } from '../../types';
import { calculateNewOption, typesToOptions } from '../../utils/line-of-coverage-type-option';
import { useDefinitionsHelpers } from '../definitions';

export interface OptionsResult {
  loading: boolean;
  error?: NextApiError;
  options: LineOfCoverageTypeOption[];
  addOption: (type: LineOfCoverageType) => unknown;
  selectOption: (option: LineOfCoverageTypeOption) => unknown;
  unselectOption: (option: LineOfCoverageTypeOption) => unknown;
}

export interface Params {
  policyType: PolicyType;
  selectAllByDefault?: boolean;
  selectedOptions: LineOfCoverageTypeOption[];
  selectOptions: (newSelectedOptions: LineOfCoverageTypeOption[]) => unknown;
}

export function useLineOfCoverageOptions(
  { policyType, selectAllByDefault, selectedOptions, selectOptions }: Params,
  config?: ConfigInterface,
): OptionsResult {
  const [selectAllByDefaultDone, setSelectAllByDefaultDone] = useState<boolean>(true);

  const [options, setOptions] = useState<LineOfCoverageTypeOption[]>([]);
  const { loading, error, policyTypeDefinitions } = useDefinitionsHelpers({}, config);

  const defaultOptionsForPolicyType: LineOfCoverageTypeOption[] = useMemo(
    () => typesToOptions(policyTypeDefinitions[policyType]?.locs ?? []),
    [policyTypeDefinitions, policyType],
  );

  useEffect(() => {
    setOptions(() => calculateOptions(defaultOptionsForPolicyType, selectedOptions));
  }, [defaultOptionsForPolicyType, selectedOptions]);

  useEffect(() => setSelectAllByDefaultDone(false), [policyType]);

  // defaultOptionsForPolicyType should start selected if selectAllByDefault is enabled:
  useEffect(() => {
    if (!selectAllByDefault || selectAllByDefaultDone) return;
    setSelectAllByDefaultDone(true);
    const unselectedOptions = differenceBy(options, selectedOptions, ({ id }) => id);
    if (unselectedOptions.length > 0) {
      selectOptions(options);
    }
  }, [selectAllByDefault, selectAllByDefaultDone, selectOptions, options, selectedOptions]);

  function addOption(type: LineOfCoverageType): void {
    const newOption = calculateNewOption(options, type);
    setOptions(add(options, newOption));
    selectOptions(add(selectedOptions, newOption));
  }

  function selectOption(option: LineOfCoverageTypeOption): void {
    selectOptions(add(selectedOptions, option));
  }

  function unselectOption(option: LineOfCoverageTypeOption): void {
    selectOptions(remove(selectedOptions, option));
  }

  return {
    loading,
    error,
    options,
    addOption,
    selectOption,
    unselectOption,
  };
}
