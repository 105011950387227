import type { SpacingValue } from '../../theme';
import { colors } from '../../theme/colors';

export function getDetailsCardOuterPadding(padding: string | SpacingValue): SpacingValue {
  if (typeof padding === 'number') {
    return padding;
  }

  switch (padding) {
    case 'default':
      return 12;
    case 'none':
      return 0;
    case 'narrow':
      return 4;
    default:
      return 12;
  }
}

interface BackgroundColorOptions {
  isHovering?: boolean;
  isHighlighted?: boolean;
}

export function getDetailsCardColor(color: string): string {
  switch (color) {
    case 'white':
      return '#FFFFFF';
    case 'light-grey':
      return colors.steel[50];
    case 'light-blue':
      return colors.brand[100];
    case 'light-yellow':
      return colors.fire[100];
    default:
      return color;
  }
}

export function getDetailsCardBackgroundColor(
  color: string,
  { isHovering, isHighlighted }: BackgroundColorOptions,
): string {
  if (isHighlighted) {
    return colors.brand[100];
  }

  if (isHovering) {
    return colors.steel[50];
  }

  switch (color) {
    case 'white':
      return '#FFFFFF';
    case 'light-grey':
      return colors.steel[50];
    case 'light-blue':
      return colors.brand[100];
    case 'light-yellow':
      return colors.fire[100];
    default:
      return color;
  }
}

/**
 * This constants in meant to use as a padding for the inner content of the card.
 * For default outer padding this achieves 16px in total: 12 + 4
 */
export const INNER_PADDING = 4;
