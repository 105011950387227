import { useEffect, useRef } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  indeterminate?: boolean;
  testId?: string;
}

export function CheckboxInput({ indeterminate, checked, ...props }: Props): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      // if checkbox is checked, set indeterminate to false, otherwise use indeterminate prop
      inputRef.current.indeterminate = checked ? false : indeterminate ?? false;
    }
  }, [indeterminate, checked]);

  return (
    <StyledInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      type="checkbox"
      ref={inputRef}
      checked={checked}
      readOnly={!props.onChange}
      data-testid={`${props.testId}-checkbox`}
    />
  );
}

const StyledInput = styled.input({
  appearance: 'none',
  cursor: 'pointer',
  border: `1px solid ${colors.steel[200]}`,
  backgroundColor: '#fff',
  borderRadius: 6,
  boxSizing: 'border-box',
  display: 'inline-block',
  verticalAlign: 'top',
  outline: 'none',
  width: 20,
  height: 20,
  margin: 0,
  position: 'relative',
  transition: 'background-color .3s ease',
  ':checked': {
    backgroundColor: colors.brand[400],
    borderColor: colors.brand[400],
    '&:disabled': {
      backgroundColor: colors.steel[200],
      borderColor: colors.steel[200],
    },
    '&:after': {
      opacity: 1,
    },
  },
  ':disabled': {
    backgroundColor: colors.steel[100],
    borderColor: colors.steel[200],
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  '&:focus': {
    boxShadow: `0 0 0 2px ${colors.brand[200]}`,
  },
  ':indeterminate': {
    borderColor: colors.brand[400],
    boxShadow: `0 0 0 1px inset ${colors.brand[400]}`,
    '&:disabled': {
      boxShadow: `0 0 0 1px inset ${colors.steel[200]}`,
      borderColor: colors.steel[200],

      '&:after': {
        backgroundColor: colors.steel[200],
      },
    },
    '&:after': {
      opacity: 1,
      border: 0,
      width: 12,
      top: '50%',
      left: '50%',
      height: 2,
      borderRadius: 8,
      backgroundColor: colors.brand[400],
      transform: 'translate(-50%, -50%)',
    },
  },
  // checkmark styles
  '&:after': {
    boxSizing: 'border-box',
    width: 6,
    height: 11,
    border: '2px solid #fff',
    borderTop: 0,
    borderLeft: 0,
    left: 6,
    top: 2,
    transform: 'rotate(43deg)',
    content: '""',
    display: 'block',
    position: 'absolute',
    opacity: 0,
    transition: 'opacity .3s ease',
  },
});
