import { CircleSpinnerInline, CircleSpinner } from '@newfront-insurance/core-ui';
import { Suspense } from 'react';
import * as React from 'react';

export type SpinnerVariant = 'default' | 'inline';

interface Props {
  children: React.ReactNode;
  loadingText?: string;
  variant?: SpinnerVariant;
}

interface SpinnerProps {
  loadingText: string;
  variant: SpinnerVariant;
}

export function Spinner({ loadingText, variant }: SpinnerProps): JSX.Element {
  switch (variant) {
    case 'inline':
      return <CircleSpinnerInline label={loadingText} />;

    default:
      return <CircleSpinner label={loadingText} />;
  }
}

export function FallbackSuspense({ children, loadingText = 'Loading...', variant = 'default' }: Props): JSX.Element {
  return <Suspense fallback={<Spinner variant={variant} loadingText={loadingText} />}>{children}</Suspense>;
}
