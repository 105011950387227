import type { RouterUseQueryOptions, RouterUseSuspenseQueryOptions } from '@newfront-insurance/data-layer-client';
import type { RouterQueryInput } from '@newfront-insurance/data-layer-server';

import { useAdminTRPC } from '../../../shared/providers/trpc';
import type { SharedRouter } from '../../../shared/trpc/router';

export function usePolicy(
  params: RouterQueryInput<SharedRouter, 'policies.getByUuid'>,
  options?: RouterUseQueryOptions<SharedRouter, 'policies.getByUuid'>,
) {
  const { useQuery } = useAdminTRPC();

  return useQuery(['policies.getByUuid', params], options);
}

export function useSuspensePolicy(
  params: RouterQueryInput<SharedRouter, 'policies.getByUuid'>,
  options?: RouterUseSuspenseQueryOptions<SharedRouter, 'policies.getByUuid'>,
) {
  const { useSuspenseQuery } = useAdminTRPC();

  return useSuspenseQuery(['policies.getByUuid', params], options);
}
