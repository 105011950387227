import { isNotNil } from '@newfront-insurance/core';
import { useCallback } from 'react';

import { useEmployeesByUserUuid } from './use-employees-by-user-uuid';
import type { NormalizedAccountEmployeeRoleMappings, Employee } from '../../bff/types';

interface WithEmployeeRoleMappings {
  employeeRoleMappings: NormalizedAccountEmployeeRoleMappings;
}

interface ServicingTeamForAccountHook {
  getServicingTeamForAccount: (withEmployeeRoleMappings: WithEmployeeRoleMappings) => Employee[];
}

export function useServicingTeamForAccount(): ServicingTeamForAccountHook {
  const { accountManagersMap, producersMap, operationsEmployeesMap } = useEmployeesByUserUuid();

  const getServicingTeamForAccount = useCallback(
    (withEmployeeRoleMappings: WithEmployeeRoleMappings) => {
      const { employeeRoleMappings } = withEmployeeRoleMappings;

      const accountManagers = [
        ...(employeeRoleMappings.PRIMARY_ACCOUNT_MANAGER || []),
        ...(employeeRoleMappings.SECONDARY_ACCOUNT_MANAGER || []),
      ].map((roleMapping) => accountManagersMap.get(roleMapping.userUuid));

      const producers = [
        ...(employeeRoleMappings.PRIMARY_PRODUCER || []),
        ...(employeeRoleMappings.SECONDARY_PRODUCER || []),
      ].map((roleMapping) => producersMap.get(roleMapping.userUuid));

      const clientServiceAssociates = [...(employeeRoleMappings.CSA || [])].map((roleMapping) =>
        operationsEmployeesMap.get(roleMapping.userUuid),
      );

      return [...accountManagers, ...producers, ...clientServiceAssociates].filter(isNotNil);
    },
    [accountManagersMap, producersMap, operationsEmployeesMap],
  );

  return { getServicingTeamForAccount };
}
