import { FallbackSuspense } from '@newfront-insurance/admin-ui/layout';
import { GeneralAppProviders } from '@newfront-insurance/app-providers';
import { registerLocale } from 'i18n-iso-countries';
import enLang from 'i18n-iso-countries/langs/en.json';
import type { AppProps } from 'next/app';

import { HeadTitle } from '@/client/components/head-title';
import { Providers } from '@/client/providers';
import { AuthProvider } from '@/client/providers/auth';
import { TRPCProvider } from '@/client/providers/trpc';
import { getConfig } from '@/config';
import '@newfront-insurance/tailwind-config/globals.css';
import '../client/global.css';

registerLocale(enLang);

const config = getConfig();

const { DATADOG } = getConfig();

const ddRumConfig = {
  appName: 'account-app',
  applicationId: DATADOG.RUM.ACCOUNTS.APPLICATION_ID,
  clientToken: DATADOG.RUM.ACCOUNTS.CLIENT_TOKEN,
};

/**
 * The component will wrap every page that is rendered. This component stays alive for the entire session. This should
 * be used to initialize providers that are used by the entire application..
 */
export default function App(props: AppProps): JSX.Element | null {
  const { Component } = props;

  return (
    <GeneralAppProviders
      authProvider={AuthProvider}
      config={config}
      ddRumConfig={ddRumConfig}
      trpcProvider={TRPCProvider}
      useConditionalAuthProvider={false}
      analyticsAppName="accounts"
    >
      <Providers>
        <HeadTitle title="Loading..." />
        <FallbackSuspense>
          <Component />
        </FallbackSuspense>
      </Providers>
    </GeneralAppProviders>
  );
}
