import { toGroupedMap } from '@newfront-insurance/core';
import type { RouterQueryOutput } from '@newfront-insurance/data-layer-server';
import { useMemo } from 'react';

import type { SharedRouter } from '../../../bff';

type Account = RouterQueryOutput<SharedRouter, 'accounts.getManyPublicAccounts'>[number];

export function useShouldShowProgramTypeByAccount(accounts: Account[]): (uuid: string) => boolean {
  const map = useMemo(() => {
    const byBusiness = toGroupedMap(accounts, ({ businessUuid }) => businessUuid);
    return new Map(
      accounts.map(({ uuid, businessUuid }) => {
        const accountsInBusiness = byBusiness.get(businessUuid) ?? [];
        return [uuid, accountsInBusiness.length > 1];
      }),
    );
  }, [accounts]);

  return function shouldShowProgramType(uuid: string): boolean {
    return map.get(uuid) ?? false;
  };
}
