import { mutate } from 'swr';
import useMutation from 'use-mutation';

import { usePolicyApi } from '../api/policy-api';
import { routes } from '../bff/routes';
import type {
  GetPolicyByUuidResponse,
  UpdatePolicyLinesOfCoverageBody,
  UpdatePolicyLinesOfCoverageParams,
  UpdatePolicyLinesOfCoverageResponse,
} from '../bff/types';
import { useGetApiCacheKey } from '../utils/hooks/useGetApiCacheKey';

// TODO: should be removed once use-mutation lib types are fixed
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useUpdatePolicyLocs() {
  const policyApi = usePolicyApi();
  const getCacheKey = useGetApiCacheKey();

  return useMutation<
    { params: UpdatePolicyLinesOfCoverageParams; body: UpdatePolicyLinesOfCoverageBody },
    UpdatePolicyLinesOfCoverageResponse | null
  >(({ params, body }) => policyApi.updateLinesOfCoverage(params, body), {
    onSuccess({ input, data }) {
      return mutate(
        getCacheKey(routes.policyByUuid.getUrl(input.params)),
        (current: GetPolicyByUuidResponse): GetPolicyByUuidResponse => {
          if (data) {
            return data;
          }

          return current;
        },
      );
    },
    throwOnFailure: true,
  });
}
