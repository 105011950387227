// Helpers
export { HttpMethod } from './types/http';
export { AuthorizationRoles } from './types/roles';

// Results
export {
  defaultErrorResult,
  isNotAuthorizedResult,
  isNotFoundResult,
  isServiceErrorResult,
  notAuthorizedResult,
  notFoundResult,
  serviceErrorResult,
  badRequestResult,
  isBadErrorResult,
  isDefaultErrorResult,
  isAnyErrorResult,
  isSuccesfulResult,
} from './results';
export { ResultType } from './types/results';
export type {
  DefaultErrorResult,
  NotAuthorizedResult,
  NotFoundResult,
  ServiceErrorResult,
  SuccessfulResult,
  BadRequestResult,
} from './types/results';

// JWT
export type { DecodedIdentityJwt, DecodedJwt } from './types/jwt';

// Error
export type { NextApiErrorDetails, NextApiErrorDto, NextApiErrorLike } from './types/error-dto';
