import { NextApiSwrProvider } from '@newfront-insurance/next-api-swr';
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  authProvider: Provider<AuthProviderContext>;
}

export function AuthNextSwrProvider({ children, authProvider }: Props): JSX.Element {
  const { getAccessTokenCallback } = useProvider(authProvider);

  return <NextApiSwrProvider authToken={getAccessTokenCallback}>{children}</NextApiSwrProvider>;
}
