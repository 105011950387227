import { colors } from './colors';

export const lightTheme = {
  base: {
    background: '#ffffff',
    border: colors.border.default,
  },
  text: {
    default: colors.steel[500],
    label: '#BEC6D0',
    placeholder: '#9BA6B5',
    selected: colors.steel[400],
    title: colors.steel[500],
    eyebrow: colors.peach[500],
  },
  link: {
    primary: {
      default: {
        color: colors.brand[400],
      },
      hover: {
        color: colors.brand[300],
      },
    },
    secondary: {
      default: {
        color: '#262d46',
      },
      hover: {
        color: colors.brand[300],
      },
    },
  },
  modal: {
    background: 'white',
  },
  card: {
    main: {
      outline: {
        background: 'white',
        border: '#BEC6D0',
      },
      noOutline: {
        background: colors.steel[50],
      },
    },
    modal: {
      outline: {
        background: 'white',
        border: '#BEC6D0',
      },
      noOutline: {
        background: colors.steel[50],
      },
    },
    backgroundHover: colors.brand[100],
    shadow: '#E9E9E9',
  },
  fileTypeIcon: {
    background: colors.brand[100],
  },
  icon: {
    color: 'currentColor',
  },
  interactiveElement: {
    selected: '#113874',
    hover: '#113874',
  },
  appBadge: {
    label: '#db0e63',
  },
  // These could be generic interactive elements
  button: {
    primary: {
      default: {
        background: '#113874',
        border: '',
        text: '',
      },
      disabled: {
        background: colors.steel[400],
        border: colors.steel[400],
        text: colors.steel[200],
      },
      focus: {
        background: '',
        border: '',
        text: '',
      },
    },
    secondary: {
      default: {
        background: 'transparent',
        border: '',
        text: '',
      },
      disabled: {
        background: 'transparent',
        border: colors.steel[400],
        text: colors.steel[300],
      },
      hover: {
        background: '',
        border: colors.brand[400],
        text: colors.steel[100],
      },
    },
  },
  popover: {
    background: 'white',
    overlay: {
      background: 'rgba(31, 21, 41, 0.6)',
    },
  },
  menu: {
    text: {
      focus: colors.brand[400],
      default: colors.steel[500],
    },
  },
  notification: {
    error: {
      background: colors.fire[500],
      border: colors.fire[500],
    },
    neutral: {
      background: '#042553',
      border: '#042553',
    },
    success: {
      background: colors.grass[400],
      border: colors.grass[400],
    },
  },
  input: {
    default: {
      text: '#000',
      background: 'white',
      border: '#dbdbdb',
    },
    focus: {
      background: 'white',
      border: colors.brand[400],
      shadow: colors.brand[200],
    },
    disabled: {
      background: colors.steel[300],
    },
    error: {
      shadow: colors.fire[200],
      border: colors.fire[500],
    },
  },
  checkbox: {
    background: '#191E2E',
    icon: '#191E2E',
  },
  segmentedControl: {
    active: {
      background: '',
      text: '',
    },
    inactive: {
      background: '',
      text: '',
    },
  },
  textarea: {
    default: {
      background: '',
      border: '',
    },
    focus: {
      background: '',
      border: '',
    },
  },
  advancedSelectInput: {
    indicatorContainer: '',
    inputHover: '',
  },
  objectSelector: {
    text: '#F8F8F8',
  },
  alert: {
    background: '',
    text: '',
  },
  dropzone: {
    border: '',
    background: '',
    text: '',
  },
};
