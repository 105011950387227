import { colors } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

export const ToolbarButton = styled.a({
  height: 40,
  width: 40,
  textIndent: -9999,
  borderRadius: 40,
  color: colors.steel[400],
  transition: 'all 200ms ease-in-out',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover, &[data-active="true"]': {
    background: colors.steel[100],
    cursor: 'pointer',
  },
  '&:focus': {
    outline: 'none',
    background: colors.steel[100],
  },
});
