/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import { CalendarDate } from '@newfront-insurance/string-formatters';
import classnames from 'classnames';
import styled from 'styled-components';

import { colors } from '../../../theme/colors';
import { Text } from '../../text';

interface Props {
  day: CalendarDate;
  onClick: (date: CalendarDate) => unknown;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCurrentMonth?: boolean;
}

export function DayCell(props: Props): JSX.Element {
  const { day, isSelected, isDisabled, isCurrentMonth, onClick } = props;

  const today = CalendarDate.today();
  const isToday = day.isSameDate(today);

  const classNames = classnames({
    DayCell: true,
    isToday,
    isSelected,
    isDisabled,
    isNotThisMonth: !isCurrentMonth,
  });

  return (
    <StyledDayCell
      className={classNames}
      onClick={() => {
        if (isDisabled) return;
        onClick(day);
      }}
      role="button"
      onKeyDown={(e) => (e.key === ' ' || e.key === 'Enter') && !isDisabled && onClick(day)}
      tabIndex={!isDisabled ? 0 : undefined}
      aria-selected={isSelected}
      aria-disabled={isDisabled}
    >
      <span className="DayCell-inner">
        <Text color="inherit">{day.getDate()}</Text>
      </span>
    </StyledDayCell>
  );
}

export function EmptyDayCell(): JSX.Element {
  return <StyledEmptyDayCell />;
}

const StyledEmptyDayCell = styled.td`
  height: 30px;
  padding: 0 2px;
`;

const StyledDayCell = styled.td`
  height: 30px;
  cursor: pointer;
  padding: 0 2px;
  border-radius: 100px;

  :focus {
    outline: none;
  }

  :focus:not(:active) {
    outline: none;
    box-shadow:
      0 0 0 3px ${colors.brand[300]}50,
      0 0 0 1px ${colors.brand[400]} inset;
  }

  &.isDisabled {
    cursor: not-allowed;
  }

  .DayCell-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 25px;
    width: 100%;
    position: relative;
  }

  &.isToday:not(.isSelected) {
    background-color: ${colors.fire[200]};
    color: ${colors.fire[500]};
  }

  &.isNotThisMonth:not(.isSelected) {
    color: ${colors.steel[300]};
  }

  &:hover {
    background-color: ${colors.steel[100]};
  }

  &.isSelected {
    color: white;
    background-color: ${colors.brand[400]};
  }

  &.isDisabled {
    color: ${colors.steel[300]};
    text-decoration: line-through;
  }

  &.isDisabled:hover {
    background-color: transparent;
  }
`;
