/* eslint-disable react/jsx-props-no-spreading */
import { isNil } from '@newfront-insurance/core';
import kebabCase from 'lodash/kebabCase';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { fontFamilies } from '../../theme';
import { colors } from '../../theme/colors';

type ChangeHandler = (value?: number) => void;

interface Props {
  allowNegative?: boolean;
  decimalScale?: number;
  defaultValue?: string | number;
  disabled?: boolean;
  displayDollarSign?: boolean;
  name: string;
  onChange?: ChangeHandler;
  placeholder?: string;
  testId?: string;
  value?: string | number;
  style?: {
    borderColor?: string;
    borderRadius?: string;
    overflow?: string;
    compact?: boolean;
  };
  isInvalid?: boolean;
  autoComplete?: 'on' | 'off';
}

const StyledCurrencyInput = styled.div<Pick<Props, 'style' | 'isInvalid'>>`
  border: 1px;
  border-style: solid;
  border-color: ${({ style, isInvalid }) => (isInvalid && colors.fire[500]) || style?.borderColor || colors.steel[200]};
  border-radius: ${({ style }) => style?.borderRadius || '3px'};
  color: #000;
  display: flex;
  overflow: ${({ style }) => style?.overflow};

  &:focus,
  &:focus-within,
  &:active:not(:disabled) {
    border-color: ${colors.brand[400]};
  }
`;

const StyledDollarSign = styled.div`
  background-color: ${colors.steel[100]};
  border-right: 1px solid ${colors.steel[75]};
  color: ${colors.steel[300]};
  font-family: ${fontFamilies.body};
  position: relative;
  width: 40px;

  &:after {
    content: '$';
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export function CurrencyInput(props: Props): JSX.Element {
  const {
    allowNegative = false,
    decimalScale = 2,
    defaultValue,
    disabled,
    displayDollarSign = true,
    name,
    onChange,
    placeholder = '0.00',
    testId: propTestId,
    value,
    style,
    isInvalid,
    autoComplete,
    ...otherProps
  } = props;

  const testId = propTestId ?? (name ? kebabCase(name) : undefined);

  const compactClassName = style?.compact ? 'py-[4px] px-[9px] leading-5' : 'py-[8px] px-[12px] leading-[22px]';
  const className = `font-body w-full border-none text-base text-steel-500 placeholder:text-steel-300 disabled:bg-steel-100 focus:outline-none ${compactClassName}`;
  return (
    <StyledCurrencyInput style={style} isInvalid={isInvalid}>
      {displayDollarSign ? <StyledDollarSign /> : null}
      <NumberFormat
        allowNegative={allowNegative}
        decimalScale={decimalScale}
        defaultValue={defaultValue}
        disabled={disabled}
        fixedDecimalScale
        isNumericString
        name={name}
        onValueChange={(valueObject) => {
          if (onChange) {
            onChange(valueObject.floatValue);
          }
        }}
        placeholder={placeholder}
        thousandSeparator
        // react-number-format requires an empty string to be passed to clear the input
        value={isNil(value) ? '' : value}
        data-testid={testId}
        {...otherProps}
        className={className}
        autoComplete={autoComplete}
      />
    </StyledCurrencyInput>
  );
}
