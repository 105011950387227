import * as React from 'react';
import styled from 'styled-components';

import { CompactSizeDetailCard } from './components/variants/compact';
import { DefaultSizeDetailCard } from './components/variants/default';
import type { DetailCardProps } from './types';
import { getDetailsCardBackgroundColor, getDetailsCardColor, getDetailsCardOuterPadding } from './utils';
import { colors } from '../../theme/colors';
import { Card } from '../card';
import { StyledCardRibbon } from '../card-ribbon';
import { Padding } from '../padding';
import { Tooltip } from '../tooltip';

export default function DetailCard(props: DetailCardProps): JSX.Element {
  const {
    actions,
    cardProps,
    hoverProps,
    children,
    defaultExpanded = false,
    eyebrowProps,
    selectableOptions,
    titleColor,
    isHighlighted,
    highlightColor = colors.brand[400],
    isHovering,
    isExpandable: isExpandableProp = true,
    ribbon,
    ribbonVariant,
    testId,
    disabledProps,
  } = props;
  const coalescedDisabledProps = disabledProps || { isDisabled: false };

  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const cardBackgroundColor = cardProps?.backgroundColor || 'white';
  const cardHideBorder = cardProps?.hideBorder || false;
  const cardPadding = cardProps?.padding || 'default';
  const cardVariant = cardProps?.variant || 'default';
  const cardHoverColor =
    hoverProps?.hoverBackgroundColor ||
    getDetailsCardBackgroundColor(cardBackgroundColor, { isHovering, isHighlighted });
  const cardTitleColor = hoverProps?.hoverTitleColor || titleColor;
  const hasActions = actions && actions.length > 0;
  const hasChildren = !!children && React.Children.toArray(children).filter(Boolean).length > 0;
  const hasEyebrow = (eyebrowProps?.text?.length ?? 0) > 0;
  const isExpandable = isExpandableProp && hasChildren;
  const isSelectable = !!selectableOptions;

  const card = (
    <div style={{ '--nfui-card-background-hover': cardHoverColor } as React.CSSProperties} data-testid={testId}>
      <StyledDiv>
        <Card
          hasShadow={false}
          backgroundColor={
            isHovering
              ? cardHoverColor
              : getDetailsCardBackgroundColor(cardBackgroundColor, { isHovering, isHighlighted })
          }
          borderColor={cardHideBorder ? getDetailsCardColor(cardBackgroundColor) : undefined}
          isRounded={cardProps?.hasRadius}
          style={
            isHighlighted
              ? {
                  boxShadow: `inset 6px 0px 0px 0px ${highlightColor}`,
                }
              : undefined
          }
        >
          <Padding size={getDetailsCardOuterPadding(cardPadding)}>
            {cardVariant === 'default' && (
              <DefaultSizeDetailCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                isHighlighted={isHighlighted}
                isSelectable={isSelectable}
                expanded={expanded}
                setExpanded={setExpanded}
                isExpandable={isExpandable}
                hasActions={hasActions ?? false}
                hasEyebrow={hasEyebrow}
                titleColor={cardTitleColor}
                testId={testId}
                disabledProps={coalescedDisabledProps}
              />
            )}
            {cardVariant === 'compact' && (
              <CompactSizeDetailCard
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                isHighlighted={isHighlighted}
                isSelectable={isSelectable}
                expanded={expanded}
                setExpanded={setExpanded}
                isExpandable={isExpandable}
                hasActions={hasActions ?? false}
                hasEyebrow={hasEyebrow}
                titleColor={cardTitleColor}
                testId={testId}
                disabledProps={coalescedDisabledProps}
              />
            )}
            {ribbon && ribbonVariant && <StyledCardRibbon variant={ribbonVariant}>{ribbon}</StyledCardRibbon>}
          </Padding>
        </Card>
      </StyledDiv>
    </div>
  );
  const { isDisabled, disabledMessage, disabledTooltipProps } = coalescedDisabledProps;
  if (isDisabled) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Tooltip message={disabledMessage} {...disabledTooltipProps}>
        <div
          style={{
            position: 'relative',
            cursor: 'not-allowed',
            background: colors.steel[50],
            opacity: '0.4',
          }}
        >
          {card}
        </div>
      </Tooltip>
    );
  }
  return card;
}

const StyledDiv = styled.div`
  &:hover > div {
    background-color: var(--nfui-card-background-hover);
  }
`;
