import { useRouter } from '@newfront-insurance/next-router-provider';

import type { SearchResultRouting } from '../../../../bff/types';

interface Props {
  goToItemResultPage: (routing: SearchResultRouting) => Promise<void>;
}

export function useItemResultRouting(): Props {
  const { router } = useRouter();

  const goToItemResultPage = async (routing: SearchResultRouting): Promise<void> => {
    if (!router.basePath && routing.relative) {
      await router.push(routing.relative);
    } else if (window !== undefined) {
      window.location.href = routing.absolute;
    }
  };

  return { goToItemResultPage };
}
