import type { Employee } from '@newfront-insurance/account-ui';
import { createContext, useCallback, useMemo, useState } from 'react';
import * as React from 'react';

import { useLoggedInUser } from '@/client/hooks/use-logged-in-user';
import { useIsProducer, useCanSelectProducer } from '@/client/hooks/use-permission';
import { useProducersMap } from '@/client/hooks/use-producers-map';

export interface SelectedProducerContextValue {
  setCurrentProducer: (producerUuid: string) => void;
  removeCurrentProducer: () => void;
  currentProducer?: Employee;
  currentProducerUserUuid?: string;
  currentProducerEmployeeUuid?: string;
}

export const SelectedProducerContext = createContext<SelectedProducerContextValue>({
  currentProducer: undefined,
  setCurrentProducer: () => ({}),
  removeCurrentProducer: () => ({}),
});

interface Props {
  children: React.ReactNode;
  localStorageKey: string;
}

export function SelectedProducerProvider({ children, localStorageKey }: Props): JSX.Element {
  const initialProducerUuid = useInitialProducerUuid(localStorageKey);
  const [currentProducerUserUuid, setCurrentProducerUserUuid] = useState<string | undefined>(initialProducerUuid);

  const { producersMap } = useProducersMap();
  const currentProducer = currentProducerUserUuid ? producersMap.get(currentProducerUserUuid) : undefined;
  const currentProducerEmployeeUuid = currentProducerUserUuid
    ? producersMap.get(currentProducerUserUuid)?.uuid
    : undefined;

  const handleSelectProducer = useCallback(
    (uuid: string) => {
      setCurrentProducerUserUuid(uuid);
      localStorage.setItem(localStorageKey, uuid);
    },
    [localStorageKey],
  );

  const handleRemoveProducer = useCallback(() => {
    setCurrentProducerUserUuid(undefined);
    localStorage.removeItem(localStorageKey);
  }, [localStorageKey]);

  const value = useMemo<SelectedProducerContextValue>(
    () => ({
      currentProducer,
      currentProducerUserUuid,
      currentProducerEmployeeUuid,
      setCurrentProducer: handleSelectProducer,
      removeCurrentProducer: handleRemoveProducer,
    }),
    [currentProducer, currentProducerUserUuid, currentProducerEmployeeUuid, handleSelectProducer, handleRemoveProducer],
  );

  return <SelectedProducerContext.Provider value={value}>{children}</SelectedProducerContext.Provider>;
}

function useInitialProducerUuid(localStorageKey: string): string | undefined {
  const canSelectProducer = useCanSelectProducer();
  const isProducer = useIsProducer();
  const user = useLoggedInUser();

  if (canSelectProducer) {
    return localStorage.getItem(localStorageKey) || undefined;
  }

  if (isProducer) {
    return user?.uuid;
  }

  return undefined;
}
