import styled from 'styled-components';

import { colors } from '../../theme/colors';

export type RibbonVariant = 'alert' | 'success' | 'neutral' | 'warning';

const variantColors: { [K in RibbonVariant]: { background: string; border: string; color: string } } = {
  alert: { background: '#FFF6F8', border: colors.fire[500], color: colors.fire[500] },
  success: { background: '#F1FBF7', border: colors.grass[400], color: '#0E5464' },
  neutral: { background: colors.brand[100], border: colors.brand[400], color: colors.brand[400] },
  warning: { background: colors.fire[100], border: colors.peach[500], color: '#B37855' },
};

export const StyledCardRibbon = styled.div(
  ({ variant }: { variant: RibbonVariant }) => `
    background-color: ${variantColors[variant].background};
    color: ${variantColors[variant].color};
    padding: 8px;
    position: relative;
    margin: 16px -20px -16px -16px;
  `,
);
