import type { BadgeType } from '@newfront-insurance/core-ui';
import { Badge, FeaturedCard } from '@newfront-insurance/core-ui';
import { PolicyStatus } from '@newfront-insurance/coverage-api';
import type { ClickHandler } from 'next-router-provider';

import type { Policy } from '../../bff/types';
import renderPolicyName from '../../utils/render-policy-name';
import { PolicyDefinitionList } from '../policy-definition-list';

export interface Props {
  /** The policy to display */
  policy: Policy;
  /** If passed, clicking on the title will navigate to the provided href */
  href?: string;
  /** This is the target attribute that will work together with the href */
  target?: string;
  /** If passed, clicking on the Client Name will navigate to the provided href */
  accountHref?: string;
  /** Function to run when you Click on the Client Name */
  onAccountClick?: () => void;
  onClick?: ClickHandler;
  /** If passed, a button with type='close' button will render and will execute this function */
  onRemove?: (policy: Policy) => void;
  /** If passed, this function will run on card selection */
  onSelect?: (policy: Policy) => void;
  isSelected?: boolean;
  /** If true, a Chevron will render to allow expanding/collapsing the panel */
  isExpandable?: boolean;
  /** Whether the card comes expanded by default */
  defaultExpanded?: boolean;
  selectType?: 'radio' | 'checkbox';
  /** Short will remove the field titles and just displauy the values */
  variant?: 'default' | 'short';
}

export function PolicyCard({
  policy,
  href,
  accountHref,
  onAccountClick,
  selectType = 'checkbox',
  target,
  isExpandable = false,
  defaultExpanded = false,
  isSelected,
  onClick,
  onSelect,
  onRemove,
  variant,
}: Props): JSX.Element {
  const { details, definition, type, status } = policy;
  let badgeType: BadgeType | undefined;
  let badgeText: string | undefined;
  switch (status) {
    case PolicyStatus.ACTIVE:
      badgeText = 'Active';
      badgeType = 'success';
      break;
    case PolicyStatus.CANCELLED:
      badgeText = 'Cancelled';
      badgeType = 'danger';
      break;
    case PolicyStatus.EXPIRED:
      badgeText = 'Expired';
      badgeType = 'info';
      break;
    case PolicyStatus.UPCOMING:
      badgeText = 'Upcoming';
      badgeType = 'warning';
      break;
    default:
      badgeText = undefined;
      badgeType = undefined;
  }

  return (
    <FeaturedCard
      eyebrow="Policy"
      title={renderPolicyName(definition?.readableName ?? type, details.displayName)}
      href={href}
      onClick={onClick}
      target={target}
      content={
        <PolicyDefinitionList
          policy={policy}
          accountHref={accountHref}
          onAccountClick={onAccountClick}
          hideTitle={variant === 'short'}
        />
      }
      badge={badgeType ? <Badge type={badgeType}>{badgeText}</Badge> : null}
      isExpandable={isExpandable}
      defaultExpanded={defaultExpanded}
      onSelect={onSelect ? () => onSelect(policy) : undefined}
      onRemove={onRemove ? () => onRemove(policy) : undefined}
      isSelected={isSelected}
      selectType={selectType}
    />
  );
}
