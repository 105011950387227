import styled from 'styled-components';

import { colors } from '../../theme/colors';

export const TextEyebrow = styled.span<{ color: string }>`
  color: ${(p) => p.color || colors.steel[300]};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
`;
