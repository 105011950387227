import { useNotifications } from '@newfront-insurance/next-notifications';

import { useAdminTRPC } from '../../../../shared/providers/trpc';

export function useCreateAddress() {
  const { useErrorHandledMutation, invalidateQuery } = useAdminTRPC();
  const notifications = useNotifications();

  return useErrorHandledMutation('address.createAddress', {
    onSuccess: async (_, { accountUuid }) => {
      notifications.add({
        type: 'success',
        title: 'Address has been created!',
      });
      await invalidateQuery(['address.getSavedAddress', { accountUuid }]);
    },
    onError: (error) => {
      notifications.addById('use-create-address-hook', {
        type: 'error',
        title: `There was a problem creating this address. ${error.message}`,
      });
    },
  });
}
