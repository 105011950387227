import { Padding, colors, Text, Flexbox, IconLocationPin } from '@newfront-insurance/core-ui';
import * as React from 'react';
import styled from 'styled-components';

import type { Suggestion, Address } from '../../../types';
import { transformSmartyStreetsLookupToAddress } from '../../../utils/format-street-address';
import { HighlightQueryInText } from '../highlight-query-in-text';

interface Props {
  suggestion: Suggestion;
  handleSelectedResult: (address: Address) => unknown;
  onKeyDown: (event: React.KeyboardEvent<Element>) => void;
  addressQuery?: string;
}

export function LookupAddress({ onKeyDown, suggestion, handleSelectedResult, addressQuery }: Props): JSX.Element {
  const address = transformSmartyStreetsLookupToAddress(suggestion);

  const { addressLine1, addressLine2, addressLine3, city, stateOrArea, countryCode, postalCode } = address;

  return (
    <StyledResult
      tabIndex={0}
      className="lookup-result"
      onClick={() => handleSelectedResult(address)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSelectedResult(address as Address);
          return;
        }
        onKeyDown(e);
      }}
      data-lookup-result
    >
      <Text size="small" color="inherit" style={{ lineHeight: 1 }}>
        <Padding y={8} x={16}>
          <Flexbox>
            <Padding right={12}>
              <IconLocationPin size={14} color="#98A6B5" />
            </Padding>
            <div>
              <Padding bottom={4}>
                <HighlightQueryInText text={addressLine1} query={addressQuery} />
                {addressLine2 && <div>{addressLine2}</div>}
                {addressLine3 && <div>{addressLine3}</div>}
              </Padding>
              <div>
                {city}, {stateOrArea}, {postalCode}, {countryCode}
              </div>
            </div>
          </Flexbox>
        </Padding>
      </Text>
    </StyledResult>
  );
}

const StyledResult = styled.div`
  line-height: 1;

  &:hover,
  :focus {
    background-color: ${colors.brand[400]};
    cursor: pointer;
    color: #fff;
  }

  &:hover > span path,
  &:focus > span path {
    fill: #fff;
  }
`;
