import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface ContextValue {
  basePath: string;
}

export const CoverageApiContext = createContext<ContextValue>({ basePath: '' });

interface Props {
  basePath: string;
  children: ReactNode;
}

/**
 * Add this provider to _app to enable the use of all of the hooks.
 */
export function CoverageApiProvider(props: Props): JSX.Element {
  const { basePath, children } = props;
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <CoverageApiContext.Provider value={{ basePath }}>{children}</CoverageApiContext.Provider>;
}

export function useCoverageApiBasePath(): string {
  const { basePath } = useContext(CoverageApiContext);

  return basePath;
}
