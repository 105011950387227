import { hasAnyScope, type AnyAuthProviderContext, hasAllScopes } from '@newfront-insurance/next-auth';
import { useProvider, type Provider } from '@newfront-insurance/react-provision';

export function useHasAnyScope<T extends AnyAuthProviderContext>(authProvider: Provider<T>, scopes: string[]): boolean {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return false;
  }

  return hasAnyScope(user.scopes ?? [], scopes);
}

export function useHasAllScopes<T extends AnyAuthProviderContext>(
  authProvider: Provider<T>,
  scopes: string[],
): boolean {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return false;
  }

  return hasAllScopes(user.scopes ?? [], scopes);
}
