import type { RouterQueryInput } from '@newfront-insurance/data-layer-server';

import { useAdminTRPC } from '../../../../shared/providers/trpc';
import type { SharedRouter } from '../../../../shared/trpc/router';

export function useSavedAddresses(params: RouterQueryInput<SharedRouter, 'address.getSavedAddress'>) {
  const { useQuery } = useAdminTRPC();

  return useQuery(['address.getSavedAddress', params]);
}
