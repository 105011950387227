/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';
import { core } from 'smartystreets-javascript-sdk';

import type { InternationalClient as SmartyStreetsInternationalClient } from '../types';

/**
 * Data stored in RequestContext
 */
export interface SmartyStreetsInternationalContextData {
  internationalStreetClient: SmartyStreetsInternationalClient;
}

/**
 * When the hook is called the SmartyStreets client must be available
 * on this context object.
 */
export const SmartyStreetsInternationalContext = createContext<SmartyStreetsInternationalContextData | null>(null);

/**
 * Provider props
 */
interface ProviderProps {
  apiKey: string;
  children: React.ReactNode;
}

/**
 * Access the SmartyStreets client directly.
 * Throw an error if client is undefined
 */
export function useSmartyStreetsInternationalClient(): SmartyStreetsInternationalContextData {
  const client = useContext(SmartyStreetsInternationalContext);

  if (!client) {
    throw new Error('SmartyStreets client must be set on SmartyStreetsInternationalContext');
  }

  return client;
}

/**
 * Create the SmartyStreets client
 */
export function SmartyStreetsInternationalProvider(props: ProviderProps): JSX.Element {
  const { apiKey, children } = props;

  const client = useMemo(() => {
    const credentials = new core.SharedCredentials(apiKey);
    // eslint-disable-next-line new-cap
    const clientBuilder = new core.ClientBuilder(credentials);

    return {
      internationalStreetClient: clientBuilder.buildInternationalStreetClient(),
    };
  }, [apiKey]);

  return (
    <SmartyStreetsInternationalContext.Provider value={client}>{children}</SmartyStreetsInternationalContext.Provider>
  );
}
