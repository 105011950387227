import { AuthRole } from '@newfront-insurance/auth-api';
import type { Provider } from '@newfront-insurance/react-provision';
import { useProvider } from '@newfront-insurance/react-provision';

import { hasAnyScope, hasAllScopes, hasScope } from '../scopes';
import type { UsePermissionOptions, AnyAuthProviderContext } from '../types';

export function useHasAnyScope<T extends AnyAuthProviderContext>(authProvider: Provider<T>, scopes: string[]): boolean {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return false;
  }

  return hasAnyScope(user.scopes ?? [], scopes);
}

export function useHasAllScopes<T extends AnyAuthProviderContext>(
  authProvider: Provider<T>,
  scopes: string[],
): boolean {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return false;
  }

  return hasAllScopes(user.scopes ?? [], scopes);
}

export function useHasScope<T extends AnyAuthProviderContext>(authProvider: Provider<T>, scope: string): boolean {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return false;
  }

  return hasScope(user.scopes ?? [], scope);
}

export function usePermission<T extends AnyAuthProviderContext>(
  authProvider: Provider<T>,
  opts: UsePermissionOptions,
): boolean {
  const { scopes, hasAll } = opts;
  const hasAnyScopeFn = useHasAnyScope(authProvider, scopes);
  const hasAllScopesFn = useHasAllScopes(authProvider, scopes);

  if (hasAll) {
    return hasAllScopesFn;
  }

  return hasAnyScopeFn;
}

export function useIsAccountManager<T extends AnyAuthProviderContext>(authProvider: Provider<T>): boolean {
  return useHasScope(authProvider, AuthRole.ACCOUNT_MANAGER);
}

export function useIsProducer<T extends AnyAuthProviderContext>(authProvider: Provider<T>): boolean {
  return useHasScope(authProvider, AuthRole.PRODUCER);
}

export function useGetUserScopes<T extends AnyAuthProviderContext>(authProvider: Provider<T>): string[] {
  const { userDetails: user } = useProvider(authProvider);

  if (!user) {
    return [];
  }

  return user.scopes ?? [];
}
