import { format } from 'date-fns';
import type { MouseEvent, ReactNode } from 'react';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { DefinitionListIcon } from './definition-list-icons';
import type { DefinitionListType, DefinitionListItem } from './types';
import type { SpacingValue } from '../../theme';
import { colors } from '../../theme/colors';
import type { HexColor } from '../text';
import { Text } from '../text';
import { TextLink } from '../text-link';
import { Tooltip } from '../tooltip';

export * from './types';
interface Props {
  items: DefinitionListItem[];
  hideTitle?: boolean;
  labelWidth?: string;
  textColor?: HexColor;
  columnSpacing?: SpacingValue;
  labelSpacing?: SpacingValue;
}

interface FormatValueProps {
  type: DefinitionListType;
  value: string | number | ReactNode;
  href?: string;
  onClick?: (e: MouseEvent) => void;
  target?: string;
  dateFormat?: string;
  isDateDue?: boolean;
  tooltipText?: string;
  breakword?: boolean;
  prewrap?: boolean;
}

export function DefinitionList(props: Props): JSX.Element {
  const { items, hideTitle, labelWidth, textColor = 'dark', columnSpacing = 4, labelSpacing = 16 } = props;

  function formatValue(p: FormatValueProps): JSX.Element {
    const { type, value, href, target, onClick, dateFormat, isDateDue, tooltipText, breakword, prewrap } = p;
    let formattedValue;
    switch (type) {
      case 'date': {
        // in the case of date, it's expected to be a string
        const parsedDate = new Date(value as string);
        formattedValue = value ? format(parsedDate, dateFormat || 'MM/dd/yyyy') : '-';
        break;
      }
      case 'currency':
        formattedValue = (
          <NumberFormat
            decimalScale={2}
            displayType="text"
            fixedDecimalScale
            prefix="$"
            thousandSeparator
            value={value as unknown as string}
          />
        );
        break;
      case 'currency-percentage':
        formattedValue = <NumberFormat displayType="text" suffix="%" value={value as number} />;
        break;
      default:
        formattedValue = value;
        break;
    }

    const text = href ? (
      <TextLink target={target || '_blank'} href={href} nowrap={false} weight={600} onClick={onClick}>
        {formattedValue}
      </TextLink>
    ) : (
      <Text color={isDateDue ? colors.fire[600] : textColor} breakword={breakword} prewrap={prewrap}>
        {formattedValue}
      </Text>
    );

    return tooltipText ? <Tooltip message={tooltipText}>{text}</Tooltip> : text;
  }

  function formatRow(item: DefinitionListItem, hide?: boolean, spacing: SpacingValue = 0): JSX.Element {
    const { type, title, value, subValue, href, onClick, target, tooltipText, testId: testIdProp } = item;
    const dateFormat = item.type === 'date' && item.format;
    const isDateDue = item.type === 'followUpDate' && item.isDue;
    const testId = testIdProp ?? (title || value);
    const breakword = 'breakword' in item ? item.breakword : false;
    const prewrap = 'prewrap' in item ? item.prewrap : false;

    return (
      <tr key={title || value?.toString()}>
        <TooltipWrapper hideTitle={hide ?? false} title={title ?? ''}>
          <StyledStaticColumn spacing={spacing} data-testid={testId}>
            <StyledIconTextWrapper width={!hide ? labelWidth : undefined} spacing={hide ? 0 : labelSpacing}>
              {type && (
                <StyledIconWrapper>
                  <DefinitionListIcon type={type} color={isDateDue ? colors.fire[600] : undefined} />
                </StyledIconWrapper>
              )}
              {!hide && title && (
                <Text truncate nowrap color={isDateDue ? colors.fire[600] : colors.steel[400]}>
                  {title}
                </Text>
              )}
            </StyledIconTextWrapper>
          </StyledStaticColumn>

          <StyledFlexColumn spacing={spacing} data-testid={`${testId}-value`}>
            {formatValue({
              type,
              value,
              href,
              onClick,
              target,
              dateFormat: dateFormat || undefined,
              isDateDue,
              tooltipText,
              breakword,
              prewrap,
            })}
            {subValue && (
              <Text color={textColor} truncate={hide}>
                {subValue}
              </Text>
            )}
          </StyledFlexColumn>
        </TooltipWrapper>
      </tr>
    );
  }

  const listItems = items.map((item) => {
    return formatRow(item, hideTitle, columnSpacing);
  });

  return (
    <StyledSpacedTable>
      <tbody>{listItems}</tbody>
    </StyledSpacedTable>
  );
}

const StyledIconWrapper = styled.div`
  margin-right: 8px;
`;

const StyledStaticColumn = styled.td<{ spacing: SpacingValue }>`
  width: auto;
  text-align: left;
  vertical-align: top;
  padding: ${({ spacing }) => `${spacing}px 0`};
`;

const StyledFlexColumn = styled.td<{ spacing: SpacingValue }>`
  text-align: left;
  vertical-align: top;
  width: 100%;
  padding: ${({ spacing }) => `${spacing}px 0`};
`;

const StyledSpacedTable = styled.table`
  border-spacing: 0;
`;

const StyledIconTextWrapper = styled.div<{ width?: string; spacing?: SpacingValue }>`
  display: flex;
  align-items: center;
  margin-right: ${({ spacing }) => `${spacing}px`};
  width: ${({ width }) => width || 'auto'};
`;

function TooltipWrapper({
  children,
  hideTitle,
  title,
}: {
  children: React.ReactNode;
  hideTitle: boolean;
  title: string;
}): JSX.Element {
  return hideTitle ? (
    <Tooltip text={title} positionTip="bottom">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
