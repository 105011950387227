/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';
import { core } from 'smartystreets-javascript-sdk';

import type { Client as SmartyStreetsUSClient } from '../types';

/**
 * Data stored in RequestContext
 */
export interface SmartyStreetsContextData {
  usAutocompleteProClient: SmartyStreetsUSClient;
}

/**
 * When the hook is called the SmartyStreets client must be available
 * on this context object.
 */
export const SmartyStreetsContext = createContext<SmartyStreetsContextData | null>(null);

/**
 * Provider props
 */
interface ProviderProps {
  apiKey: string;
  children: React.ReactNode;
}

/**
 * Access the SmartyStreets client directly.
 * Throw an error if client is undefined
 */
export function useSmartyStreetsClient(): SmartyStreetsContextData {
  const client = useContext(SmartyStreetsContext);

  if (!client) {
    throw new Error('SmartyStreets client must be set on SmartyStreetsContext');
  }

  return client;
}

/**
 * Create the SmartyStreets client
 */
export function SmartyStreetsProvider(props: ProviderProps): JSX.Element {
  const { apiKey, children } = props;

  const client = useMemo(() => {
    const credentials = new core.SharedCredentials(apiKey);
    // eslint-disable-next-line new-cap
    const clientBuilder = new core.ClientBuilder(credentials);

    return {
      usAutocompleteProClient: clientBuilder.buildUsAutocompleteProClient(),
    };
  }, [apiKey]);

  return <SmartyStreetsContext.Provider value={client}>{children}</SmartyStreetsContext.Provider>;
}
