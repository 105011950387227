/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { MenuContainer } from './container';
import { MenuItemDivider } from './divider';
import { MenuItemLink } from './link';
import { MenuItemTitle } from './title';
import { Tooltip } from '../../tooltip';
import type { MenuItem } from '../types';
import { MenuItemType } from '../types';

interface Props {
  items?: MenuItem[];
  children?: React.ReactNode;
  onItemClick?: () => unknown;
  testId?: string;
}

export function Menu(props: Props): JSX.Element {
  const { items, onItemClick, children, testId } = props;

  if (!children && !items) {
    throw new Error('Item or Children must be used.');
  }

  if (children) {
    return <MenuContainer onItemClick={onItemClick}>{children}</MenuContainer>;
  }

  return (
    <MenuContainer onItemClick={onItemClick} testId={testId}>
      {items?.map((item, index) => {
        if (item.type === MenuItemType.DIVIDER) {
          return <MenuItemDivider key={`divider-${index}`} />;
        }
        if (item.type === MenuItemType.TITLE) {
          const { title } = item;
          return <MenuItemTitle key={`${title}-${index}`}>{title}</MenuItemTitle>;
        }
        if (item.type === MenuItemType.LINK) {
          return (
            <React.Fragment key={`${item.href ?? '-'}-${item.label}-${index}`}>
              <Tooltip message={item.tooltip} positionTip={item.tooltipPosition}>
                <MenuItemLink
                  href={item.href || '#'}
                  isDisabled={item.isDisabled}
                  onClick={item.onClick}
                  target={item.target}
                  testId={item.testId}
                >
                  {item.label}
                </MenuItemLink>
              </Tooltip>
            </React.Fragment>
          );
        }
        throw new Error('Invalid MenuItemType');
      })}
    </MenuContainer>
  );
}
