import kebabCase from 'lodash/kebabCase';
import type { CSSProperties } from 'react';

import { CheckboxInput } from '../checkbox-input';
import { Flexbox } from '../flexbox';
import { Padding } from '../padding';
import type { HexColor, TextColors } from '../text';
import { Text } from '../text';

interface Props {
  id?: string;
  label: string;
  isActive?: boolean;
  onClick?: (isActive: boolean) => unknown;
  disabled?: boolean;
  count?: number;
  testId?: string;
  style?: CSSProperties;
  color?: TextColors | HexColor;
  disabledColor?: TextColors | HexColor;
}

export function CheckboxRow({
  id,
  label,
  isActive,
  disabled,
  onClick,
  count,
  style = {},
  testId: propTestId,
  color = 'default',
  disabledColor = 'light',
}: Props): JSX.Element {
  const testId = propTestId ?? id ?? kebabCase(label);

  return (
    <Flexbox style={style}>
      <CheckboxInput
        id={id}
        testId={testId}
        checked={isActive}
        onChange={(e) => {
          onClick?.(e.target.checked);
        }}
        disabled={disabled}
      />
      <Padding left={8} right={4}>
        <label htmlFor={id}>
          <Text
            style={{
              pointer: 'cursor',
            }}
            color={disabled ? disabledColor : color}
          >
            {label}
          </Text>
        </label>
      </Padding>
      {count ? <Text color="light">({count})</Text> : null}
    </Flexbox>
  );
}
