import type { SavedAddress } from '@newfront-insurance/address-api';

import { AddressInput } from '../../internals/components/address-input';
import { SmartyStreetsProvider } from '../../providers/smartystreets';
import { SmartyStreetsInternationalProvider } from '../../providers/smartystreets-international';
import { SMARTY_STREETS_AUTOCOMPLETE_PUBLIC_KEY } from '../../utils/smarty-street-public-key';

interface Props {
  accountUuid: string;
  onChange: (address?: SavedAddress) => unknown;
  selectedAddress?: SavedAddress;
  disabled?: boolean;
  width?: number | string;
  disableCloseCustomAddressModalOnEscape?: boolean;
}

export function AddressSelector({
  accountUuid,
  onChange,
  selectedAddress,
  disabled,
  width,
  disableCloseCustomAddressModalOnEscape,
}: Props): JSX.Element {
  return (
    <SmartyStreetsInternationalProvider apiKey={SMARTY_STREETS_AUTOCOMPLETE_PUBLIC_KEY}>
      <SmartyStreetsProvider apiKey={SMARTY_STREETS_AUTOCOMPLETE_PUBLIC_KEY}>
        <AddressInput
          accountUuid={accountUuid}
          selectedAddress={selectedAddress}
          onChange={onChange}
          disabled={disabled}
          width={width}
          disableCloseCustomAddressModalOnEscape={disableCloseCustomAddressModalOnEscape}
        />
      </SmartyStreetsProvider>
    </SmartyStreetsInternationalProvider>
  );
}
