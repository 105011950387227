import type { AccountRoleMapping as ApiAccountRoleMapping } from '@newfront-insurance/account-api';
import omit from 'lodash/omit';

import { formatDate } from './dates';
import type { AccountRoleMapping } from '../types';

export function formatAccountRoleMapping(accountRoleMapping: ApiAccountRoleMapping): AccountRoleMapping {
  return {
    ...omit(accountRoleMapping, 'hideFromClientView'),
    endsAt: formatDate(accountRoleMapping.endsAt),
    startsAt: formatDate(accountRoleMapping.startsAt),
  };
}
