import type { SyntheticEvent } from 'react';

export enum MenuItemType {
  LINK = 'link',
  DIVIDER = 'divider',
  TITLE = 'title',
}

export interface MenuItemLinkType {
  type: MenuItemType.LINK;
  label: string;
  href?: string;
  isDisabled?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  target?: string;
  testId?: string;
  tooltip?: string;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
}

export interface MenuItemDividerType {
  type: MenuItemType.DIVIDER;
}

export interface MenuItemTitleType {
  type: MenuItemType.TITLE;
  title: string;
}

export type MenuItem = MenuItemLinkType | MenuItemDividerType | MenuItemTitleType;
