import Highlighter from 'react-highlight-words';

interface Props {
  text: string;
  query?: string;
}

export function HighlightQueryInText({ text, query }: Props): JSX.Element {
  if (!query) return <span>{text}</span>;
  const equalisedText = text.toLowerCase();
  const equalisedQuery = query?.toLowerCase();

  return (
    <Highlighter
      highlightClassName={equalisedText.split(' ').join('-')}
      searchWords={equalisedQuery.split(' ')}
      autoEscape
      textToHighlight={text}
    />
  );
}
