import { useReducer } from 'react';

import { reducer } from './reducer';
import { LineOfCoverageEditorActionType } from './types';
import type { LineOfCoverageTypeDetails } from '../../..';

export interface SelectedLinesOfCoverageHook {
  selectedLineOfCoverageTypeDetails: LineOfCoverageTypeDetails[];
  isDeleting: boolean;
  removeLoc: (index: number) => void;
  addLoc: (lineOfCoverage: LineOfCoverageTypeDetails) => void;
  updateLocDisplayName: (index: number, displayName: string) => void;
}

export function useSelectedLinesOfCoverage(
  initialLineOfCoverageTypeDetails: LineOfCoverageTypeDetails[],
): SelectedLinesOfCoverageHook {
  const [state, dispatch] = useReducer(reducer, {
    selectedLineOfCoverageTypeDetails: initialLineOfCoverageTypeDetails,
    isDeletingCoverage: false,
  });

  const removeLoc = (index: number, isDeleting = false): void => {
    dispatch({
      type: LineOfCoverageEditorActionType.REMOVE_SELECTED_LOC,
      payload: {
        index,
        isDeleting,
      },
    });
  };

  const addLoc = (lineOfCoverage: LineOfCoverageTypeDetails): void => {
    dispatch({
      type: LineOfCoverageEditorActionType.ADD_SELECTED_LOC,
      payload: {
        lineOfCoverage,
      },
    });
  };

  const updateLocDisplayName = (index: number, displayName: string): void => {
    dispatch({
      type: LineOfCoverageEditorActionType.UPDATE_DISPLAY_NAME,
      payload: {
        index,
        displayName,
      },
    });
  };

  return {
    selectedLineOfCoverageTypeDetails: state.selectedLineOfCoverageTypeDetails,
    isDeleting: state.isDeletingCoverage,
    removeLoc,
    addLoc,
    updateLocDisplayName,
  };
}
