import { IconBugReport, Tooltip } from '@newfront-insurance/core-ui';

import { ToolbarButton } from './toolbar-button';

const SERVICE_DESK_URL = 'https://newfront.atlassian.net/servicedesk/customer/portal/10/group/39/create/149';

export function ReportBugButton(): JSX.Element {
  return (
    <Tooltip message="Report a Bug" positionTip="bottom" offset="5px">
      <ToolbarButton tabIndex={0} title="Report a Bug" onClick={() => window.open(SERVICE_DESK_URL, '_blank')}>
        <IconBugReport />
      </ToolbarButton>
    </Tooltip>
  );
}
