import { IconSearch2, Portal, Tooltip, useKeyEvent } from '@newfront-insurance/core-ui';
import { useState } from 'react';

import { ToolbarButton } from './toolbar-button';
import { GlobalSearch } from '../../search-popover';
import { ACTIVE_GLOBAL_SEARCH_KEY } from '../lib/consts';

export function SearchButton(): JSX.Element {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  useKeyEvent(
    ACTIVE_GLOBAL_SEARCH_KEY,
    () => {
      const { activeElement } = document;
      if (activeElement?.tagName !== 'BODY') {
        return;
      }
      setIsSearchActive(true);
    },
    [],
  );

  return (
    <>
      <Tooltip message="Search" command="/" positionTip="bottom" offset="5px">
        <ToolbarButton
          tabIndex={0}
          title="Search"
          data-active={isSearchActive}
          onClick={async (e) => {
            e.preventDefault();
            setIsSearchActive((isActive) => !isActive);
          }}
        >
          <IconSearch2 />
        </ToolbarButton>
      </Tooltip>
      {isSearchActive && (
        <Portal name="global-search">
          <GlobalSearch setIsSearchActive={setIsSearchActive} />
        </Portal>
      )}
    </>
  );
}
