import classNames from 'classnames';
import { forwardRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../theme/colors';
import { MiniIconPerson } from '../mini-icons';
import { Text } from '../text';

export interface AvatarProps {
  imageUrl?: string;
  initials?: string;
  sizeInPixels: number;
  badgeText?: string;
  badgeColor?: string;
  disabled?: boolean;
  onClick?: () => unknown;
  isActive?: boolean;
}

interface AvatarBadgeProps {
  badgeText?: string;
  badgeColor?: string;
}

function AvatarBadge({ badgeColor = colors.steel[500], badgeText }: AvatarBadgeProps): JSX.Element | null {
  if (!badgeText) {
    return null;
  }
  return (
    <StyledMiniBadge className="employee-mini-badge" backgroundColor={badgeColor}>
      {badgeText}
    </StyledMiniBadge>
  );
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref): JSX.Element => {
  const {
    imageUrl,
    sizeInPixels,
    badgeText,
    badgeColor = colors.steel[500],
    disabled = false,
    onClick,
    isActive = false,
    initials,
  } = props;
  const [hasError, setError] = useState(false);

  const containerClass = classNames({
    isInteractive: !!onClick && !disabled,
    isActive: isActive && !disabled,
  });

  // Show the fallback
  if (!imageUrl || hasError) {
    return (
      <StyledContainer ref={ref} sizeInPixels={sizeInPixels} className={containerClass} onClick={onClick}>
        <StyledFallback height={sizeInPixels} width={sizeInPixels}>
          {initials ? (
            <Text weight={600} color="white" textAlign="center">
              {initials}
            </Text>
          ) : (
            <MiniIconPerson color={colors.steel[200]} />
          )}
        </StyledFallback>
        <AvatarBadge badgeColor={badgeColor} badgeText={badgeText} />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer ref={ref} sizeInPixels={sizeInPixels} className={containerClass} onClick={onClick}>
      <AvatarImage
        alt="photo"
        height={sizeInPixels}
        width={sizeInPixels}
        src={`${imageUrl}`}
        onError={() => setError(true)}
      />
      <AvatarBadge badgeColor={badgeColor} badgeText={badgeText} />
    </StyledContainer>
  );
});

const AvatarImage = styled.img`
  border-radius: 50%;
  height: inherit;
  object-fit: cover;
  max-width: inherit;
`;

const StyledContainer = styled.div<{ sizeInPixels: number }>`
  position: relative;
  height: ${({ sizeInPixels }) => sizeInPixels}px;
  width: ${({ sizeInPixels }) => sizeInPixels}px;
  border-radius: 50%;
  transition: 200ms box-shadow ease-in-out;

  &.isInteractive {
    cursor: pointer;
  }

  &.isInteractive:hover,
  &.isActive {
    box-shadow: 0 0 0 2px ${colors.brand[300]};
  }
`;

const StyledMiniBadge = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: row;
  padding: 1px 2px;
  border-radius: 2px;
  color: #fff;
  position: absolute;
  height: 10px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  right: 0;
  bottom: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 9px;
  line-height: 8px;
  text-align: center;
  text-transform: uppercase;
`;

const StyledFallback = styled.div<{
  height: number;
  width: number;
  disabled?: boolean;
}>`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  border-radius: 50%;
  background-color: ${colors.steel[300]};
  display: flex;
  align-items: center;
  justify-content: center;
`;
