import type { Option } from '@newfront-insurance/core-ui';
import { AdvancedSelectInput } from '@newfront-insurance/core-ui';
import type { PolicyType } from '@newfront-insurance/coverage-api';

import { useDefinitionsHelpers } from '../../hooks/definitions';

export interface Props {
  onChange: (value: PolicyType) => unknown;
  placeholder?: string;
  value?: PolicyType;
  /** Setting it to true will allow the user to clear the selection with an X that shows up at the far right */
  isClearable?: boolean;
}

export function PolicyTypeSelectInput({ placeholder, value, onChange, isClearable }: Props): JSX.Element {
  const { policyTypes, renderPolicyType } = useDefinitionsHelpers();

  const options: Option[] = policyTypes.map((policyType: PolicyType) => ({
    label: renderPolicyType(policyType),
    value: policyType,
  }));

  return (
    <AdvancedSelectInput
      isClearable={isClearable}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={(newValue) => onChange(newValue as PolicyType)}
    />
  );
}
