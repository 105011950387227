import { datadogRum } from '@newfront-insurance/dd-rum';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

import { useAdminTRPC } from '../../../../../shared/providers/trpc';
import type { ErrorResponse, SearchResult, SearchSuggestion } from '../../../../bff/types/search-engine';

interface ReturnProps {
  handleSearch: (value: string) => Promise<void>;
  handleReset: () => void;
  handleSelectSuggestion: (suggestion: string) => Promise<void>;
  isLoading: boolean;
  results?: SearchResult[];
  suggestions?: SearchSuggestion[];
  error?: ErrorResponse;
}

export function useGlobalSearch(): ReturnProps {
  const { useQuery } = useAdminTRPC();
  const [queryValue, setQueryValue] = useState<string | null>(null);
  const { data, isLoading } = useQuery(['globalSearch.search', { query: queryValue ?? '' }], {
    enabled: true,
  });
  const [withSuggestions, setWithSuggestions] = useState<boolean>(true);

  const threshold = 128;
  const [isLengthAboveThreshold, setIsLengthAboveThreshold] = useState<boolean>(false);

  const handleSearch = useCallback(
    async (value: string, withSuggestionsOverride = true): Promise<void> => {
      if (!value) {
        return;
      }

      setWithSuggestions((currentSuggestions) => {
        if (currentSuggestions === withSuggestionsOverride) {
          return currentSuggestions;
        }

        return withSuggestionsOverride;
      });

      const debouncedDatadogAction = debounce(() => {
        datadogRum.addAction('Global opensearch performed', {});
      }, 500);
      debouncedDatadogAction();

      const isCurrentQueryLengthAboveThreshold = value.length > threshold;
      setIsLengthAboveThreshold(isCurrentQueryLengthAboveThreshold);

      // only update the query if it is not above the threshold, to avoid hitting the backend unnecessarily
      if (!isCurrentQueryLengthAboveThreshold) {
        setQueryValue(value);
      }
    },
    [setQueryValue, setWithSuggestions, setIsLengthAboveThreshold],
  );

  const handleReset = (): void => {
    setQueryValue('');
  };

  const handleSelectSuggestion = async (suggestion: string): Promise<void> => {
    await handleSearch(suggestion, false);
  };

  return {
    handleSearch,
    handleReset,
    handleSelectSuggestion,
    isLoading,
    results: queryValue && !isLengthAboveThreshold ? data?.searchResults : undefined,
    suggestions: queryValue && withSuggestions && !isLengthAboveThreshold ? data?.searchSuggestions : undefined,
    error: isLengthAboveThreshold
      ? {
          statusText: `Unsupported request type`,
          data: {
            errorMessage: `Your query was too long, please be more specific. (max ${threshold} characters).`,
          },
        }
      : undefined,
  };
}
