import urlcat from 'urlcat';

import type { GetAccountByUuidParams } from './types';

interface GetUrlProps<Params> {
  params?: Params;
  basePath?: string;
}

function route<Params>(path: string) {
  return ({ params, basePath }: GetUrlProps<Params>): string => `${basePath || ''}${urlcat(path, params || {})}`;
}

interface Route<Params = unknown> {
  path: string;
  getUrl: ({ params, basePath }: GetUrlProps<Params>) => string;
}

function makeRoute<Params = object>(path: string): Route<Params> {
  return {
    path,
    getUrl: route<Params>(path),
  };
}

export const routes = {
  getAccountByUuid: makeRoute<GetAccountByUuidParams>('/account/:accountUuid'),
  getAccountManagers: makeRoute(`/account-managers`),
  getOperationsEmployees: makeRoute('/operations-employees'),
  getProducers: makeRoute(`/producers`),
  searchEngine: makeRoute('/search-engine'),
};
