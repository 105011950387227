import type { UserProfile } from '@newfront-insurance/next-auth';
import { useProvider } from '@newfront-insurance/react-provision';

import { AuthProvider } from '@/client/providers/auth';

export function useLoggedInUser(): UserProfile {
  const { userDetails } = useProvider(AuthProvider);
  if (!userDetails) throw new Error('Not logged in');

  return userDetails;
}
