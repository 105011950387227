import Head from 'next/head';

interface Props {
  title: string;
}

export function HeadTitle({ title }: Props): JSX.Element {
  return (
    <Head>
      <title>{title}</title>
    </Head>
  );
}
