/* eslint-disable react/no-unstable-nested-components */
import type { DateInputFormat } from '@newfront-insurance/string-formatters';
import {
  CalendarDate,
  getLocalDateInputFormat,
  DateInputFormatPlaceholder,
} from '@newfront-insurance/string-formatters';
import kebabCase from 'lodash/kebabCase';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from '../../hooks/click-outside';
import { useKeyEvent } from '../../hooks/key-event';
import { CalendarDatePicker } from '../calendar-date-picker';
import type { Size } from '../date-picker-input/types';
import { Input } from '../input';
import { Padding } from '../padding';
import { ControlledPopper } from '../popper';

const EARLIEST_POSSIBLE_DATE = new CalendarDate('1900-01-01');

export interface CalendarDatePickerProps {
  autoComplete?: boolean;
  clearOnDisabled?: boolean;
  disabled?: boolean;
  disableWeekends?: boolean;
  fieldId?: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  maxDate?: CalendarDate;
  minDate?: CalendarDate;
  name: string;
  onChange: (value?: CalendarDate) => unknown;
  testId?: string;
  value?: CalendarDate;
  aiSuggestionValue?: CalendarDate;
  format?: DateInputFormat;
  size?: Size;
}

export function CalendarDatePickerInput(props: CalendarDatePickerProps): JSX.Element {
  const {
    autoComplete = false,
    clearOnDisabled,
    disableWeekends,
    disabled,
    value,
    isInvalid,
    isRequired = false,
    maxDate,
    minDate = EARLIEST_POSSIBLE_DATE, // Default to earliest possible date
    name,
    onChange,
    fieldId,
    testId: propTestId,
    format = getLocalDateInputFormat(),
    aiSuggestionValue,
    size,
  } = props;

  const testId = propTestId ?? kebabCase(fieldId ?? name);

  const [isOpen, setVisible] = useState(false);
  const popoverRef = useRef<HTMLElement | null>(null);
  const triggerRef = useRef<HTMLElement | null>(null);

  // using popover as controlled component, hence we must handle onclick outside event
  useOnClickOutside(popoverRef, (e) => {
    if (isOpen && e.target !== triggerRef.current) {
      setVisible(false);
    }
  });

  // This value will only be used if there is an initialValue. We'll use this to store the value of the input internally
  // and call onChange whenever it becomes a valid date.
  const [inputValue, setInputValue] = useState<string>(() => formatValue(format, value));

  // Hide if they tab out. We don't use the input blur because
  // it loses focus when you interact with the date picker popover.
  useKeyEvent('Tab', () => setVisible(false), []);
  useKeyEvent('Enter', () => setVisible(false), []);

  // Check valid date based on min/max dates and weekends.
  const isValidDate = useCallback(
    (date: CalendarDate) => {
      return date.check({
        allowWeekends: !disableWeekends,
        maxDate,
        minDate,
      });
    },
    [disableWeekends, maxDate, minDate],
  );

  // Update the input value to match the current value of the date picker.
  const resetInputValue = useCallback(() => {
    setInputValue(formatValue(format, value));
  }, [value, format]);

  // Keep the internal input value in sync whenever the value changes.
  // This allows us to reset the value of the input externally.
  useEffect(() => {
    resetInputValue();
  }, [resetInputValue]);

  // When the input is blurred we'll parse the value from the input and emit
  // a change event. If the value is invalid, this will return a null value
  // and clear the field. This prevents invalid values from being able to be set
  // and allows us to keep the internal value in sync.
  const onBlur = useCallback(
    (inputStr: string | undefined) => {
      if (inputStr === '') {
        onChange(undefined);
        return;
      }

      const newDate = CalendarDate.fromString(inputStr ?? '', format);
      // If unable to parse a valid date from the input value.
      if (!newDate || !isValidDate(newDate)) {
        // Reset back to the previous value.
        resetInputValue();
      } else {
        // The new value will trigger the input value to be updated.
        onChange(newDate);
      }
    },
    [format, isValidDate, onChange, resetInputValue],
  );

  return (
    <ControlledPopper
      offsetDistance={4}
      isOpen={isOpen}
      onToggle={() => setVisible(false)}
      placement="bottom-start"
      trigger={({ triggerRef: currentTriggerRef }) => {
        triggerRef.current = currentTriggerRef?.current;

        return (
          <Input
            withAiSuggestion={aiSuggestionValue && value?.isSameDate(aiSuggestionValue)}
            disabled={disabled}
            name={name}
            innerRef={currentTriggerRef}
            fieldId={fieldId}
            testId={testId}
            onChange={setInputValue}
            onFocus={() => setVisible(true)}
            onKeyDown={(event) => {
              // const el = event.target as HTMLInputElement;
              if (event.key === 'Enter') {
                (event.target as HTMLInputElement).blur();
              }
            }}
            onBlur={onBlur}
            placeholder={DateInputFormatPlaceholder[format]}
            type="date"
            value={clearOnDisabled && disabled ? '' : inputValue}
            isRequired={isRequired}
            autoComplete={autoComplete ? 'on' : 'off'}
            isInvalid={isInvalid}
            size={size}
          />
        );
      }}
    >
      <Padding size={8}>
        <div
          ref={(ref): void => {
            popoverRef.current = ref;
          }}
        >
          <CalendarDatePicker
            value={value}
            validate={isValidDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(newDate) => {
              setVisible(false);

              // If it is not a valid date, just skip
              if (newDate && !isValidDate(newDate)) return;

              // No existing value and we don't want to trigger a change
              if (!value && !newDate) return;

              // Same calendar date value and we don't want to trigger a change.
              if (newDate && value && value.isSameDate(newDate)) return;

              onChange(newDate);
            }}
          />
        </div>
      </Padding>
    </ControlledPopper>
  );
}

function formatValue(format: DateInputFormat, date?: CalendarDate): string {
  if (date) {
    return date.inputFormat(format);
  }
  return '';
}
