import { toRecord } from '@newfront-insurance/core';
import type {
  LineOfCoverageTypeDefinition,
  LineOfCoverageType,
  PolicyTypeDefinition,
  PolicyType,
} from '@newfront-insurance/coverage-api';
import type { NextApiError } from '@newfront-insurance/next-api-error';
import type { NextApiSubscription, ConfigInterface } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext, useMemo, useCallback } from 'react';

import { routes } from '../bff/routes';
import type { GetDefinitionsParams, GetDefinitionsResponse } from '../bff/types';
import { CoverageApiContext } from '../provider';

function useDefinitions(
  // eslint-disable-next-line default-param-last
  params: GetDefinitionsParams = {},
  config?: ConfigInterface,
): NextApiSubscription<GetDefinitionsResponse | null> {
  const { basePath } = useContext(CoverageApiContext);
  return useNextApiSubscription(routes.definitions.getUrl(params, basePath), config);
}

export interface DefinitionsHelpers {
  loading: boolean;
  error?: NextApiError;
  lineOfCoverageTypes: LineOfCoverageType[];
  lineOfCoverageTypeDefinitions: Record<LineOfCoverageType, LineOfCoverageTypeDefinition>;
  policyTypes: PolicyType[];
  policyTypeDefinitions: Record<PolicyType, PolicyTypeDefinition>;
  renderPolicyType: (policyType: PolicyType) => string;
  renderLineOfCoverageType: (lineOfCoverageType: LineOfCoverageType) => string;
}

// eslint-disable-next-line default-param-last
export function useDefinitionsHelpers(params: GetDefinitionsParams = {}, config?: ConfigInterface): DefinitionsHelpers {
  const { data, error } = useDefinitions(params, config);

  const locDefinitionsArray: LineOfCoverageTypeDefinition[] = useMemo(() => data?.linesOfCoverage ?? [], [data]);

  const policyTypeDefinitions: Record<PolicyType, PolicyTypeDefinition> = useMemo(
    () => data?.policyTypes ?? {},
    [data],
  );

  const lineOfCoverageTypes: LineOfCoverageType[] = useMemo(
    () => locDefinitionsArray.map(({ id }) => id),
    [locDefinitionsArray],
  );

  const lineOfCoverageTypeDefinitions: Record<LineOfCoverageType, LineOfCoverageTypeDefinition> = useMemo(
    () => toRecord(locDefinitionsArray || [], ({ id }) => id),
    [locDefinitionsArray],
  );

  const policyTypes: PolicyType[] = useMemo(() => Object.keys(policyTypeDefinitions), [policyTypeDefinitions]);

  const renderPolicyType = useCallback(
    (type: PolicyType): string => policyTypeDefinitions[type]?.readableName || type,
    [policyTypeDefinitions],
  );

  const renderLineOfCoverageType = useCallback(
    (type: LineOfCoverageType) => lineOfCoverageTypeDefinitions[type]?.readableName || type,
    [lineOfCoverageTypeDefinitions],
  );

  return {
    loading: !data && !error,
    error,
    lineOfCoverageTypes,
    lineOfCoverageTypeDefinitions,
    policyTypes,
    policyTypeDefinitions,
    renderPolicyType,
    renderLineOfCoverageType,
  };
}
