import {
  HTTPError,
  createTRPCClient,
  getPreferredErrorMessage,
  useAnalytics,
  useClientQueryHooks,
} from '@newfront-insurance/data-layer-client';
import { useRouter } from '@newfront-insurance/next-router-provider';
import { createProvider, useProvider } from '@newfront-insurance/react-provision';

import type { AppRouter } from '@/api/trpc';
import { AuthProvider } from '@/client/providers/auth';

const TIMEOUT_SECONDS = 60;

export const TRPCProvider = createProvider(() => {
  const { getAccessTokenCallback } = useProvider(AuthProvider);
  const { router } = useRouter();
  const analytics = useAnalytics();

  const trpcClient = createTRPCClient<AppRouter>({
    url: `${router.basePath}/api/trpc`,
    getToken: getAccessTokenCallback,
    timeoutInMilliseconds: TIMEOUT_SECONDS * 1000,
    onError: (error) => {
      const message = getPreferredErrorMessage(error);

      if (error.cause instanceof HTTPError) {
        analytics.track({
          event: 'client_request_error',
          properties: {
            statusCode: error.cause.response.status,
            message,
            bffUrl: error.cause.response.url,
            url: window.location.href,
            accountUuid: router.query.accountUuid,
          },
        });
      }
    },
  });

  return useClientQueryHooks(trpcClient);
});
