import urlcat from 'urlcat';

import type {
  GetAccountByUuidParams,
  GetPublicAccountsParams,
  GetAccountsParams,
  GetPublicAccountByUuidParams,
} from './types';

interface GetUrlProps<Params> {
  params?: Params;
  basePath?: string;
}

function route<Params>(path: string) {
  return ({ params, basePath }: GetUrlProps<Params>): string => `${basePath || ''}${urlcat(path, params || {})}`;
}

interface Route<Params = unknown> {
  path: string;
  getUrl: ({ params, basePath }: GetUrlProps<Params>) => string;
}

function makeRoute<Params = object>(path: string): Route<Params> {
  return {
    path,
    getUrl: route<Params>(path),
  };
}

export const routes = {
  getAccount: makeRoute<GetAccountByUuidParams>('/accounts/:accountUuid'),
  getAccountManagers: makeRoute(`/employees/account-managers`),
  getAccounts: makeRoute<GetAccountsParams>('/accounts'),
  getClientServiceAssociates: makeRoute('/employees/client-service-associates'),
  getProducers: makeRoute(`/employees/producers`),
  getPublicAccount: makeRoute<GetPublicAccountByUuidParams>('/accounts/:accountUuid/public'),
  getPublicAccounts: makeRoute<GetPublicAccountsParams>('/accounts/public'),
};
