/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import type { UseComboboxGetItemPropsOptions, UseComboboxGetToggleButtonPropsOptions } from 'downshift';
import type {
  HTMLAttributes,
  ReactNode,
  RefObject,
  SyntheticEvent,
  PropsWithChildren,
  ButtonHTMLAttributes,
} from 'react';
import { forwardRef } from 'react';
import * as React from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import styled from 'styled-components';

import type { BaseSelectorProps, DisabledProps } from './types';
import { fontFamilies, fontSizes } from '../../../theme';
import { colors } from '../../../theme/colors';
import { Flexbox } from '../../flexbox';
import { IconButton } from '../../icon-button';
import { IconArrowDropDown, IconSearch2 } from '../../icons';
import { Padding } from '../../padding';
import { Popper } from '../../popper';
import { Spacing } from '../../spacing';
import { CircleSpinnerInline } from '../../spinner';
import { Text } from '../../text';
import { Tooltip } from '../../tooltip';

interface UnfocusedInputProps<T>
  extends Pick<
    BaseSelectorProps<T>,
    'isLoading' | 'emptyPlaceholder' | 'placeholder' | 'itemToString' | 'isClearable' | 'renderInputToolbar' | 'testId'
  > {
  getToggleButtonProps: (options: UseComboboxGetToggleButtonPropsOptions) => object;
  hasValue?: boolean;
  renderSelectedItem?: () => ReactNode;
  renderSelectedItemHover?: () => ReactNode;
  renderUnfocusedInputToolbar?: () => ReactNode;
  onClear: () => void;
  isOpen: boolean;
  testId?: string;
  withAIStyling?: boolean;
  disabled?: boolean;
  isCompact?: boolean;
}

export function UnfocusedInput<T extends object>({
  getToggleButtonProps,
  isLoading,
  emptyPlaceholder,
  renderUnfocusedInputToolbar,
  renderSelectedItem,
  renderSelectedItemHover,
  hasValue,
  isClearable,
  isOpen,
  onClear,
  testId,
  withAIStyling,
  disabled = false,
  isCompact = false,
}: UnfocusedInputProps<T>): JSX.Element {
  const [isHovering, hoverRef] = useHoverIntent<HTMLDivElement>({
    interval: 300,
    timeout: 200,
    sensitivity: 10,
  });

  return (
    <div ref={hoverRef}>
      <Popper
        isOpen={isHovering}
        placement="bottom"
        trigger={({ triggerRef }) => (
          <SelectUnfocusedInput
            withAIStyling={withAIStyling}
            {...getToggleButtonProps({ tabIndex: 0, ref: triggerRef })}
            isClearable={!disabled && !!hasValue && isClearable && !isOpen}
            onClear={(e) => {
              e.stopPropagation();

              onClear();
            }}
            testId={testId}
            disabled={disabled}
            isCompact={isCompact}
          >
            {!hasValue ? (
              <>
                {isLoading && (
                  <>
                    <CircleSpinnerInline />
                    <Spacing width={8} />
                  </>
                )}

                <SelectPlaceholder>{isLoading ? 'Loading...' : emptyPlaceholder}</SelectPlaceholder>
              </>
            ) : (
              renderSelectedItem?.()
            )}

            {isOpen && (
              <Flexbox marginLeft="auto" marginRight={8}>
                {renderUnfocusedInputToolbar?.()}
              </Flexbox>
            )}
          </SelectUnfocusedInput>
        )}
      >
        {hasValue && renderSelectedItemHover?.()}
      </Popper>
    </div>
  );
}

interface SelectableItemProps<T>
  extends Pick<BaseSelectorProps<T>, 'renderItem' | 'renderItemHover' | 'getDisabledProps'> {
  getItemProps: (options: UseComboboxGetItemPropsOptions<T>) => object;
  item: T;
  isHovering: boolean;
  isSelected: boolean;
  index: number;
  renderItemHover?: (item: T) => ReactNode;
  hasScrollbarOffset?: boolean;
}

export function SelectableItem<T>({
  getItemProps,
  renderItem,
  renderItemHover,
  item,
  isHovering,
  isSelected,
  index,
  hasScrollbarOffset,
  getDisabledProps,
}: SelectableItemProps<T>): JSX.Element {
  const disabled = getDisabledProps?.(item, { index });

  return (
    <div
      {...getItemProps({
        item,
        index,
      })}
    >
      <Popper
        portalElement={document.body}
        offsetDistance={hasScrollbarOffset ? 24 : 12}
        isOpen={!!renderItemHover && isHovering}
        placement="right"
        trigger={({ triggerRef }) => (
          <DisabledSelectedItemOverflow
            enabled={disabled?.isDisabled}
            message={disabled?.disabledMessage}
            tooltipProps={disabled?.disabledTooltipProps}
          >
            <div ref={triggerRef}>
              {renderItem(item, {
                isHovering,
                isSelected,
                isDisabled: !!disabled?.isDisabled,
              })}
            </div>
          </DisabledSelectedItemOverflow>
        )}
      >
        {!disabled?.isDisabled && (
          <div
            role="presentation"
            onClick={(e) => {
              // we want to prevent the click event from bubbling up to the menu
              e.stopPropagation();
            }}
          >
            {renderItemHover?.(item)}
          </div>
        )}
      </Popper>
    </div>
  );
}

function SelectPlaceholder({ children }: PropsWithChildren<unknown>): JSX.Element {
  return <Text color={colors.steel[300]}>{children}</Text>;
}

export function SimpleSelectValue({ children }: PropsWithChildren<unknown>): JSX.Element {
  return (
    <Text
      nowrap
      color={colors.steel[500]}
      weight={600}
      textAlign="left"
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '90%',
        wordBreak: 'break-word',
      }}
    >
      {children}
    </Text>
  );
}

interface SelectInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  renderInputToolbar?: BaseSelectorProps<unknown>['renderInputToolbar'];
  inputRef: RefObject<HTMLInputElement>;
  assistiveText?: string;
  hasBorder?: boolean;
  testId?: string;
  withAIStyling?: boolean;
  isCompact?: boolean;
}

export const SelectInput = forwardRef<HTMLInputElement, SelectInputProps>(
  ({ assistiveText, renderInputToolbar, inputRef, hasBorder, testId, withAIStyling, isCompact, ...props }, ref) => {
    const focusInput = (): void => {
      inputRef.current?.focus();
    };

    const toolbar = renderInputToolbar?.({ focusInput });
    const iconWrapperStyles = {
      minWidth: 16,
      minHeight: 16,
      display: 'flex',
    };

    return (
      <StyledSelectInputContainer
        hasToolbar={!!toolbar}
        hasBorder={hasBorder}
        data-testid={`${testId}-input-container`}
        withAIStyling={withAIStyling}
        isCompact={isCompact}
      >
        <div style={iconWrapperStyles}>
          <IconSearch2 size={16} />
        </div>

        <StyledInput ref={ref} {...props} />

        {assistiveText && (
          <Text
            size="small"
            color={colors.steel[300]}
            style={{
              marginRight: 8,
            }}
          >
            {assistiveText}
          </Text>
        )}
        {toolbar ? <Flexbox marginLeft="auto">{toolbar}</Flexbox> : null}
      </StyledSelectInputContainer>
    );
  },
);

interface SelectUnfocusedInputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isClearable?: boolean;
  onClear?: (e: SyntheticEvent) => void;
  clearButtonRef?: RefObject<HTMLAnchorElement>;
  testId?: string;
  withAIStyling?: boolean;
  isCompact?: boolean;
}

const SelectUnfocusedInput = forwardRef<HTMLButtonElement, SelectUnfocusedInputProps>(
  ({ children, isClearable, onClear, clearButtonRef, testId, withAIStyling, disabled, isCompact, ...props }, ref) => {
    return (
      <StyledSelectInputContainer
        as="button"
        type="button"
        ref={ref}
        disabled={disabled}
        {...props}
        withAIStyling={withAIStyling}
        data-testid={`${testId}-select-input-container`}
        isCompact={isCompact}
      >
        {children}
        <StyledSelectInputChevronContainer>
          {isClearable && (
            <IconButton
              type="close"
              ariaLabel="clear selection"
              onClick={onClear}
              innerRef={clearButtonRef}
              testId={`${testId}-clear-selection-button`}
            />
          )}
          <IconArrowDropDown color={disabled ? colors.steel[200] : undefined} />
        </StyledSelectInputChevronContainer>
      </StyledSelectInputContainer>
    );
  },
);

interface VisibilityHiddenProps {
  children: ReactNode;
  enabled?: boolean;
}

/**
 * This makes the element hidden when the `enabled` prop is false.
 * Downshift requires the input to always be on the screen in order to inject its props.
 */
export function VisibilityHidden({ children, enabled }: VisibilityHiddenProps): JSX.Element {
  return (
    <div
      style={
        enabled
          ? {
              visibility: 'hidden',
              display: 'none',
            }
          : undefined
      }
    >
      {children}
    </div>
  );
}

export function DefaultNoResultsDisplay(): JSX.Element {
  return (
    <Flexbox justifyContent="center" alignItems="center">
      <Padding size={20}>
        <Text weight={500} color={colors.steel[400]}>
          No results
        </Text>
      </Padding>
    </Flexbox>
  );
}

interface DisabledSelectedItemOverflowProps {
  enabled?: boolean;
  message?: string;
  tooltipProps?: DisabledProps['disabledTooltipProps'];
  children: JSX.Element;
}

function DisabledSelectedItemOverflow({
  children,
  enabled,
  message,
  tooltipProps,
}: DisabledSelectedItemOverflowProps): JSX.Element {
  if (!enabled) {
    return children;
  }
  return (
    <Tooltip message={message} {...tooltipProps}>
      <div
        style={{
          position: 'relative',
          cursor: 'not-allowed',
          background: colors.steel[50],
          opacity: '0.4',
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
}

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  isLoading?: boolean;
  isOpen?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  overflowRef?: RefObject<HTMLDivElement>;
}

const menuOverflowStyles = {
  maxHeight: 500,
  overflow: 'auto',
};

const menuStyles = { borderRadius: 4 };

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ isLoading, isOpen, children, header, footer, overflowRef, ...rest }, ref) => {
    return (
      <div ref={ref} style={menuStyles} {...rest}>
        {header}

        <div ref={overflowRef} style={menuOverflowStyles}>
          {isOpen && (
            <>
              {isLoading ? (
                <Flexbox justifyContent="center">
                  <Padding size={20}>
                    <CircleSpinnerInline />
                  </Padding>
                </Flexbox>
              ) : (
                children
              )}
            </>
          )}
        </div>

        {footer}
      </div>
    );
  },
);

const StyledSelectInputContainer = styled.div<{
  hasToolbar?: boolean;
  hasBorder?: boolean;
  withAIStyling?: boolean;
  isCompact?: boolean;
}>(({ hasToolbar, hasBorder = true, withAIStyling, isCompact }) => ({
  outline: 0,
  ':focus': {
    borderColor: colors.brand[400],
  },

  background: '#fff',
  flex: 1,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  color: colors.steel[400],
  boxSizing: 'border-box',
  border: hasBorder ? `1px solid ${colors.steel[200]}` : undefined,
  borderBottom: !hasBorder ? `1px solid ${colors.steel[200]}` : undefined,
  padding: isCompact ? '0px 32px 0px 12px' : '8px 40px 8px 16px',
  paddingRight: hasToolbar ? '16px' : '40px',
  borderRadius: hasBorder ? 4 : undefined,
  height: isCompact ? 30 : 40,
  '&:disabled': {
    background: colors.steel[50],
  },
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  ...(withAIStyling && {
    ':focus': {
      borderColor: colors.purple[500],
    },
    borderColor: colors.purple[500],
    background: colors.purple[100],
  }),
}));

const StyledInput = styled.input({
  display: 'flex',
  flex: 1,
  width: '100%',
  paddingLeft: 8,
  boxSizing: 'border-box',
  border: 0,
  boxShadow: 'none',
  outline: 0,
  ':focus': {
    borderColor: colors.brand[400],
  },
  fontFamily: fontFamilies.body,
  fontSize: fontSizes.regular.fontSize,
  color: colors.steel[500],
  '::placeholder': {
    color: colors.steel[300],
  },
});

const StyledSelectInputChevronContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  right: 16,
  top: '50%',
  transform: 'translateY(-50%)',
});
