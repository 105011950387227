import {
  Badge,
  Box,
  colors,
  Flexbox,
  fontFamilies,
  IconLock,
  PopoverAnimation,
  Tether,
  Text,
  usePopover,
} from '@newfront-insurance/core-ui';
import kebabCase from 'lodash/kebabCase';
import type { RefObject } from 'react';
import { useEffect } from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import styled from 'styled-components';

import type { ObjectType, SearchResult } from '../../../../bff/types';

const ITEM_RESULT_TYPE: Record<ObjectType, string> = {
  account: 'ACCOUNT',
  document: 'DOCUMENT',
  policy: 'POLICY',
};

interface Props {
  onClick: () => void;
  onHover?: () => void;
  renderPreview: (result: SearchResult, containerId?: string) => JSX.Element;
  result: SearchResult;
  isActiveResult?: boolean;
}

const HOVER_INTENT_INTERVAL_MS = 200;

export function Result({ onClick, result, renderPreview, isActiveResult = false, onHover }: Props): JSX.Element {
  const [isHovering, ref] = useHoverIntent({
    interval: HOVER_INTENT_INTERVAL_MS,
    timeout: 100,
  });

  const { badges, description, hasViewAccess, label, routing, type } = result;
  const containerId = `id-${kebabCase(label)}-${kebabCase(type)}-container`;
  const { isPopoverOpen, targetRef, openPopover, closePopover } = usePopover({
    excludeTarget: true,
  });

  const anchorRef = targetRef as RefObject<HTMLAnchorElement>;
  const wrapRef = ref as RefObject<HTMLLIElement>;

  useEffect(() => {
    if (isHovering && isActiveResult) {
      openPopover();
    } else {
      closePopover();
    }
  }, [closePopover, isHovering, openPopover, isActiveResult]);

  useEffect(() => {
    if (isActiveResult) {
      anchorRef.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [anchorRef, isActiveResult]);

  useEffect(() => {
    if (isHovering) {
      onHover?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovering]);

  return (
    <StyledResultItem ref={wrapRef} role="option" aria-selected={isActiveResult} id={containerId}>
      <StyledLink
        href={routing.absolute}
        onClick={(e) => {
          if (e.ctrlKey || e.metaKey || !hasViewAccess) {
            return;
          }

          e.preventDefault();
          onClick();
        }}
        ref={anchorRef}
      >
        {!hasViewAccess && <IconLock />}
        <Box style={{ flex: 1 }}>
          <Flexbox justifyContent="space-between">
            <StyledTitle>{ITEM_RESULT_TYPE[type]}</StyledTitle>
            {badges && badges.length > 0 ? (
              <Flexbox gap={8} width="auto" display="inline-flex">
                {badges.map((badge, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Badge size="small" type={badge.type} key={index}>
                    {badge.content}
                  </Badge>
                ))}
              </Flexbox>
            ) : null}
          </Flexbox>
          <Text weight={600} color={colors.steel[500]}>
            {label}
          </Text>
          {description && (
            <Text weight={400} color={colors.steel[500]}>
              {description}
            </Text>
          )}
          {!hasViewAccess && (
            <Text weight={400} color={colors.fire[500]}>
              Please add yourself to the servicing team of the account to view this.
            </Text>
          )}
        </Box>
      </StyledLink>
      {renderPreview && (
        <Tether targetRef={targetRef} targetAnchor="left" anchor="right" xOffset={-7}>
          <PopoverAnimation isOpen={isPopoverOpen} direction="right">
            {renderPreview(result, containerId)}
          </PopoverAnimation>
        </Tether>
      )}
    </StyledResultItem>
  );
}

const StyledLink = styled.a`
  border: 0;
  border-left: solid 4px transparent;
  cursor: pointer;
  font-family: ${fontFamilies.body};
  padding: 11px 24px;
  position: relative;
  transition: all ease-in 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledResultItem = styled.li`
  list-style: none;

  &:hover,
  &:focus,
  &[aria-selected='true'] {
    ${StyledLink} {
      border-left-color: ${colors.brand[300]};
      background-color: ${colors.brand[100]};
    }
  }
`;

const StyledTitle = styled.h5`
  margin: 0;
  margin-bottom: 2px;
  font-size: 11px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${colors.fire[400]};
`;
