import { colors, Padding, Text, LineClamp, Stack, IconButton, Input, Tooltip } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

import { useDefinitionsHelpers } from '../../../hooks/definitions';
import type { LineOfCoverageTypeDetails } from '../../../types';

interface Props {
  index: number;
  lineOfCoverage: LineOfCoverageTypeDetails;
  onRemove: (index: number, isDeleting?: boolean) => void;
  onDisplayNameChange: (index: number, displayName: string) => void;
}

export function LineOfCoverageTypeCard({ lineOfCoverage, onRemove, onDisplayNameChange, index }: Props): JSX.Element {
  const { renderLineOfCoverageType } = useDefinitionsHelpers();
  const readableName = renderLineOfCoverageType(lineOfCoverage.type);
  const existentLoc = !!lineOfCoverage.uuid;

  return (
    <StyledRow>
      <StyledActionMenu className="card-menu">
        <Tooltip text={existentLoc ? 'Delete coverage' : 'Deselect coverage'} positionTip="left">
          <IconButton
            type={existentLoc ? 'remove' : 'close'}
            // if loc contains uuid, it means we're deleting an existent loc
            onClick={() => onRemove(index, !!existentLoc)}
          />
        </Tooltip>
      </StyledActionMenu>
      <Padding size={16} right={20}>
        <Stack gap={4} direction="vertical">
          <Text weight={600} color="dark" truncate>
            <LineClamp numOfLines={2}>{readableName}</LineClamp>
          </Text>
          <Text>
            <LineClamp numOfLines={2}>
              <Input
                type="text"
                style={{
                  border: 0,
                  padding: 0,
                  height: 18,
                }}
                value={lineOfCoverage.displayName ?? ''}
                placeholder="Add a display name..."
                onChange={(displayName) => onDisplayNameChange(index, displayName)}
              />
            </LineClamp>
          </Text>
        </Stack>
      </Padding>
    </StyledRow>
  );
}

const StyledActionMenu = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
`;

const StyledRow = styled.div`
  background-color: white;
  border: 1px solid ${colors.steel[200]};
  border-radius: 6px;
  position: relative;
`;
