import type { Money } from '@newfront-insurance/core-money';
import { toMoneyOrUndefined, toNumberOrUndefinedFromMoney } from '@newfront-insurance/core-money';
import { CurrencyInput, Field, Flexbox, Input } from '@newfront-insurance/core-ui';
import get from 'lodash/get';

type CurrencyWithNumber = Record<string, Money | number | undefined> | undefined;

interface Props {
  name: string;
  value?: CurrencyWithNumber;
  onChange: (value: CurrencyWithNumber) => void;
  currencyFieldLabel?: string;
  currencyFieldName: string;
  numberFieldLabel?: string;
  numberFieldName: string;
  decimalScale?: number;
  appendText?: string;
  disabled?: boolean;
}

export function CurrencyWithNumber(props: Props): JSX.Element | null {
  const {
    onChange,
    name,
    value,
    currencyFieldLabel,
    currencyFieldName,
    numberFieldLabel,
    numberFieldName,
    decimalScale,
    appendText,
    disabled,
  } = props;

  function handleChange(newValue: CurrencyWithNumber): void {
    const currencyValue = newValue?.[currencyFieldName] as Money;
    const numberValue = newValue?.[numberFieldName] as number;

    if (!currencyValue && !numberValue) {
      onChange(undefined);

      return;
    }

    onChange({
      [currencyFieldName]: currencyValue,
      [numberFieldName]: numberValue,
    });
  }

  return (
    <Flexbox gap={16} flexDirection="row">
      <Field label={currencyFieldLabel || ''} fieldId={`${name}.${currencyFieldName}`}>
        <CurrencyInput
          name={`${name}.${currencyFieldName}`}
          value={toNumberOrUndefinedFromMoney(get(value, currencyFieldName) as Money)}
          decimalScale={decimalScale}
          onChange={(inputValue?: number) =>
            handleChange({ ...value, [currencyFieldName]: toMoneyOrUndefined(inputValue) })
          }
          disabled={disabled}
        />
      </Field>
      <Field label={numberFieldLabel || ''} fieldId={`${name}.${numberFieldName}`}>
        <Input
          type="number"
          value={(get(value, numberFieldName) as number | undefined) || ''}
          onChange={(inputValue) => {
            const newNumberValue = inputValue ? Number(inputValue) : undefined;

            handleChange({ ...value, [numberFieldName]: newNumberValue as number });
          }}
          appendText={appendText}
          disabled={disabled}
        />
      </Field>
    </Flexbox>
  );
}
