import { AUTH_ROLE_TO_DEPARTMENT_ROLE } from '@newfront-insurance/account-api';
import { isNotNil } from '@newfront-insurance/core';
import type { EmployeeAvatarType } from '@newfront-insurance/core-ui';
import type { Employee as LegacyEmployee } from '@newfront-insurance/next-newfront-api-client';
import type { Employee as ApiEmployee } from '@newfront-insurance/users-api';

import type { Employee } from '../types';

export function formatEmployee(
  employee: ApiEmployee | undefined,
  legacyEmployee: LegacyEmployee,
  avatarType: EmployeeAvatarType,
): Employee {
  const roles = employee?.roles || [];
  const legacyFullName = [legacyEmployee.firstName, legacyEmployee.lastName].filter(isNotNil).join(' ').trim();

  return {
    departments: roles.map((role) => AUTH_ROLE_TO_DEPARTMENT_ROLE[role]).filter(isNotNil),
    email: employee?.email || legacyEmployee.email,
    extension: legacyEmployee.extension,
    firstName: employee?.name?.givenName || legacyEmployee.firstName,
    imageUrl: legacyEmployee.imageUrl,
    lastName: employee?.name?.familyName || legacyEmployee.lastName,
    legacyId: legacyEmployee.id,
    name: employee?.name?.fullName || legacyFullName,
    phone: legacyEmployee.phone,
    roles,
    title: employee?.title || legacyEmployee.title,
    type: avatarType,
    userUuid: legacyEmployee.userUuid,
    uuid: legacyEmployee.uuid,
    status: legacyEmployee.status,
  };
}
