import type { LineOfCoverageType } from '@newfront-insurance/coverage-api';
import sortBy from 'lodash/sortBy';

import type { LineOfCoverageTypeOption } from '../types';

export function getOptionLabel(
  { type, index }: LineOfCoverageTypeOption,
  renderLineOfCoverageType: (lineOfCoverageType: LineOfCoverageType) => string,
): string {
  const typeReadableName = renderLineOfCoverageType(type);
  if (index === 0) return typeReadableName;
  return `${typeReadableName} ${index + 1}`;
}

export function toOption(type: LineOfCoverageType, index: number): LineOfCoverageTypeOption {
  return {
    id: `${type}_${index}`,
    type,
    index,
  };
}

/**
 * Get LineOfCoverageTypeOption array with the options from an array of LineOfCoverageType
 * which can include repetitions
 * @param types
 */
export function typesToOptions(types: LineOfCoverageType[]): LineOfCoverageTypeOption[] {
  const indexesPerType: Record<LineOfCoverageType, number> = {};

  return sortOptions(
    types.map((type: LineOfCoverageType) => {
      const index: number = indexesPerType[type] || 0;
      indexesPerType[type] = index + 1;
      return toOption(type, index);
    }),
  );
}

/**
 * Calculates which would be the new option if a LineOfCoverageType needs to be added to the current options.
 * @param currentOptions
 * @param type
 */
export function calculateNewOption(
  currentOptions: LineOfCoverageTypeOption[],
  type: LineOfCoverageType,
): LineOfCoverageTypeOption {
  const index: number = currentOptions.filter((option: LineOfCoverageTypeOption) => option.type === type).length;
  return toOption(type, index);
}

export function sortOptions(options: LineOfCoverageTypeOption[]): LineOfCoverageTypeOption[] {
  return sortBy(options, (option) => getOptionLabel(option, (type) => type));
}

export function optionsToTypes(options: LineOfCoverageTypeOption[]): string[] {
  return options.map(({ type }) => type);
}
