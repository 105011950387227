import kebabCase from 'lodash/kebabCase';
import { useMemo } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { uid } from 'uid';

import { generateTestId } from '../../lib/test-helpers';
import { colors } from '../../theme';
import { colors as newColors } from '../../theme/colors';
import { Flexbox } from '../flexbox';
import { Stack } from '../stack';
import type { TextSizes, TextColors, TextWeights } from '../text';
import { Text } from '../text';

interface Props {
  children?: React.ReactNode;
  defaultChecked?: boolean;
  disabled?: boolean;
  inputSize?: number;
  isChecked?: boolean;
  label: string;
  name: string;
  onChange?: () => unknown;
  testId?: string;
  textColor?: TextColors;
  textSize?: TextSizes;
  textWeight?: TextWeights;
  value: string | number;
  wrapLabel?: boolean;
}

export interface SelectableOption {
  label: string;
  value: string | number;
  testId?: string;
  inputSize?: number;
}

export function RadioButton({
  children,
  defaultChecked,
  disabled,
  inputSize = 20,
  isChecked,
  label,
  name,
  onChange,
  testId: propTestId,
  textColor = 'default',
  textSize = 'regular',
  textWeight,
  value,
  wrapLabel = true,
}: Props): JSX.Element {
  const id = useMemo(() => uid(), []);
  const color = (): TextColors => {
    if (disabled && !isChecked) {
      return 'light';
    }
    return textColor;
  };
  const testId = propTestId ?? generateTestId(kebabCase(label), 'radio-button');
  return (
    <StyledWrapper>
      <Flexbox alignItems="center">
        <label htmlFor={id} className="h-fit">
          <div className="flex flex-row items-center">
            <StyledRadio
              checked={isChecked}
              data-testid={testId}
              defaultChecked={defaultChecked}
              disabled={disabled}
              id={id}
              name={name}
              onChange={onChange}
              readOnly={!onChange}
              size={inputSize}
              type="radio"
              value={value}
            />
            <span>
              <Stack direction="vertical" gap={4}>
                <Text size={textSize} color={color()} weight={textWeight} nowrap={!wrapLabel}>
                  {label}
                </Text>
                {isChecked && children ? children : null}
              </Stack>
            </span>
          </div>
        </label>
      </Flexbox>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledRadio = styled.input<{ size: number }>`
  &:checked,
  &:not(:checked) {
    left: -9999px;
    position: absolute;
  }

  & + span,
  &:not(:checked) + span {
    color: #666;
    cursor: pointer;
    display: inline-block;
    line-height: 24px;
    padding-left: 28px;
    position: relative;
    box-sizing: border-box;
  }

  & + span:before,
  &:not(:checked) + span:before {
    background: #fff;
    box-shadow:
      0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px 0px 0px 1px ${colors.gray_xl};
    border-radius: 100%;
    content: '';
    height: ${({ size }) => `${size}`}px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: border-width 0.2s ease;
    width: ${({ size }) => `${size}`}px;
  }

  &:focus + span:before {
    box-shadow:
      0px 0px 8px 0.5px ${newColors.brand[400]},
      inset 0px 0px 0px 1px ${colors.gray_xl};
    transition: box-shadow 0.3s ease;
  }

  &:checked + span:before {
    box-shadow:
      0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px 0px 0px 8px ${colors.primary};
    transition: box-shadow 0.3s ease;
  }

  &:focus:checked + span:before {
    box-shadow:
      0px 0px 8px 0.5px ${newColors.brand[400]},
      inset 0px 0px 0px 8px ${colors.primary};
    transition: box-shadow 0.3s ease;
  }

  &:disabled + span {
    cursor: not-allowed;
  }

  &:disabled&:checked + span:before {
    box-shadow:
      0px 1px 4px rgba(0, 0, 0, 0.1),
      inset 0px 0px 0px 8px ${colors.gray};
  }
`;
