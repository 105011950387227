import { Alert, Box, colors, Padding, Text } from '@newfront-insurance/core-ui';
import { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Result } from './result';
import { Suggestion } from './suggestion';
import type { SearchResult } from '../../../../bff/types';
import { bugsMessage } from '../../../utils';
import { SearchContext } from '../context';

interface Props {
  onRecentlySearchedClick: (value: string) => void;
  onRenderPreview: (result: SearchResult, containerId?: string) => JSX.Element;
  onSearchResultClick: (result: SearchResult, rank: number) => unknown;
  onSearchSuggestionClick: (suggestion: string) => Promise<void>;
}

export function Popout({
  onRecentlySearchedClick,
  onRenderPreview,
  onSearchResultClick,
  onSearchSuggestionClick,
}: Props): JSX.Element | null {
  const {
    activeResultIndex,
    currentQuery,
    error,
    recentlySearched,
    recentlyVisited,
    results,
    setActiveResultIndex,
    suggestions,
  } = useContext(SearchContext);
  const dvRef = useRef<HTMLDivElement>(null);

  // back to the initial position of the scroll
  useEffect(() => {
    if (activeResultIndex === 0) {
      dvRef.current?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [activeResultIndex]);

  if (error) {
    const customMessage = `${error.statusText}: ${error.data?.errorMessage || error.data?.errors?.[0] || ''}`;
    return (
      <StyledContainer>
        <Padding x={24} y={12}>
          <Alert>
            <Text>
              {bugsMessage(`${error.data ? customMessage : 'There was a problem getting the search results.'}`)}
            </Text>
          </Alert>
        </Padding>
      </StyledContainer>
    );
  }

  const hasRecentlySearched = recentlySearched.length > 0;
  const hasRecentlyVisited = recentlyVisited.length > 0;
  const hasResults = results && results.length > 0;
  const hasSuggestions = suggestions && suggestions.length > 0;

  if ((hasRecentlySearched || hasRecentlyVisited) && !currentQuery) {
    return (
      <StyledContainer>
        {hasRecentlySearched && (
          <Padding left={24} bottom={hasRecentlyVisited ? 24 : 12}>
            <Box>
              <Text size="small" textAlign="left" weight={300} color={colors.steel[400]}>
                Recently searched
              </Text>
              {recentlySearched.map((item) => (
                <Box key={item} onClick={() => onRecentlySearchedClick(item)}>
                  <Text marginTop={12} marginBottom={4} weight={400}>
                    {item}
                  </Text>
                </Box>
              ))}
            </Box>
          </Padding>
        )}
        {hasRecentlyVisited && (
          <Box>
            <Padding left={24} x={12}>
              <Text size="small" textAlign="left" weight={300} color={colors.steel[400]}>
                Recently visited
              </Text>
            </Padding>
            {recentlyVisited.map((result, index) => (
              <Result
                key={result.id}
                result={result}
                onClick={() => onSearchResultClick(result, index)}
                renderPreview={onRenderPreview}
              />
            ))}
          </Box>
        )}
      </StyledContainer>
    );
  }

  if (!results || !currentQuery) {
    return null;
  }

  return (
    <StyledContainer style={{ minHeight: hasResults ? '460px' : 'auto' }} ref={dvRef}>
      {hasSuggestions && (
        <Padding top={hasSuggestions ? 8 : 0} x={24}>
          {suggestions
            ?.filter((suggestion) => suggestion !== currentQuery)
            .map((suggestion) => {
              return (
                <Suggestion
                  key={suggestion}
                  currentQuery={currentQuery}
                  suggestion={suggestion}
                  onClick={() => onSearchSuggestionClick(suggestion)}
                />
              );
            })}
        </Padding>
      )}

      {hasResults ? (
        <ul role="listbox" aria-labelledby="global-search-label" style={{ padding: 0 }}>
          {results.map((result, i) => (
            <Result
              isActiveResult={activeResultIndex === i}
              key={result.id}
              result={result}
              onClick={() => onSearchResultClick(result, i)}
              onHover={() => setActiveResultIndex(i)}
              renderPreview={onRenderPreview}
            />
          ))}
        </ul>
      ) : (
        <Padding size={24}>
          <Box>
            <Text weight={400} textAlign="center" color="secondary">
              Sorry we couldn’t find any matches
            </Text>
            <Text weight={400} textAlign="center" color="light">
              Try other search terms
            </Text>
          </Box>
        </Padding>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  background: #fff;
  border-top: 1px solid rgba(190, 199, 208, 0.5);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-sizing: border-box;
  left: 0;
  max-height: calc(100vh - 260px);
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 12px 0;
  position: relative;
  width: 100%;
  z-index: 3;

  &::-webkit-scrollbar-track {
    background-color: #fff;
    position: absolute;
    border-bottom-right-radius: 4px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${colors.steel[200]};
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 10px;
    background: ${colors.steel[200]};
    border: 2px solid #fff;
  }
`;
