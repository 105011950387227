/* eslint-disable no-process-env */
import { createAuthProvider, IDPHint } from '@newfront-insurance/next-auth';

import { getConfig } from '@/config';

const { API, KEYCLOAK } = getConfig();

export const AuthProvider = createAuthProvider({
  idpHint: IDPHint.GSUITE,
  authApiConfig: {
    clientId: KEYCLOAK.CLIENT_ID,
    realm: KEYCLOAK.REALM.EMPLOYEES,
    url: API.AUTH,
  },
});
