import { ThemeCSSVariableProvider, ThemeGlobalStyles, ThemeProvider } from '@newfront-insurance/core-ui';
import { ThemeProvider as ThemeProviderV2 } from '@newfront-insurance/core-ui/v2';
import type { AuthProviderContext } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';
import type { ReactNode } from 'react';

import type { ErrorCallback } from './components/error-boundary';
import { LayoutConfigProvider } from './config';
import type { AppType } from './metadata/apps';

interface Props {
  apiBasePath: string;
  children: ReactNode;
  authProvider: Provider<AuthProviderContext>;
  currentApp: AppType;
  legacyAppBaseUrl: string;
  logoutRedirect: string;
  onError?: ErrorCallback;
}

/**
 * Add this provider to _app to enable the use of all of the hooks.
 */
export function AdminLayoutProvider(props: Props): JSX.Element {
  const { apiBasePath, children, authProvider, currentApp, legacyAppBaseUrl, logoutRedirect, onError } = props;

  return (
    <ThemeProviderV2 attribute="class" defaultTheme="light" disableTransitionOnChange>
      <ThemeProvider>
        <ThemeCSSVariableProvider>
          <ThemeGlobalStyles>
            <LayoutConfigProvider
              authProvider={authProvider}
              apiBasePath={apiBasePath}
              currentApp={currentApp}
              legacyAppBaseUrl={legacyAppBaseUrl}
              logoutRedirect={logoutRedirect}
              onError={onError}
            >
              {children}
            </LayoutConfigProvider>
          </ThemeGlobalStyles>
        </ThemeCSSVariableProvider>
      </ThemeProvider>
    </ThemeProviderV2>
  );
}
