import { colors } from '@newfront-insurance/core-ui';
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  align-items: center;
  background-color: ${colors.brand[100]};
  box-shadow: 2px -2px 8px 1px ${colors.brand[100]};
  display: flex;
  height: 52px;
  left: 53px;
  padding: 2px;
  position: relative;
`;
