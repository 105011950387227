import type { Option } from '@newfront-insurance/core-ui';
import { AdvancedSelectInput } from '@newfront-insurance/core-ui';
import type { LineOfCoverageType } from '@newfront-insurance/coverage-api';
import { useCallback, useMemo } from 'react';

import { useDefinitionsHelpers } from '../../hooks/definitions';

export interface Props {
  /** The current value of the select */
  value?: LineOfCoverageType;
  /** An array of types you want to filter out so that they don't get displayed in the options list */
  blacklist?: LineOfCoverageType[];
  onChange: (lineOfCoverageType: LineOfCoverageType) => unknown;
}

/**
 * Select with all lines of coverage types (except for the ones in the optional blacklist)
 */
export function LineOfCoverageTypeSelectInput({ value, blacklist = [], onChange }: Props): JSX.Element {
  const { lineOfCoverageTypes, renderLineOfCoverageType } = useDefinitionsHelpers();

  // all LOCs except the ones in the blacklist
  const options: Option[] = useMemo(
    () =>
      lineOfCoverageTypes
        .filter((type: LineOfCoverageType) => !blacklist.includes(type))
        .map((type: LineOfCoverageType): Option => ({ value: type, label: renderLineOfCoverageType(type) })),
    [lineOfCoverageTypes, renderLineOfCoverageType, blacklist],
  );

  const handleChange = useCallback((val: string | string[]) => onChange(val as LineOfCoverageType), [onChange]);

  return <AdvancedSelectInput isClearable value={value} options={options} onChange={handleChange} />;
}
