import type { SavedAddress } from '@newfront-insurance/address-api';
import { Padding, colors, Text, Flexbox, IconBookmark } from '@newfront-insurance/core-ui';
import * as React from 'react';
import styled from 'styled-components';

import { HighlightQueryInText } from '../highlight-query-in-text';

interface Props {
  address: SavedAddress;
  handleSelectedResult: (address: SavedAddress) => unknown;
  onKeyDown: (event: React.KeyboardEvent<Element>) => void;

  addressQuery?: string;
}

export function SuggestedAddress({ onKeyDown, address, handleSelectedResult, addressQuery }: Props): JSX.Element {
  const { addressLine1, addressLine2, city, displayName, stateOrArea, countryCode, postalCode } = address;

  return (
    <StyledResult
      tabIndex={0}
      onClick={() => handleSelectedResult(address)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSelectedResult(address);
          return;
        }
        onKeyDown(e);
      }}
      data-previously-saved-address
    >
      <Text size="small" color="inherit" style={{ lineHeight: 1 }}>
        <Padding y={8} x={16}>
          <Flexbox>
            <Padding right={12}>
              <IconBookmark size={14} color="#98A6B5" />
            </Padding>
            <div>
              {displayName && (
                <Padding bottom={4}>
                  <Text color="inherit" size="regular" style={{ lineHeight: '1' }}>
                    <HighlightQueryInText text={displayName} query={addressQuery} />
                  </Text>
                </Padding>
              )}
              <Padding bottom={4}>
                <HighlightQueryInText text={addressLine1} query={addressQuery} />
                {addressLine2 && <HighlightQueryInText text={addressLine2} query={addressQuery} />}
              </Padding>
              <div>
                <HighlightQueryInText
                  text={`${city}, ${stateOrArea}, ${postalCode}, ${countryCode}`}
                  query={addressQuery}
                />
              </div>
            </div>
          </Flexbox>
        </Padding>
      </Text>
    </StyledResult>
  );
}

const StyledResult = styled.div`
  line-height: 1;

  &:hover,
  :focus {
    background-color: ${colors.brand[400]};
    cursor: pointer;
    color: #fff;
  }

  &:hover > span path,
  &:focus > span path {
    fill: #fff;
  }
`;
