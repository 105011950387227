import { colors } from './colors';
import type { Theme } from './types';

export const darkTheme: Theme = {
  base: {
    background: '#191E2E',
    border: colors.steel[500],
  },
  text: {
    default: colors.steel[50],
    label: colors.steel[200],
    placeholder: colors.steel[300],
    selected: colors.steel[200],
    title: '',
    eyebrow: '',
  },
  link: {
    primary: {
      default: {
        color: colors.brand[400],
      },
      hover: {
        color: colors.brand[300],
      },
    },
    secondary: {
      default: {
        color: colors.steel[300],
      },
      hover: {
        color: colors.steel[200],
      },
    },
  },
  modal: {
    background: colors.steel[400],
  },
  card: {
    main: {
      outline: {
        background: colors.steel[400],
        border: colors.steel[400],
      },
      noOutline: {
        background: colors.steel[500],
      },
    },
    modal: {
      outline: {
        background: colors.steel[500],
        border: colors.steel[400],
      },
      noOutline: {
        background: '#353C55',
      },
    },
    backgroundHover: '#113874',
    shadow: '#3E4A63',
  },
  fileTypeIcon: {
    background: colors.steel[500],
  },
  icon: {
    color: '',
  },
  interactiveElement: {
    selected: '#113874',
    hover: '#113874',
  },
  appBadge: {
    label: colors.steel[100],
  },
  // These could be generic interactive elements
  button: {
    primary: {
      default: {
        background: '#113874',
        border: '',
        text: '',
      },
      disabled: {
        background: colors.steel[400],
        border: colors.steel[400],
        text: colors.steel[200],
      },
      focus: {
        background: '',
        border: '',
        text: '',
      },
    },
    secondary: {
      default: {
        background: 'transparent',
        border: '',
        text: '',
      },
      disabled: {
        background: 'transparent',
        border: colors.steel[400],
        text: colors.steel[300],
      },
      hover: {
        background: '',
        border: colors.brand[400],
        text: colors.steel[100],
      },
    },
  },
  popover: {
    background: '#191E2E',
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  menu: {
    text: {
      focus: '',
      default: '',
    },
  },
  notification: {
    error: {
      background: '',
      border: '',
    },
    neutral: {
      background: '',
      border: '',
    },
    success: {
      background: '',
      border: '',
    },
  },
  input: {
    default: {
      text: '#000',
      background: 'white',
      border: '#dbdbdb',
    },
    focus: {
      background: 'white',
      border: colors.brand[400],
      shadow: colors.brand[200],
    },
    disabled: {
      background: colors.steel[300],
    },
    error: {
      shadow: colors.fire[200],
      border: colors.fire[500],
    },
  },
  checkbox: {
    background: '#191E2E',
    icon: '#191E2E',
  },
  segmentedControl: {
    active: {
      background: '',
      text: '',
    },
    inactive: {
      background: '',
      text: '',
    },
  },
  textarea: {
    default: {
      background: '',
      border: '',
    },
    focus: {
      background: '',
      border: '',
    },
  },
  advancedSelectInput: {
    indicatorContainer: '',
    inputHover: '',
  },
  objectSelector: {
    text: '#F8F8F8',
  },
  alert: {
    background: '',
    text: '',
  },
  dropzone: {
    border: '',
    background: '',
    text: '',
  },
};
