import { useProducers } from '@newfront-insurance/admin-ui/accounts';
import { toMap } from '@newfront-insurance/core';

import type { Employee } from '@/api/types/employee';

export interface ProducersMapHook {
  producersMap: Map<string, Employee>;
}

export type FilterField = 'userUuid' | 'uuid';

export function useProducersMap(filterField?: FilterField): ProducersMapHook {
  const { data: producers } = useProducers();

  const producersMap = mapEmployees(producers ?? [], filterField);

  return { producersMap };
}

function mapEmployees(employeeList: Employee[], filterField: FilterField = 'userUuid'): Map<string, Employee> {
  return toMap(employeeList, (employee) => employee[filterField]);
}
