/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InputType } from '@newfront-insurance/dsl-schema-api';
import type { ComponentType } from 'react';

import type { Schema, SchemaFieldMetadataType } from '../../../types';

export enum Application {
  QUOTING = 'quoting',
  ACCOUNTS = 'accounts',
}

export interface SchemaInputControlProps {
  name: string;
  type: InputType;
  metadata?: SchemaFieldMetadataType;
  schema: Schema;
  fieldPath: string;
  accountUuid?: string;
  app?: Application; // Used when the input behaves differently in different applications
  suggestedValues?: any[];
  maxOptionWidth?: number;
  isCompact?: boolean;
}

/**
 * Props for a custom input component for a new inputType handler added to the SchemaInputControl
 */
export type SchemaInputControlCustomInputProps<T, K> = Omit<SchemaInputControlProps, 'type' | 'metadata'> & {
  value: T;
  metadata?: K;
  onChange: (newValue: T) => void;
  accountUuid?: string;
  disabled?: boolean;
};

/**
 * Custom input component for a new inputType handler added to the SchemaInputControl
 */
export type SchemaInputControlCustomInput<T, K> = ComponentType<SchemaInputControlCustomInputProps<T, K>>;

/**
 * Components registered as new input types with the handler for the new case. This makes the control extendable.
 */
export const customInputComponentsRegistry: Record<string, SchemaInputControlCustomInput<any, any>> = {};
