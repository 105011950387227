import type { Money } from '@newfront-insurance/core-money';
import { moneyToString } from '@newfront-insurance/core-money';
import { DslCustomInputType } from '@newfront-insurance/coverage-api';
import { InputType } from '@newfront-insurance/dsl-schema-api';
import { addCustomSchemaHandler } from '@newfront-insurance/dsl-schema-ui';
import find from 'lodash/find';

import { AddressDslDisplay, AddressDslInput } from '../internals/components/address-dsl-field';
import { AddressOrStateDslDisplay, AddressOrStateDslInput } from '../internals/components/address-or-state-dsl-field';
import {
  CurrencyOrCheckboxDisplay,
  CurrencyOrCheckboxDslInput,
} from '../internals/components/currency-or-checkbox-dsl-field';
import {
  LineOfCoverageSelectDisplay,
  LineOfCoverageSelectDslInput,
} from '../internals/components/line-of-coverage-select-dsl-field';
import {
  NumberOrCheckboxDisplay,
  NumberOrCheckboxDslInput,
} from '../internals/components/number-or-checkbox-dsl-field';

export function registerCustomDSLFields() {
  addCustomSchemaHandler(
    DslCustomInputType.NUMBER_OR_CHECKBOX,
    NumberOrCheckboxDisplay,
    NumberOrCheckboxDslInput,
    (value, field) => {
      if (!value || !field) {
        return '-';
      }

      if (value?.[field.inputMetadata.checkboxFieldName]) {
        return field.inputMetadata.checkboxFieldLabel;
      }

      return `${value?.[field.inputMetadata.numberFieldName]}${field.inputMetadata.appendText ?? ''}`;
    },
  );

  addCustomSchemaHandler(
    InputType.ADDRESS_OR_STATE,
    AddressOrStateDslDisplay,
    AddressOrStateDslInput,
    (value, field) => {
      let returnValue;
      if (value?.address?.formattedAddress) {
        returnValue = value.address.formattedAddress;
      }

      if (value?.state) {
        const option = find(field?.properties?.state.inputMetadata?.options, { value: value.state });
        returnValue = option?.label ?? value.state;
      }
      return returnValue ?? '-';
    },
  );

  addCustomSchemaHandler(
    DslCustomInputType.CURRENCY_OR_CHECKBOX,
    CurrencyOrCheckboxDisplay,
    CurrencyOrCheckboxDslInput,
    (value, field) => {
      if (!value || !field) {
        return '-';
      }

      if (value?.[field.inputMetadata.checkboxFieldName]) {
        return field.inputMetadata.checkboxFieldLabel;
      }

      const moneyValue = value?.[field.inputMetadata.currencyFieldName] as Money | undefined;

      return moneyValue ? moneyToString(moneyValue, { fractionDigits: 2 }) : '-';
    },
  );

  addCustomSchemaHandler(
    DslCustomInputType.LINE_OF_COVERAGE_SELECT,
    LineOfCoverageSelectDisplay,
    LineOfCoverageSelectDslInput,
    () => {
      return '-';
    },
  );

  addCustomSchemaHandler(InputType.ADDRESS, AddressDslDisplay, AddressDslInput, (value) => {
    return value?.formattedAddress;
  });
}
