import { Text, colors } from '@newfront-insurance/core-ui';

import type { Address } from '../../types';

interface Props {
  address?: Address;
  width?: number | string;
  color?: string;
}
export function AddressDisplayField({ address, width = 400, color = 'primary' }: Props): JSX.Element {
  return (
    <div className={`relative w-[${width}]`}>
      <div className="block h-[40px] w-full  appearance-none rounded-sm border border-solid border-steel-200 bg-white py-2 pl-2  pr-[28px] text-base leading-[21px] text-steel-500">
        <Text color={address?.formattedAddress ? color : colors.steel[300]}>
          {address?.formattedAddress || 'No address'}
        </Text>
      </div>
    </div>
  );
}
