import { DefinitionList, DetailCard, IconCircleInformation } from '@newfront-insurance/core-ui';
import type { DetailCardProps } from '@newfront-insurance/core-ui';
import { Popover, PopoverContent, PopoverTrigger } from '@newfront-insurance/core-ui/v2';
import { PolicyStatus } from '@newfront-insurance/coverage-api';
import type { Link } from '@newfront-insurance/next-router-provider';
import type { CalendarDate } from '@newfront-insurance/string-formatters';
import { formatDollars, DateFormatStyle } from '@newfront-insurance/string-formatters';
import React from 'react';

export type LineOfCoverageStatus = PolicyStatus & 'QUOTE';

export interface LineOfCoverageCardDetails {
  uuid: string;
  displayName?: string;
  definition?: {
    readableName: string;
  };
  title: string;
  startDate?: CalendarDate;
  endDate?: CalendarDate;
  lineOfCoverageStatus?: LineOfCoverageStatus;
  policy?: {
    policyNumber: string;
    status: PolicyStatus;
    carrier?: { name: string };
    billing?: { premium?: number };
  };
}

interface Props
  extends Pick<
    DetailCardProps,
    | 'actions'
    | 'cardProps'
    | 'hoverProps'
    | 'isExpandable'
    | 'defaultExpanded'
    | 'selectableOptions'
    | 'isHovering'
    | 'isHighlighted'
    | 'eyebrowProps'
  > {
  lineOfCoverage: LineOfCoverageCardDetails;
  policyDetailsLink?: Partial<Pick<Link, 'onClick' | 'href'>>;
  isCompact?: boolean /** Renders definition list in popover instead of expandable section */;
}

const LINE_OF_COVERAGE_STATUS_LABELS: Record<LineOfCoverageStatus, DetailCardProps['badgeProps']> = {
  [PolicyStatus.ACTIVE]: {
    content: 'Active',
    type: 'success',
  },
  [PolicyStatus.UPCOMING]: {
    content: 'Upcoming',
    type: 'info',
  },
  [PolicyStatus.EXPIRED]: {
    content: 'Expired',
    type: 'warning',
  },
  [PolicyStatus.CANCELLED]: {
    content: 'Cancelled',
    type: 'warning',
  },
  QUOTE: {
    content: 'Quote',
    type: 'glitter',
  },
};

export function LineOfCoverageCard({
  lineOfCoverage,
  actions,
  cardProps,
  hoverProps,
  isExpandable = false,
  defaultExpanded = true,
  selectableOptions,
  isHovering,
  isHighlighted,
  eyebrowProps,
  policyDetailsLink,
  isCompact,
}: Props): JSX.Element {
  const { policy, startDate, endDate } = lineOfCoverage;
  const status = lineOfCoverage.lineOfCoverageStatus || (lineOfCoverage.policy?.status as LineOfCoverageStatus);

  const definitionList = (
    <DefinitionList
      items={[
        {
          title: 'Policy number',
          type: 'number',
          value: policy?.policyNumber ?? '-',
          href: policyDetailsLink?.href,
          onClick: policyDetailsLink?.onClick,
          tooltipText: policyDetailsLink ? 'View policy details' : undefined,
        },
        {
          title: 'Premium',
          type: 'currency',
          value: policy?.billing?.premium !== undefined ? formatDollars(policy.billing.premium) : '-',
        },
        {
          title: 'Effective dates',
          type: 'dateRange',
          // TODO: should we use the policy's date range instead of the line of coverage's? also, should we consider the cancellationDate here?
          value: `${
            startDate
              ? startDate.format({
                  style: DateFormatStyle.STANDARD,
                })
              : 'N/A'
          } - ${endDate ? endDate.format({ style: DateFormatStyle.STANDARD }) : 'N/A'}`,
        },
        { title: 'Carrier', type: 'business', value: policy?.carrier?.name ?? '-' },
      ]}
    />
  );

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const popoverInfo = (
    <Popover open={isPopoverOpen}>
      <PopoverTrigger
        className="flex items-center"
        onMouseOver={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
      >
        <IconCircleInformation />
      </PopoverTrigger>
      <PopoverContent className="z-[9999]">{definitionList}</PopoverContent>
    </Popover>
  );

  return (
    <DetailCard
      title={lineOfCoverage.title}
      badgeProps={status ? LINE_OF_COVERAGE_STATUS_LABELS[status] : undefined}
      actions={isCompact ? [popoverInfo, ...(actions ?? [])] : actions}
      cardProps={cardProps}
      hoverProps={hoverProps}
      isExpandable={isExpandable}
      defaultExpanded={defaultExpanded}
      selectableOptions={selectableOptions}
      isHovering={isHovering}
      isHighlighted={isHighlighted}
      eyebrowProps={eyebrowProps}
    >
      {isCompact ? null : definitionList}
    </DetailCard>
  );
}
