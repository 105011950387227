import type { TextSizes } from '@newfront-insurance/core-ui';
import { Text } from '@newfront-insurance/core-ui';

interface Props {
  name?: string;
  label: string;
  size?: TextSizes;
  color?: string;
}

/**
 * Renders label text given a label
 * @param props
 */
export function Label({ name, label, size = 'small', color = 'light' }: Props): JSX.Element {
  return (
    <Text size={size} color={color} testId={`Label-${name}`}>
      {label}
    </Text>
  );
}
