import type { ConfigInterface, NextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useContext } from 'react';

import { routes } from '../bff/routes';
import type { GetPoliciesFromAccountParams, GetPoliciesFromAccountResponse } from '../bff/types';
import { CoverageApiContext } from '../provider';

export function usePoliciesFromAccount(
  params: GetPoliciesFromAccountParams,
  config?: ConfigInterface,
): NextApiSubscription<GetPoliciesFromAccountResponse | null> {
  const { basePath } = useContext(CoverageApiContext);

  return useNextApiSubscription<GetPoliciesFromAccountResponse | null>(
    routes.accountPolicies.getUrl(params, basePath),
    config,
  );
}
