import { Text } from '@newfront-insurance/core-ui';
import type { CurrencyOrCheckboxInputMetadata } from '@newfront-insurance/coverage-api';
import { getStringifiedFieldValue } from '@newfront-insurance/dsl-schema-ui';
import type {
  SchemaFieldType,
  SchemaInputControlCustomInputProps,
  CustomValue,
} from '@newfront-insurance/dsl-schema-ui';

import { AddressOrState } from './address-or-state';
import { AddressDisplayField, AddressFormattedText } from '../../../../../address';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AddressValue = Record<string, any> | undefined;

export function AddressOrStateDslInput({
  value,
  onChange,
  fieldPath,
  schema,
  name,
  accountUuid,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
SchemaInputControlCustomInputProps<any, any>): JSX.Element | null {
  // if accountUuid was not provided, we can't render the address selector
  if (!accountUuid) {
    return <AddressDisplayField address={value} width="100%" />;
  }
  return (
    <AddressOrState
      value={value}
      onChange={onChange}
      schema={schema}
      fieldPath={fieldPath}
      name={name}
      accountUuid={accountUuid}
    />
  );
}

// eslint-disable-next-line react/function-component-definition
export const AddressOrStateDslDisplay: CustomValue<AddressValue, Omit<CurrencyOrCheckboxInputMetadata, 'label'>> = ({
  value,
  field,
}) => {
  if (value?.address) {
    return <AddressFormattedText address={value.address} />;
  }

  if (value?.state) {
    return value.state;
  }
  // fallback
  const displayValue = getStringifiedFieldValue(value?.state, field.state as SchemaFieldType);
  return (
    <Text prewrap size={field.size} color={field.color} weight={field.weight} testId={field.testId}>
      {displayValue || '-'}
    </Text>
  );
};
