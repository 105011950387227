import { colors } from '../../theme/colors';

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  size?: number;
  color?: string;
}

const DEFAULT_COLOR = colors.steel[400];

/* eslint-disable  max-len */
export const MiniIconName = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.75C4.35 6.75 3 5.4 3 3.75V3C3 1.35 4.35 0 6 0C7.65 0 9 1.35 9 3V3.75C9 5.4 7.65 6.75 6 6.75Z"
      fill={color}
    />
    <path d="M7.5 8.25H4.5C2.4 8.25 0.75 9.9 0.75 12H11.25C11.25 9.9 9.6 8.25 7.5 8.25Z" fill={color} />
  </svg>
);

export const MiniIconBusiness = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 6V0.75C9.75 0.3 9.45 0 9 0H3C2.55 0 2.25 0.3 2.25 0.75V6H0V12H12V6H9.75ZM7.5 9.75H4.5V8.25H7.5V9.75ZM7.5 6.75H4.5V5.25H7.5V6.75ZM7.5 3.75H4.5V2.25H7.5V3.75Z"
      fill={color}
    />
  </svg>
);

export const MiniIconDocument = ({ color = DEFAULT_COLOR, size = 12 }: Props): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 11.25V3.75L7.5 0H1.5C1.05 0 0.75 0.3 0.75 0.75V11.25C0.75 11.7 1.05 12 1.5 12H10.5C10.95 12 11.25 11.7 11.25 11.25ZM2.25 1.5H6.75V4.5H9.75V10.5H2.25V1.5Z"
      fill={color}
    />
  </svg>
);

export const MiniIconDate = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 2H10V0H8V2H4V0H2V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3L0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V3C12 2.73478 11.8946 2.48043 11.7071 2.29289C11.5196 2.10536 11.2652 2 11 2V2ZM10 10H2V5H10V10Z"
      fill={color}
    />
  </svg>
);

export const MiniIconCurrency = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 1.5H0.75C0.3 1.5 0 1.8 0 2.25V9.75C0 10.2 0.3 10.5 0.75 10.5H11.25C11.7 10.5 12 10.2 12 9.75V2.25C12 1.8 11.7 1.5 11.25 1.5ZM8.325 9H3.675C3.375 7.95 2.55 7.125 1.5 6.825V5.175C2.55 4.875 3.375 4.05 3.675 3H8.4C8.7 4.05 9.525 4.875 10.575 5.175V6.9C9.45 7.125 8.625 7.95 8.325 9Z"
      fill={color}
    />
    <path
      d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z"
      fill={color}
    />
  </svg>
);

export const MiniIconNote = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg height="12" width="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M10,0H2A1,1,0,0,0,1,1V11a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V1A1,1,0,0,0,10,0ZM9,10H3V2H9Z" fill={color} />
      <rect height="1" width="4" x="4" y="4" />
      <rect height="1" width="4" x="4" y="7" />
    </g>
  </svg>
);

export const MiniIconHash = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.28467 5.06301L8.82725 6.96986H11.3382V8.66301H8.44769L7.6983 12H5.55718L6.3163 8.66301H4.42822L3.68856 12H1.59611L2.3163 8.66301H0V6.96986H2.69586L3.15328 5.06301H0.70073V3.34521H3.51338L4.26277 0H6.39416L5.64477 3.34521H7.57178L8.3309 0H10.4234L9.66423 3.34521H12V5.06301H9.28467ZM4.80779 6.96986H6.71533L7.17275 5.06301H5.26521L4.80779 6.96986Z"
      fill={color}
    />
  </svg>
);

export const MiniIconHourGlass = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5H10" stroke={color} strokeWidth="1.66667" strokeMiterlimit="10" strokeLinecap="square" />
    <path d="M1 12.5H10" stroke={color} strokeWidth="1.66667" strokeMiterlimit="10" strokeLinecap="square" />
    <path
      d="M3.5 10.9999V10.6054C3.50003 10.5071 3.52907 10.4109 3.5835 10.3289L5.0835 8.07894C5.12916 8.01046 5.19102 7.95431 5.26359 7.91548C5.33616 7.87664 5.41719 7.85632 5.4995 7.85632C5.58181 7.85632 5.66284 7.87664 5.73541 7.91548C5.80798 7.95431 5.86984 8.01046 5.9155 8.07894L7.4155 10.3289C7.47029 10.4111 7.49952 10.5077 7.4995 10.6064V10.9999H3.5Z"
      fill={color}
    />
    <path
      d="M2 12.5V10.6055C2.00001 10.2106 2.11692 9.82455 2.336 9.496L4 7L2.336 4.504C2.11692 4.17545 2.00001 3.78939 2 3.3945V1.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeMiterlimit="10"
    />
    <path
      d="M9 1.5V3.3945C8.99999 3.78939 8.88308 4.17545 8.664 4.504L7 7L8.664 9.496C8.88308 9.82455 8.99999 10.2106 9 10.6055V12.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeMiterlimit="10"
    />
  </svg>
);

export const MiniIconPerson = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6.75C4.35 6.75 3 5.4 3 3.75V3C3 1.35 4.35 0 6 0C7.65 0 9 1.35 9 3V3.75C9 5.4 7.65 6.75 6 6.75Z"
      fill={color}
    />
    <path d="M7.5 8.25H4.5C2.4 8.25 0.75 9.9 0.75 12H11.25C11.25 9.9 9.6 8.25 7.5 8.25Z" fill={color} />
  </svg>
);

export const MiniIconGroup = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3594_55164)">
      <path
        d="M4.28571 7.75H7.71429C8.81424 7.75 9.82033 8.38369 10.5615 9.31561C11.2447 10.1747 11.6783 11.2585 11.7419 12.25H0.258125C0.321728 11.2585 0.755326 10.1747 1.43852 9.31561C2.17967 8.38369 3.18576 7.75 4.28571 7.75Z"
        fill={color}
        stroke="white"
        strokeWidth="0.5"
      />
      <circle cx="6" cy="3.5" r="2.75" fill={color} stroke="white" strokeWidth="0.5" />
    </g>
    <g clipPath="url(#clip1_3594_55164)">
      <path
        d="M9.28571 8.85H12.7143C13.7742 8.85 14.7542 9.46116 15.4832 10.3779C16.1308 11.1922 16.5486 12.2103 16.6338 13.15H5.36617C5.45138 12.2103 5.86918 11.1922 6.51679 10.3779C7.24582 9.46116 8.22578 8.85 9.28571 8.85Z"
        fill={color}
        stroke="white"
        strokeWidth="0.7"
      />
      <circle cx="11" cy="4.5" r="2.65" fill={color} stroke="white" strokeWidth="0.7" />
    </g>
    <defs>
      <clipPath id="clip0_3594_55164">
        <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
      </clipPath>
      <clipPath id="clip1_3594_55164">
        <rect width="12" height="12" fill="white" transform="translate(5 1.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const MiniIconContactCard = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.25 1.5H9.75C9.3 1.5 9 1.8 9 2.25C9 2.7 8.7 3 8.25 3C7.8 3 7.5 2.7 7.5 2.25C7.5 1.8 7.2 1.5 6.75 1.5H5.25C4.8 1.5 4.5 1.8 4.5 2.25C4.5 2.7 4.2 3 3.75 3C3.3 3 3 2.7 3 2.25C3 1.8 2.7 1.5 2.25 1.5H0.75C0.3 1.5 0 1.8 0 2.25V9.75C0 10.2 0.3 10.5 0.75 10.5H11.25C11.7 10.5 12 10.2 12 9.75V2.25C12 1.8 11.7 1.5 11.25 1.5ZM5.25 8.25H2.25V5.25H5.25V8.25ZM9.75 8.25H6.75V7.5H9.75V8.25ZM9.75 6H6.75V5.25H9.75V6Z"
      fill={color}
    />
  </svg>
);

export const MiniIconQuestionMark = ({ color = '#B8BEC2' }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6" r="6" fill={color} />
    <path
      d="M5.0439 7.64992V7.28802C5.0439 6.97503 5.11231 6.7028 5.24914 6.47131C5.38596 6.23983 5.63626 5.99368 6.00001 5.73285C6.35042 5.48833 6.58069 5.28945 6.69082 5.13622C6.80429 4.98298 6.86102 4.81181 6.86102 4.62272C6.86102 4.4108 6.78093 4.24941 6.62074 4.13856C6.46055 4.02771 6.23696 3.97228 5.94996 3.97228C5.44937 3.97228 4.87871 4.13204 4.23796 4.45155L3.69232 3.38054C4.43652 2.973 5.22578 2.76923 6.06008 2.76923C6.74755 2.76923 7.29319 2.93061 7.69699 3.25338C8.10414 3.57615 8.30771 4.00652 8.30771 4.54447C8.30771 4.9031 8.22428 5.21283 8.05741 5.47366C7.89055 5.73448 7.57352 6.02791 7.1063 6.35394C6.78593 6.58543 6.58236 6.76148 6.49559 6.88211C6.41216 7.00275 6.37045 7.16087 6.37045 7.35649V7.64992H5.0439ZM4.88371 9.32246C4.88371 9.04859 4.9588 8.84156 5.10897 8.70137C5.25915 8.56118 5.47774 8.49108 5.76474 8.49108C6.04173 8.49108 6.25531 8.56281 6.40549 8.70626C6.559 8.84972 6.63576 9.05512 6.63576 9.32246C6.63576 9.58003 6.559 9.7838 6.40549 9.93377C6.25197 10.0805 6.03839 10.1538 5.76474 10.1538C5.48441 10.1538 5.26749 10.0821 5.11398 9.93866C4.96047 9.79195 4.88371 9.58655 4.88371 9.32246Z"
      fill="#ffffff"
    />
  </svg>
);

export const MiniIconInformation = ({ color = '#B8BEC2' }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1994_5769)">
      <path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM9 12H7V7H9V12ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 9 4.4 9 5C9 5.6 8.6 6 8 6Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1994_5769">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MiniIconPlus = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1429 5.21429H7.28571V1.35714C7.28571 0.883839 6.90188 0.5 6.42857 0.5H5.57143C5.09812 0.5 4.71429 0.883839 4.71429 1.35714V5.21429H0.857143C0.383839 5.21429 0 5.59812 0 6.07143V6.92857C0 7.40188 0.383839 7.78571 0.857143 7.78571H4.71429V11.6429C4.71429 12.1162 5.09812 12.5 5.57143 12.5H6.42857C6.90188 12.5 7.28571 12.1162 7.28571 11.6429V7.78571H11.1429C11.6162 7.78571 12 7.40188 12 6.92857V6.07143C12 5.59812 11.6162 5.21429 11.1429 5.21429Z"
      fill={color}
    />
  </svg>
);

export const MiniIconClock = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 2.5V6H9.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MiniIconLocation = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.8538 0C2.89184 0 0.53746 2.325 0.53746 5.25C0.53746 6.675 1.06909 8.025 2.13236 9C2.20831 9.075 5.24621 11.775 5.32216 11.85C5.62595 12.075 6.08164 12.075 6.30948 11.85C6.38543 11.775 9.49928 9.075 9.49928 9C10.5625 8.025 11.0942 6.675 11.0942 5.25C11.1701 2.325 8.81575 0 5.8538 0ZM5.8538 6.75C5.01837 6.75 4.33484 6.075 4.33484 5.25C4.33484 4.425 5.01837 3.75 5.8538 3.75C6.68922 3.75 7.37275 4.425 7.37275 5.25C7.37275 6.075 6.68922 6.75 5.8538 6.75Z"
      fill={color}
    />
  </svg>
);

export const MiniIconEmail = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5L0 1.8L6 5.175L12 1.875V1.5C12 1.10218 11.842 0.720644 11.5607 0.43934C11.2794 0.158035 10.8978 0 10.5 0Z"
      fill={color}
    />
    <path
      d="M5.625 6.67502L0 3.52502V9.00002C0 9.39785 0.158035 9.77938 0.43934 10.0607C0.720644 10.342 1.10218 10.5 1.5 10.5H10.5C10.8978 10.5 11.2794 10.342 11.5607 10.0607C11.842 9.77938 12 9.39785 12 9.00002V3.52502L6.375 6.67502C6.2568 6.72696 6.1291 6.75377 6 6.75377C5.8709 6.75377 5.7432 6.72696 5.625 6.67502Z"
      fill={color}
    />
  </svg>
);

export const MiniIconPhone = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M9 0H3C2.46957 0 1.96086 0.210714 1.58579 0.585786C1.21071 0.960859 1 1.46957 1 2V10C1 10.5304 1.21071 11.0391 1.58579 11.4142C1.96086 11.7893 2.46957 12 3 12H9C9.53043 12 10.0391 11.7893 10.4142 11.4142C10.7893 11.0391 11 10.5304 11 10V2C11 1.46957 10.7893 0.960859 10.4142 0.585786C10.0391 0.210714 9.53043 0 9 0V0ZM3 9V2H9V9H3Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export const MiniIconFollowUpDate = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M7.5 10.5H4.5C4.5 11.325 5.175 12 6 12C6.825 12 7.5 11.325 7.5 10.5Z" fill={color} />
    <path
      d="M11.25 8.25H10.875C10.35 7.725 9.75 6.975 9.75 6V3.75C9.75 1.65 8.1 0 6 0C3.9 0 2.25 1.65 2.25 3.75V6C2.25 6.975 1.65 7.725 1.125 8.25H0.75C0.3 8.25 0 8.55 0 9C0 9.45 0.3 9.75 0.75 9.75H11.25C11.7 9.75 12 9.45 12 9C12 8.55 11.7 8.25 11.25 8.25Z"
      fill={color}
    />
  </svg>
);

export const MiniIconBriefcase = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.80056 8.92847V8.42847H0.606565L0.600565 10.5793C0.600565 11.2488 1.13456 11.7856 1.80056 11.7856H10.2006C10.8666 11.7856 11.4006 11.2488 11.4006 10.5793V8.42847H7.20056V8.92847H4.80056ZM10.8006 3.34117H8.39456V2.13482L7.19456 0.928467H4.79456L3.59456 2.13482V3.34117H1.20056C0.540565 3.34117 0.000564575 3.88402 0.000564575 4.54751V6.22212C0.000564575 6.89164 0.534565 7.42847 1.20056 7.42847H4.80056V6.35704H7.20056V7.42847H10.8006C11.4606 7.42847 12.0006 6.88561 12.0006 6.22212V4.54751C12.0006 3.88402 11.4606 3.34117 10.8006 3.34117ZM7.20056 3.34117H4.80056V2.13482H7.20056V3.34117Z"
      fill={color}
    />
  </svg>
);

export const MiniIconPieChart = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.89967 7.00293H5.42473V2.52851C5.42473 2.47346 5.37968 2.42841 5.32462 2.42841H4.99926C4.34264 2.42774 3.69235 2.55672 3.0857 2.80794C2.47905 3.05916 1.92798 3.42768 1.46411 3.89236C1.0074 4.3476 0.643589 4.88731 0.392933 5.48143C0.132646 6.09828 0 6.75268 0 7.42835C0 8.10402 0.132646 8.75716 0.392933 9.37402C0.644461 9.96836 1.00486 10.5039 1.46411 10.9631C1.92337 11.4223 2.45771 11.7826 3.05337 12.0341C3.66922 12.2954 4.33153 12.4295 5.00051 12.4283C5.65713 12.429 6.30742 12.3 6.91407 12.0488C7.52072 11.7975 8.07179 11.429 8.53566 10.9643C8.99492 10.5051 9.35532 9.97086 9.60684 9.37527C9.86812 8.75949 10.0022 8.09725 10.001 7.42835V7.10303C9.99978 7.04797 9.95473 7.00293 9.89967 7.00293ZM11.9996 5.30849L11.9671 4.95564C11.8607 3.80451 11.3502 2.71844 10.5268 1.89888C9.70411 1.07702 8.62007 0.568477 7.46213 0.461206L7.10799 0.428674C8.04917 -0.576331 6.99912 0.468713 6.99912 0.527521V5.32851C6.99912 5.38357 7.04417 5.42861 7.09923 5.42861L11.8995 5.4161C11.9583 5.41485 12.0046 5.36605 11.9996 5.30849Z"
      fill={color}
    />
  </svg>
);

export const MiniIconBusinessAlternative = ({ color = DEFAULT_COLOR }: Props): JSX.Element => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 10.5H7V7.5H5V10.5H3V5.5H1V11.5C1 11.7652 1.10536 12.0196 1.29289 12.2071C1.48043 12.3946 1.73478 12.5 2 12.5H10C10.2652 12.5 10.5196 12.3946 10.7071 12.2071C10.8946 12.0196 11 11.7652 11 11.5V5.5H9V10.5Z"
      fill={color}
    />
    <path
      d="M11.916 3.723L9.91599 0.723C9.87037 0.654449 9.80853 0.598226 9.73596 0.559323C9.66338 0.52042 9.58233 0.500043 9.49999 0.5H2.49999C2.41764 0.500043 2.33659 0.52042 2.26402 0.559323C2.19144 0.598226 2.1296 0.654449 2.08399 0.723L0.083986 3.723C0.033881 3.79829 0.00513697 3.88577 0.000815375 3.97611C-0.00350622 4.06645 0.0167564 4.15627 0.0594452 4.236C0.102134 4.31573 0.16565 4.3824 0.243229 4.42889C0.320808 4.47538 0.409544 4.49995 0.499986 4.5H11.5C11.5904 4.49995 11.6792 4.47538 11.7567 4.42889C11.8343 4.3824 11.8978 4.31573 11.9405 4.236C11.9832 4.15627 12.0035 4.06645 11.9992 3.97611C11.9948 3.88577 11.9661 3.79829 11.916 3.723Z"
      fill={color}
    />
  </svg>
);
