import urlcat from 'urlcat';

import type {
  ArchiveInsurableItemParams,
  GetDefinitionsParams,
  GetEnrichedLinesOfCoverageFromAccountParams,
  GetEnrichedLinesOfCoverageFromPolicyParams,
  GetEnrichedScheduledItemByUuidParams,
  GetInsurableItemByUuidParams,
  GetInsurableItemMetadataParams,
  GetLineOfCoverageMetadataParams,
  GetPoliciesFromAccountParams,
  GetPolicyByUuidParams,
  GetScheduledItemsFromInsurableItemParams,
  UnarchiveInsurableItemParams,
  UnlinkParams,
  UpdateInsurableItemDisplayNameParams,
  UpdateInsurableItemParams,
  UpdatePolicyBillingParams,
  UpdatePolicyDateRangeParams,
  UpdatePolicyDetailsParams,
  UpdatePolicyLinesOfCoverageParams,
  UpdatePolicyNotesParams,
} from './types';

function route<Params>(path: string) {
  return (params?: Params, basePath?: string): string => `${basePath ?? ''}${urlcat(path, params ?? {})}`;
}

interface Route<Params = unknown> {
  path: string;
  getUrl: (params: Params, basePath?: string) => string;
}

function makeRoute<Params = object>(path: string): Route<Params> {
  return {
    path,
    getUrl: route<Params>(path),
  };
}

export const routes = {
  accountEnrichedLinesOfCoverage: makeRoute<GetEnrichedLinesOfCoverageFromAccountParams>(
    '/line-of-coverage-from-account/:accountUuid',
  ),
  accountPolicies: makeRoute<GetPoliciesFromAccountParams>('/policies-from-account/:accountUuid'),
  createScheduledItems: makeRoute('/scheduled-item/create'),
  updateScheduledItem: makeRoute('/scheduled-item/:scheduledItemUuid/update'),
  deleteScheduledItem: makeRoute('/scheduled-item/:scheduledItemUuid/delete'),
  definitions: makeRoute<GetDefinitionsParams>('/definitions'),
  enrichedScheduledItemByUuid: makeRoute<GetEnrichedScheduledItemByUuidParams>(
    '/scheduled-item/enriched/:scheduledItemUuid',
  ),
  createInsurableItem: makeRoute('/insurable-items/create'),
  insurableItemByUuid: makeRoute<GetInsurableItemByUuidParams>('/insurable-items/:insurableItemUuid'),
  updateInsurableItem: makeRoute<UpdateInsurableItemParams>('/insurable-items/:insurableItemUuid/update'),
  updateInsurableItemDisplayName: makeRoute<UpdateInsurableItemDisplayNameParams>(
    '/insurable-items/:insurableItemUuid/update-display-name',
  ),
  archiveInsurableItem: makeRoute<ArchiveInsurableItemParams>('/insurable-items/:insurableItemUuid/archive'),
  unarchiveInsurableItem: makeRoute<UnarchiveInsurableItemParams>('/insurable-items/:insurableItemUuid/activate'),
  insurableItemMetadata: makeRoute<GetInsurableItemMetadataParams>('/insurable-item-metadata/:insurableItemType'),
  insurableItemTypeDefinitions: makeRoute('/insurable-item-type-definitions'),
  insurableItemsByAccount: makeRoute('/insurable-items-from-account/:accountUuid'),
  linesOfCoverageMetadata: makeRoute<GetLineOfCoverageMetadataParams>('/line-of-coverage-metadata/:lineOfCoverageType'),
  policyByUuid: makeRoute<GetPolicyByUuidParams>('/policies/:policyUuid'),
  createHistoricalPolicy: makeRoute('/create-historical-policy'),
  renewPolicyAsIs: makeRoute('/renew-policy-as-is'),
  policyEnrichedLinesOfCoverage: makeRoute<GetEnrichedLinesOfCoverageFromPolicyParams>(
    '/line-of-coverage-from-policy/:policyUuid',
  ),
  unlink: makeRoute<UnlinkParams>('/scheduled-items/:scheduledItemUuid/unlink'),
  updatePolicyLinesOfCoverage: makeRoute<UpdatePolicyLinesOfCoverageParams>('/policies/:policyUuid/lines-of-coverage'),
  updatePolicyNotes: makeRoute<UpdatePolicyNotesParams>('/policies/:policyUuid/notes'),
  updatePolicyBilling: makeRoute<UpdatePolicyBillingParams>('/policies/:policyUuid/billing'),
  updatePolicyDetails: makeRoute<UpdatePolicyDetailsParams>('/policies/:policyUuid/details'),
  updatePolicyDateRange: makeRoute<UpdatePolicyDateRangeParams>('/policies/:policyUuid/date-range'),
  scheduledItemsByInsurableItem: makeRoute<GetScheduledItemsFromInsurableItemParams>(
    '/insurable-items/:insurableItemUuid/scheduled-items',
  ),
};
