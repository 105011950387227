/* eslint-disable react/no-array-index-key */
import type { CalendarDate } from '@newfront-insurance/string-formatters';
import styled from 'styled-components';

import { DayCell, EmptyDayCell } from './day';
import { Text } from '../../text';
import type { CalendarHook } from '../hooks/calendar';

const labels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

interface Props {
  onClick: (date: CalendarDate) => unknown;
  calendarHook: CalendarHook;
  validate?: (date: CalendarDate) => boolean;
  selectedDate?: CalendarDate;
}

export default function CalendarMonth(props: Props): JSX.Element {
  const { calendarHook, onClick, validate, selectedDate } = props;
  const { calendar } = calendarHook;

  return (
    <div>
      <StyledCalendar cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            {labels.map((label) => (
              <StyledTableHeader key={label}>
                <Text weight={600} color="dark">
                  {label}
                </Text>
              </StyledTableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendar.map((week, index) => (
            <tr key={index}>
              {week.map((calendarDay) => {
                const isValid = validate ? validate(calendarDay) : true;
                const isSelected = selectedDate && calendarDay.isSameDate(selectedDate);
                const isCurrentMonth = calendarDay.getMonth() === calendarHook.month;

                return (
                  <DayCell
                    day={calendarDay}
                    isSelected={isSelected}
                    isDisabled={!isValid}
                    isCurrentMonth={isCurrentMonth}
                    key={calendarDay.toString()}
                    onClick={() => {
                      onClick(calendarDay);
                    }}
                  />
                );
              })}
            </tr>
          ))}
          {calendar.length === 5 && (
            <tr>
              {[...Array(7)].map((_, index) => (
                <EmptyDayCell key={`empty-day-${index}`} />
              ))}
            </tr>
          )}
        </tbody>
      </StyledCalendar>
    </div>
  );
}

const StyledCalendar = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const StyledTableHeader = styled.th`
  height: 30px;
  padding-top: 5px;
`;
