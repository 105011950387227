import { CheckboxRow, Flexbox, Input, Text } from '@newfront-insurance/core-ui';
import type { NumberOrCheckboxInputMetadata } from '@newfront-insurance/coverage-api';
import { getStringifiedFieldValue } from '@newfront-insurance/dsl-schema-ui';
import type { SchemaInputControlCustomInputProps, CustomValue } from '@newfront-insurance/dsl-schema-ui';

export type NumberOrCheckboxInputValue = Record<string, number | boolean | undefined> | undefined;

export function NumberOrCheckboxDslInput({
  onChange,
  value,
  metadata,
}: SchemaInputControlCustomInputProps<NumberOrCheckboxInputValue, NumberOrCheckboxInputMetadata>): JSX.Element | null {
  // we can assume the metadata is always defined
  if (!metadata) {
    return null;
  }

  const checkboxValue = value?.[metadata.checkboxFieldName] || false;

  function handleNumberChange(stringValue: string): void {
    // metadata should always be defined
    if (!metadata) {
      return;
    }

    if (!stringValue && !checkboxValue) {
      onChange(undefined);
      return;
    }

    onChange({
      ...value,
      [metadata.numberFieldName]: Number(stringValue),
      [metadata.checkboxFieldName]: checkboxValue || false,
    });
  }

  function handleCheckboxChange(isActive: boolean): void {
    // metadata should always be defined
    if (!metadata) {
      return;
    }

    if (!isActive) {
      onChange(undefined);

      return;
    }

    onChange({
      [metadata.checkboxFieldName]: isActive,
    });
  }

  return (
    <Flexbox flexDirection="column" gap={8} marginTop="auto">
      <Input
        type="number"
        appendText={metadata?.appendText}
        value={(value?.[metadata.numberFieldName] as number) ?? ''}
        onChange={handleNumberChange}
        disabled={!!checkboxValue}
      />
      <CheckboxRow
        label={metadata?.checkboxFieldLabel ?? ''}
        isActive={!!checkboxValue}
        onClick={handleCheckboxChange}
      />
    </Flexbox>
  );
}

// eslint-disable-next-line react/function-component-definition
export const NumberOrCheckboxDisplay: CustomValue<NumberOrCheckboxInputValue, NumberOrCheckboxInputMetadata> = ({
  field,
  value,
}) => {
  return <Text>{getStringifiedFieldValue(value, field)}</Text>;
};
