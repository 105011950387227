import type { DefinitionListItem } from '@newfront-insurance/core-ui';
import { DefinitionList } from '@newfront-insurance/core-ui';
import { CalendarDate, DateFormatStyle } from '@newfront-insurance/string-formatters';
import compact from 'lodash/compact';

import type { Policy } from '../../bff/types';
import { stringifyLinesOfCoverage } from '../../utils/stringifyLinesOfCoverage';

interface Props {
  policy: Policy;
  accountHref?: string;
  onAccountClick?: () => void;
  hideTitle?: boolean;
}

export function PolicyDefinitionList(props: Props): JSX.Element {
  const { policy, hideTitle, onAccountClick, accountHref } = props;

  return (
    <DefinitionList
      hideTitle={hideTitle}
      items={createPolicyDefinitionList({
        policy,
        onAccountClick,
        accountHref,
      })}
      labelWidth="150px"
    />
  );
}

export function createPolicyDefinitionList(props: Props): DefinitionListItem[] {
  const { policy, accountHref, onAccountClick } = props;
  const { account, expirationDate, linesOfCoverage, effectiveDate, cancellationEffectiveDate, policyNumber, carrier } =
    policy;

  let items: DefinitionListItem[] = [];

  if (account) {
    items.push({
      type: 'name',
      title: 'Client',
      value: account?.name || '-',
      href: accountHref,
      onClick: onAccountClick,
    });
  }

  items = compact([
    ...items,
    linesOfCoverage && {
      type: 'document',
      title: 'Line of coverage',
      value: linesOfCoverage.length > 0 ? stringifyLinesOfCoverage(linesOfCoverage) : '-',
    },
    {
      type: 'dateRange',
      title: 'Effective dates',
      value: `${CalendarDate.fromDate(effectiveDate, 'UTC').format({
        style: DateFormatStyle.STANDARD,
      })} - ${CalendarDate.fromDate(cancellationEffectiveDate ?? expirationDate, 'UTC').format({
        style: DateFormatStyle.STANDARD,
      })}`,
    },
    cancellationEffectiveDate && {
      type: 'dateRange',
      title: 'Prev. exp. date',
      value: `${CalendarDate.fromDate(expirationDate, 'UTC').format({
        style: DateFormatStyle.STANDARD,
      })}`,
    },
    {
      type: 'business',
      title: 'Carrier',
      value: carrier?.name || '-',
    },
  ]);

  if (policyNumber) {
    items.push({
      type: 'number',
      title: 'Policy number',
      value: policyNumber,
      target: '_blank',
    });
  }

  return items;
}
