/* eslint-disable react/jsx-no-constructed-context-values */
import type { ReactNode } from 'react';
import { createContext } from 'react';

interface ContextValue {
  basePath: string;
}

export const NewfrontApiContext = createContext<ContextValue>({ basePath: '' });

interface Props {
  basePath: string;
  children: ReactNode;
}

/**
 * Add this provider to _app to enable the use of all of the hooks.
 */
export function NewfrontApiProvider(props: Props): JSX.Element {
  const { basePath, children } = props;
  return <NewfrontApiContext.Provider value={{ basePath }}>{children}</NewfrontApiContext.Provider>;
}
