import type { AuthProviderContext, AuthProviderProps, UsePermissionOptions } from '@newfront-insurance/next-auth';
import type { Provider } from '@newfront-insurance/react-provision';

import { useHasAllScopes, useHasAnyScope } from './scopes';

export function usePermission(
  opts: UsePermissionOptions,
  authProvider: Provider<AuthProviderContext, AuthProviderProps>,
): boolean {
  const { scopes, hasAll } = opts;
  const hasAnyScope = useHasAnyScope(authProvider, scopes);
  const hasAllScopes = useHasAllScopes(authProvider, scopes);

  if (hasAll) {
    return hasAllScopes;
  }

  return hasAnyScope;
}
