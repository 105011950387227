import type { DepartmentRole } from '@newfront-insurance/account-api';
import type { EmployeeAvatarType } from '@newfront-insurance/core-ui';
import type { Employee as ApiEmployee } from '@newfront-insurance/next-newfront-api';

export enum EmployeeStatus {
  ACTIVE = 'active',
  LOCKED = 'locked',
}

export type Employee = Pick<
  ApiEmployee,
  'email' | 'firstName' | 'imageUrl' | 'lastName' | 'legacyId' | 'name' | 'title' | 'userUuid' | 'uuid'
> & {
  extension?: number;
  phone?: string;
  type: EmployeeAvatarType;
  departments?: DepartmentRole[];
  roles?: string[];
  status?: EmployeeStatus;
};
