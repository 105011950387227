import { fontSizes } from '@newfront-insurance/core-ui';
import * as React from 'react';

import type { AppType } from '../../../../metadata/apps';
import { getAppTypeMetadata } from '../../../../metadata/apps';

interface Props {
  type: AppType;
  iconSize?: 32 | 40;
  textSize?: 'small' | 'large';
  showDescription?: boolean;
  isShowingDarkSidebar?: boolean;
}

export function AppBadge(props: Props): JSX.Element {
  const { type, iconSize = 32, textSize = 'small', showDescription = false, isShowingDarkSidebar } = props;
  const { label, icon: Icon, description } = getAppTypeMetadata(type);

  const textSizeValue = textSize === 'small' ? fontSizes.regular.fontSize : fontSizes.large.fontSize;

  return (
    <div className={`flex flex-row items-center ${iconSize === 40 ? 'gap-5' : 'gap-2'}`}>
      <Icon size={iconSize} />
      <div className={`flex flex-col ${!showDescription ? 'gap-1' : 'gap-0'}`}>
        <span
          className="block font-semibold leading-4"
          style={
            { '--fontSize': textSizeValue, color: isShowingDarkSidebar ? '#fff' : '#db0e63' } as React.CSSProperties
          }
        >
          {label}
        </span>
        {showDescription ? <span className=" text-sm leading-3 text-steel-400">{description}</span> : null}
      </div>
    </div>
  );
}
