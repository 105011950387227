import { getBusinessIdentifierLabel, IDENTITY_TYPE_LABELS } from '@newfront-insurance/account-common';
import type { DefinitionListItem } from '@newfront-insurance/core-ui';

import type { AccountCardAccount, AccountCardPublicAccount, AccountCardAccountBase } from './types';

export function buildIdentitiesDefinitions(
  account: Pick<AccountCardAccountBase, 'businessIdentifier' | 'personalIdentities'>,
  showTitleAsBlock?: boolean,
): DefinitionListItem[] {
  const { businessIdentifier, personalIdentities } = account;

  if (personalIdentities && personalIdentities.length > 0) {
    return personalIdentities.map((identity) => ({
      title: IDENTITY_TYPE_LABELS[identity.type],
      value: showTitleAsBlock ? IDENTITY_TYPE_LABELS[identity.type] : identity.value,
      subValue: showTitleAsBlock ? identity.value : undefined,
      type: 'number',
    }));
  }

  if (businessIdentifier) {
    return [
      {
        title: getBusinessIdentifierLabel(businessIdentifier?.countryCode || 'US') || '',
        value: businessIdentifier?.value || '',
        type: 'number',
      },
    ];
  }

  return [];
}

export function isPublicAccount(
  account: AccountCardAccount | AccountCardPublicAccount,
): account is AccountCardPublicAccount {
  return !('details' in account);
}
