import classnames from 'classnames';
import * as React from 'react';
import type { StyledComponentProps } from 'styled-components';
import styled from 'styled-components';

import { shadows } from '../../theme';
import { colors } from '../../theme/colors';

type StyledProps = StyledComponentProps<'div', object, object, never>;

interface Props {
  backgroundColor?: string;
  borderColor?: string;
  children: React.ReactNode;
  hasShadow?: boolean;
  isRounded?: boolean;
  overflow?: string;
  testId?: string;
}

export function Card({
  children,
  borderColor,
  backgroundColor,
  hasShadow = true,
  overflow = 'hidden',
  isRounded = true,
  className,
  testId,
  ...rest
}: Props & StyledProps): JSX.Element {
  const classNames = classnames({ hasShadow, [`${className}`]: className });

  return (
    <StyledCard
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      className={classNames}
      overflow={overflow}
      isRounded={isRounded}
      testId={testId}
      // eslint-disable-next-line
      {...rest}
    >
      {children}
    </StyledCard>
  );
}

const StyledCard = styled.div`
  background-color: ${({ backgroundColor }: Props) => backgroundColor || 'white'};
  border: 1px solid ${({ borderColor }: Props) => borderColor || '#dbdbdb'};
  border-radius: ${({ isRounded }: Props) => (isRounded ? '4px' : '0px')};
  box-sizing: border-box;
  overflow: ${({ overflow }: Props) => overflow};

  &.hasShadow {
    box-shadow: ${shadows.card};
  }
`;

/**
 * Read about making cards accessible: https://inclusive-components.design/cards/
 */
export const InteractiveCard = styled(Card).attrs((props) => ({
  tabIndex: 1,
  hasShadow: props.hasShadow || false,
}))`
  &:focus,
  &:active,
  &:hover {
    outline: none;
    cursor: pointer;
    border-color: ${colors.brand[400]};
    box-shadow: 0 0 0 3px ${colors.brand[100]};
  }
`;
