import { CheckboxInput } from '../../checkbox-input';
import { IconRadioButton } from '../../icons';
import { Padding } from '../../padding';
import type { DetailCardProps } from '../types';
import { INNER_PADDING } from '../utils';

export function SelectableControl(
  props: Pick<DetailCardProps, 'selectableOptions' | 'testId'> & { disabled?: boolean },
): JSX.Element {
  const { selectableOptions, testId, disabled } = props;
  return (
    <Padding top={INNER_PADDING}>
      <div
        aria-checked={!!selectableOptions?.isSelected}
        aria-hidden="true"
        onClick={selectableOptions?.type === 'single' ? selectableOptions?.onSelect : undefined}
        role="checkbox"
        tabIndex={0}
      >
        {selectableOptions?.type === 'multiple' ? (
          <CheckboxInput
            checked={selectableOptions?.isSelected}
            indeterminate={selectableOptions?.indeterminate}
            onChange={selectableOptions?.onSelect}
            testId={testId}
            disabled={disabled}
          />
        ) : (
          <IconRadioButton isActive={selectableOptions?.isSelected} testId={`${testId}-radio`} disabled={disabled} />
        )}
      </div>
    </Padding>
  );
}
