/* eslint-disable react/jsx-props-no-spreading */
import { useVirtualizer } from '@tanstack/react-virtual';
import type { UseComboboxReturnValue } from 'downshift';
import type { ReactNode, RefObject } from 'react';
import { useRef } from 'react';

import type { BaseSelectorProps } from './types';
import { Popper } from '../../popper';

import { Menu, SelectableItem } from '.';

interface Props<T>
  extends Pick<
    BaseSelectorProps<T>,
    | 'renderInputToolbar'
    | 'itemToString'
    | 'emptyPlaceholder'
    | 'placeholder'
    | 'isLoading'
    | 'renderNoResults'
    | 'items'
    | 'getItemKey'
    | 'getDisabledProps'
    | 'renderItem'
    | 'renderItemHover'
    | 'testId'
  > {
  isOpen: boolean;
  renderInput: (inputOptions: { triggerRef: RefObject<HTMLInputElement> }) => ReactNode;
  getComboboxProps: UseComboboxReturnValue<T>['getComboboxProps'];
  getToggleButtonProps: UseComboboxReturnValue<T>['getToggleButtonProps'];
  getItemProps: UseComboboxReturnValue<T>['getItemProps'];
  getMenuProps: UseComboboxReturnValue<T>['getMenuProps'];
  getIsSelectedItem: (item: T) => boolean;
  inputRef: RefObject<HTMLInputElement>;
  highlightedIndex: number;
  header?: ReactNode;
  menuFooter?: ReactNode;
}

export function Selector<T extends object>({
  getComboboxProps,
  getDisabledProps,
  getIsSelectedItem,
  getItemKey,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  isLoading,
  isOpen,
  items,
  menuFooter,
  header,
  renderInput,
  renderItem,
  renderItemHover,
  renderNoResults,
  testId,
}: Props<T>): JSX.Element {
  const menuRef = useRef<HTMLDivElement>(null);
  const menuOverflowRef = useRef<HTMLDivElement>(null);
  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => menuOverflowRef.current,
    estimateSize: () => 125,
    getItemKey: (index) => getItemKey(items[index]),
  });

  return (
    <div
      {...getComboboxProps(
        {
          style: {
            position: 'relative',
          },
        },
        { suppressRefError: true },
      )}
      data-testid={testId}
    >
      <Popper
        style={{
          width: '100%',
        }}
        isOpen={isOpen}
        placement="bottom"
        trigger={({ triggerRef }) => renderInput({ triggerRef: triggerRef as RefObject<HTMLInputElement> })}
      >
        <Menu
          header={header}
          isOpen={isOpen}
          isLoading={isLoading}
          overflowRef={menuOverflowRef}
          {...getMenuProps(
            {
              ref: menuRef,
            },
            { suppressRefError: true },
          )}
          footer={menuFooter}
        >
          {items.length === 0 ? (
            renderNoResults?.()
          ) : (
            <div
              style={{
                height: rowVirtualizer.getTotalSize(),
                position: 'relative',
                width: '100%',
              }}
              data-testid={`${testId}-item-container`}
            >
              {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                const item = items[virtualItem.index];
                const { index } = virtualItem;

                return (
                  <div
                    key={virtualItem.key}
                    ref={rowVirtualizer.measureElement}
                    data-index={virtualItem.index}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      transform: `translateY(${virtualItem.start}px)`,
                    }}
                    data-testid={`${testId}-hovered-items`}
                  >
                    <SelectableItem
                      key={getItemKey(item)}
                      getItemProps={getItemProps}
                      item={item}
                      isHovering={index === highlightedIndex}
                      isSelected={getIsSelectedItem(item)}
                      index={index}
                      renderItem={renderItem}
                      renderItemHover={renderItemHover}
                      getDisabledProps={getDisabledProps}
                      hasScrollbarOffset={getElementHasScrollbar(menuRef.current).vertical}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </Menu>
      </Popper>
    </div>
  );
}

/**
 * We need an extra offset on the hover popover in case there is a scrollbar, the scrollbar width isn't counted
 * as an offset by Popper, this detects the scrollbar
 */
function getElementHasScrollbar(element?: HTMLElement | null): { horizontal: boolean; vertical: boolean } {
  if (!element) {
    return {
      horizontal: false,
      vertical: false,
    };
  }

  const hasHorizontalScrollbar = element.scrollWidth > element.clientWidth;
  const hasVerticalScrollbar = element.scrollHeight > element.clientHeight;

  return {
    horizontal: hasHorizontalScrollbar,
    vertical: hasVerticalScrollbar,
  };
}
