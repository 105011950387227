import type { ConfigInterface, NextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useProvider } from '@newfront-insurance/react-provision';

import { routes } from '../../bff/routes';
import type { GetAccountByUuidParams, GetAccountByUuidResponse } from '../../bff/types';
import { LayoutConfigProvider } from '../config';

export function useAccountByUuid(
  params: GetAccountByUuidParams,
  config?: ConfigInterface,
): NextApiSubscription<GetAccountByUuidResponse | null> {
  const { apiBasePath: basePath } = useProvider(LayoutConfigProvider);

  return useNextApiSubscription<GetAccountByUuidResponse | null>(
    routes.getAccountByUuid.getUrl({ params, basePath }),
    config,
  );
}
