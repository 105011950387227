import type { Address } from '@newfront-insurance/address-api';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Padding,
  Text,
  Spacing,
  Flexbox,
  MessageBox,
} from '@newfront-insurance/core-ui';
import { useState } from 'react';
import type { internationalStreet } from 'smartystreets-javascript-sdk';

import { formatCandidateToAddress } from '../../../utils/format-street-address';
import { SelectableAddress } from '../selectable-address';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  candidates: internationalStreet.Candidate[];
  enteredAddress: Address;
  onSelectAddress: (selectedAddress?: Address) => void;
  width?: string;
}

export function VerifyInternationalAddressModal({
  isOpen,
  handleClose,
  enteredAddress,
  candidates,
  onSelectAddress,
  width,
}: Props): JSX.Element {
  const [selectedCandidate, setSelectedCandidate] = useState<Address | undefined>();
  const [selectEnteredAddress, setSelectedAddress] = useState<boolean>(false);

  // if there's no candidates, preselect the entered address
  if ((!candidates || candidates.length === 0) && !selectedCandidate && !selectEnteredAddress) {
    setSelectedAddress(true);
    setSelectedCandidate(enteredAddress);
  }

  function message(text: string): JSX.Element {
    return (
      <Padding y={12}>
        <MessageBox variant="alert" message={text} />
      </Padding>
    );
  }

  return (
    <Modal isOpen={!!isOpen} onToggle={() => handleClose()} width={width}>
      <ModalBody>
        <ModalHeader titleText="Verify international address" onClose={() => handleClose()} />
        <Padding size={24}>
          <div>
            <Text>Verification results</Text>
            {/** if there's no candicates display a message  */}
            {(!candidates || candidates.length === 0) && message('No verification results found for this address')}

            {/** if we have candidates ...  */}
            {candidates.length > 0 &&
              candidates.map((candidate: internationalStreet.Candidate) => {
                const formattedCandidateAddress = formatCandidateToAddress(candidate);
                const status = candidate.analysis.verificationStatus;

                /**
                 * If the candidate is unverified - dont display a SelectableOption.
                 * Display a message and preselect the entered address
                 */

                if (status === 'None') {
                  if (!selectedCandidate && !selectEnteredAddress) {
                    setSelectedAddress(true);
                    setSelectedCandidate(enteredAddress);
                  }
                  return message(
                    `This address could not be verified.
                    If you wish to proceed with your entered address please select it below,
                    otherwise click Cancel`,
                  );
                }

                return (
                  <SelectableAddress
                    key={formattedCandidateAddress.formattedAddress}
                    isActive={
                      selectEnteredAddress ||
                      !candidates ||
                      selectedCandidate?.formattedAddress === formattedCandidateAddress.formattedAddress
                    }
                    onChange={() => {
                      const formattedCandidate = formatCandidateToAddress(candidate);
                      setSelectedCandidate(formattedCandidate);
                    }}
                    candidate={candidate}
                  />
                );
              })}
          </div>

          {/** user entered address ...  */}
          <Text>Entered address</Text>
          <SelectableAddress
            isActive={selectEnteredAddress || selectedCandidate?.formattedAddress === enteredAddress.formattedAddress}
            onChange={() => setSelectedCandidate(enteredAddress)}
            enteredAddress={enteredAddress}
          />
        </Padding>
        <ModalFooter>
          <Flexbox alignItems="center" justifyContent="flex-end">
            <Button type="button" size="secondary" onClick={() => handleClose()}>
              Cancel
            </Button>
            <Spacing width={12} />
            <Button type="submit" disabled={!selectedCandidate} onClick={() => onSelectAddress(selectedCandidate)}>
              Select address
            </Button>
          </Flexbox>
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
