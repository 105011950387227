export { AccountUIApiContext, AccountUIApiProvider } from './provider';

export { ContactCard } from './components/contact-card';

export type { Contact } from './bff/types/contact';
export type {
  Account,
  AccountRoleMapping,
  AccountEmployeeRoleMapping,
  GetAccountByUuidParams,
  GetAccountByUuidResponse,
  GetAccountsParams,
  GetAccountsResponse,
  NormalizedAccountEmployeeRoleMappings,
} from './bff/types/account';
export type {
  PublicAccount,
  GetPublicAccountByUuidParams,
  GetPublicAccountByUuidResponse,
  GetPublicAccountsParams,
  GetPublicAccountsResponse,
} from './bff/types/public-account';
export type { Identity } from './bff/types/account-identity';
export type { Status } from './bff/types/status';
export type { Employee } from './bff/types/employee';
export { EmployeeStatus } from './bff/types/employee';

export * from './bff/formatters';

export * from './hooks';

// Utils and helpers
export * from './lib';

export { ACCOUNT as ACCOUNT_MOCK, API_ACCOUNT as API_ACCOUNT_MOCK } from './mocks/account';
export {
  PUBLIC_ACCOUNT as PUBLIC_ACCOUNT_MOCK,
  API_PUBLIC_ACCOUNT as API_PUBLIC_ACCOUNT_MOCK,
} from './mocks/public-account';
export {
  ACCOUNT_ROLE_MAPPINGS as ACCOUNT_ROLE_MAPPINGS_MOCK,
  ACCOUNT_EMPLOYEE_ROLE_MAPPINGS as ACCOUNT_EMPLOYEE_ROLE_MAPPINGS_MOCK,
  ACCOUNT_MANAGER,
  CLIENT_SERVICE_ASSOCIATE,
  PRODUCER,
  SECONDARY_PRODUCER,
} from './mocks/employees';
