import { useLoggedInUser } from '@/client/hooks/use-logged-in-user';

interface CurrentUserHook {
  currentUserUuid: string;
}

export function useCurrentUserUuid(): CurrentUserHook {
  const user = useLoggedInUser();
  if (!user) throw new Error('Not logged in');

  const currentUserUuid = user.uuid;

  return { currentUserUuid };
}
