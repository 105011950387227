import { useKeyEvent, useOnClickOutside, zIndices } from '@newfront-insurance/core-ui';
import { datadogRum } from '@newfront-insurance/dd-rum';
import * as React from 'react';
import styled from 'styled-components';

import { SearchContainer } from './components/container';
import { RenderCardPreview } from './helpers/index';
import { useItemResultRouting } from './hooks/use-item-result-routing';
import { useGlobalSearch } from './hooks/use-search';

interface Props {
  setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GlobalSearch({ setIsSearchActive }: Props): JSX.Element {
  const { handleSearch, isLoading, results, suggestions, handleReset, handleSelectSuggestion, error } =
    useGlobalSearch();

  const innerRef = React.createRef<HTMLDivElement>();

  useOnClickOutside(innerRef, (): void => {
    setIsSearchActive(false);
  });

  useKeyEvent('Escape', () => setIsSearchActive(false), []);

  const { goToItemResultPage } = useItemResultRouting();

  return (
    <StyledOverlay>
      <SearchContainer
        ref={innerRef}
        isSearching={isLoading}
        maxWidth="700px"
        onChange={(value) => {
          handleSearch(value);
        }}
        onClickResultItem={async (data, rank) => {
          setIsSearchActive(false);
          await goToItemResultPage(data.routing);
          datadogRum.addAction('Global search result selected', { searchResult: data, rank });
        }}
        onSetIsSearchActive={setIsSearchActive}
        onSelectSuggestion={async (suggestion: string) => {
          await handleSelectSuggestion(suggestion);
          datadogRum.addAction('Global search result selected (suggestion)', { suggestion });
        }}
        onReset={() => {
          handleReset();
        }}
        onRenderPreview={RenderCardPreview}
        placeholder="Search..."
        results={results}
        suggestions={suggestions}
        error={error}
      />
    </StyledOverlay>
  );
}

const StyledOverlay = styled.div`
  background: rgb(0, 0, 0, 0.3);
  transition: background ease-in-out 0.4ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.overlay};
  padding: 7px;
`;
