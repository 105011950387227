import { paramCase } from 'change-case';

type NestedObject = { [key: string]: NestedObject | string };

/**
 * Takes a theme object and returns a new flat object with valid CSS variables where the key is the
 * CSS variable name and the value is the color.
 */
export function toCSSVariables(input: NestedObject, prefix = '--nfui', initialColors = {}): Record<string, string> {
  return Object.keys(input).reduce((colors, name) => {
    const value = input[name];
    const newPrefix = `${prefix}-${paramCase(name)}`;
    if (typeof value === 'string') {
      return {
        ...colors,
        [newPrefix]: value,
      };
    }
    return toCSSVariables(value, newPrefix, colors);
  }, initialColors);
}
