import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { registerCustomDSLFields } from '../../features';

export function CoverageDslProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
  useEffect(() => {
    registerCustomDSLFields();
  }, []);
  return <>{children}</>;
}
