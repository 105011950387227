import type { IconProps } from './types';

export function IconPlus({ className }: IconProps) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" className={className}>
      <path
        // eslint-disable-next-line max-len
        d="M10.6565 4.875H7.28149V1.5C7.28149 1.08586 6.94563 0.75 6.53149 0.75H5.78149C5.36735 0.75 5.03149 1.08586 5.03149 1.5V4.875H1.65649C1.24235 4.875 0.906494 5.21086 0.906494 5.625V6.375C0.906494 6.78914 1.24235 7.125 1.65649 7.125H5.03149V10.5C5.03149 10.9141 5.36735 11.25 5.78149 11.25H6.53149C6.94563 11.25 7.28149 10.9141 7.28149 10.5V7.125H10.6565C11.0706 7.125 11.4065 6.78914 11.4065 6.375V5.625C11.4065 5.21086 11.0706 4.875 10.6565 4.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
