/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

import type { ErrorResponse, SearchResult, SearchSuggestion } from '../../../../bff/types';

interface SearchContextProps {
  activeResultIndex: number;
  currentQuery: string;
  error?: ErrorResponse;
  isSearching: boolean;
  recentlySearched: string[];
  recentlyVisited: SearchResult[];
  results?: SearchResult[];
  setActiveResultIndex: React.Dispatch<React.SetStateAction<number>>;
  setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  suggestions?: SearchSuggestion[];
}

export const SearchContext = createContext<SearchContextProps>({
  activeResultIndex: 0,
  currentQuery: '',
  error: undefined,
  isSearching: false,
  recentlySearched: [],
  recentlyVisited: [],
  results: undefined,
  setActiveResultIndex: () => {},
  setIsSearchActive: () => {},
  suggestions: undefined,
});
