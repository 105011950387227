import styled from 'styled-components';

import { usePopover } from '../../hooks/popover';
import { colors } from '../../theme/colors';
import type { Employee } from '../employee-badge';
import { EmployeeBadge } from '../employee-badge';
import { EmployeesDetailsPopover } from '../employees-details-popover';
import type { EmployeeDetailsPopoverVariant } from '../employees-details-popover/types';

interface Props {
  employees: Employee[];
  onCopyEmail?: () => unknown;
  variant?: EmployeeDetailsPopoverVariant;
  containerId?: string;
  viewMoreHref?: string;
}

export function EmployeesRow({
  employees,
  variant = 'details',
  onCopyEmail,
  containerId,
  viewMoreHref,
}: Props): JSX.Element {
  const [visibleEmployees, hiddenEmployees] = [employees.slice(0, 4), employees.slice(4)];
  const { popoverRef, targetRef, isPopoverOpen, togglePopover, closePopover } = usePopover();

  return (
    <>
      {visibleEmployees.length > 0 && (
        <EmployeesDetailsPopover
          popoverRef={popoverRef}
          targetRef={targetRef}
          isPopoverOpen={isPopoverOpen}
          closePopover={closePopover}
          employees={hiddenEmployees}
          variant={variant}
          onCopyEmail={onCopyEmail}
          containerId={containerId}
        />
      )}

      <div className="flex flex-row items-center gap-1">
        {visibleEmployees.map((employee) => (
          <EmployeeBadge
            key={employee.uuid}
            employee={employee}
            variant={variant}
            onCopyEmail={onCopyEmail}
            containerId={containerId}
          />
        ))}
        {hiddenEmployees.length > 0 && (
          <div className="pl-1">
            {viewMoreHref ? (
              <StyledButton type="button" href={viewMoreHref}>
                +{hiddenEmployees.length}
              </StyledButton>
            ) : (
              <StyledButton type="button" onClick={togglePopover} ref={targetRef as React.RefObject<HTMLAnchorElement>}>
                +{hiddenEmployees.length}
              </StyledButton>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  background: none;
  color: ${colors.brand[400]};
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
