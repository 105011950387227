import { toMap } from '@newfront-insurance/core';
import { useMemo } from 'react';

import { useAccountManagers } from './use-account-managers';
import { useOperationsEmployees } from './use-operations-employees';
import { useProducers } from './use-producers';
import type { Employee } from '../../bff/types';

export interface NormalizedServicingTeams {
  accountManagersMap: Map<string, Employee>;
  producersMap: Map<string, Employee>;
  operationsEmployeesMap: Map<string, Employee>;
}

const mapEmployees = (employeeList: Employee[]): Map<string, Employee> => {
  return toMap(employeeList, (employee) => employee.userUuid);
};

interface EmployeesReturnProps {
  accountManagers: Employee[];
  producers: Employee[];
  operationsEmployees: Employee[];
}

export function useEmployees(): EmployeesReturnProps {
  const { data: accountManagers } = useAccountManagers();
  const { data: producers } = useProducers();
  const { data: operationsEmployees } = useOperationsEmployees();

  return {
    accountManagers: accountManagers || [],
    producers: producers || [],
    operationsEmployees: operationsEmployees || [],
  };
}

export function useEmployeesByUserUuid(): NormalizedServicingTeams {
  const { accountManagers, producers, operationsEmployees } = useEmployees();

  const accountManagersMap = useMemo(() => mapEmployees(accountManagers), [accountManagers]);

  const producersMap = useMemo(() => mapEmployees(producers), [producers]);

  const operationsEmployeesMap = useMemo(() => mapEmployees(operationsEmployees), [operationsEmployees]);

  return { accountManagersMap, producersMap, operationsEmployeesMap };
}
