import type { DecodedJwt } from '@newfront-insurance/next-auth-api-types';

import type { AuthScopesInterface } from './types';

/**
 * Check to see if the current user roles contains any of the chosen scopes.
 * @param roles Array of scopes belonging to the current user
 * @param scopes Scopes you want to check
 * @returns boolean
 */
export function hasAnyScope(roles: string[], scopes: string[]): boolean {
  const uniqueScopes = new Set(roles);
  // Array.some returns false if the input array is empty
  return scopes.some((role) => uniqueScopes.has(role));
}

/**
 * Check to see if the current user roles contains ALL of the chosen scopes.
 * @param roles Array of scopes belonging to the current user
 * @param scopes Scopes you want to check
 * @returns boolean
 */
export function hasAllScopes(roles: string[], scopes: string[]): boolean {
  const uniqueScopes = new Set(roles);
  // This check is necessary as Array.every returns true if the input array is empty
  return scopes.length === 0 ? false : scopes.every((scope) => uniqueScopes.has(scope));
}

/**
 * Check to see if the current user roles contains the chosen scope
 * @param roles Array of scopes belonging to the current user
 * @param scope Single scope to check
 * @returns boolean
 */
export function hasScope(roles: string[], scope: string): boolean {
  return hasAnyScope(roles, [scope]);
}

/**
 * Helper class for determining the roles of a token.
 */
export class AuthScopes implements AuthScopesInterface {
  scopes: string[];

  constructor(tokenParsed?: Pick<DecodedJwt, 'scope'>) {
    this.scopes = tokenParsed?.scope?.split(' ') ?? [];
  }

  hasScope(scope: string): boolean {
    return hasScope(this.scopes, scope);
  }

  hasAllScopes(scopes: string[]): boolean {
    return hasAllScopes(this.scopes, scopes);
  }

  hasAnyScope(scopes: string[]): boolean {
    return hasAnyScope(this.scopes, scopes);
  }
}
