import Fuse from 'fuse.js';
import memoize from 'lodash/memoize';

export function fuzzySearch<T>(data: T[], searchTerm: string, options: Partial<Fuse.IFuseOptions<T>>): T[] {
  const searcher = memoize(createSearchFn);

  if (searchTerm === '') {
    return data;
  }

  const searchResults = searcher(data, { ...DEFAULT_FUZZY_SEARCH_OPTIONS, ...options }).search(searchTerm);

  return searchResults.map((el) => el.item);
}

function createSearchFn<T>(data: T[], options: Fuse.IFuseOptions<T>): Fuse<T> {
  return new Fuse(data, { ...DEFAULT_FUZZY_SEARCH_OPTIONS, ...options });
}

export const DEFAULT_FUZZY_SEARCH_OPTIONS = {
  threshold: 0.1,
  ignoreLocation: true,
  fieldNormWeight: 1,
};
