import type { ConfigInterface, NextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useNextApiSubscription } from '@newfront-insurance/next-api-swr';
import { useProvider } from '@newfront-insurance/react-provision';

import { routes } from '../../bff/routes';
import type { Employee } from '../../bff/types';
import { LayoutConfigProvider } from '../config';

export function useAccountManagers(config?: ConfigInterface): NextApiSubscription<Employee[] | null> {
  const { apiBasePath: basePath } = useProvider(LayoutConfigProvider);

  return useNextApiSubscription<Employee[] | null>(routes.getAccountManagers.getUrl({ basePath }), config);
}
