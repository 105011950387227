import { CalendarDate } from '@newfront-insurance/string-formatters';

import CalendarHeader from './components/header';
import CalendarMonth from './components/month';
import { useCalendar } from './hooks/calendar';
import { Padding } from '../padding';

interface Props {
  onChange: (date?: CalendarDate) => unknown;
  value?: CalendarDate;
  validate?: (date: CalendarDate) => boolean;
  maxDate?: CalendarDate;
  minDate?: CalendarDate;
}

export function CalendarDatePicker(props: Props): JSX.Element {
  const { onChange, value, validate, maxDate, minDate } = props;
  const maxYear = maxDate?.getYear();
  const minYear = minDate?.getYear();
  const today = CalendarDate.today();

  const calendar = useCalendar({
    initialVisibleMonth: value ? value.getMonth() : today.getMonth(),
    initialVisibleYear: value ? value.getYear() : today.getYear(),
  });

  return (
    <div style={{ width: 224 }}>
      <Padding bottom={8}>
        <CalendarHeader calendarHook={calendar} minYear={minYear} maxYear={maxYear} />
      </Padding>
      <CalendarMonth calendarHook={calendar} onClick={onChange} validate={validate} selectedDate={value} />
    </div>
  );
}
