/* eslint-disable @typescript-eslint/naming-convention */
import { forwardRef } from 'react';

import { colors } from '../../theme/colors';
import type { AvatarProps } from '../avatar';
import { Avatar } from '../avatar';

export enum EmployeeAvatarType {
  AccountManager = 'AM',
  CustomerService = 'AC',
  Producer = 'PD',
  WC_CONSULTANT = 'WCC',
  RISK_CONTROL_CONSULTANT = 'RCC',
  BI_CLAIMS_CONSULTANT = 'BCC',
}

export interface ServiceTeamMember {
  imageUrl?: string;
  type: EmployeeAvatarType;
  name: string;
}

const getEmployeeColor = (employeeType?: EmployeeAvatarType): string | undefined => {
  if (employeeType === EmployeeAvatarType.AccountManager) {
    return colors.brand[400];
  }
  if (employeeType === EmployeeAvatarType.Producer) {
    return colors.steel[500];
  }
  if (employeeType === EmployeeAvatarType.CustomerService) {
    return colors.steel[400];
  }
  return undefined;
};

interface Props extends AvatarProps {
  employeeType?: EmployeeAvatarType;
}

export const EmployeeAvatar = forwardRef<HTMLDivElement, Props>((props, ref): JSX.Element => {
  const { employeeType, sizeInPixels, disabled, imageUrl, initials, isActive, onClick } = props;
  return (
    <Avatar
      sizeInPixels={sizeInPixels}
      disabled={disabled}
      imageUrl={imageUrl}
      initials={initials}
      isActive={isActive}
      onClick={onClick}
      badgeColor={getEmployeeColor(employeeType)}
      badgeText={employeeType}
      ref={ref}
    />
  );
});
