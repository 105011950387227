/* eslint-disable @typescript-eslint/no-explicit-any */

import { isNotNil } from '@newfront-insurance/core';
import { Text } from '@newfront-insurance/core-ui';
import { InputType } from '@newfront-insurance/dsl-schema-api';
import find from 'lodash/find';
// import type { SchemaFieldType } fr¨om 'types';

import type { ValueProps, CustomValue } from './registry';
import { customValueComponentsRegistry } from './registry';
import { getStringifiedFieldValue } from '../utils/getStringifiedFieldValue';

export function Value({
  name,
  value,
  field,
  accountUuid,
  size = 'regular',
  color = 'dark',
  weight = 300,
}: ValueProps): JSX.Element {
  const testId = `Value-${name}`;
  let displayValue;

  const { inputType: type, inputMetadata: metadata } = field;

  switch (type) {
    // multi select is an exception for now because of the way it's displayed
    // we probably want to change it to display inline, comma separated, but for now it should be fine.
    case InputType.MULTI_SELECT: {
      return (
        <div className="flex flex-col gap-0.5">
          {value?.map((item: string) => {
            const option = find(metadata?.options, { value: item });
            const key = `${testId}-${item}`;
            return (
              <Text
                size={size}
                color={color}
                weight={weight}
                testId={key}
                key={key}
                title={`${option?.label} ${option?.description ?? ''}`}
              >
                {`${option?.label} ${option?.description ?? ''}`}
              </Text>
            );
          }) || (
            <Text size={size} color={color} weight={weight}>
              -
            </Text>
          )}
        </div>
      );
    }

    default: {
      // Check if there is a custom component for the type and use it if that is the case
      const CustomComponent = customValueComponentsRegistry[type]?.component;

      if (CustomComponent) {
        return (
          <CustomComponent
            name={name}
            value={value}
            field={field}
            size={size}
            color={color}
            weight={weight}
            accountUuid={accountUuid}
          />
        );
      }

      // fallback
      displayValue = getStringifiedFieldValue(value, field);

      break;
    }
  }

  return (
    <Text prewrap size={size} color={color} weight={weight} testId={testId} title={displayValue || '-'}>
      {displayValue || '-'}
    </Text>
  );
}

/**
 * Allows to extend Value by adding new inputType handlers
 * @param inputType
 * @param customValue
 */
Value.registerComponent = (
  inputType: string,
  customValue: CustomValue<any>,
  customGetStringifiedFieldValue?: (value: any, field?: any) => string | null,
) => {
  const previouslyRegisteredComponent = customValueComponentsRegistry[inputType];

  if (isNotNil(previouslyRegisteredComponent)) {
    // eslint-disable-next-line no-console
    console.warn(`Value.registerComponent for type "${inputType}" override. It was already set.`);
  }

  customValueComponentsRegistry[inputType] = {
    component: customValue,
    getStringifiedFieldValue: customGetStringifiedFieldValue,
  };
};
