import * as React from 'react';
import type { StyledComponentProps } from 'styled-components';
import styled from 'styled-components';

import { fontFamilies } from '../../theme';
import { colors } from '../../theme/colors';
import { CircleSpinnerInline } from '../spinner';

type StyledProps = StyledComponentProps<'button', object, object, never>;

interface Props extends StyledProps {
  showing: boolean;
  isLoading?: boolean;
  onClick: (mouseEvent?: React.MouseEvent<HTMLButtonElement>) => unknown;
  testId?: string;
}

export default function ToggleDetailsButton({ showing, isLoading = false, onClick, testId }: Props): JSX.Element {
  return (
    <StyledExpandButton type="button" onClick={onClick} loading={isLoading} data-testid={testId}>
      {showing ? 'Hide details' : 'Show details'}{' '}
      {!isLoading && (
        <StyledButtonIconWrapper rotate={showing ? 0 : 180}>
          <Icon />
        </StyledButtonIconWrapper>
      )}
      {isLoading && (
        <LoadingSpinnerWrapper>
          <CircleSpinnerInline />
        </LoadingSpinnerWrapper>
      )}
    </StyledExpandButton>
  );
}

const LoadingSpinnerWrapper = styled.div`
  margin-left: 4px;
`;

function Icon(): JSX.Element {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M0 5.5L5 0.5L10 5.5H0Z" fill="currentColor" />
    </svg>
  );
}

const StyledExpandButton = styled.button<{ loading: boolean }>`
  height: 24px;
  outline: 0;
  border: 0;
  background: none;
  font-size: 14px;
  box-sizing: border-box;
  color: ${colors.steel[400]};
  padding: 2px 8px;
  margin-left: -8px;
  border-radius: 4px;
  display: inline-flex;
  line-height: 14px;
  align-items: center;
  font-family: ${fontFamilies.body};

  ${({ loading }) =>
    !loading
      ? `&:hover {
      background-color: ${colors.steel[100]};
    }`
      : `pointer-events: none;`}
`;

const StyledButtonIconWrapper = styled.div(
  ({ rotate }: { rotate: number }) => `
  margin-left: 8px;
  outline: 0;
  display: flex;
  align-items: center;
  transform: rotate(${rotate}deg)
  `,
);
