export enum ResultType {
  BAD_REQUEST = 'BAD_REQUEST',
  NOT_FOUND = 'NOT_FOUND',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  SERVICE_ERROR = 'SERVICE_ERROR',
  DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type Error = {
  status: number;
  message: string;
};

export interface BadRequestResult {
  type: ResultType.BAD_REQUEST;
  message: string;
  originalError?: Error;
}

export interface NotFoundResult {
  type: ResultType.NOT_FOUND;
  message: string;
  originalError?: Error;
}

export interface NotAuthorizedResult {
  type: ResultType.NOT_AUTHORIZED;
  message: string;
  originalError?: Error;
}

export interface ServiceErrorResult {
  type: ResultType.SERVICE_ERROR;
  message: string;
  originalError?: Error;
}

export interface DefaultErrorResult {
  type: ResultType.DEFAULT_ERROR;
  message: string;
  originalError?: Error;
}

type Err = BadRequestResult | NotFoundResult | NotAuthorizedResult | ServiceErrorResult | DefaultErrorResult;

export type SuccessfulResult<T> = T extends Err ? never : T;
